import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  TableRow,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { ArrowBack } from "@material-ui/icons";
import Chip from "@material-ui/core/Chip";
import clsx from "clsx";
import SnackBar from "../../../components/SnackBar";
import {
  DateTimePicker,
  DateRangePicker,
  LocalizationProvider,
} from "@material-ui/pickers";
import momentAdapter from "@material-ui/pickers/adapter/moment";
import withStyles from "@material-ui/core/styles/withStyles";
import EditIcon from "@material-ui/icons/Edit";
import { api_get, api_put } from "../../../utils/Api";
import moment from "moment";
import Label from "components/Label";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      maxWidth: 360,
      display: "flex",
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  heading: {
    fontWeight: "400",
    marginLeft: theme.spacing(2),
  },
  listItem: {
    padding: 0,
  },
  pagination: {
    flexGrow: 1,
  },
}));

const EventOnErrorForm = (props) => {
  const { id } = useParams();
  const { className, edit, meta, ...rest } = props;
  const [open, setOpen] = useState(false);
  const [message, setAlertMessage] = useState("");

  const [severity, setAlertSeverity] = useState("success");

  const [teacherEvents, setTeacherEvents] = useState([]);
  const [getEventId, setGetEventId] = useState("");
  const [isEditClicked, setIsEditCliked] = useState(false);

  const startDateOfCurrentMonth = moment().startOf("month");
  const endDateOfCurrentMonth = moment().endOf("month");

  const [filterByDate, setFilterByDate] = useState([
    startDateOfCurrentMonth,
    endDateOfCurrentMonth,
  ]);

  useEffect(() => {
    getData();
  }, [filterByDate]);

  const getData = async () => {
    api_get(
      `events/instructor/${id}?startDate=${moment(filterByDate[0]).format(
        "YYYY-MM-DD"
      )}&endDate=${moment(filterByDate[1]).format("YYYY-MM-DD")}`
    ).then((data) => {
      setTeacherEvents(data.payload);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function (key, index) {
      if (index == 0) {
        setAlertSeverity("error");
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };

  const [startDate, setStartDate] = useState(null);
  const startDateChange = (value) => {
    setStartDate(value);
  };
  const tableCell = [
    "id",
    "Title",
    "Division",
    "Created At",
    "Start Date",
    "Correction",
  ];

  const eventOnErrorValidation = (value) => {
    if (!value) {
      return { success: false, message: "Start Date is missing" };
    }
  };
  const handleSubmit = () => {
    const validation = eventOnErrorValidation(startDate);
    if (validation) {
      setOpen(true);
      setAlertMessage(validation.message);
      setAlertSeverity("warning");
    } else {
      api_put(`events/${getEventId?.id}`, {
        startedAt: moment(startDate).format("YYYY-MM-DD HH:mm"),
      })
        .then((result) => {
          if (result?.errors) {
            setAlertSeverity("error");
            showErrorMessage(result?.errors);
          } else {
            setAlertSeverity("success");
            setAlertMessage(result.message);
            setOpen(true);
            setStartDate(null);
            setIsEditCliked(false);
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  };
  const classes = useStyles();

  const StartField = withStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRight: 0,
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
        },
        "&:hover fieldset": {
          borderColor: "#ccc",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#ccc",
        },
      },
    },
  })(TextField);

  const EndField = withStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderLeft: 0,
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
        },
        "&:hover fieldset": {
          borderColor: "#ccc",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#ccc",
        },
      },
    },
  })(TextField);

  const handleChangeFilterByDate = (value) => {
    setFilterByDate(value);
  };
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <>
      {isEditClicked && (
        <div className={classes.row}>
          <IconButton onClick={() => setIsEditCliked(false)}>
            <ArrowBack fontSize="inherit" />
          </IconButton>
          <Typography style={{ fontSize: "17px" }} className={classes.heading}>
            Event On Errors Start Date :
          </Typography>
        </div>
      )}

      {isEditClicked ? (
        <>
          <Grid
            item
            md={7}
            xs={12}
            style={{
              display: "flex",
              flexDirection: isXs ? "column" : "row",
              width: "100%",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <LocalizationProvider dateAdapter={momentAdapter}>
              <DateTimePicker
                renderInput={(props) => (
                  <TextField variant="outlined" fullWidth {...props} />
                )}
                value={startDate}
                autoOk
                ampm={false}
                label="Start date"
                onChange={startDateChange}
                name="startDate"
              />
            </LocalizationProvider>
            <Label>
              This Event has startDate&nbsp;:{" "}
              <strong>
                {moment(getEventId?.start_date).format("YYYY-MM-DD HH:mm")}
              </strong>
            </Label>
          </Grid>
          <Grid item md={3} xs={3} style={{ marginTop: "10px" }}>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Save details
            </Button>
          </Grid>
          <SnackBar
            open={open}
            message={message}
            severity={severity}
            handleClose={handleClose}
          />
        </>
      ) : (
        <Grid container spacing={3}>
          <Grid item sx={{ mb: 1 }}>
            <LocalizationProvider dateAdapter={momentAdapter}>
              <DateRangePicker
                startText={"Filter Events On Error With "}
                endText=""
                value={filterByDate}
                onChange={handleChangeFilterByDate}
                onError={() => null}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <StartField
                      {...startProps}
                      size="small"
                      FormHelperTextProps={{ style: { display: "none" } }}
                    />
                    <EndField
                      {...endProps}
                      size="small"
                      FormHelperTextProps={{ style: { display: "none" } }}
                    />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Card {...rest} className={clsx(classes.root, className)}>
            {teacherEvents?.length ? (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {tableCell.map((tableCell) => {
                        return <TableCell>{tableCell}</TableCell>;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {teacherEvents.map((event) => (
                      <TableRow key={event?.id}>
                        <TableCell component="th" scope="row">
                          {event?.id}
                        </TableCell>
                        <TableCell>{event?.content?.name}</TableCell>
                        <TableCell>
                          <List>
                            {event?.subject_events?.map((item) => (
                              <ListItem
                                key={item.id}
                                className={classes.listItem}
                              >
                                <Chip
                                  label={item?.subject?.division?.name}
                                  style={{
                                    backgroundColor: "#FD9D59",
                                    color: "white",
                                  }}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </TableCell>
                        <TableCell>
                          <Chip
                            size="small"
                            color={"primary"}
                            label={moment(event?.created_at)
                              .format("DD/MM/YYYY")
                              .toString()}
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            size="small"
                            color={"primary"}
                            label={moment(event?.start_date)
                              .format("DD/MM/YYYY HH:mm")
                              .toString()}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            className={classes.signOutButton}
                            color="inherit"
                            onClick={() => {
                              setGetEventId(event);
                              setIsEditCliked(true);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  This Teacher does not have any events
                </Typography>
              </CardContent>
            )}
          </Card>
        </Grid>
      )}
    </>
  );
};

export default EventOnErrorForm;
