// @flow
import React from 'react';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import Label from '../../../components/Label';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));
type Props = {
  contentEvents: {
    id: null,
    amount: 0,
    subjects: [],
    groups: [],
    webinar_account: null,
    expired_at: null,
    active: '1',

  },
  className: string,
}
const BasicsInfo = (props: Props): React$Element<any> => {
  const editInfo = (id) => {
    const url = `/finance/affiliations/edit/${id}`;
    window.open(url, '_blank');
  };
  const classes = useStyles();
  const {className, contentEvents} = props;

  return (
      <Card
          className={clsx(classes.root, className)}
      >
        <CardHeader title="Content Event info"/>
        <Divider/>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                ID
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {contentEvents.id}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Name
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {contentEvents.content?.name}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Subjects
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {contentEvents.subject_events?.map(subject => {
                    return (
                        <div>
                          <Label>{subject.subject.division.name} </Label>  <Label color='primary'>{subject.subject.name} </Label>
                        </div>
                    );
                  }) || 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Groups
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {contentEvents?.content?.group_contents?.map(group => {
                    return (
                        <div>
                          <Label>{group.groups.name} </Label>
                        </div>
                    );
                  }) || 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Webinar Account
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {contentEvents?.webinar_account?.email}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Start date
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {contentEvents.start_date}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                End date
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {contentEvents.end_date}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Active
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  <Label
                      color={contentEvents.active == '1' ? 'success' : 'error'}>
                    {contentEvents.active == '1' ? 'TRUE' : 'FALSE'}
                  </Label>
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
  );
};

BasicsInfo.propTypes = {
  className: PropTypes.string,
  affiliation: PropTypes.object.isRequired,
};

export default BasicsInfo;
