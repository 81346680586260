import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Grid,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  DialogTitle,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LoadingScreen from "../LoadingScreen";
import useStyles, { colors } from "./modalStyle";
import useCell from "components/CellStyle/cellStyle";

const TableTitle = ({ title, value }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classes.dialogContentHeader}
    >
      <Paper className={classes.point_title} elevation={0}>
        {title}
      </Paper>
      <Paper className={classes.point_info}>
        <div
          style={{
            color: value === 0 ? "#dddddd" : "#c20000",
          }}
        >
          {`${value === 0 ? "" : "-"}${value} pts`}
        </div>
      </Paper>
    </Grid>
  );
};

const MonthDetailsDefault = ({ id, open, handleClose }) => {
  const classes = useStyles();
  const cellClasses = useCell();
  const dispatch = useDispatch();
  const { data, title, status} = useSelector(
    (state) => state.incomes.MonthDetailsDefaults
  );

  const handleActiveValue = (Tab) => {
    let somme=0;
    const found = Tab.filter((el) => el.active);
    found.map(s=>{somme+=s.points}) 
    return somme ? somme : 0;
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={(e, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <div className={classes.label}>{title}</div>
          <div className={classes.close_btn} onClick={() => handleClose(id)}>
            X
          </div>
        </div>
      </DialogTitle>
      {status === "failed" && (
        <>
          <DialogContent className={classes.dialogContent}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.dialogContentHeader}
            >
              <Paper elevation={0}>
                <Alert severity="error">
                  Un error est servenu: Merci de contacter administrateur
                </Alert>
              </Paper>
            </Grid>
          </DialogContent>
        </>
      )}
      {status === "idle" && <div>Pas de contenu </div>}
      {status === "loading" && <LoadingScreen />}
      {status === "succeeded" && (
        <>
          <DialogContent className={classes.dialogContent}>
            <Grid container spacing={3}>
              {data?.map(
                (defaultTable, index) =>
                  defaultTable.details.length > 0 && (
                    <Grid item md={6} xs={12} key={`default${index}`}>
                      <TableTitle
                        title={defaultTable.title}
                        value={handleActiveValue(data[index].details)}
                      />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>{"Pénality"}</TableCell>
                              {defaultTable.type.toLowerCase() !=='none'&& (
                                <TableCell>
                                  {`Nombre(${defaultTable.type})`}{" "}
                                </TableCell>
                              )}
                              <TableCell align="center">
                                Points Reduits
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {defaultTable &&
                              defaultTable?.details?.map((row, index2) => (
                                <TableRow key={index2}>
                                  {row.penalty && (
                                    <TableCell
                                      align="left"
                                      className={
                                        row.active
                                          ? `${cellClasses.cell} ${cellClasses.redBg}`
                                          : cellClasses.cell
                                      }
                                    >
                                      {row.penalty}
                                    </TableCell>
                                  )}

                                  {row?.number && defaultTable.type.toLowerCase() !== "none" && (
                                    <TableCell
                                      align="left"
                                      className={
                                        row.active
                                          ? `${cellClasses.cell} ${cellClasses.redBg}`
                                          : cellClasses.cell
                                      }
                                    >
                                      {defaultTable.isInterval
                                        ? `${
                                            data[index].details[index2-1]?.number ? `${
                                              data[index]?.details[
                                                index2 - 1
                                              ]?.number
                                            }  `
                                          : 0
                                          } \u2794  ${
                                            data[index]?.details[index2 ]
                                              ?.number
                                             
                                          } `
                                        : row.number}
                                    </TableCell>
                                  )}

                                  {row.points && (
                                    <TableCell
                                      align="center"
                                      className={
                                        row.active
                                          ? `${cellClasses.cell} ${cellClasses.redBg}`
                                          : cellClasses.cell
                                      }
                                    >
                                      {`${row.points} pts`}
                                    </TableCell>
                                  )}
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )
              )}
            </Grid>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default MonthDetailsDefault;
