// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { api_get, api_post, api_put } from "../../../utils/Api";
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import optionsParser from '../../../helpers/optionsParser';
import mapStateToRequest from '../../../helpers/mapStateToRequest';

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
}));

type Props = {
  edit: boolean,
  className: string
}
const Form = (props: Props): React$Element<any> => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    title: '',
    link: '',
  });
  const [message, setAlertMessage]=useState('');
  const [severity, setAlertSeverity]=useState('success');


  useEffect(() => {
    if (edit) {
      api_get(`help-videos/${id}`).then((result)=>{
        const payload=result.payload;
        setValues({
          title: payload.title,
          link: payload.link,
        });
      });
    }
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const showErrorMessage=(errors)=>{
    Object.keys(errors).map(function(key, index) {
      if (index==0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };

  const formValidation = (values) => {
    if (!values?.title) {
      return { success: false, 'message': 'Title is missing' };
    }
    if (!values?.link) {
      return { success: false, 'message': 'Link is missing' };
    }
  };

  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
      const requestValues = mapStateToRequest(values);
      if (edit) {
        api_put(`help-videos/${id}`, requestValues).then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            setAlertSeverity('success');
            setAlertMessage('Help video updated successfully');
            setOpen(true);
            window.location.href = '/settings/help-videos';
          }
        });
      } else {
        api_post(`help-videos`, requestValues).then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            setAlertSeverity('success');
            setAlertMessage('Help video  created successfully');
            setOpen(true);
            window.location.href = '/settings/help-videos';
          }
        }).catch((error) => {
          throw new Error(error);
        });
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
      <div>
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
          <form
              autoComplete="off"
              noValidate
          >
            <CardHeader
                subheader="Help Videos form"
                //title="Help Videos"
            />
            <Divider/>
            <CardContent>
              <Grid
                  container
                  spacing={3}
              >
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                  <TextField
                      fullWidth
                      label="Title"
                      name="title"
                      onChange={handleChange}
                      required
                      value={values.title || ''}
                      variant="outlined"
                  />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                  <TextField
                      fullWidth
                      label="Link"
                      name="link"
                      onChange={handleChange}
                      required
                      value={values.link || ''}
                      variant="outlined"
                  />
                </Grid>
              </Grid>

            </CardContent>
            <Divider/>
            <CardActions>
              <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}>
                Save details
              </Button>
            </CardActions>
          </form>
        </Card>
        <SnackBar open={open} message={message} severity={severity} handleClose={handleClose}/>
      </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
};

export default Form;
