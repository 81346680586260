// @flow
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import Label from '../../../components/Label';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));
type Props = {
  code: {
    id: null,
    amount: 0,
    reference: '',
    payment_method: null,
    status:  'pending' | 'ignored' | 'approuved' | 'active',
    office: null,
    user: null,
  },
  className: string,
}
const BasicsInfo = (props: Props): React$Element<any> => {
  const classes = useStyles();
  const {className, code} = props;

  return (
      <Card
          className={clsx(classes.root, className)}
      >
        <CardHeader title="Code Info"/>
        <Divider/>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                ID
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {code.id}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Amount
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {code.amount}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Reference
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {code.reference}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Payment Method
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {code.payment_method?.name || 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Status
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  <Label color={code.status === 'approuved' ?
                      'success' :
                      code.status === 'ignored' ? 'error' : 'secondary'}>
                    {code.status}
                  </Label>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Office
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {code.office?.name || 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                User
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {code.user?.email || 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {/* <Box*/}
        {/*    p={1}*/}
        {/*    display="flex"*/}
        {/*    flexDirection="column"*/}
        {/*    alignItems="flex-start"*/}
        {/* >*/}
        {/*  <Button startIcon={<PersonIcon/>}*/}
        {/*          onClick={(event) => switchStudent(event, code.id)}>*/}
        {/*    Login as User*/}
        {/*  </Button>*/}
        {/* </Box>*/}
      </Card>
  );
};

BasicsInfo.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired,
};

export default BasicsInfo;
