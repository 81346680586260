// @flow
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
 
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import { AsyncPaginate } from "react-select-async-paginate";
import theme from "../../../theme";
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import momentAdapter from "@material-ui/pickers/adapter/moment";
import Checkbox from "@material-ui/core/Checkbox";
import Moment from "moment";
import { api_get, api_post, api_put } from "../../../utils/Api";
import LoadingApi from 'components/LoadingApi/LoadingApi';
import {getOffers, getGroups} from "../../../helpers/methods";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    overflow: "visible",
  },
 
  mtop: {
    marginTop: 24,
  },
  mtop0: {
    marginTop: 0,
  },
  content: {
    padding: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
}));

const Form = (props) => {
  const { id } = useParams();
  const { className, edit, ...rest } = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    active: false,
    startDate: Moment().format("YYYY-MM-DD hh:mm"),
    endDate: Moment().format("YYYY-MM-DD hh:mm"),
    group: null,
    offer: null,
  });
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("success");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) {
      setLoading(true)
      api_get(`offer-group-links/${id}`).then((result) => {
        setLoading(false)
        const data = result.payload;
        setValues({
          offer: data.offer,
          group: data.user_group,
          active: data.active,

          startDate: data.start_date || "",
          endDate: data.end_date || "",
        });
      });
    }
  }, []);

  const startDateChange = (value) => {
    if (value) {
      setValues({ ...values, startDate: value.format("YYYY-MM-DD hh:mm") });
    }
  };
  const endDateChange = (value) => {
    if (value) {
      setValues({ ...values, endDate: value.format("YYYY-MM-DD hh:mm") });
    }
  };
  const handleCheckChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked,
    });
  };

  const offerChange = (value) => {
    setValues({ ...values, offer: value });
  };

  const userGroupChange = (value) => {
    setValues({ ...values, group: value });
  };

  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity("error");
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const showValidationErrorMessage = (message) => {
    setAlertSeverity("error");
    setAlertMessage(message);
    setOpen(true);
  };
  const showSucessMessage = (message) => {
    setAlertSeverity("success");
    setAlertMessage(message);
    setOpen(true);
  };
  const formValidation = (values) => {
    if (!values?.offer) {
      return { success: false, message: "Offer is missing" };
    }
    if (!values?.group) {
      return { success: false, message: "Group is missing" };
    }
  };
  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      showValidationErrorMessage(validation.message);
    } else {
      const requestValues = mapStateToRequest(values);
      setLoading(true);
      requestValues.active = requestValues.active == 0 ? false : true;
      if (edit) {
        api_put(`offer-group-links/${id}`, requestValues).then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            showSucessMessage("Offer groups updated successfully");
            setLoading(false);
            window.location.href = "/offers/offer-group-links";
          }
        });
      } else {
        api_post(`offer-group-links`, requestValues)
          .then((result) => {
            if (result.errors) {
              showErrorMessage(result.errors);
            } else {
              showSucessMessage("Offer groups created successfully");
              setLoading(false);
              window.location.href = "/offers/offer-group-links";
            }
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Card className={classes.root}>
              <CardContent>
                <AsyncPaginate
                  loadOptions={getOffers}
                  onChange={offerChange}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  value={values.offer}
                  placeholder="Offers"
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      minHeight: "53px",
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: "#000",
                      fontSize: theme.typography.fontSize,
                      fontFamily: theme.typography.fontFamily,
                    }),
                  }}
                  menuPortalTarget={document.querySelector("body")}
                />
                <Box mt={2} mb={1}>
                  <AsyncPaginate
                    loadOptions={getGroups}
                    onChange={userGroupChange}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={values.group}
                    placeholder="User group"
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        minHeight: "53px",
                      }),
                      placeholder: (base) => ({
                        ...base,
                        color: "#000",
                        fontSize: theme.typography.fontSize,
                        fontFamily: theme.typography.fontFamily,
                      }),
                    }}
                    menuPortalTarget={document.querySelector("body")}
                  />
                </Box>
              </CardContent>
            </Card>
            <Box mt={3}>
              <Card>
                <CardHeader title="Dates" />
                <Divider />
                <CardContent>
                  <Box mt={0} mb={1}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6}>
                        <LocalizationProvider dateAdapter={momentAdapter}>
                          <DatePicker
                            label="Start Date"
                            name="start_date"
                            inputFormat="DD/MM/yyyy"
                            inputVariant="outlined"
                            value={values.startDate}
                            onChange={startDateChange}
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                fullWidth
                                variant="outlined"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <LocalizationProvider dateAdapter={momentAdapter}>
                          <DatePicker
                            label="End Date"
                            name="end_date"
                            inputFormat="DD/MM/yyyy"
                            inputVariant="outlined"
                            value={values.endDate}
                            onChange={endDateChange}
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                fullWidth
                                variant="outlined"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box mt={0}>
              <Card className={classes.root}>
                <CardHeader title="Status" />
                <Divider className={classes.mtop0} />
                <CardContent className={classes.mtop0}>
                  <Box mt={0}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.active}
                          onChange={handleCheckChange}
                          value={values.active}
                          name="active"
                          size="small"
                        />
                      }
                      label="Active ?"
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
        <Box mt={0}>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Save details
          </Button>
        </Box>
      </form>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
      <LoadingApi open={loading} onClick={handleCloseLoading}/>
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,
};

export default Form;
