import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import Label from '../../../components/Label';

const useStyles = makeStyles(() => ({
  root: {},
}));

const SlicesInfo = ({className, slices, ...rest}) => {
  const classes = useStyles();
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >

      <CardHeader
        title="Payment Agreement slices"
      />
      <Divider />
      <Box >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Slice</TableCell>
              <TableCell>Amounts</TableCell>
              <TableCell>Start date</TableCell>
              <TableCell>End date</TableCell>
              <TableCell>Paid</TableCell>
              <TableCell>Check</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slices?.length ? (slices.map((slice, index) => (
              <TableRow key={slice.id}>
                <TableCell>
                  {index}
                </TableCell>
                <TableCell>
                  {slice?.amount}
                </TableCell>
                <TableCell>
                  {moment(slice.start_date).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell>
                  {moment(slice.validity_date).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell>
                  <Label color={slice.paid === true ? 'success' : 'error'}>
                    {slice.paid === true ? 'Paid' : 'Unpaid'}
                  </Label>
                </TableCell>
                <TableCell>
                  <Label >
                    {slice?.is_check === true ? 'True' : 'False'}
                  </Label>
                </TableCell>
              </TableRow>
            ))
              ) :(
                  <TableRow >
                    <TableCell>
                      He does not have any slice
                    </TableCell>
                  </TableRow>
              )}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

SlicesInfo.propTypes = {
  className: PropTypes.string,
  slices: PropTypes.object.isRequired,
};

export default SlicesInfo;
