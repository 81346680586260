import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', width: '5%', label: 'Id', show: true, sortable: true},
  {accessor: 'content', width: '15%', label: 'Content', show: true, type: 'contentLink',},
  {accessor: 'user', type: 'concatString', show: true, label: 'User'},
  {accessor: 'description', show: true, label: 'Description', width: '25%', type: 'fieldValue'},
  {accessor: 'report_reason.reason', show: true, label: 'Reason', type: 'fieldValue'},
  {accessor: 'status', name: 'status', label: 'Status', width: '6%', show: true, sortable: true, type: 'statusColor'},
  {accessor: 'created_at', name: 'createdAt', label: 'createdAt', width: '13%', type : 'date', show: true, sortable: true},

];
const fieldSearchable = [
  'id',
];
const fieldFilterable = [
  {name: 'status', label: 'Status', type: 'dropdown', options: ['Pending', 'In progress', 'Resolved', 'Rejected'],},
  {name:'reportReason.id',label:'Reason',type:'videoReportReason'}
];
const VideoReportList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`video-reports`}
          exportUrl={`video-reports/export`}
          pageLink ={'/contents/video-reports'}
          searchMessage={'Search (ID)'}
          baseUrl={`video-reports`}
          removeAdd={true}
          noShow={true}
          noDelete={true}
          noDeleteMany={true}
      />
  );
};
export default VideoReportList;
