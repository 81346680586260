import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'name', label: 'Name',  show: true},
 /* {accessor: 'type', label: 'Type',  show: false},*/
  {accessor: 'scope.name', label: 'Scope',  show: true},
 {
    accessor: 'instructor',
    label: 'Professor',
    //width: '20%',
    show: true,
   type: 'concatString'
  },
 /* {
    accessor: 'datas.video_duration',
    label: 'Professor',
    //width: '20%',
    show: true,
  },*/
  {
    accessor: 'creator_admin.name',
   // type: 'Added By',
    label: 'Added By',
    //width: '10%',
    show: true,
  },

  {accessor: 'deleted_at', type: 'date', label: 'Deleted At', show: true},
];
const fieldSearchable = [
  'id',
  'name',
];
const fieldFilterable = [
  {name: 'instructor.id', label: 'Instructor', type: 'instructorAutocomplete'},
  {name: 'scope.id', label: 'Scope', type: 'scopeAutocomplete'},
  {name: 'chapterContents.chapter.subject.division.id', label: 'Division', type: 'divisionAutocomplete',},
  {name: 'chapterContents.chapter.id', label: 'Chapter', type: 'chapterAutocomplete'},
  {name: 'createdAt', label: 'Created At', type: 'dateRange'},
  {name: 'active', label: 'Active', type: 'boolean'},
  {name: 'isDraft', label: 'Draft', type: 'boolean'},
  {name: 'downloadable', label: 'Downloadable', type: 'boolean'},
  {name: 'rtl', label: 'Rtl', type: 'boolean'},
];
const ContentArchiveList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`contents/archived`}
          noExport={true}
          pageLink ={'/archives/contents'}
          searchMessage={'Search (ID, Name)'}
          baseUrl={`contents/archived`}
          removeAdd={true}
          restore={true}
          noShow={true} noDelete={true} noEdit = {true} noDeleteMany={true}
      />
  );
};

export default ContentArchiveList;
