import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Grid, makeStyles} from '@material-ui/core';
import BasicsInfo from './BasicsInfo';
import SlicesInfo from "../../PaymentAgreement/PaymentAgreementShow/SlicesInfo";
import HistoriesCodeInfo from "./HistoriesCodeInfo";

const useStyles = makeStyles(() => ({
  root: {},
}));

const Details = ({
  code,
  subjects,
                   histories,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={3}
      {...rest}
    >
      <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
      >
        <BasicsInfo code={code} />
      </Grid>
      <Grid
          item
          lg={8}
          md={8}
          xl={8}
          xs={12}
      >
        <HistoriesCodeInfo histories={histories} />
      </Grid>
    </Grid>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  subjects: PropTypes.object.isRequired,
  histories: PropTypes.object.isRequired,

};

export default Details;
