import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '6%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '40%', show: true},
  {accessor: 'created_at', label: 'Created At', width: '27%', show: true, type: 'date'},
  {accessor: 'updated_at', label: 'Updated At', width: '27%', show: true, type: 'date'},
];
const fieldSearchable = [
  'name',
];
const fieldFilterable = [];
const UserTypeList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`user-types`}
          noExport={'true'}
          pageLink ={'/settings/user-types'}
          searchMessage={'Search (Name)'}
          baseUrl={`user-types`}
      />
  );
};

export default UserTypeList;
