// @flow
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import schemaParser from "../../../helpers/schemaParser";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import DivisionDetailForm from "./DivisionDetailForm";
import { AsyncPaginate } from "react-select-async-paginate";
import theme from "../../../theme";
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import Moment from "moment";
import momentAdapter from "@material-ui/pickers/adapter/moment";
import CardActionArea from "@material-ui/core/CardActionArea";
import { api_get, api_post_serialize } from "../../../utils/Api";
import FileDropZone from "../../../components/FileDropZone";
import DescriptionField from "../../../components/DescriptionField";
import ColorPicker from "material-ui-color-picker";
import LoadingApi from "components/LoadingApi/LoadingApi";
import { getDivisions, getGroups } from "../../../helpers/methods";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    overflow: "visible",
  },
  mtop: {
    marginTop: 24,
  },
  mtop0: {
    marginTop: 0,
  },

  content: {
    padding: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
}));
let schema = {};
const getSchema = () => {
  schemaParser("offers").then((data) => {
    schema = data;
  });
};
type Props = {
  edit: boolean,
  className: string,
};
type formState = {
  name: string,
};
const Form = (props: Props): React$Element<any> => {
  const { id } = useParams();
  const { className, edit, ...rest } = props;
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);
  const [formState, setFormState] = useState<formState>({
    isValid: false,
    values: {
      name: "",
      description: undefined,
      color: "",
      type: "",
      price: 0,
      position: 0,
      offerDivisionDetails: {},
      active: true,
      visibleForNoGroup: true,
      offerGroupVisibilities: [],
      groupEventsVisibilities: [],
      //division_id: null,
      startDate: Moment().format("YYYY-MM-DD hh:mm"),
      endDate: Moment().format("YYYY-MM-DD hh:mm"),
      validityEnd: Moment().format("YYYY-MM-DD hh:mm"),
      validityStart: Moment().format("YYYY-MM-DD hh:mm"),
    },
    touched: {},
    errors: {},
  });
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("success");
  //const [divisions, setDivisions] = useState([]);
  const [selectedDivisions, setSelectedDivisions] = useState([]);
  const [openedDivision, setOpenedDivision] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSchema();
    if (edit) {
      setLoading(true);
      api_get(`offers/${id}`).then((result) => {
        setLoading(false);
        const data = result.payload[0];
        const groups = result?.payload[1];
        const offerDivisionDetails = {};
        data.offer_division_details.map((o) => {
          offerDivisionDetails[o?.division?.id] = {
            id: o.id,
            division: o.division.id,
            description: o?.description,
            price: o?.price,
            active: o.active,
            periods: o.periods,
            startDate: o.start_date,
            endDate: o.end_date,
            validityEnd: o.validity_end,
            validityStart: o.validity_start,
            validityMethod: o.validity_method,
            validityDuration: o.validity_duration,
            offerDivisionSubjects: o.offer_division_subjects || [],
            dailyPrice:o.daily_price,
            weeklyPrice:o.weekly_price,
            dailyDigitalVirgoServiceId:o.daily_digital_virgo_service_id,
            weeklyDigitalVirgoServiceId:o.weekly_digital_virgo_service_id,
          };
        });

        const offerDivisions = [];
        data.offer_division_details.map((o) => {
          offerDivisions.push(o?.division);
        });
        setSelectedDivisions(offerDivisions);
        setFormState({
          ...formState,
          values: {
            name: data.name,
            color: data.color,
            active: data.active,
            description: data?.description,
            type: data.type,
            price: data?.price,
            position: data.position,
            startDate: data.start_date,
            endDate: data.end_date,
            visibleForNoGroup: data.visible_for_no_group,
            validityMethod: data.validity_method,
            validityDuration: data.validity_duration,
            validityStart: data.validity_start,
            validityEnd: data.validity_end,
            offerGroupVisibilities: data.offer_group_visibilities,
            offerDivisionDetails: offerDivisionDetails,
            imageFile: data.thumbnail,
            groupEventsVisibilities: groups,
            dailyPrice:data.daily_price,
            weeklyPrice:data.weekly_price,
            dailyDigitalVirgoServiceId:data.daily_digital_virgo_service_id,
            weeklyDigitalVirgoServiceId:data.weekly_digital_virgo_service_id,
          },
        });
      });
    }
  }, []);

  const handleColorChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        color: event,
      },
      touched: {
        ...formState.touched,
        color: true,
      },
    }));
  };
  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const handleApiValidation = (errors) => {
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  };
  const showErrorMessages = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity("error");
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const showErrorMessage = (message) => {
    setAlertSeverity("error");
    setAlertMessage(message);
    setOpen(true);
  };
  const showSucessMessage = (message) => {
    setAlertSeverity("success");
    setAlertMessage(message);
    setOpen(true);
  };
  const formValidation = (values) => {
    if (!values?.name) {
      return { success: false, message: "Name is missing" };
    }
    if (!values?.description) {
      return { success: false, message: "Description is missing" };
    }
    if (!values?.color) {
      return { success: false, message: "Color is missing" };
    }
    if (!values?.type) {
      return { success: false, message: "Type is missing" };
    }
    if (!values?.price && values?.price !== 0 ) {
      return { success: false, message: "Price is missing" };
    }
    if (!values?.validityDuration && values?.validityMethod === "fixed") {
      return { success: false, message: "validity duration is missing" };
    }
    if (!values?.validityMethod) {
      return { success: false, message: "validity method is missing" };
    }
  };
  const handleSubmit = () => {
    const validation = formValidation(formState?.values);
    if (validation) {
      showErrorMessage(validation?.message);
    } else {
      setLoading(true);
      const requestValues = mapStateToRequest(formState?.values, [
        "offerDivisionDetails",
        "offerGroupVisibilities",
        "groupEventsVisibilities",
      ]);
      let newArr = [];
      let newArr2 = [];
      requestValues?.offerGroupVisibilities &&
        requestValues.offerGroupVisibilities.map((option) => {
          newArr.push({ group: option?.group ? option?.group.id : option?.id });
        });
      requestValues.offerGroupVisibilities = newArr;
      requestValues.groupEventsVisibilities = requestValues?.groupEventsVisibilities
        ? requestValues?.groupEventsVisibilities
        : [];
      /* requestValues?.groupEventsVisibilities &&
        requestValues.groupEventsVisibilities.map((option) => {
          newArr2.push({
            group: option?.group ? option?.group?.id : option?.id,
          });
        });*/
      for (const detail in requestValues?.offerDivisionDetails) {
        requestValues.offerDivisionDetails[detail].active =
          requestValues?.offerDivisionDetails[detail].active == true ? 1 : 0;
        requestValues.offerDivisionDetails[
          detail
        ].validityDuration = requestValues?.offerDivisionDetails[detail]
          .validityDuration
          ? requestValues?.offerDivisionDetails[detail].validityDuration
          : "";
        const arrayOption = [];
        const arrySubjectOffer = [];

        if (
          requestValues?.offerDivisionDetails[detail]?.periods instanceof Array
        ) {
          requestValues.offerDivisionDetails[detail].periods.map((period) => {
            arrayOption.push(period["value"]);
            requestValues.offerDivisionDetails[
              detail
            ].periods = arrayOption.toString();
          });
        }
        if (
          requestValues?.offerDivisionDetails[detail]
            ?.offerDivisionSubjects instanceof Array
        ) {
          requestValues.offerDivisionDetails[detail].offerDivisionSubjects.map(
            (offerSubject) => {
              arrySubjectOffer.push({
                price: offerSubject?.price ,
                subject: offerSubject?.subject.id || "",
                nbSessions: parseInt(offerSubject?.nb_sessions) || "",
                description: offerSubject?.description || "",
                active: (offerSubject?.active == true ? 1 : 0) || 0,
              });
            }
          );
        }

        requestValues.offerDivisionDetails[
          detail
        ].offerDivisionSubjects = requestValues.offerDivisionDetails[detail]
          .offerDivisionSubjects
          ? arrySubjectOffer
          : [];
      }

      requestValues.active = requestValues.active == true ? 1 : 0;
      requestValues.visibleForNoGroup =
        requestValues.visibleForNoGroup == true ? 1 : 0;
      if (edit) {
        api_post_serialize(`offers/${id}`, requestValues, true).then(
          (result) => {
            if (result.errors) {
              showErrorMessage(result.errors);
            } else if (result?.code !== 200 && result?.code !== 201) {
              showErrorMessage("Please check values");
              //handleApiValidation(result.message);
              //showErrorMessage(result.message);
            } else {
              showSucessMessage("Updated successfully");
              setLoading(false);
              window.location.href = "/offers/offers";
            }
          }
        );
      } else {
        api_post_serialize(`offers`, requestValues, true)
          .then((result) => {
            if (result?.errors || result?.code !== 200) {
              showErrorMessage("Please check values");
              // handleApiValidation(result.errors);
              //showErrorMessage(result.errors);
            } else {
              showSucessMessage("Created successfully");
              setLoading(false);
            window.location.href = "/offers/offers";
            }
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
  };

  const onFileChange = (files) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        imageFile: files[0],
      },
    }));
  };

  const hasError = (field) =>
    formState?.touched[field] && formState.errors[field] ? true : false;

  const toggleDivisionDetail = (e) => {
    const divisionId = e.target.value;
    const divisionDetail = formState?.values.offerDivisionDetails[divisionId];
    let offerDivisionDetails = formState?.values.offerDivisionDetails;
    if (!divisionDetail) {
      offerDivisionDetails = {
        ...offerDivisionDetails,
        [divisionId]: {
          division: divisionId,
        },
      };
    } else {
      delete offerDivisionDetails[divisionId];
    }

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState?.values,
        offerDivisionDetails: offerDivisionDetails,
      },
    }));
  };
  const handleDivisionExpand = (division) => {
    setOpenedDivision(
      Number(openedDivision) === division.id ? false : division.id
    );
  };

  const liveSessionGroupChange = (options) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        groupEventsVisibilities: options,
      },
    }));
  };

  const visibleGroupChange = (options) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        offerGroupVisibilities: options,
      },
    }));
  };

  const handleDescriptionChange = (value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState?.values,
        description: value?.editor?.getData(),
      },
    }));
  };
  const startDateChange = (value) => {
    if (value) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          startDate: value.format("YYYY-MM-DD hh:mm"),
        },
      }));
    }
  };
  const endDateChange = (value) => {
    if (value) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          endDate: value.format("YYYY-MM-DD hh:mm"),
        },
      }));
    }
  };
  const validityStartChange = (value) => {
    if (value) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          validityStart: value.format("YYYY-MM-DD hh:mm"),
        },
      }));
    }
  };
  const validityEndChange = (value) => {
    if (value) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          validityEnd: value.format("YYYY-MM-DD hh:mm"),
        },
      }));
    }
  };

  const divisionChange = (value) => {
    setSelectedDivisions([...selectedDivisions, value]);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Card className={classes.root}>
              <CardContent>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  required
                  value={formState.values.name || ""}
                  variant="outlined"
                  size="small"
                  helperText={
                    hasError("name") ? formState.errors.name[0] : null
                  }
                />
                <Box mt={2} mb={1}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <ColorPicker
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={formState.values.color || ""}
                      onChange={handleColorChange}
                      name="color"
                      defaultValue="#Color"
                      //disabled="true"
                      variant="outlined"
                    />
                  </FormControl>
                </Box>
                <Box mt={2} mb={1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Description *
                  </Typography>
                </Box>
                <Paper variant="outlined">
                  {edit && formState?.values?.description ? (
                    <DescriptionField
                      values={formState?.values}
                      handleBodyChange={handleDescriptionChange}
                      value={formState?.values?.description}
                    />
                  ) : !edit ? (
                    <DescriptionField
                      values={formState?.values}
                      handleBodyChange={handleDescriptionChange}
                    />
                  ) : formState?.values?.description === null ? (
                    <DescriptionField
                      values={formState?.values}
                      handleBodyChange={handleDescriptionChange}
                    />
                  ) : formState?.values?.description === "" ? (
                    <DescriptionField
                      values={formState?.values}
                      handleBodyChange={handleDescriptionChange}
                    />
                  ) : (
                    ""
                  )}
                </Paper>
              </CardContent>
            </Card>
            <Box mt={3}>
              <Card>
                <CardHeader title="Visibility dates" />
                <Divider />
                <CardContent>
                  <Box mt={0} mb={1}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6}>
                        <LocalizationProvider dateAdapter={momentAdapter}>
                          <DatePicker
                            label="Start date"
                            name="startDate"
                            inputFormat="DD/MM/yyyy"
                            inputVariant="outlined"
                            value={formState?.values?.startDate || ""}
                            onChange={startDateChange}
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                fullWidth
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <LocalizationProvider dateAdapter={momentAdapter}>
                          <DatePicker
                            label="End date"
                            name="endDate"
                            inputFormat="DD/MM/yyyy"
                            inputVariant="outlined"
                            value={formState?.values?.endDate || ""}
                            onChange={endDateChange}
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                fullWidth
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box mt={3}>
              <Card>
                <CardHeader title="Validity dates" />
                <Divider />
                <CardContent>
                  <Box mt={0} mb={1}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6}>
                        <FormControl variant="outlined" fullWidth size="small">
                          <InputLabel id="demo-simple-select-outlined-label">
                            Validity method
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={formState?.values?.validityMethod || ""}
                            onChange={handleChange}
                            label="validity_method"
                            error={hasError("validity_method")}
                            name="validityMethod"
                          >
                            <MenuItem value="fixed">Fixed</MenuItem>
                            <MenuItem value="calculated">Calculated</MenuItem>
                          </Select>
                          {hasError("validityMethod") && (
                            <FormHelperText>
                              {formState.errors.validityMethod[0]}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <TextField
                          fullWidth
                          label="Validity duration"
                          name="validityDuration"
                          onChange={handleChange}
                          required
                          value={formState?.values?.validityDuration || ""}
                          variant="outlined"
                          size="small"
                          helperText={
                            hasError("validityDuration")
                              ? formState.errors.validityDuration[0]
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <LocalizationProvider dateAdapter={momentAdapter}>
                          <DatePicker
                            label="Validity Start Date"
                            name="validityStart"
                            inputFormat="DD/MM/yyyy"
                            inputVariant="outlined"
                            value={formState?.values?.validityStart}
                            onChange={validityStartChange}
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                fullWidth
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <LocalizationProvider dateAdapter={momentAdapter}>
                          <DatePicker
                            label="Validity End Date *"
                            name="validityEnd"
                            inputFormat="DD/MM/yyyy"
                            inputVariant="outlined"
                            value={formState?.values?.validityEnd}
                            onChange={validityEndChange}
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                fullWidth
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box mt={3}>
              <Card>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <CardHeader title="Divisions" />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CardActionArea style={{ margin: "10px -10px" }}>
                      <AsyncPaginate
                        loadOptions={getDivisions}
                        value={formState?.values?.division}
                        onChange={divisionChange}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        placeholder="Division"
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          placeholder: (base) => ({
                            ...base,
                            color: "#000",
                            fontSize: theme.typography.fontSize,
                            fontFamily: theme.typography.fontFamily,
                          }),
                        }}
                        menuPortalTarget={document.querySelector("body")}
                      />
                    </CardActionArea>
                  </Grid>
                </Grid>
                <Divider />
                <CardContent>
                  {selectedDivisions.map((division) => (
                    <Accordion
                      key={division.id}
                      expanded={openedDivision === division.id}
                      onChange={() => handleDivisionExpand(division)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                      >
                        <FormControlLabel
                          aria-label="Acknowledge"
                          onClick={toggleDivisionDetail}
                          control={
                            <Checkbox
                              checked={
                                !!formState.values.offerDivisionDetails[
                                  division.id
                                ]
                              }
                            />
                          }
                          label={division.name}
                          value={division.id}
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        {openedDivision && (
                          <DivisionDetailForm
                            formState={formState}
                            setFormState={setFormState}
                            classes={classes}
                            divisionId={division.id}
                            schema={schema}
                          />
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card className={classes.root}>
              <CardHeader title="Basic" />
              <Divider className={classes.mtop0} />
              <CardContent className={classes.mtop0}>
                <Box mt={0}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={formState?.values?.type || ""}
                      onChange={handleChange}
                      label="Type"
                      name="type"
                      error={hasError("type")}
                    >
                      <MenuItem value="primary">Primary</MenuItem>
                      <MenuItem value="pack">Pack</MenuItem>
                      <MenuItem value="unity">Unit</MenuItem>
                    </Select>
                    {hasError("type") && (
                      <FormHelperText>
                        {formState.errors.type[0]}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box mt={2}>
                  <TextField
                    error={hasError("price")}
                    fullWidth
                    helperText={
                      hasError("price") ? formState.errors.price[0] : null
                    }
                    label="Price"
                    name="price"
                    onChange={handleChange}
                    value={formState.values?.price }
                    variant="outlined"
                  />
                </Box>
                <Box mt={2}>
                  <TextField
                    error={hasError("position")}
                    fullWidth
                    helperText={
                      hasError("position") ? formState.errors.position[0] : null
                    }
                    label="Position"
                    name="position"
                    onChange={handleChange}
                    value={formState.values.position || ""}
                    variant="outlined"
                  />
                </Box>
              </CardContent>
            </Card>
            <Box mt={3}>
              <Card className={classes.root}>
                <CardHeader title="Visibility" />
                <Divider className={classes.mtop0} />
                <CardContent className={classes.mtop0}>
                  <Box mt={0}>
                    <AsyncPaginate
                      loadOptions={getGroups}
                      value={formState?.values?.offerGroupVisibilities}
                      onChange={visibleGroupChange}
                      getOptionLabel={(option) =>
                        option.group ? option.group.name : option.name
                      }
                      getOptionValue={(option) => option}
                      placeholder="Visible for groups"
                      isMulti
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "53px",
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: "#000",
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector("body")}
                    />
                  </Box>
                  <Box mt={2}>
                    <AsyncPaginate
                      loadOptions={getGroups}
                      value={formState?.values?.groupEventsVisibilities}
                      onChange={liveSessionGroupChange}
                      getOptionLabel={(option) =>
                        option.group ? option.group.name : option.name
                      }
                      getOptionValue={(option) => option}
                      placeholder="Live session visible for groups"
                      isMulti
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "53px",
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: "#000",
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector("body")}
                    />
                  </Box>
                  <Box mt={2}></Box>
                </CardContent>
              </Card>
            </Box>
            <Box mt={3}>
              <Card className={classes.root}>
                <CardHeader title="Status" />
                <Divider className={classes.mtop0} />
                <CardContent className={classes.mtop0}>
                  <Box mt={0}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState?.values?.active || ""}
                          onChange={handleChange}
                          value={formState.values.active}
                          name="active"
                          size="small"
                        />
                      }
                      label="Active"
                    />
                  </Box>
                  <Box mt={0}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.visibleForNoGroup}
                          onChange={handleChange}
                          value={formState?.values?.visibleForNoGroup || ""}
                          name="visibleForNoGroup"
                          size="small"
                        />
                      }
                      label="Visible without group"
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box mt={3}>
              <Card className={classes.root}>
                <CardHeader title="Upload Zone" />
                <Divider className={classes.mtop0} />
                <CardContent className={classes.mtop0}>
                  <Box mt={0}>
                    <CardContent>
                      <Box mt={0} mb={0}>
                        <Typography variant="subtitle2" color="textSecondary">
                          Files
                        </Typography>
                      </Box>
                      <FileDropZone
                        values={formState.values}
                        setValues={setFormState}
                        onFileChange={onFileChange}
                        isImage={true}
                        isPdf={false}
                      />
                    </CardContent>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Save details
          </Button>
        </Box>
      </form>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
      <LoadingApi open={loading} onClick={handleCloseLoading} />
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
  loading: PropTypes.bool,
};

export default Form;
