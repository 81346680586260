import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import BooleanFilter from "./Basic/BooleanFilter";
import TextFilter from "./Basic/TextFilter";
import DropdownFilter from "./Basic/DropdownFilter";
import DivisionAutocompleteFilter from "./Autocomplete/DivisionAutocompleteFilter";
import UserGroupAutocompleteFilter from "./Autocomplete/UserGroupAutocompleteFilter";
import UserAutocompleteFilter from "./Autocomplete/UserAutcompleteFilter";
import DateFilter from "./Basic/DateFilter";
import DateRangeFilter from "./Basic/DateRangeFilter";
import NumberRangeFilter from "./Basic/NumberRangeFilter";
import NumberFilter from "./Basic/NumberFilter";
import OfferAutocompleteFilter from "./Autocomplete/OfferAutocompleteFilter";
import AgencyAutocompleteFilter from "./Autocomplete/AgencyAutocompleteFilter";
import OfficeAutocompleteFilter from "./Autocomplete/OfficeAutocompleteFilter";
import AccountAutocompleteFilter from "./Autocomplete/AccountAutocompleteFilter";
import PaymentMethodAutocompleteFilter from "./Autocomplete/PaymentMethodAutocompleteFilter";
import SubjectAutocompleteFilter from "./Autocomplete/SubjectAutocompleteFilter";
import WebinarAccountAutocompleteFilter from "./Autocomplete/WebinarAccountAutcompleteFilter";
import InstructorAutocompleteFilter from "./Autocomplete/InstructorAutocompleteFilter";
import ContentTypeAutocompleteFilter from "./Autocomplete/ContentTypeAutocompleteFilter";
import ScopeAutocompleteFilter from "./Autocomplete/ScopeAutocompleteFilter";
import ChapterAutocompleteFilter from "./Autocomplete/ChapterAutocompleteFilter";
import StateAutocompleteFilter from "./Autocomplete/StateAutocompleteFilter";
import DelegationAutocompleteFilter from "./Autocomplete/DelegationAutocompleteFilter";
import AdminRoleAutocompleteFilter from "./Autocomplete/AdminRoleAutocompleteFilter";
import StudentLevelsAutocompleteFilter from "./Autocomplete/StudentLevelsAutocompleteFilter";
import mapStateToRequest from "../../helpers/mapStateToRequest";
import VideoReportReasonFilter from "./Autocomplete/VideoReportReasonFilter";
import AdminAutocompleteFilter from "./Autocomplete/AdminAutocompleteFilter";
import TraineeshipAutocompleteFilter from "./Autocomplete/TraineeshipAutocompleteFilter";
import LevelAutocompleteFilter from "./Autocomplete/LevelAutocompleteFilter";
import DateTimeFilter from "./Basic/DateTimeFilter";
import DomainAutocompleteFilter from "./Autocomplete/DomainAutocompleteFilter";
import ParentUserAutcompleteFilter from "./Autocomplete/ParentUserAutcompleteFilter";
import PenaltyUnitAutocompleteFilter from "./Autocomplete/PenaltyUnitAutocompleteFilter";
import PenaltyAutocompleteFilter from "./Autocomplete/PenaltyAutocompleteFilter";
import SingleUserGroupAutocompleteFilter from "./Autocomplete/SingleUserGroupAutocompleteFilter";
import UsersAutocompleteFilter from "./Autocomplete/UsersAutcompleteFilter";
import ParentsAutocompleteFilter from "./Autocomplete/ParentsAutocompleteFilter";
import InstructorsAutocompleteFilter from "./Autocomplete/InstructorsAutocompleteFilter";
import BusinessAutocompleteFilter from "./Autocomplete/BusinessAutocompleteFilter";

import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { UrlHandler } from "helpers/methods";
import EventAutocompleteFilter from "./Autocomplete/EventsAutoCompleteFilter";
import PenaltyDetailsAutocompleteFilter from "./Autocomplete/PenaltyDetailsAutoCompleteFilter";
import GiftRequestAutocompleteFilter from "./Autocomplete/GiftRequestAutocompleteFilter";
import OptionalSubjectAutocompleteFilter from "./Autocomplete/OptionalSubjectAutocompleteFilter";
import {useStore} from "../../contexts/JWTAuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  spacer: {
    flexGrow: 1,
  },
  card: {},
  cardContent: {
    paddingBottom: "16px !important",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  autocomplete: {
    marginTop: theme.spacing(1),
  },
  textField: {
    minWidth: "100%",
  },
  resetButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Filter = (props) => {
  const {
    className,
    filtersChange,
    fields,
    values,
    pageLink,
    initializeData,
    rowsPerPage,
      setData,
    ...rest
  } = props;
    const { user } = useStore();
    const classes = useStyles();
  const [filterValues, setFilterValues] = useState({});
  const [reset, setReset] = useState(false);

  const location = useLocation();
  const [storedValues, setStoredValues] = useState({});

  useEffect(() => {
    if (
      filterValues.points &&
      filterValues.points[0] === undefined &&
      filterValues.points[1] === undefined
    ) {
      setFilterValues({});
    }
  }, [filterValues.points]);

  useEffect(() => {
    if (Object.keys(filterValues).length === 0) {
      setFilterValues({
        ...storedValues,
      });
    }
  }, []);

  const filterValuesChange = (value) => {
    if (Object.keys(filterValues).length === 0) {
      setReset(false);
      setFilterValues({
        ...storedValues,
        ...value,
      });
    } else {
      setFilterValues({
        ...filterValues,
        ...value,
      });
    }
  };

  const submitFilter = () => {
    let ignoreField = [];
    for (const v in filterValues || storedValues) {
      if (filterValues[v]?.length === 2) {
        ignoreField.push(v);
      }
    }
    filtersChange(mapStateToRequest(filterValues, ignoreField));
    localStorage.setItem("filterValues", JSON.stringify(filterValues));
  };

  const resetFilter = () => {
    const uri = window.location.toString();

    if (uri.indexOf("?") > 0) {
      const cleanUri = uri.substring(0, uri.indexOf("?"));
      window.history.replaceState({}, document.title, cleanUri);
    }
    setFilterValues({});
    setStoredValues({});
    setReset(true);
    localStorage.removeItem("filterValues");
      if((uri.includes("affiliations") && user.can_display_affiliations === true) || !uri.includes("affiliations")) {
          initializeData(rowsPerPage);
      }else{
          setData([]);
      }
  };
  useEffect(() => {
    if (!window.location.search) {
      //localStorage.removeItem("filterValues");
      localStorage.removeItem("FilterButton");
    } else {
      setStoredValues(JSON.parse(localStorage.getItem("filterValues")));
    }
  }, [window.location.search]);

  const renderType = (field) => {
    switch (field.type) {
      case "boolean":
        return (
          <BooleanFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "text":
        return (
          <TextFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "number":
        return (
          <NumberFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "dropdown":
        return (
          <DropdownFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "date":
        return (
          <DateFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "dateTime":
        return (
          <DateTimeFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "dateRange":
        return (
          <DateRangeFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "divisionAutocomplete":
        return (
          <DivisionAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "offerAutocomplete":
        return (
          <OfferAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
        case "giftsAutocomplete":
          return (
            <GiftRequestAutocompleteFilter
              values={
                Object.keys(filterValues).length === 0
                  ? storedValues || {}
                  : filterValues
              }
              filtersChange={filterValuesChange}
              field={field}
            />
          );
      case "userGroupAutocomplete":
        return (
          <UserGroupAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "singleUserGroupAutocomplete":
        return (
          <SingleUserGroupAutocompleteFilter
            values={filterValues}
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "userAutocomplete":
        return (
          <UserAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "instructorsAutocomplete":
        return (
          <InstructorsAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "parentsAutocomplete":
        return (
          <ParentsAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "usersAutocomplete":
        return (
          <UsersAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "agencyAutocomplete":
        return (
          <AgencyAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "officeAutocomplete":
        return (
          <OfficeAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "officeIsMultiAutocomplete":
        return (
          <OfficeAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            isMulti
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "paymentMethodAutocomplete":
        return (
          <PaymentMethodAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "accountAutocomplete":
        return (
          <AccountAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "subjectAutocomplete":
        return (
          <SubjectAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "optionalSubjectAutocomplete":
        return (
          <OptionalSubjectAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "subjectIsMultiAutocomplete":
        return (
          <SubjectAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            isMulti
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "webinarAccountAutocomplete":
        return (
          <WebinarAccountAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "instructorAutocomplete":
        return (
          <InstructorAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "businessAutocomplete":
        return (
          <BusinessAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );

      case "eventAutocomplete":
        return (
          <EventAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "scopeAutocomplete":
        return (
          <ScopeAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "contentTypeAutocomplete":
        return (
          <ContentTypeAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "chapterAutocomplete":
        return (
          <ChapterAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "stateAutocomplete":
        return (
          <StateAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "delegationAutocomplete":
        return (
          <DelegationAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "adminRoleAutocomplete":
        return (
          <AdminRoleAutocompleteFilter
            values={
              Object.keys(filterValues)?.length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "adminIsMultiRoleAutocomplete":
        return (
          <AdminRoleAutocompleteFilter
            values={
              Object.keys(filterValues)?.length === 0
                ? storedValues || {}
                : filterValues
            }
            isMulti
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "studentLevelsAutocomplete":
        return (
          <StudentLevelsAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "studentLevelsIsMultiAutocomplete":
        return (
          <StudentLevelsAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            isMulti
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "videoReportReason":
        return (
          <VideoReportReasonFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "adminAutocomplete":
        return (
          <AdminAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "traineeshipAutocompleteFilter":
        return (
          <TraineeshipAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "levelAutocompleteFilter":
        return (
          <LevelAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "domainAutocomplete":
        return (
          <DomainAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "parentUserAutocomplete":
        return (
          <ParentUserAutcompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "PenaltyAutocompleteFilter":
        return (
          <PenaltyAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "PenaltyUnitAutocomplete":
        return (
          <PenaltyUnitAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "PenaltyDetailsAutocomplete":
        return (
          <PenaltyDetailsAutocompleteFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
          />
        );
      case "numberRange":
        return (
          <NumberRangeFilter
            values={
              Object.keys(filterValues).length === 0
                ? storedValues || {}
                : filterValues
            }
            filtersChange={filterValuesChange}
            field={field}
            reset={reset}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={2} alignItems="center">
            {fields?.map((f) => (
              <Grid item xs={12} md={3} key={f?.name}>
                {renderType(f)}
              </Grid>
            ))}
            <Grid item xs={6} md={"auto"}>
              <Link
                to={{
                  pathname: location.pathname,
                  search: reset
                    ? ""
                    : storedValues
                      ? `?${UrlHandler(filterValues, storedValues)}`
                      : "",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitFilter}
                >
                  Filter
                </Button>
              </Link>
              <Button
                className={classes.resetButton}
                variant="contained"
                onClick={resetFilter}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  filtersChange: PropTypes.func,
  initializeData: PropTypes.func,
  fields: PropTypes.array,
  values: PropTypes.object,
};

export default Filter;
