// @flow
import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import {api_get, api_post, api_put} from '../../../../utils/Api';
import { Avatar, Typography } from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
  root: {
      width: "100%",
      "& > * + *": {
          marginTop: theme.spacing(2),
      },
      overflow: "visible",
  },
  mtop: {
      marginTop: 24,
  },
  mtop0: {
      marginTop: 0,
  },
  content: {
      padding: 0,
  },
  uploadedFiles: {
      marginTop: 10,
  },
}));
type Props = {
  className: string
}

const AccountDetails = (props: Props): React$Element<any> => {
  const {className, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
    UserName: '',
    email: '',
  });

  useEffect(() => { 
      api_get(`me`).then((result) => {
        const {payload} = result;
        setValues({
          name: payload.name,
          UserName: payload.username,
          email: payload.email,
        });
      });
  }, []);



  const handleChange = (event) => {
   
  };
  const handleSubmit = () => {
  
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="User information"
          
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth disabled

                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth disabled
                label="User Name"
                name="UserName"
                onChange={handleChange}
                required
                value={values.UserName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth disabled
                label="Email Address"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
           
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button disabled
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default AccountDetails;
