import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
}));

const SubscriptionsInfo = ({className, subscriptions, ...rest}) => {
  const classes = useStyles();

  return (
      <Card
          className={clsx(classes.root, className)}
          {...rest}
      >

        <Box minWidth={1150}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>End date</TableCell>
                <TableCell>Total Seats</TableCell>
                <TableCell>Group</TableCell>
                <TableCell>Divisions</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions?.length ? (subscriptions.map((subscription) => (
                      <TableRow key={subscription.id}>
                        <TableCell>
                          {subscription.id}
                        </TableCell>
                        <TableCell>
                          {moment(subscription.end_date).format('DD/MM/YYYY')}
                        </TableCell>
                       
                        <TableCell>
                          {subscription.total_seats}
                        </TableCell>
                        <TableCell>
                          {subscription.group?.name}
                        </TableCell>
                        {
                          subscription.business_subscription_divisions.map(division => {
                            return (
                                <TableCell style={{display: 'flex'}}>
                                  {division?.division.name}
                                </TableCell>
                            );
                          })
                        }

                      </TableRow>
                  ))
              ) : (
                  <TableRow>
                    <TableCell>
                      He does not have any subscription
                    </TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </Card>
  );
};

SubscriptionsInfo.propTypes = {
  className: PropTypes.string,
  subscriptions: PropTypes.object.isRequired,
};

export default SubscriptionsInfo;
