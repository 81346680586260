import React from 'react';

import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id',label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '35%', show: true},
  {accessor: 'description', label: 'Description', width: '15%', show: false},
  {accessor: 'subject.name', label: 'Subject', width: '20%', show: true},
  {accessor: 'subject.division.name', label: 'Division', width: '20%', show: true,},
  {accessor: 'visible', type: 'boolean', label: 'Visible', width: '10%', show: true,},
];
const fieldSearchable = [
  'id',
];
const fieldFilterable = [
  {name: 'name', label: 'Name', type: 'text'},
  {name: 'subject.id', label: 'Subject', type: 'subjectAutocomplete'},
  {name: 'visible', label: 'Visible', type: 'boolean'},
  {name: 'createdAt', label: 'Created At', type: 'dateRange'},

];
const ChapterList = () => {

  return (
      <TableList
           columnsList={columnsList}
           fieldSearchable={fieldSearchable}
           fieldFilterable={fieldFilterable}
           url={`chapters`}
          exportUrl={`chapters/export`}
          pageLink ={'/contents/chapters'}
          searchMessage={'Search (ID)'}
          baseUrl={`chapters`}
          deleteUrl={`delete/many-chapters`}
          keyValue={"chapters"}
          cacheUrl={'flush-key/chapter'}

      />
  );
};

export default ChapterList;
