import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '6%', show: true},
  {accessor: 'name', label: 'Name', show: true, width: '40%'},
  {accessor: 'created_at', label: 'Created At', width: '27%', show: true, type: 'date'},
  {accessor: 'updated_at', label: 'Updated At', width: '27%', show: true, type: 'date'},
];
const fieldSearchable = [
  'name',
];
const fieldFilterable = [];
const LevelList = () => {

  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`student-levels`}
          noExport={'true'}
          pageLink ={'/settings/student-levels'}
          searchMessage={'Search (Name)'}
          baseUrl={`user-types`}
          deleteUrl={`delete/many-student-levels `}
          keyValue={"studentLevels"}
          noShow={true}
          noFilter={true}
      />
  );
};

export default LevelList;
