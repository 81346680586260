import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import Table from '../../../components/Table/Table';
import Loading from "../../../components/Loading/Loading";
import optionsParser from "../../../helpers/optionsParser";
import {api_get, api_post} from "../../../utils/Api";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
}));
const columnsList = [
    {accessor: 'id', name: 'id', label: 'Id', width: '7%', show: true, sortable: true, type: 'userSwitch'},
    {accessor: 'last_name', name: 'lastName', label: 'Last name', width: '10%', show: true, sortable: true,},
    {accessor: 'name', name: 'name', label: 'First name', width: '20%', show: true, sortable: true,},
    {accessor: 'phone', label: 'Phone', width: '20%', show: true, type: 'fieldValue'},
    {accessor: 'email', label: 'Email', width: '20%', show: true, type: 'fieldValue'},
    {accessor: 'division.name', label: 'Division', width: '20%', show: true, type: 'fieldValue'},
    //{accessor: 'affiliations', label: 'affiliations', width: '20%', show: true, type: 'affiliationList',},
    {accessor: 'verified', type: 'boolean', color: 'primary', label: 'Verified', show: true, width: '20%',},
    {accessor: 'completed', type: 'boolean', color: 'primary', label: 'Completed', show: true,},
    {accessor: 'points', name:'points', label: 'Points', width: '30%', show: true, sortable: true, type: 'pointsType'},
    {accessor: 'created_at', name:'createdAt', label: 'Created At', width: '20%', show: true, sortable: true, type: 'date'},
];

const SegmentPreview = (props) => {
    const {data, setData, values, ...rest} = props;
    const [columns, columnsChange] = useState(columnsList);
    const [sort, sortChange] = useState({accessor: 'id', order: 'desc'});
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(12);
    useEffect(() => {
        getListData(rowsPerPage, values);
    }, [page, sort, rowsPerPage]);

    const handleSortChange = (accessor) => {
        sortChange({
            accessor: accessor,
            order: sort.order === 'asc' ? 'desc' : 'asc',
        });
    };

    const getListData = (perPage = 12) => {
        api_post(`segments/preview?page=${page}&perPage=${perPage}`, values).then((data) => {
            setData(data);
        });
    };

    const handlePageChange = (event, page) => {
        setPage(page + 1);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
    };
    const classes = useStyles();
    if (!data) {
        return (<Loading/>);
    }
    return (
        <div className={classes.root}>
                <Table columns={columns}
                       data={data}
                       handleSortChange={handleSortChange}
                       initializeData={getListData}
                       sort={sort}
                       handlePageChange={handlePageChange}
                       page={page}
                       rowsPerPage={rowsPerPage}
                       handleRowsPerPageChange={handleRowsPerPageChange}
                       noAction={true}
                       noCheck={true}
                       />
        </div>
    );
};

export default SegmentPreview;
