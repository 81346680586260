import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Accordion,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

import clsx from "clsx";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import optionsParser from "../../../helpers/optionsParser";
import {
  api_get,
  api_post,
  api_post_serialize,
  api_put,
} from "../../../utils/Api";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import { AsyncPaginate } from "react-select-async-paginate";
import theme from "../../../theme";
import ListMarkSheets from "./ListMarkSheets";
import FileDropZone from "../../../components/FileDropZone";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const PaymentAgreementForm = (props) => {
  const { id } = useParams();
  const { className, search, userId, edit, ...rest } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [fileName, setName] = useState("");
  const [fileUrl, setUrl] = useState("");
  const [values, setValues] = useState({
    user: userId || "",
    division: "",
    status: "processed",
    trimester: 1,
  });
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("success");
  const [withAI, setWithAI] = useState(false);
  const [userSheets, getSheets] = useState([]);
  const tableCell = ["id", "division", "trimester", "status", "file"];
  useEffect(() => {
    getUserSheets();
  }, []);

  const getUserSheets = () => {
    api_get(`mark-sheet/user/${userId}`).then((result) => {
      getSheets(result.payload);
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function (key, index) {
      if (index == 0) {
        setAlertSeverity("error");
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const onFileChange = (files) => {
    if (files && files.length > 0) {
      setValues({ ...values, file: files[0] });
    }
  };

  const divisionChange = (value) => {
    setValues({ ...values, division: value });
  };
  const getDivisions = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name"])
      .replace("&search=", ";")
      .replace("searchJoin=or", ";");
    const result = await api_get(
      `divisions?page=${page.page}&search=isPublic:1${options}searchJoin=and`
    );
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };

  const formValidation = (values) => {
    /* if (!values?.amount) {
      return { success: false, 'message': 'Amount is missing' };
    }
    if (!values?.user) {
      return { success: false, 'message': 'User is missing' };
    }
    if (!values?.offer) {
      return { success: false, 'message': 'Offer is missing' };
    }
    if (!values?.quantity) {
      return { success: false, 'message': 'Quantity is missing' };
    }*/
  };
  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity("warning");
      setOpen(true);
    } else {
      const requestValues = mapStateToRequest(values, ["file"]);
      api_post_serialize(
        `mark-sheet?type=${withAI ? "ai" : "human"}`,
        requestValues,
        true
      )
        .then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            setAlertSeverity("success");
            setAlertMessage("markSheet successfully added");
            setOpen(true);
            getUserSheets();
            setUrl("");
            setName("");
            setValues({
              ...values,
              user: userId || "",
              division: "",
              status: "processed",
              trimester: 1,
              file: {},
            });
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  };
  const handleCheckChange = (event) => {
    setWithAI(event.target.checked);
  };
  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <ListMarkSheets userMarks={userSheets} tableCell={tableCell} />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes?.heading}>
                Add new MarkSheet
              </Typography>
            </AccordionSummary>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={withAI}
                        onChange={handleCheckChange}
                        value={withAI}
                        name="AI"
                        size="small"
                      />
                    }
                    label="AI"
                  />
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12}>
                    <AsyncPaginate
                      loadOptions={getDivisions}
                      onChange={divisionChange}
                      placeholder="Division"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      value={values.division}
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "53px",
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: "#000",
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector("body")}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      select
                      name="status"
                      variant="outlined"
                      label="Status"
                      // defaultValue="payment"
                      value={values.status}
                      onChange={handleChange}
                    >
                      <MenuItem key="processed" value="processed">
                        Processed
                      </MenuItem>
                      <MenuItem key="pending" value="pending">
                        Pending
                      </MenuItem>
                      <MenuItem key="rejected" value="rejected">
                        Rejected
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      select
                      name="trimester"
                      id="trimester"
                      variant="outlined"
                      label="Trimester"
                      value={values.trimester}
                      onChange={handleChange}
                    >
                      {[1, 2, 3].map((tir) => {
                        return (
                          <MenuItem key={tir} value={tir}>
                            {tir}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FileDropZone
                      fileName={fileName}
                      fileUrl={fileUrl}
                      values={values}
                      setValues={setValues}
                      onFileChange={onFileChange}
                      multipleTypes={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                onClick={(event) => handleSubmit(event)}
              >
                Save details
              </Button>
            </CardActions>
          </Accordion>
        </form>
      </Card>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

PaymentAgreementForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
};

export default PaymentAgreementForm;
