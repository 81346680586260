import React ,{useState}from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import SnackBar from "../SnackBar";
import { api_post } from "../../utils/Api";
import { AsyncPaginate } from "react-select-async-paginate";
import theme from "../../theme";
import {getSubjects} from "../../helpers/methods";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: "red",
    },
  },
  content: {
    padding: 0,
  },
}));
const CopyChapter = (props) => {
  const {
    className,
    handleCopy,
    open,
    itemId,
    service,
    pageLink,
    initializeData,
    rowsPerPage,
    itemName,
    setOpen,
    ...rest
  } = props;

  const classes = useStyles();
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [openSnack, setOpenSnack] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [values, setValues] = useState({
      oldChapterId:`${itemId}`,
      subjectId:''

    })
  const handleClose = () => {
    setOpenSnack(false);
  };

  const subjectChange = (options) => {
   setValues({...values,subjectId:options.id.toString()});
  };

  const showAlertMessage = (message) => {
    setAlertSeverity('error');
    setAlertMessage(message);
    setOpenSnack(true);
  };

  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpenSnack(true);
      }
    });
  };
  const handleCopyChapter =()=>{
     if (values){
     api_post(`chapters/copy-chapter`, values).then((result) => {
      if(result.errors){
      showErrorMessage(result.errors)
    }else if(result?.code !== 200 && result?.code !== 201){
      showAlertMessage(result?.message? result.message:'Technical error! Contact the Developers Team')
    } else {
      setAlertSeverity('success');
      setAlertMessage('chapter copied successfully');
      setOpenSnack(true);
      //window.location.href = '/contents/chapters';
      setTimeout(() => {
     setOpen(false)
      }, 1500);
    }
  })}}


  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        open={open}
        onClose={handleCopy}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="responsive-dialog-title">
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>
            Chapter copy :
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ marginBottom: "20px" }}>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                Chapter title :
              </span>
              <span style={{ color: "orange",fontWeight: "bold"}}>{itemName}.</span>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Chapter ID :
              </span>
              <span style={{ color: "green" ,fontWeight: "bold"}}>{itemId}</span>
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Select subjects to copy into :
            </div>
            <AsyncPaginate
              loadOptions={getSubjects}
              value={values.subjectIds}
              onChange={subjectChange}
              getOptionLabel={(option) =>
                option?.division?.name + " | " + option?.name
              }
              getOptionValue={(option) => option?.id}
              placeholder="Subjects"
              additional={{
                page: 1,
              }}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                control: (base) => ({
                  ...base,
                  minHeight: "40px",
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "#546e7a",
                  fontSize: theme.typography.fontSize,
                  fontFamily: theme.typography.fontFamily,
                }),
              }}
              menuPortalTarget={document.querySelector("body")}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCopy} color="primary">
            Cancel
          </Button>
          <Button onClick={(event)=>handleCopyChapter(event)} color="primary" autoFocus>
            copy 
          </Button>
        </DialogActions>
        <SnackBar
        open={openSnack}
        handleClose={handleClose}
        message="Element successfully copied"
        severity={"success"}
      />
      </Dialog>

    </div>
  );
};

CopyChapter.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  handleCopy: PropTypes.func,
  itemId: PropTypes.number,
};

export default CopyChapter;
