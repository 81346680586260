import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {Card, CardContent, Grid,} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import { changeYear, fetchIncomes, fetchMonthIncomes } from "slices/incomes";
import SelectIncomesYearDropdown from "./SelectIncomesYearDropdown";
import { openModal } from "slices/modals";
import { months } from "utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      maxWidth: 360,
    },
  },
  datePicker: {
    dispaly: "flex",
    alignItems: "end",
    justifyContent: "end",
    marginBottom: "20px",

  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "5px",
    padding:"5px",
    minWidth:"60px"
  },
  monthValues: {
    alignItems: "center",
    marginBottom: "20px",
    justifyContent: "center",
  },
  title: {
    fontWeight: "bold",
    marginBottom: "5px",
  },
  month_statestique:{
    fontWeight:'bold',
    fontSize:'18px',
    marginBottom:'20px',
    color:'#0093e2'
  }
}));
const currentYear = new Date().getFullYear();
const yearsIncomesList = [0, 1, 2, 3].map((i) => ({
  value: currentYear - i,
  text: String(currentYear - i),
}));

const IncomesForm = (props) => {
  const { id } = useParams();
  const { className, edit, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, status, numbersCurrentMonth } = useSelector(
    (state) => state.incomes.yearIncomes
  );
  const { year } = useSelector((state) => state.incomes.currentDataDate);

  const headersRowIncomes = [
    { title: "Mois" },
    { title: "Solde" },
    { title: "Défaut" },
    { title: "Solde Final" },
    { title: "" },
  ];

  useEffect(() => {
    dispatch(fetchIncomes({ instructor: id, year: year }));
  }, [year, dispatch]);

  return (
    <div>
      <Typography className={classes.month_statestique}>
      Les chiffres de ce mois-ci :
      </Typography>
      <Grid id="top-row" container spacing={3} className={classes.monthValues}>
        {numbersCurrentMonth.length > 0 &&
          numbersCurrentMonth.map((item) => {
            return (
              <Grid item xs={2}>
                <Paper className={classes.paper}>
                  <div className={classes.title}>{item.title}</div>
                  <div>{item.value}</div>
                </Paper>
              </Grid>
            );
          })}
      </Grid>

      <Grid
        container
        alignItems="right"
        spacing={3}
        className={classes.datePicker}
      >
        <SelectIncomesYearDropdown
          optionsList={yearsIncomesList}
          changeHandler={(option) => dispatch(changeYear(option))}
        />
      </Grid>

      <Grid container spacing={3}>
        <Card {...rest} className={clsx(classes.root, className)}>

            {data?.length ? (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {headersRowIncomes.map((tableCell) => {
                        return <TableCell>{tableCell.title}</TableCell>;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item,index) => (
                      <TableRow
                        key={item?.id}
                        // onClick={() => handleRowClick(item)}
                      >
                        <TableCell component="th" scope="row">
                          {item.month}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {item?.balance}
                        </TableCell>
                        <TableCell style={{ color: "red", fontWeight: "bold" }}>
                          {item?.fault}
                        </TableCell>
                        <TableCell
                          style={{ color: "#00B828", fontWeight: "bold" }}
                        >
                          {item?.finalBlance}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            color: "#0093e2",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                             dispatch(
                               fetchMonthIncomes({
                                 instructor:id,
                                month: months[index].monthNumber,
                                year,
                              })
                            );
                             dispatch(openModal("month-incomes-modal",{id:id}));
                          }}
                        >
                          <span>Voir détails</span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  This Teacher does not have any items
                </Typography>
              </CardContent>
            )}

        </Card>
      </Grid>
    </div>
  );
};
IncomesForm.propTypes = {
  className: PropTypes.string,
};

export default IncomesForm;
