import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '5%', sortable: true, show: true},
  {accessor: 'name',  color: 'primary', label: 'Name', width: '20%', show: true,},
  {accessor: 'thumbnail', label: 'Thumbnail', width: '10%', show: true, type: 'thumbnailDisplay'},
  {accessor: 'price', name: 'price', label: 'Price', width: '10%', show: true, sortable: true, type: 'pointsType'},
  {accessor: 'validity_start', label: 'Validity Start', width: '20%', show: true, type: 'date',},
  {accessor: 'validity_end', label: 'Validity End', width: '20%', show: true, type: 'date',},
  {accessor: 'type', name: 'type', label: 'Type', type: 'statusColor', width: '10%', show: true, sortable: true,},
  {accessor: 'start_date', name: 'startDate', type: 'date', label: 'Start date', width: '10%', sortable: true, show: true,},
  {accessor: 'end_date', name: 'endDate', type: 'date', label: 'End date', width: '10%', sortable: true, show: true,},
  {accessor: 'active', name: 'active', type: 'boolean', label: 'Active', width: '5%', show: true, sortable: true,},
];

const fieldSearchable = [
  'name',
];
const fieldFilterable = [
  {name: 'type', label: 'Type', type: 'dropdown', options: ['primary', 'pack', 'unity']},
  {name: 'startDate', label: 'Start date', type: 'dateRange'},
  {name: 'endDate', label: 'End date', type: 'dateRange'},
  {name: 'active', label: 'Active', type: 'boolean'},
  {name: 'price', label: 'Price', type: 'number'},
  {name: 'offerDivisionDetails.division.id', label: 'Division', type: 'divisionAutocomplete'},
];
const OfferList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`offers`}
          exportUrl={`offers/export`}
          pageLink ={'/offers/offers'}
          searchMessage={'Search (Name)'}
          baseUrl={`offers`}
          deleteUrl={`delete/many-offers`}
          keyValue={"offers"}
          cacheUrl={'flush-key/offer'}
      />
  );
};

export default OfferList;
