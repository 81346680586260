import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'user', label: 'User', type: 'concatString', width: '20%', show: true},
  {accessor: 'reason', label: 'Reason', width: '25%', show: true},
  {accessor: 'banned_by.username', label: 'Banned By', width: '20%', show: true},
  {accessor: 'banned_until', type: 'date', width: '10%', label: 'Banned Until', show: true},
  {accessor: 'created_at', type: 'date', width: '10%', label: 'Created At', show: true},
  {accessor: 'updated_at', type: 'date', width: '10%', label: 'Updated At', show: true},
];
const fieldSearchable=[
  'id',
  'user.id',
];
const fieldFilterable = [
];
const BannedUserList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`banned-user`}
          exportUrl={`banned-user/export`}
          pageLink ={'/user-settings/banned-users'}
          searchMessage={'Search (ID, UserID)'}
          baseUrl={`banned-user`}
          noDelete={true}
          noDeleteMany={true}
          noShow={true}
      />
  );
};

export default BannedUserList;
