import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Grid, makeStyles} from '@material-ui/core';
import SlicesInfo from './PaymentAgreementSlicesInfo';
import PaymentAgreementInfo from './PaymentAgreementInfo';

const useStyles = makeStyles(() => ({
  root: {},
}));

const PaymentAgreement = ({
                            paymentAgreement,
                            className,
                            ...rest
                          }) => {
  const classes = useStyles();
  return (
      <Grid
          className={clsx(classes.root, className)}
          container
          spacing={3}
          {...rest}
      >
        <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
        >
            {paymentAgreement &&<PaymentAgreementInfo paymentAgreement={paymentAgreement}/>}
        </Grid>
        <Grid
            item
            lg={8}
            md={8}
            xl={8}
            xs={12}
        >
            {paymentAgreement && <SlicesInfo slices={paymentAgreement?.payment_agreement_slices}/>}
        </Grid>
      </Grid>
  );
};

PaymentAgreement.propTypes = {
  className: PropTypes.string,
  slices: PropTypes.object.isRequired,
  paymentAgreement: PropTypes.object.isRequired,

};

export default PaymentAgreement;
