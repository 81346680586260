import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Grid, makeStyles} from '@material-ui/core';
import BasicsInfo from './BasicsInfo';
import HistoriesSubscriptionsInfo from "./HistoriesSubscriptionsInfo";

const useStyles = makeStyles(() => ({
  root: {},
}));

const Details = ({
                   editDivision,
                   histories,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={3}
      {...rest}
    >
      <Grid
        item
        lg={4}
        md={6}
        xl={3}
        xs={12}
      >
        <BasicsInfo editDivision={editDivision} />
      </Grid>
      <Grid
          item
          lg={8}
          md={8}
          xl={3}
          xs={12}
      >
        <HistoriesSubscriptionsInfo histories={histories} />
      </Grid>
    </Grid>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  histories: PropTypes.object.isRequired,

};

export default Details;
