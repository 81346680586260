import { makeStyles } from '@material-ui/core';

const useCell = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    background: '#ffffff',
    borderRadius: '16px',
    padding: '20px',
  },
  grid: {
    marginBottom: '20px',
  },
  link: {
    margin: '0px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#2BA7DF',
    cursor: 'pointer',
    padding: '0px',
    verticalAlign: 'baseline',
  },
  cell: {
    fontSize: '18px',
    padding: '10px 0px 10px 10px',
    height: '41px',
    lineHeight: '21px',
    color: '#000000',
  },
  greenCell: {
    color: '#00B828',
  },
  redCell: {
    color: '#C20000',
  },
  redBg: {
    backgroundColor: '#FFDDDD',
    color: '#C20000',
  },
  icon: {
    paddingTop: '7px',
  },
}));

export default useCell;
