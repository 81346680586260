// @flow
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import { api_get, api_post, api_put } from "../../../utils/Api";
import { useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import validate from "validate.js";
import schemaParser from "../../../helpers/schemaParser";
import FormHelperText from "@material-ui/core/FormHelperText";
import optionsParser from "../../../helpers/optionsParser";
import { AsyncPaginate } from "react-select-async-paginate";
import theme from "../../../theme";
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import {getCountryStates} from "../../../helpers/methods";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
}));

let schema = {};
const getSchema = () => {
  schemaParser("institutes").then((data) => {
    schema = data.payload;
  });
};
type Props = {
  edit: boolean,
  className: string,
};
const Form = (props: Props): React$Element<any> => {
  const { id } = useParams();
  const { className, edit, ...rest } = props;
  const classes = useStyles();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      state: { id: null },
      name: "",
      delegation: null,
      adresse: null,
      private: false,
      approved: false,
    },
    touched: {},
    errors: {},
  });
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("success");

  useEffect(() => {
    let errors = {};
    if (Object.keys(formState.values).length) {
      errors = validate(formState.values, schema);
    }
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    getSchema();
    if (edit) {
      api_get(`institutes/${id}`).then((result) => {
        const { payload } = result;
        setFormState({
          ...formState,
          values: {
            name: payload.name || "",
            delegation: payload.delegation,
            state: payload.state,
            adresse: payload.adresse || null,
            private: payload.private == true ? "1" : "0",
            approved: payload.approved == true ? "1" : "0",
          },
        });
      });
    }
  }, []);
  const getDelegations = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name"]);
    const result = await api_get(
      `${formState.values.state.id}/delegations?page=${page.page}${options}`
    );
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const handleApiValidation = (errors) => {
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  };
  const formValidation = (values) => {
    if (!values?.name) {
      return { success: false, 'message': 'Name is missing' };
    }
    if (!values?.adresse) {
      return { success: false, 'message': 'Address is missing' };
    }
    if (!values?.state) {
      return { success: false, 'message': 'State is missing' };
    }
    if (!values?.delegation) {
      return { success: false, 'message': 'Delegation is missing' };
    }
  };
  const handleSubmit = () => {
    const validation = formValidation(formState.values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
      const requestValues = mapStateToRequest(formState.values, [], ['private','approved']);
      if (edit) {
        api_put(`institutes/${id}`, requestValues).then((result) => {
          if (result.errors) {
            handleApiValidation(result.errors);
          } else {
            setAlertSeverity("success");
            setAlertMessage("Institute updated successfully");
            setOpen(true);
            window.location.href = "/settings/institutes";
          }
        });
      } else {
        api_post(`institutes`, requestValues)
            .then((result) => {
              if (result.errors) {
                handleApiValidation(result.errors);
              } else {
                setAlertSeverity("success");
                setAlertMessage("Institute created successfully");
                setOpen(true);
                window.location.href = "/settings/institutes";
              }
            })
            .catch((error) => {
              throw new Error(error);
            });
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const stateChange = (value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        state: value,
      },
      touched: {
        ...formState.touched,
        state: true,
      },
    }));
  };

  const delegationChange = (value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        delegation: value,
      },
      touched: {
        ...formState.touched,
        delegation: true,
      },
    }));
  };

  const hasError = (field) => (formState.errors[field] ? true : false);

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete='off' noValidate>
          <CardHeader subheader='Institutes form'  />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  error={hasError("name")}
                  fullWidth
                  helperText={
                    hasError("name") ? formState.errors.name[0] : null
                  }
                  label='Name'
                  name='name'
                  onChange={handleChange}
                  value={formState.values.name || ""}
                  variant='outlined'
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  error={hasError("adresse")}
                  fullWidth
                  helperText={
                    hasError("adresse") ? formState.errors.adresse[0] : null
                  }
                  label='Address'
                  name='adresse'
                  onChange={handleChange}
                  value={formState.values.adresse || ""}
                  variant='outlined'
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <AsyncPaginate
                  loadOptions={getCountryStates}
                  value={formState.values.state}
                  onChange={stateChange}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder='States *'
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: "53px",
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: "#000",
                      fontSize: theme.typography.fontSize,
                      fontFamily: theme.typography.fontFamily,
                    }),
                  }}
                  menuPortalTarget={document.querySelector("body")}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <AsyncPaginate
                  loadOptions={getDelegations}
                  value={formState.values.delegation}
                  onChange={delegationChange}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder='Delegations *'
                  cacheUniqs={[formState.values.state.id]}
                  noOptionsMessage={() => "You may choose a state before"}
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: "53px",
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: "#000",
                      fontSize: theme.typography.fontSize,
                      fontFamily: theme.typography.fontFamily,
                    }),
                  }}
                  menuPortalTarget={document.querySelector("body")}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>Private</FormLabel>
                  <RadioGroup
                      aria-label='private'
                      name='private'
                      onChange={handleChange}
                      value={formState.values.private}
                      error={hasError("private").toString()}
                      row
                  >
                    <FormControlLabel
                        control={<Radio />}
                        value='1'
                        label='Yes'
                    />
                    <FormControlLabel
                        control={<Radio />}
                        value='0'
                        label='No'
                    />
                  </RadioGroup>
                  {hasError("private") && (
                      <FormHelperText>
                        {formState.errors.private[0]}
                      </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>Approved</FormLabel>
                  <RadioGroup
                    aria-label='approved'
                    name='approved'
                    onChange={handleChange}
                    value={formState.values.approved || ""}
                    error={hasError("approved").toString()}
                    row
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value='1'
                      label='Yes'
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value='0'
                      label='No'
                    />
                  </RadioGroup>
                  {hasError("approved") && (
                    <FormHelperText>
                      {formState.errors.approved[0]}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              // disabled={!formState.isValid}
              color='primary'
              variant='contained'
              onClick={handleSubmit}
            >
              Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
};

export default Form;
