import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import FormEdit from './components/FormEdit';
import {Form} from './components';
import SimpleToolbar from '../../components/Toolbar/SimpleToolbar';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const DivisionEditEdit = (props) => {
  const {Link , pageLink, title, ...rest} = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SimpleToolbar
          Link = {Link}
          pageLink = {'/contents/divisions'}
          title = {'Divisions Edit'} />
      <div className={classes.content}>
          <FormEdit edit={true}/>
      </div>
    </div>
  );
};

DivisionEditEdit.propTypes = {
  user: PropTypes.object,
};

export default DivisionEditEdit;
