import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  makeStyles,
  Tab,
  Tabs,
} from "@material-ui/core";
import Page from "../../components/Page";
import SegmentInfo from "./SegmentationView/Index";
import BasicsInfo from "./SegmentationView/BasicsInfo";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import SimpleToolbar from '../../components/Toolbar/SimpleToolbar';
import { api_get } from "../../utils/Api";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const SegmentationShow = (props) => {
  const {Link , pageLink, title, ...rest} = props;
  const { id } = useParams();
  const classes = useStyles();
  const [segment, setSegment] = useState([]);
  const [segmentUsers, setUsers] = useState([]);
  const [meta, setMeta] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(24);

  const [currentTab, setCurrentTab] = useState("details");

  const tabs = [{ value: "details", label: "Basics" }];

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    api_get(`segments/${id}`).then((result) => {
      setSegment(result.payload);
    });
    api_get(`segments/preview/${id}?page=${page}&perPage=${rowsPerPage}`).then(
      (result) => {
        setUsers(result.payload);
        setMeta(result.meta);
      }
    );
  }, [page, rowsPerPage]);

  if (!Object.keys(segment).length) {
    return <Loading />;
  }

  const handlePageChange = (event, page) => {
    setPage(page + 1);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };
  return (
    <div>
      <SimpleToolbar
         Link = {Link}
         pageLink = {'/user-settings/segments'}
         title = {'Segmentation'} />
      <Page className={classes.root} title="Code Details">
        <Container maxWidth={false}>
          <Box mt={3}>
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
              textColor="secondary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box mt={3}>
            {currentTab === "details" && (
              <SegmentInfo
                handleRowsPerPageChange={handleRowsPerPageChange}
                handlePageChange={handlePageChange}
                meta={meta}
                segment={segment}
                users={segmentUsers}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            )}
            {currentTab === "invoices" && <BasicsInfo />}
          </Box>
        </Container>
      </Page>
    </div>
  );
};

export default SegmentationShow;
