import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import Label from '../../../components/Label';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  slices:{
    fontWeight: theme.typography.fontWeightMedium,
    row: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1),
    },
  }
}));

const BasicsInfo = ({
                      affiliation,
                      className,
                      ...rest
                    }) => {
  const editInfo = (id) => {
    const url = `/finance/affiliations/edit/${id}`;
    window.open(url, '_blank');
  };
  const classes = useStyles();
  return (
      <Card
          className={clsx(classes.root, className)}
          {...rest}
      >
        <CardHeader title="Affilations info"/>
        <Divider/>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                ID
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {affiliation.id}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Amount Paid
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {affiliation.amount_paid || 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Group
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {affiliation.group?.name}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                User
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {`${affiliation.user?.name}  ${affiliation.user?.last_name}`  || 'NULL'}
                  {' '}
                  <Label>
                    {affiliation.user?.id}
                  </Label>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Division
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {affiliation.division?.name}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Start date
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {affiliation.start_date}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                End date
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {affiliation.end_date}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Responsible
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {affiliation.responsible}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Active
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  <Label
                      color={affiliation.active == '1' ? 'success' : 'error'}>
                    {affiliation.active == '1' ? "TRUE" : "FALSE"}
                  </Label>
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box
            p={1}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
        >
          <Button startIcon={<EditIcon/>}
                  onClick={() => editInfo(affiliation.id)}>
            Edit affiliation
          </Button>
        </Box>
      </Card>
  );
};

BasicsInfo.propTypes = {
  className: PropTypes.string,
  affiliation: PropTypes.object.isRequired,
};

export default BasicsInfo;
