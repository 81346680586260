import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Box, Typography, Paper,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import MenuItem from '@material-ui/core/MenuItem';
import { api_get, api_put } from "../../../utils/Api";
import QuillEditor from "../../../components/QuillEditor";
import Autocomplete from '@material-ui/lab/Autocomplete';
import LoadingApi from 'components/LoadingApi/LoadingApi';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    overflow: "visible",
  },
  mtop: {
    marginTop: 24,
  },
  mtop0: {
    marginTop: 0,
  },
}));
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
const FormEdit = (props) => {
  const classes = useStyles();
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const [accounts, getAccounts] = useState([]);
  const [offices, getOffices] = useState([]);
  const [values, setValues] = useState({
        reference: '',
        amount: '',
        comment: '',
        account: '',
        office: '',
        type: '',
  });
  const [message, setAlertMessage]=useState('');
  const [severity, setAlertSeverity]=useState('success');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }

  useEffect(() => {
    api_get(`company-accounts`).then((data) => {
      getAccounts(data.payload);
    });
    api_get(`offices?perPage=100`).then((data)=>{
      getOffices(data.payload);
    });
    api_get(`codes/${id}`).then((result)=>{
      const data=result.payload;
      setValues({
        reference: data.reference,
        amount: data?.amount,
        comment: data.comment,
        account: data.account ? data.account : null,
        type: data.type,
        status: data.status,
        office: data.office ? data.office : null,
        paymentMethod: data?.payment_method?.id,
      });
    });
  }, [id]);

  const showErrorMessage=(errors)=>{
    Object.keys(errors).map(function(key, index) {
      if (index === 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
      }
    });
  };
  const accountChange = (event, value) => {
    setValues({
      ...values,
      account: value ? value : null,
    });
  };
  const officeChange = (event, value) => {
    setValues({
      ...values,
      office: value ? value : null,
    });
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleDescriptionChange = (value) => {
    values.comment = value;
  };
  const handleSubmit = () => {
    values.account = values.account ? values.account.id : null;
    values.office = values.office ? values.office.id : null;
    setLoading(true);
    api_put(`codes/${id}`, values).then((result) => {
      if (result?.errors) {
        showErrorMessage(result.errors);
       }
      else if(result?.code !== 200  && result?.code !== 201){
        setAlertSeverity('warning');
        setAlertMessage(result?.message ? result.message : 'Technical error! Contact the Developers Team');
        setOpen(true);
     
    } else {
        setAlertSeverity('success');
        setAlertMessage('code updated successfully');
        setOpen(true);
        setLoading(false);
        window.location.href = '/finance/finance-codes';
      }
    });
  };
  return (
      <div {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Card className={classes.root}>
                <CardContent>
                  <TextField
                      fullWidth
                      label="Reference"
                      name="reference"
                      onChange={handleChange}
                      required
                      value={values.reference || ''}
                      variant="outlined"
                      size="small"
                  />
                  <Box mt={2} mb={1}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Comment
                    </Typography>
                  </Box>
                  <Paper variant="outlined">
                    <QuillEditor
                        className={classes.editor}
                        value={values.comment || ''}
                        modules={modules}
                        formats={formats}
                        onChange={(value) => handleDescriptionChange(value)}
                    />
                  </Paper>
                </CardContent>
              </Card>
              <Box mt={3}>
                <Card>
                  <CardHeader title="Account & Office" />
                  <Divider />
                  <CardContent>
                    <Box mt={0} mb={1}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                          <Autocomplete
                              label="account"
                              id="tags-outlined"
                              name="account"
                              options={accounts}
                              getOptionLabel={(option) => option?.name}
                              value={values.account || ''}
                              filterSelectedOptions
                              onChange={accountChange}
                              renderInput={(params) => (
                                  <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Accounts"
                                      placeholder="Select an account"
                                  />
                              )}
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Autocomplete
                              label="Offices"
                              id="tags-outlined"
                              name="office"
                              options={offices}
                              getOptionLabel={(option) => option?.name}
                              value={values.office || ''}
                              filterSelectedOptions
                              onChange={officeChange}
                              renderInput={(params) => (
                                  <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Offices"
                                      placeholder="Select an office"
                                  />
                              )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card className={classes.root}>
                <CardHeader title="Basic" />
                <Divider className={classes.mtop0} />
                <CardContent className={classes.mtop0}>
                  <Box mt={0}>
                    <TextField
                        classes={{root: classes.root}}
                        select
                        name="type"
                        variant="outlined"
                        label="Type"
                        value={values.type || ''}
                        onChange={handleChange}
                    >
                      <MenuItem key="payment" value="payment">Versement</MenuItem>
                      <MenuItem key="transfer" value="transfer">Virement</MenuItem>
                      <MenuItem key="cash" value="cash">Espèce</MenuItem>
                      <MenuItem key="check" value="check">Chèques</MenuItem>
                      <MenuItem key="poste" value="poste">Poste</MenuItem>
                    </TextField>
                  </Box>
                  <Box mt={2}>
                    <TextField
                        classes={{root: classes.root}}
                        select
                        name="status"
                        variant="outlined"
                        label="Status"
                        value={values.status || ''}
                        onChange={handleChange}
                    >
                      <MenuItem key="active" value="active">Active</MenuItem>
                      <MenuItem key="pending" value="pending">Pending</MenuItem>
                      <MenuItem key="approuved" value="approuved">Approuved</MenuItem>
                      <MenuItem key="ignored" value="ignored">Ignored</MenuItem>
                    </TextField>
                  </Box>
                  <Box mt={2}>
                    <TextField
                        classes={{root: classes.root}}
                        type="number"
                        name="amount"
                        variant="outlined"
                        label="amount"
                        value={values.amount || ''}
                        onChange={handleChange}
                    >
                    </TextField>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Box mt={3}>
            <Button
                color="primary"
                variant="contained"
                onClick={(event) => handleSubmit(event)}
            >
              Save details
            </Button>
          </Box>
        </form>
        <SnackBar
            open={open}
            message={message}
            severity={severity}
            handleClose={handleClose}
        />
        <LoadingApi open={loading} onClick={handleCloseLoading}/>
      </div>
  );
};
FormEdit.propTypes = {
  user: PropTypes.object,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,
};
export default FormEdit;
