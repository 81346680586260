import React, {useEffect, useState} from 'react';
import {
    Box,
    Container,
    Divider,
    makeStyles,
    Tab,
    Tabs,
} from '@material-ui/core';
import Page from '../../components/Page';
import BasicsInfo from './DivisionEditView/BasicsInfo';
import DivisionEdit from './DivisionEditView/Index';
import {useParams} from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import SimpleToolbar from '../../components/Toolbar/SimpleToolbar';
import { api_get } from "../../utils/Api";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
}));

const DivisionEditShow = (props) => {
    const {Link , pageLink, title, ...rest} = props;
    const {id} = useParams();
    const classes = useStyles();
    const [editDivision, setEditDivision] = useState([]);
    const [currentTab, setCurrentTab] = useState('details');

    const tabs = [
        {value: 'details', label: 'Basics'},
    ];

    const handleTabsChange = (event, value) => {
        setCurrentTab(value);
    };

    useEffect(() => {
        api_get(`edit-division/${id}`).then((result) => {
            setEditDivision(result.payload);
        });
    }, []);
    if (!Object.keys(editDivision).length) {
        return (<Loading/>);
    }
    return (
        <div>
            <SimpleToolbar
                Link = {Link}
                pageLink = {'/contents/divisions'}
                title = {'Divisions Edit'} /> 
            <Page
                className={classes.root}
                title="Division Edit Request Details"
            >
                <Container maxWidth={false}>
                    <Box mt={3}>
                        <Tabs
                            onChange={handleTabsChange}
                            scrollButtons="auto"
                            value={currentTab}
                            variant="scrollable"
                            textColor="secondary"
                        >
                            {tabs.map((tab) => (
                                <Tab
                                    key={tab.value}
                                    label={tab.label}
                                    value={tab.value}
                                />
                            ))}
                        </Tabs>
                    </Box>
                    <Divider/>
                    <Box mt={3}>
                        {currentTab === 'details' && <DivisionEdit editDivision={editDivision} histories={editDivision.groups}/>}
                        {currentTab === 'invoices' && <BasicsInfo/>}
                    </Box>
                </Container>
            </Page>
        </div>

    );
};

export default DivisionEditShow;
