import React, { useEffect } from "react";
import TableList from "../../components/List/TableList";

const columnsList = [
    {accessor: 'id', name: 'id', label: 'Id', width: '5%', show: true, sortable: true},
    {accessor: 'parent_user', name: 'Parent', label: 'Parent', width: '25%', show: true,type: 'concatStringParent'},
    {accessor: 'user', name: 'Children', label: 'Children', width: '25%', show: true, type: 'concatString'},
    {accessor: "approved", name: "approved", label: "Approved", width: "10%", show: true, type: "boolean"},
    {accessor: "created_at", name: "created_at", label: "Created At", width: "20%", show: true, type: "date"},
    {accessor: "admin-user.username", name: "admin-user.username", label: "Admin", width: "20%", show: true, type: "fieldValue"},
    {accessor: "user.user_files", name: "user.user_files", label: "Files", width: "25%", show: true, type: "File"},


];
  const fieldSearchable = [
    'id',
  ];
  const fieldFilterable = [
      {name: 'parentUser.id', label: 'Parent', type: 'parentUserAutocomplete'},
      {name: 'user.id', label: 'User', type: 'userAutocomplete'},
      {name: "approved", label: "Approved", type: "boolean"},
      ,

  ];
const ChildrenList = () => {
  return (
    <TableList
      columnsList={columnsList}
      fieldSearchable={fieldSearchable}
      fieldFilterable={fieldFilterable}
      url={`parent-user-children`}
      exportUrl={`parent-user-children/export`}
      pageLink={'/users/parent-user-children'}
      searchMessage={"Search (ID)"}
      baseUrl={`parent-user-children`}
      title={'Approval-Requests'}
      deleteUrl={`delete/many-parent-user-children`}
      keyValue={"parentUserChildren"}
       />
  );
};

export default ChildrenList;