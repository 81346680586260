import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  icon: {
    position: 'absolute',
    width: '16px',
    height: '16px',
    top: '6px',
    left: '14px',
    color: 'black',
  },
  chevron: {
    position: 'absolute',
    width: '26px',
    height: '26px',
    top: '2px',
    right: '8px',
    color: 'black',
    zIndex:999999
  },
  year_select:{
    position: 'relative',
    justifyContent:'end',
    alignItems:'end'
  },

   Select: {
        cursor: "pointer",
        appearance: 'none',
        background: '#ffffff',
        bordeRadius: '8px',
        border: '1px solid #dddddd',
        color: 'black',
        fontFamily: 'inherit',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        height: '31px',
        lineHeight: '23px',
        padding:' 0px 36px',
        width: '185px',
        outline: 'none',
      }

  
});

export default useStyles;
