import React from 'react';
import TableList from "../../components/List/TableList";


const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '6%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', show: true, width: '25%'},
  {accessor: 'state.name', label: 'State', show: true, width: '25%'},
  {accessor: 'created_at', label: 'Created At', show: true, type: 'date', width: '20%'},
  {accessor: 'updated_at', label: 'Updated At', show: true, type: 'date', width: '20%'},
];
const fieldSearchable = [
  'name',
];
const fieldFilterable = [
  {name: 'state.id', label: 'State', type: 'stateAutocomplete'},
];
const DelegationList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`delegations`}
          noExport={true}
          pageLink ={'/settings/delegations'}
          searchMessage={'Search (Name)'}
          baseUrl={`delegations`}
          noShow={true}
      />
  );
};

export default DelegationList;
