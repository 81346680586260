import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import SnackBar from '../SnackBar';

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0,
    },
}));
const FlushCache = (props) => {
    const {className, handleCache, open, itemId,service,pageLink, initializeData, cacheUrl, cache_method, rowsPerPage, ...rest} = props;
    const classes = useStyles();
    const [openAlert, setOpenAlert] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [severity, setAlertSeverity] = React.useState('success');
    const handleClose = () => {
        setOpenAlert(false);
    };
    const flushCacheElement=()=>{
        if(service !== null && !cacheUrl) {
            service.cache_method(`${service.base_cache_url}/user-${itemId}`).then((data)=>{
                if(data?.code !== 200 && data?.code !== 201){
                    setOpenAlert(true);
                    let errorMessage = data?.message? data.message:'Technical error! Contact the Developers Team';
                    setAlertSeverity('warning')
                    setMessage(errorMessage)
                } else {
                    setOpenAlert(true);
                    setMessage('Success')
                    handleCache();
                    initializeData(rowsPerPage);
                }
            });
        }else{
            cache_method(`${cacheUrl}`).then((data)=>{
                if(data?.code !== 200 && data?.code !== 201){
                    setOpenAlert(true);
                    let errorMessage = data?.message? data.message:'Technical error! Contact the Developers Team';
                    setAlertSeverity('warning')
                    setMessage(errorMessage)
                } else {
                    setMessage('Success')
                    setOpenAlert(true);
                    handleCache();
                    initializeData(rowsPerPage);
                }
            });
        }
    };
    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Dialog
                open={open}
                onClose={handleCache}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">FLush cache</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure to flush cache?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCache} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={flushCacheElement} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackBar
                open={openAlert}
                handleClose={handleClose}
                message={message}
                severity={severity}
            />
        </div>
    );
};

FlushCache.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    handleRestore: PropTypes.func,
    itemId: PropTypes.number,
};

export default FlushCache;
