import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { api_delete, api_get, api_post } from "../utils/Api";
import {
  Box,
  Button,
  Dialog,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
} from "@material-ui/core";
import SnackBar from "./SnackBar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/styles";
import optionsParser from "../helpers/optionsParser";
import { AsyncPaginate } from "react-select-async-paginate";
import { DeleteOutline } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  codeListItem: {
    fontSize: "16px",
    opacity: "0.8",
  },
  messageCode: {
    fontSize: "13px",
    marginTop: "15px",
    marginBottom: "5px",
  },
  validate: {
    width: "80px",
    color: "white !important",
    fontWeight: "bold !important",
    fontSize: "12px",
    textTransform: "none !important",
    height: "35px !important",
    borderRadius: "2px",
    borderBottomLeftRadius: "0",
    borderTopLeftRadius: "0",
    boxShadow: "2px 0px 2px rgba(133, 132, 132, 0.781)",
  },
  paper: {
    minWidth: "500px",
    [theme.breakpoints.down("xs")]: {
      minWidth: 300,
    },
  },
}));

const BuyForStudentDialog = (props) => {
  const { noButton, openModal, initializeData, rowsPerPage, ...rest } = props;
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [discounts, setDiscounts] = useState([
    { name: "Réduction en pourcentage", value: "percentage_discount" },
    { name: "Réduction fixe", value: "fixe_discount" },
    { name: "Prix fixe", value: "fixe_price" },
  ]);
  const [values, setValues] = useState({
    quantity: 1,
    note: "",
    discount_method: "",
    discount_value: null,
    rest_year: false,
    offer: null,
    user: null,
    offer_subject_details: null,
  });
  const [discountValues, setDiscountValues] = useState({
    quantity: 1,
    code: "",
    offerId: "",
    userId: null,
    allYear: false,
  });
  const [giftCode, setGiftCode] = useState("");
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("success");
  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openMessage, setOpenMessageModal] = useState(false);
  const [userDiscountCodes, setUserDiscountCodes] = useState([]);

  useEffect(() => {
    if (values?.user?.id) {
      getUserDiscountCodes();
    }
  }, [values?.user?.id]);
  const getUserDiscountCodes = () => {
    api_get(`user-discount-code/${values?.user?.id}`).then((result) => {
      setUserDiscountCodes(result.payload);
    });
  };
  useEffect(() => {
    if (values?.user?.id) {
      getUserGiftCodes();
    }
  }, [values?.user?.id]);
  const [userGiftCode, setUserGiftCode] = useState([]);
  const getUserGiftCodes = () => {
    api_get(`generated-gifts/${values?.user?.id}`).then((result) => {
      setUserGiftCode(result.payload);
    });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  const handleMessageClose = () => {
    setOpenMessageModal(false);
  };

  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function (key, index) {
      if (index == 0) {
        setAlertSeverity("error");
        setAlertMessage(errors["message"]);
        setOpenMessageModal(true);
      }
    });
  };
  const formValidation = (values) => {
    if (!values?.offer) {
      return { success: false, message: "Offer is missing" };
    }
    if (!values?.user) {
      return { success: false, message: "User is missing" };
    }
    if (!values?.offer) {
      return { success: false, message: "Offer is missing" };
    }
    if (!values?.quantity && !values?.rest_year) {
      return { success: false, message: "Choose quantity is missing" };
    }
    if (
      values?.quantity &&
      parseInt(values?.quantity) === 0 &&
      !values?.rest_year
    ) {
      return { success: false, message: "Quantity value must be > 0" };
    }
    if (values?.discount_value && parseInt(values?.discount_value) === 0) {
      return { success: false, message: "Discount value must be > 0" };
    }
  };
  const deleteDiscountCode = (generatedDiscountCodeId, userId) => {
    api_delete(
      `user-discount-code/${generatedDiscountCodeId}/users/${userId}`
    ).then((data) => {
      if (data?.code !== 200 && data?.code !== 201) {
        setOpenAlert(true);
        let errorMessage = data?.message
          ? data.message
          : "Technical error! Contact the Developers Team";
        setAlertSeverity("warning");
        setAlertMessage(errorMessage);
      } else {
        getUserDiscountCodes();
        setAlertMessage("Discount code successfully deleted");
        setOpenAlert(true);
        setAlertSeverity("success");
      }
    });
  };

  const deleteGiftCode = (generatedGiftCodeId, userId) => {
    api_delete(`generated-gifts/${generatedGiftCodeId}/users/${userId}`).then(
      (data) => {
        if (data?.code !== 200 && data?.code !== 201) {
          setOpenAlert(true);
          let errorMessage = data?.message
            ? data.message
            : "Technical error! Contact the Developers Team";
          setAlertSeverity("warning");
          setAlertMessage(errorMessage);
        } else {
          getUserGiftCodes();
          setAlertMessage("Gift code successfully deleted");
          setOpenAlert(true);
          setAlertSeverity("success");
        }
      }
    );
  };
  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity("warning");
      setOpenAlert(true);
    } else {
      values.offer = values.offer.id;
      values.user = values.user.id;
      api_post(`buy-for-user`, values)
        .then((result) => {
          if (result?.errors) {
            showErrorMessage(result.errors);
          } else if (result?.code !== 200 && result?.code !== 201) {
            setAlertSeverity("warning");
            setAlertMessage(result?.message);
            setOpenAlert(true);
          } else {
            setAlertSeverity("success");
            setOpenMessageModal(true);
            setAlertMessage("Affiliation created successfully");
            setOpen(false);
            setValues({
              amount: 0,
              note: "",
              user: null,
              offer: null,
              quantity: 0,
              discount_method: "",
              discount_value: 0,
              rest_year: false,
            });
            setDiscountValues({
              ...discountValues,
              giftCode: "",
              code: "",
            });
            setUserDiscountCodes([]);
            initializeData(rowsPerPage);
            //window.location.href = '/finance/affiliations';
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };
  const getOffers = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name"])
      .replace("&search=", ";")
      .replace("&searchJoin=or", "&searchJoin=and");

    const result = await api_get(
      `offers?search=active:1${options}&page=${page.page}`
    );
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  const groupChange = (value) => {
    setValues({
      ...values,
      offer: value,
    });
  };
  const userChange = (value) => {
    setValues({
      ...values,
      user: value,
    });
  };
  const handleDiscountChange = (event) => {
    setDiscountValues({
      ...discountValues,
      [event.target.name]: event.target.value,
    });
  };
  const getUsers = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, [
      "name",
      "lastName",
      "id",
      "phone",
      "email",
    ]);
    const result = await api_get(
      `users?search=type.id=1&page=${page.page}${options}`
    );
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  const codeFormValidation = (values) => {
    if (!discountValues?.code) {
      return { success: false, message: "Code is missing" };
    }
    if (!values?.offerId) {
      return { success: false, message: "offer is missing" };
    }
    if (!values?.userId) {
      return { success: false, message: "User is missing" };
    }
    if (!values?.quantity && !values?.allYear) {
      return { success: false, message: "Quantity is missing" };
    }
  };
  const giftCodeFormValidation = (values) => {
    if (!giftCode) {
      return { success: false, message: "Code is missing" };
    }

    if (!values?.userId) {
      return { success: false, message: "User is missing" };
    }
  };
  const handleDiscountSubmit = () => {
    const data = {
      code: discountValues.code,
      offerId: values?.offer?.id,
      userId: values?.user?.id,
      allYear: values?.rest_year === false ? 0 : 1,
      quantity: values?.quantity.toString(),
    };
    const codeValidation = codeFormValidation(data);
    if (codeValidation) {
      setAlertMessage(codeValidation.message);
      setAlertSeverity("warning");
      setOpenAlert(true);
    } else {
      api_post(`discount-code`, data)
        .then((result) => {
          if (result.code !== 200) {
            showErrorMessage(result);
          } else {
            setAlertSeverity("success");
            setAlertMessage("User discount code successfully added");
            setOpen(true);
            getUserDiscountCodes();
            setValues({ ...values, quantity: "" });
            setDiscountValues({
              ...discountValues,
              code: "",
            });
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  };

  const handleGiftCodeSubmit = () => {
    const data = {
      code: giftCode,
      userId: values?.user?.id,
    };
    const codeValidation = giftCodeFormValidation(data);
    if (codeValidation) {
      setAlertMessage(codeValidation.message);
      setAlertSeverity("warning");
      setOpenAlert(true);
    } else {
      api_post(`generated-gifts`, data)
        .then((result) => {
          if (result.code !== 200) {
            showErrorMessage(result);
          } else {
            setAlertSeverity("success");
            setAlertMessage("User gift code successfully added");
            setOpenAlert(true);
            getUserGiftCodes();
            setGiftCode("");
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  };
  return (
    <div>
      {!noButton && (
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Buy for student
        </Button>
      )}

      <SnackBar
        open={openMessage}
        message={message}
        severity={severity}
        handleClose={handleMessageClose}
      />
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="responsive-dialog-title">Buy offer</DialogTitle>
        <DialogContent>
          <Box mt={1}>
            <AsyncPaginate
              loadOptions={getOffers}
              value={values.offer}
              onChange={groupChange}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder="Offer"
              additional={{
                page: 1,
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  height: "30px",
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "#000",
                  fontSize: theme.typography.fontSize,
                  fontFamily: theme.typography.fontFamily,
                }),
              }}
              menuPortalTarget={document.querySelector("MuiPaper-root")}
            />
          </Box>
          <Box mt={2}>
            <AsyncPaginate
              loadOptions={getUsers}
              value={values.user}
              onChange={userChange}
              getOptionLabel={(option) =>
                "[" +
                option.id +
                "]" +
                " " +
                option.name +
                " " +
                option.last_name +
                " " +
                (option?.phone ? option.phone : "") +
                " " +
                (option?.email ? "[" + option?.email + "]" : "")
              }
              getOptionValue={(option) => option.id}
              placeholder="User"
              additional={{
                page: 1,
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  height: "30px",
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "#000",
                  fontSize: theme.typography.fontSize,
                  fontFamily: theme.typography.fontFamily,
                }),
              }}
              menuPortalTarget={document.querySelector("MuiPaper-root")}
            />
          </Box>
          <Box m={0} pt={1}>
            <TextField
              autoFocus
              margin="dense"
              type="number"
              variant="outlined"
              name="quantity"
              label="Quantity"
              style={{ width: "fullWidth", zIndex: 0, position: "relative" }}
              value={values.quantity || ""}
              onChange={handleChange}
              fullWidth
            />
          </Box>
          <Box m={1} pt={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={values.rest_year}
                  onChange={handleChange}
                  name="rest_year"
                  color="primary"
                  disabled={values?.offer?.type === "pack" ? true : false}
                />
              }
              label="Rest year"
            />
          </Box>
          <Box>
            <TextField
              autoFocus
              fullWidth
              select
              variant="outlined"
              style={{ width: "fullWidth", zIndex: 0, position: "relative" }}
              size="small"
              name="discount_method"
              id="discount"
              label="Discount"
              value={values.discount_method || ""}
              onChange={handleChange}
            >
              {discounts.map((discount) => {
                return (
                  <MenuItem key="discount-value" value={discount.value}>
                    {discount.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
          <Box m={0} pt={1}>
            <TextField
              autoFocus
              margin="dense"
              type="number"
              variant="outlined"
              name="discount_value"
              label="Discount value"
              value={values.discount_value || ""}
              onChange={handleChange}
              fullWidth
              style={{ zIndex: 0, position: "relative" }}
            />
          </Box>
          <Box m={0} pt={1}>
            <TextField
              autoFocus
              margin="dense"
              variant="outlined"
              type="text"
              name="note"
              label="Note"
              value={values.note || ""}
              onChange={handleChange}
              fullWidth
              style={{ zIndex: 0, position: "relative" }}
            />
          </Box>
          {userDiscountCodes !== [] &&
            userDiscountCodes?.map((item) => {
              return (
                <div className={classes.codeListItem}>
                  <IconButton
                    onClick={() =>
                      deleteDiscountCode(item?.id, values?.user?.id)
                    }
                  >
                    <DeleteOutline style={{ verticalAlign: "middle" }} />{" "}
                  </IconButton>
                  <span style={{ fontWeight: "bold" }}>
                    {item?.discount_code_offer_division?.discount_code?.code}
                  </span>
                </div>
              );
            })}
          <div className={classes.messageCode}>Discount Code Information</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <TextField
              disabled={userDiscountCodes?.length >= 2}
              outlined
              name="code"
              label="ِEnter discount code"
              type="string"
              value={discountValues.code || ""}
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleDiscountSubmit}
                      className={classes.validate}
                      style={{
                        backgroundColor:
                          userDiscountCodes?.length >= 2
                            ? "lightgray"
                            : "#2ba7df",
                      }}
                      disabled={userDiscountCodes?.length >= 2}
                      edge="end"
                      root={{ borderRadius: "0.1em" }}
                    >
                      Add Code
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleDiscountChange}
            />
          </div>
          {userGiftCode !== [] &&
            userGiftCode?.map((item) => {
              return (
                <div className={classes.codeListItem}>
                  <IconButton
                    onClick={() => deleteGiftCode(item?.id, values?.user?.id)}
                  >
                    <DeleteOutline style={{ verticalAlign: "middle" }} />{" "}
                  </IconButton>
                  <span style={{ fontWeight: "bold" }}>{item?.gift_code}</span>
                </div>
              );
            })}
          <div className={classes.messageCode}>Gift Code Information</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <TextField
              disabled={userGiftCode?.length >= 2}
              outlined
              name="giftCode"
              label="ِEnter gift code"
              type="string"
              value={giftCode || ""}
              margin="dense"
              onChange={(e) => setGiftCode(e.target.value)}
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleGiftCodeSubmit}
                      className={classes.validate}
                      style={{
                        backgroundColor:
                          userGiftCode?.length >= 2 ? "lightgray" : "#2ba7df",
                      }}
                      disabled={userGiftCode?.length >= 2}
                      edge="end"
                      root={{ borderRadius: "0.1em" }}
                    >
                      Add Code
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBar
        open={openAlert}
        message={message}
        severity={severity}
        handleClose={handleCloseAlert}
      />
    </div>
  );
};

export default BuyForStudentDialog;
