import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Grid, makeStyles} from '@material-ui/core';
import BasicsInfo from './BasicsInfo';
import DestinataireList from "./DestinataireList";

const useStyles = makeStyles(() => ({
  root: {},
}));

const Details = ({
                   sms,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={3}
      {...rest}
    >
      <Grid
        item
        lg={4}
        md={6}
        xl={3}
        xs={12}
      >
        <BasicsInfo sms={sms} />
      </Grid>

      <Grid
        item
        lg={8}
        md={8}
        xl={8}
        xs={12}
      >
        <DestinataireList segment={sms && sms.segment} division={sms && sms.division} states={sms && sms.state}
                          groups={sms && sms.groups} phone={sms && sms.phone}
                          users={sms && sms.users} noAffiliated = {sms && sms.to_not_affiliated}
                          noEmail ={sms && sms.without_email} noLevels ={sms && sms.without_levels}
        />
      </Grid>
    </Grid>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  sms: PropTypes.object.isRequired,

};

export default Details;
