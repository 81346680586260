import React from 'react';
import PropTypes from 'prop-types';
import {ListItem, CardContent, CardHeader,
    Divider, ListItemIcon, ListItemText,
    makeStyles, Table, TableBody,
    TableCell, TableRow, Typography,
} from '@material-ui/core';
import Label from '../../../components/Label';
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import IconButton from "@material-ui/core/IconButton";
import {Link} from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const useStyles = makeStyles((theme) => ({
    root: {},
    fontWeightMedium: {
        fontWeight: theme.typography.fontWeightMedium,
    },
}));

const ListMarkSheets = ({
                            userMarks,
                        tableCell,
                        ...rest
                    }) => {
    const classes = useStyles();

    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }
    return (
        <>
        <CardHeader
            title="User MarkSheets List"
        />
    <Divider/>
    {userMarks && userMarks.length ? (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {tableCell.map((tableCell) => {
                            return (
                                <TableCell>{tableCell}</TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userMarks.map((mark) => (
                        <TableRow key={mark.name}>
                            <TableCell component="th" scope="row">
                                {mark.id}
                            </TableCell>
                            <TableCell>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    <Label
                                        color={'warning'}>
                                        {mark?.division?.name}
                                    </Label>
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.fontWeightMedium}>{mark.trimester}</TableCell>
                            <TableCell>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    <Label
                                        color={mark.status == 'processed' ? 'success' :mark.status == 'pending'? 'warning' : 'error'}>
                                        {mark.status}
                                    </Label>
                                </Typography>
                            </TableCell>
                            <TableCell>
                                        <ListItem>
                                            <ListItemLink href={mark?.url}>
                                                <ListItemIcon>
                                                    <FileCopyIcon/>
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={mark?.file_name}
                                                    style={{overflowWrap: 'break-word'}}
                                                    primaryTypographyProps={{variant: "h5"}}
                                                />
                                            </ListItemLink>
                                        </ListItem>
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    className={classes.signOutButton}
                                    color="inherit"
                                    component={Link}
                                    to={`/user-settings/mark-sheets/edit/${mark.id}`}
                                    target='_blank'
                                >
                                    <EditIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ) : (
        <CardContent>
            <Typography variant="body2" color="textSecondary"
                        component="p">
                This user does not have any markSheets
            </Typography>
        </CardContent>
    )}
        </>
    );
};

ListMarkSheets.propTypes = {
    className: PropTypes.string,
};

export default ListMarkSheets;
