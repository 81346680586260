import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Grid,
  Icon,
  TableRow,
  TableCell,
  Tooltip,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  CardContent,
  DialogTitle,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LoadingScreen from "../LoadingScreen";
import { openModal } from "../../slices/modals";
import { fetchMonthDetailsDirect } from "../../slices/incomes";
import { headersRowImportIncomes as headersRow } from "../../utils/constants";
import { headersNotExistTeacher as headersNotExisted } from "../../utils/constants";
import Typography from "theme/typography";
import useStyles, { colors } from "./modalStyle";

export const classBadge = (value) => {
  if (value === 0) return colors.disable.color;
  if (value > 0) return colors.balance.color;
  if (value < 0) return colors.fault.color;
};

const ImportedIncomes = ({ id, data, open, handleClose }) => {
  const classes = useStyles();
  const { status, notImportedData, notExistedInstuctors } = useSelector(
    (state) => state.incomes.importIncomes
  );
  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={(e, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <div className={classes.label}>{`Import Response`}</div>
          <div className={classes.close_btn} onClick={() => handleClose(id)}>
            X
          </div>
        </div>
      </DialogTitle>
      {status === "failed" && (
        <>
          <DialogContent className={classes.dialogContent}>
            <Grid
              container
              direction="column"
              justify="space-between"
              alignItems="center"
              className={classes.dialogContentHeader}
            >
              <Paper elevation={0}>
                <Alert severity="error">
                  Un error est servenu: Merci de contacter administrateur
                </Alert>
              </Paper>
            </Grid>
          </DialogContent>
        </>
      )}
      {status === "idle" && <div>Pas de contenu </div>}
      {status === "loading" && <LoadingScreen />}
      {status === "succeeded" && (
        <>
          <DialogContent className={classes.dialogContent}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.dialogContentHeader}
            ></Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Paper className={classes.importIncomes_title} elevation={0}>
                  Data not imported
                </Paper>
                <br/>
                {notImportedData?.length && (
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {headersRow.map((tableCell) => {
                            return (
                              <TableCell align={tableCell.align}>
                                {tableCell.title}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {notImportedData?.map((row, index) => (
                          <TableRow key={index}>
                            {" "}
                            <TableCell
                              style={{ fontWeight: "bold" }}
                              component="th"
                              scope="row"
                            >
                              {row?.instructor}
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              {row?.division}
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              {`${row?.price} dt`}
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              {row?.startDateSheet}
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              {row?.endDateSheet}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontWeight: "bold" }}
                            >
                              {row?.contentType}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{color:'#c20000', fontWeight: "bold" }}
                            >
                              {row?.note}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Paper className={classes.importIncomes_title} elevation={0}>
                  Teacher not existed
                </Paper>
                <br/>
                {notExistedInstuctors?.length && (
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {headersNotExisted?.map((tableCell) => {
                            return (
                              <TableCell align={tableCell.align}>
                                {tableCell.title}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {notExistedInstuctors?.map((row, index) => (
                          <TableRow key={index}>
                            {" "}
                            <TableCell
                              style={{ fontWeight: "bold" }}
                              component="th"
                              scope="row"
                            >
                              {row?.id}
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              {row?.phone}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default ImportedIncomes;
