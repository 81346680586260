// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  
  Divider,
  Grid, IconButton,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes, {instanceOf} from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import {DatePicker, LocalizationProvider} from '@material-ui/pickers';
import momentAdapter from '@material-ui/pickers/adapter/moment';
import Checkbox from '@material-ui/core/Checkbox';
import {api_get, api_post, api_put} from "../../../utils/Api";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import Expressions from './RuleExpression/Expressions';
import LoadingApi from 'components/LoadingApi/LoadingApi';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    overflow: 'visible',
  },
  mtop: {
    marginTop: 24,
  },
  mtop0: {
    marginTop: 0,
  },
 
  content: {
    padding: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
}));

const Form = (props) => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
    description: '',
    active: 1,
    startDate: null,
    endDate: null,
    hasForum: false,
    hasExam: false,
    freeDuration: 0,
  });
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(1);
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  useEffect(() => {
    if (edit && tab === 1) {
      setLoading(true)
      api_get(`groups/${id}`).then((result) => {
        setLoading(false)
        const data = result.payload;
        setValues({
          name: data.group.name,
          active: data.group.active,
          hasForum: data.group?.has_forum,
          hasExam: data.group?.has_exam,
          freeDuration: data.group?.free_duration,
          startDate: data.group.start_date,
          endDate: data.group.end_date,
          description: data.group.description,
          rule: data.rule ? Object.fromEntries(Object.entries(data.rule).sort()) : null
        });
      });
    }
  }, [edit, id, tab]);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const startDateChange = (value) => {
    if (value) {
      setValues({...values, startDate: value.format('YYYY-MM-DD hh:mm')});
    }
  };
  const endDateChange = (value) => {
    if (value) {
      setValues({...values, endDate: value.format('YYYY-MM-DD hh:mm')});
    }
  };
  const handleCheckChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked,
    });
  };

  const freeDurationChange = (e) => {
    setValues({
      ...values,
      freeDuration: e.target.value,
    });
  };

  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const handleSubmit = (expressions) => {
    let newValues={...values};
    if(expressions && expressions['expression-type-e0']) {
      newValues['rule']=expressions;
    }
    let val=expressions ? newValues : values;
    val.active = val.active === true ? 1 : 0;
    if (edit) {
      api_put(`groups/${id}`,val).then((result) => {
        if (result.errors) {
          showErrorMessage(result.errors);
        } else {
          setAlertSeverity('success');
          setLoading(false);
          setAlertMessage('User Group updated successfully');
          setOpen(true);
          window.location.href = '/acl/groups';
        }
      });
    } else {
      api_post(`groups`,val).then((result) => {
        if (result.errors) {
          showErrorMessage(result.errors);
        } else {
          setAlertSeverity('success');
          setAlertMessage('user created successfully');
          setLoading(false);
          setOpen(true);
          window.location.href = '/acl/groups';
        }
      }).catch((error) => {
        throw new Error(error);
      });
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }
  return (
      <div {...rest} className={clsx(classes.root, className)}>
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
        <TabContext value={tab}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <TabList onChange={handleChangeTab}
                     aria-label="simple tabs example" indicatorColor="primary"
                     textColor="primary">
              <Tab label="Basics" value={1}/>
              <Tab label="Permission Rules" value={2} />
            </TabList>
          </div>
          <TabPanel value={1}>
        <form autoComplete="off" noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Card className={classes.root}>
                <CardContent>
                  <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      required
                      value={values.name || ''}
                      variant="outlined"
                  />
                  <Box mt={3} >
                    <TextField
                        fullWidth
                        label="Description"
                        name="description"
                        onChange={handleChange}
                        required
                        value={values.description || ''}
                        variant="outlined"
                    />
                  </Box>
                </CardContent>
              </Card>
              <Box mt={3}>
                <Card>
                  <CardHeader title="Dates"/>
                  <Divider/>
                  <CardContent>
                    <Box mt={0} mb={1}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                          <LocalizationProvider dateAdapter={momentAdapter}>
                            <DatePicker
                                label="Start Date"
                                name="startDate"
                                inputFormat="DD/MM/yyyy"
                                inputVariant="outlined"
                                value={values.startDate}
                                onChange={startDateChange}
                                renderInput={(props) => <TextField
                                    {...props}
                                    fullWidth
                                    variant="outlined"
                                />}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <LocalizationProvider dateAdapter={momentAdapter}>
                            <DatePicker
                                label="End Date"
                                name="endDate"
                                inputFormat="DD/MM/yyyy"
                                inputVariant="outlined"
                                value={values.endDate}
                                onChange={endDateChange}
                                renderInput={(props) => <TextField
                                    {...props}
                                    fullWidth
                                    variant="outlined"
                                />}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box mt={0}>
                <Card className={classes.root}>
                  <CardHeader title="Status"/>
                  <Divider className={classes.mtop0}/>
                  <CardContent className={classes.mtop0}>
                    <Box mt={0}>
                      <FormControlLabel
                          control={
                            <Checkbox
                                checked={values.active}
                                onChange={handleCheckChange}
                                value={values.active}
                                name="active"
                                size="small"
                            />
                          }
                          label="Active"
                      />
                      <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.hasForum}
                          onChange={handleCheckChange}
                          value={values.hasForum}
                          name="hasForum"
                          size="small"
                        />
                      }
                      label="hasForum ?"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.hasExam}
                          onChange={handleCheckChange}
                          value={values.hasExam}
                          name="hasExam"
                          size="small"
                        />
                      }
                      label="hasExam ?"
                    />
                    </Box>
                    <Box mt={3}>
                    <TextField
                      type="number"
                      variant="outlined"
                      label="Free-Duration"
                      value={values?.freeDuration}
                      onChange={freeDurationChange}
                      InputProps={{ inputProps: { min: 0} }}
                    ></TextField>
                  </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Box mt={3}>
            <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
            >
              Save details
            </Button>
          </Box>
        </form>
          </TabPanel>
          <TabPanel value={2}>
            <Expressions userId={id} rule={values.rule} handleSubmit={handleSubmit} values={values} edit={edit}/>
          </TabPanel>
        </TabContext>
        </Card>
        <SnackBar
            open={open}
            message={message}
            severity={severity}
            handleClose={handleClose}
        />
       <LoadingApi open={loading} onClick={handleCloseLoading}/>
      </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,
};

export default Form;
