import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import Toolbar from '../../components/Toolbar/Toolbar'
import Table from '../../components/Table/Table';
import Collapse from '@material-ui/core/Collapse';
import Columns from '../../components/Columns';
import Filter from '../../components/Filter/Filter';
import optionsParser from '../../helpers/optionsParser';
import Loading from '../../components/Loading/Loading';
import {api_delete, api_get, get_file} from "../../utils/Api";
import { SearchUrlHandler } from 'helpers/methods';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));
const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', show: true, sortable: true, width: '5%'},
  {accessor: 'content.name', label: 'Title', show: true, width: '20%'},
  {accessor: 'subject_events', label: 'Subject', show: false, type:'concatSubject'},
  {accessor: 'content.active', type: 'boolean', color: 'primary', label: 'Active', show: true, width: '5%'},
  {accessor: 'content.is_draft', type: 'boolean', color: 'primary', label: 'Draft', show: true, width: '5%'},
  {accessor: 'canceled', type: 'boolean', color: 'primary', label: 'Canceled', show: true, width: '5%'},
  {accessor: 'url', label: 'Url', show: false, width: '20%'},
  {accessor: 'webinar_account.email', label: 'Webinar', show: true, width: '10%'},
  {accessor: 'start_date', name: 'startDate', label: 'Start Date', show: true, sortable: true, type : 'dateTime', width: '10%'},
  {accessor: 'end_date', name: 'endDate', label: 'End Date', show: true, sortable: true, type : 'dateTime', width: '10%'},
  {accessor: 'content.instructor', label: 'Professor', show: true, width: '10%', type: 'instructorSwitch'},
  {accessor: 'content.group_contents', type: 'groupType',label: 'Groups', show: false, width: '10%'},
  {accessor: 'content.creator_admin.username', label: 'Admin', show: true, width: '10%', type: 'fieldValue'},
  {accessor: 'content.created_at', label: 'created at', show: true, type : 'date', width: '10%'},
  {accessor: 'content.content_student_levels', label: 'Levels', show: false, type: 'studentLevels'},
  {accessor: 'reason', label: 'Reason', show: false, type: 'fieldValue'},
];
const fieldSearchable = [
  'id',
  //'content.name',
];
const fieldFilterable = [
  {name: 'content.name', label: 'Name', type: 'text'},
  {name: 'url', label: 'Url', type: 'text'},
  {name: 'webinarAccount', label: 'Webinar account', type: 'webinarAccountAutocomplete',},
  {name: 'content.instructor.id', label: 'Instructor', type: 'instructorAutocomplete',},
  {name: 'subjectEvents.subject.division.id', label: 'Division', type: 'divisionAutocomplete',},
  {name: 'subjectEvents.subject.id', label: 'Subject', type: 'subjectAutocomplete',},
  {name: 'content.groupContents.groups.id', label: 'Groups', type: 'userGroupAutocomplete',},
  {name: 'startDate', type: 'dateRange', label: 'Start Date'},
  //{name: 'endDate', type: 'date', label: 'End Date'},
  {name: 'content.creatorAdmin.id', label: 'Admin', type: 'adminAutocomplete'},
  {name: 'canceled', label: 'Canceled', type: 'boolean'},
];
const ContentEventList = () => {
  const [search, searchChange] = useState('');
  const [filters, filtersChange] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [columns, columnsChange] = useState(columnsList);
  const [sort, sortChange] = useState({accessor: 'id', order: 'desc'});
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(24);
  useEffect(() => {
    getData();
  }, [page, sort, rowsPerPage]);

  useEffect(() => {
    if (search.length) {
      const options = optionsParser(search, filters, sort, fieldSearchable).replace( '&search=', ';' );
      setPage(1);
      setIsSearch(true)
      api_get(`events?page=${page}&search=traineeship:isnull${options}&searchJoin=and`).then((data) => {
        setData(data);
      });
    }
    if(Object.keys(filters).length){
      const options = optionsParser(search, filters, sort, fieldSearchable)
          .replace( '&search=', ';' )
          .replace('&search=', 'search=');

      const formattedOptions = SearchUrlHandler(window.location.search, sort)
          .replace( '&search=', ';' )
          .replace('&searchJoin=and', '')
          .replace('&search=', 'search=');

      setPage(1);
      api_get(`events?page=${page}&search=traineeship:isnull${window.location.search ? formattedOptions : options}&searchJoin=and`).then((data) => {
        setData(data);
      });
    }
  }, [filters, search]);

  useEffect(() => {
    if (!search && isSearch) {
      setIsSearch(false)
      setPage(1);
      getData();
    }
  }, [search]);
  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };
  const toggleColumns = () => {
    setColumnsOpen(!columnsOpen);
  };
  const handleSortChange = (accessor) => {
    sortChange({
      accessor: accessor,
      order: sort.order === 'asc' ? 'desc' : 'asc',
    });
  };

  const getData = () => {
    const options = optionsParser(search, filters, sort, fieldSearchable);
    const formattedOptions = SearchUrlHandler(window.location.search, sort)
        .replace( '&search=', ';' )
        .replace('&searchJoin=and', '')

    api_get(`events?search=traineeship:isnull${window.location.search ? formattedOptions: options}&page=${page}&perPage=${rowsPerPage}`).then((data) => {
      setData(data);
    });
  };

  const getListData = () => {
    const options = optionsParser(null, null, sort, null);
    const formattedOptions = SearchUrlHandler(window.location.search, sort)
        .replace( '&search=', ';' )
        .replace('&searchJoin=and', '')

    api_get(`events?search=traineeship:isnull&page=${page}${window.location.search ? formattedOptions: options}&perPage=${rowsPerPage}`).then((data) => {
      setData(data);
    });
  };
  const handlePageChange = (event, page) => {
    setPage(page + 1);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    //getData(event.target.value)
  };

  const handleExport = () => {
    const options = optionsParser(search, filters, sort, fieldSearchable);
    get_file(`events/export?searchJoin=and&page=${page}${options}`)
  }
  const classes = useStyles();
  if (!data.payload) {
    return (<Loading/>);
  }
  return (
      <div className={classes.root}>
        <Toolbar toggleFilters={toggleFilters} toggleColumns={toggleColumns}
                 searchChange={searchChange} pageLink='/contents/content-events' searchMessage={'Search (ID)'}
                 cacheUrl='flush-key/event' cache_method = {api_get} initializeData={getListData}
                 handleExport={handleExport}
        />
        <div className={classes.content}>
          <Collapse in={columnsOpen}>
            <Columns columnsChange={columnsChange} columns={columns}/>
          </Collapse>
          <Collapse in={filtersOpen}>
            <Filter fields={fieldFilterable} values={filters}
                    filtersChange={filtersChange} initializeData={getListData}/>
          </Collapse>
          <Table columns={columns} data={data}
                 handleSortChange={handleSortChange} sort={sort}
                 handlePageChange={handlePageChange} page={page}
                 rowsPerPage={rowsPerPage}
                 handleRowsPerPageChange={handleRowsPerPageChange}
                 tableService={{method: api_delete, base_url: `events`}}
                 deleteUrl={`delete/many-events`}
                 keyValue={"events"} 
                 pageLink='/contents/content-events' 
                 initializeData={getData}
                 replace ={true}/>
        </div>
      </div>
  );
};

export default ContentEventList;
