// @flow
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Box,
  CardMedia,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { api_get, api_post } from "../../../utils/Api";
import { useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import MenuItem from "@material-ui/core/MenuItem";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import GetAppIcon from "@material-ui/icons/GetApp";
import ContentCopyIcon from "@material-ui/icons/FileCopy";
import CopyCode from "./CopyCode";
import LoadingApi from "components/LoadingApi/LoadingApi";
import theme from "theme";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
  button: {
    margin: "10px",
  },
  importButton: {
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(-15),
  },

  downloadButton: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(-15),
    flexGrow: 1,
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginTop: 100,
    },
  },
}));
type Props = {
  edit: boolean,
  className: string,
};

const Form = (props: Props): React$Element<any> => {
  const { id } = useParams();
  const { className, edit, ...rest } = props;
  const [accounts, getAccounts] = useState([]);
  const [importedCodes, getCodes] = useState([]);
  const [message, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [severity, setAlertSeverity] = useState("success");
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);
  const responsive = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  const [values, setValues] = useState({
    codes: [
      {
        reference: "",
        amount: 0,
        comment: "",
        account: null,
        office: null,
        type: "",
      },
    ],
  });

  useEffect(() => {
    api_get(`company-accounts`).then((data) => {
      getAccounts(data.payload);
    });
    if (edit) {
      setLoading(true);
      api_get(`codes/${id}`).then((result) => {
        setLoading(false);
        const data = result.payload;
        setValues({
          reference: data.reference,
          amount: data.amount,
          comment: data.comment,
          account: data.account,
          office: data.office,
          type: data.type,
        });
      });
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
  };
  const handleModalOpen = () => {
    setOpenModal(!openModal);
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function (key, index) {
      if (index == 0) {
        setAlertSeverity("error");
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const formValidation = (values) => {
    return values?.codes.map((code, index) => {
      if (!code?.reference) {
        return {
          success: false,
          message: "Reference " + index + " is missing",
        };
      }
      if (!code?.account) {
        return { success: false, message: "Account " + index + " is missing" };
      }
      if (!code?.amount) {
        return { success: false, message: "Amount " + index + " is missing" };
      }
      if (!code?.type) {
        return { success: false, message: "Type " + index + " is missing" };
      }
    });
  };
  const handleSubmit = () => {
    const validations = formValidation(values).filter(
      (validation) => validation?.message && validation.message !== undefined
    );
    setLoading(true);
    if (validations?.length > 0) {
      validations.map((validation) => {
        setAlertMessage(validation?.message);
        setAlertSeverity("warning");
        setOpen(true);
      });
    } else {
      api_post(`many-codes`, values)
        .then((result) => {
          if (result.errors) {
            setAlertSeverity("error");
            showErrorMessage(result.errors);
          } else {
            setAlertSeverity("success");
            setAlertMessage("code created successfully");
            setOpen(true);
            setLoading(false);
            window.location.href = "/finance/finance-codes";
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  };

  const AddRemoveCode = (operation, index) => {
    if (operation == "plus") {
      setValues((previousData) => ({
        ...previousData,
        codes: values?.codes?.concat({
          reference: "",
          amount: "",
          comment: values?.codes[0]?.comment,
          account: values?.codes[0]?.account,
          type: values?.codes[0].type,
        }),
      }));
    } else {
      setValues((previousData) => ({
        ...previousData,
        codes: values.codes.filter((num, i) => {
          return i != index;
        }),
      }));
    }
  };

  const handleFiledsChange = (event, index) => {
    const newArr = { ...values };
    newArr.codes[index][event.target.name] = event.target.value;
    setValues({ ...newArr });
  };
  const onFileChange = (e) => {
    const file = e.target.files[0];
    onFileUpload(file);
  };
  const onFileUpload = (file) => {
    api_post(`codes/upload`, { file }, true)
      .then((result) => {
        if (result.errors) {
          showErrorMessage(result.errors);
        } else {
          getCodes(result);
          setValues((values) => ({
            ...values,
            codes: result.importedCodes,
          }));
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  return (
    <div>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <CardMedia>
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            startIcon={<ContentCopyIcon />}
            className={classes.downloadButton}
            onClick={handleModalOpen}
          >
            Copy Codes
          </Button>
        </CardMedia>
        <CardMedia className={classes.button}>
          <Button
            component="label"
            color="primary"
            variant="outlined"
            fullWidth
            startIcon={<CloudUploadIcon />}
            className={classes.importButton}
          >
            Import
            <input
              type="file"
              style={{ display: "none" }}
              onChange={onFileChange}
            />
          </Button>
        </CardMedia>
        <CardMedia>
          {!responsive && (
            <Button
              variant="outlined"
              size="medium"
              color="primary"
              startIcon={<GetAppIcon />}
              component={"a"}
              href={"/samples/import-codes.xlsx"}
              className={classes.downloadButton}
            >
              Download Sample
            </Button>
          )}
        </CardMedia>
      </div>

      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <CardHeader subheader="Codes form" />
          <Divider />
          <CardContent>
            {values.codes.map((code, index) => {
              return (
                <Grid container spacing={3} key={index}>
                  <p></p>
                  <Grid item md={2} xs={4}>
                    <TextField
                      fullWidth
                      name="reference"
                      id="reference"
                      variant="outlined"
                      label="Reference"
                      value={
                        code.reference ? code.reference : code["reference"]
                      }
                      onChange={(e) => handleFiledsChange(e, index)}
                    />
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <TextField
                      fullWidth
                      select
                      freeSolo
                      name="account"
                      id="account"
                      variant="outlined"
                      label="account"
                      value={code.account}
                      onChange={(e) => handleFiledsChange(e, index)}
                    >
                      {accounts.map((account) => {
                        return (
                          <MenuItem key={account.id} value={account.id}>
                            {account.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <TextField
                      fullWidth
                      type="number"
                      name="amount"
                      variant="outlined"
                      label="amount"
                      value={code.amount ? code.amount : code["amount"]}
                      onChange={(e) => handleFiledsChange(e, index)}
                    />
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <TextField
                      fullWidth
                      select
                      name="type"
                      variant="outlined"
                      label="Type"
                      // defaultValue="payment"
                      value={code.type ? code.type : code["type"]}
                      onChange={(e) => handleFiledsChange(e, index)}
                    >
                      <MenuItem key="payment" value="payment">
                        Versement
                      </MenuItem>
                      <MenuItem key="transfer" value="transfer">
                        Virement
                      </MenuItem>
                      <MenuItem key="cash" value="cash">
                        Espèce
                      </MenuItem>
                      <MenuItem key="check" value="check">
                        Chèques
                      </MenuItem>
                      <MenuItem key="poste" value="poste">
                        Poste
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <TextField
                      fullWidth
                      name="comment"
                      id="comment"
                      variant="outlined"
                      label="Comment"
                      value={code.comment ? code.comment : code["comment"]}
                      onChange={(e) => handleFiledsChange(e, index)}
                    />
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <Box m={1} pt={1} hidden={index !== 0}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => AddRemoveCode("plus")}
                      >
                        +
                      </Button>
                    </Box>
                    <Box m={1} pt={1} hidden={index === 0}>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={(event) => AddRemoveCode(event, index)}
                      >
                        -
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </CardContent>
          <Divider />
          <CardActions>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
      <CopyCode
        open={openModal}
        setOpenModal={setOpenModal}
        accounts={accounts}
      />
      <LoadingApi open={loading} onClick={handleCloseLoading} />
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
  loading: PropTypes.bool,
};

export default Form;
