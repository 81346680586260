// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,

  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import {api_get, api_post, api_put} from "../../../utils/Api";
import LoadingApi from 'components/LoadingApi/LoadingApi';

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
  
}));
type Props = {
  edit: boolean,
  className: string
}
const Form = (props: Props): React$Element<any> => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
    description: '',
  });
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) {
      setLoading(true)
      api_get(`content-scopes/${id}`).then((result) => {
        setLoading(false)
        const data = result.payload;
        setValues({
          name: data.name,
          description: data.description,
        });
      });
    }
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const formValidation = (values) => {
    if (!values?.name) {
      return { success: false, 'message': 'Name is missing' };
    }
    if (!values?.description) {
      return { success: false, 'message': 'Description is missing' };
    }
  };
  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
      setLoading(true);
      if (edit) {
        setLoading(true)
        api_put(`content-scopes/${id}`, values).then((result) => {
          setLoading(false)
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            setAlertSeverity('success');
            setAlertMessage('Scope updated successfully');
            setOpen(true);
            setLoading(false);
            window.location.href = '/contents/content-scopes';
          }
        });
      } else {
        api_post('content-scopes', values)
            .then((result) => {
              if (result.errors) {
                showErrorMessage(result.errors);
              } else {
                setAlertMessage('Scope created successfully');
                setAlertSeverity('success');
                setOpen(true);
                setLoading(false);
                window.location.href = '/contents/content-scopes';
              }
            })
            .catch((error) => {
              throw new Error(error);
            });
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }
    
  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete='off' noValidate>
          <CardHeader subheader='Scopes form'  />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label='Name'

                  name='name'
                  onChange={handleChange}
                  required
                  value={values.name || ''}
                  variant='outlined'
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label='Description'

                  name='description'
                  onChange={handleChange}
                  required
                  value={values.description || ''}
                  variant='outlined'
                />
              </Grid>
              {/* <Grid
                item
                md={6}
                xs={12}
              >
                <FormControl component="fieldset">
                  <FormLabel component="legend">Public</FormLabel>
                  <RadioGroup aria-label="Public" name="is_public"
                    onChange={handleChange}
                    value={values.is_public || ''} row>
                    <FormControlLabel control={<Radio/>} value="1"
                      label="Yes"/>
                    <FormControlLabel control={<Radio/>} value="0"
                      label="No"/>
                  </RadioGroup>
                </FormControl>
              </Grid> */}
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color='primary'
              variant='contained'
              onClick={handleSubmit}
            >
              Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
     <LoadingApi open={loading} onClick={handleCloseLoading}/>
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,
};

export default Form;
