import React, {createContext, useContext, useEffect, useReducer} from 'react';
import jwtDecode from 'jwt-decode';
import SplashScreen from '../components/SplashScreen';
import axios from '../utils/axios';
import headers from '../helpers/headers';

const apiUrl = process.env.REACT_APP_API_URL;

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = (token) => {
  if (!token) {
    return false;
  }

  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (token) => {
  if (token) {
    localStorage.setItem('token', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const {isAuthenticated, user} = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      const {user} = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case 'REGISTER': {
      const {user} = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return {...state};
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {
  },
  register: () => Promise.resolve(),
});

export const AuthProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const login = async (username, password) => {
    const response = await axios.post(`/auth/login_check`, {
      username,
      password,
    });
    const {token, user} = response.data.payload;

    setSession(token);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        token,
      },
    });
    return window.location = '/';
  };

  const logout = () => {
    setSession(null);
    dispatch({type: 'LOGOUT'});
  };

  const register = async (email, name, password) => {
    const response = await axios.post('/api/account/register', {
      email,
      name,
      password,
    });
    const {token, user} = response.data;

    window.localStorage.setItem('token', token);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const token = window.localStorage.getItem('token');
        if (token && isValidToken(token)) {
          const response = await axios.get(`/me`, {headers});
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user:response.data.payload,
            },
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen/>;
  }

  return (
      <AuthContext.Provider
          value={{
            ...state,
            method: 'JWT',
            login,
            logout,
            register,
          }}
      >
        {children}
      </AuthContext.Provider>
  );
};

export default AuthContext;
export const useStore = () => useContext(AuthContext);

