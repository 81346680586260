import React from "react";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {},
}));

const AffiliationsNotes = ({ className, notes, ...rest }) => {
  const classes = useStyles();
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Affiliations Notes" />
      <Divider />
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Note</TableCell>
              <TableCell>CreatedBy</TableCell>
              <TableCell>CreatedAt </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notes?.length ? (
              notes.map((value) => (
                <TableRow key={value.id}>
                  <TableCell>{value.note}</TableCell>
                  <TableCell>{value?.admin_user?.username}</TableCell>
                  <TableCell>
                    {moment(value.created_at).format("DD/MM/YYYY HH:mm")}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell>No affiliation notes available.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

AffiliationsNotes.propTypes = {
  className: PropTypes.string,
  notes: PropTypes.object.isRequired,
};

export default AffiliationsNotes;
