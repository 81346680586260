import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PersonOutline';
import Label from '../../../components/Label';
import {api_get} from "../../../utils/Api";

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const BasicsInfo = ({
                      user,
                      className,
                      ...rest
                    }) => {
  const classes = useStyles();

  const fullName=(firstName,lastName)=>{
    return `${firstName} ${lastName}`
  }
  return (
      <Card
          className={clsx(classes.root, className)}
          {...rest}
      >
        <CardHeader title="Parent info"/>
        <Divider/>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                ID
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.id}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Full Name
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {fullName(user.last_name,user.name)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Email
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.email ?? '---'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Phone
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.phone ?? '---'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Brith date
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.birth_date ?? '---'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Country
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.country?.name ?? '---'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                State
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.state?.name ?? '---'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Delegation
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.delegation?.name ?? '---'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Completed
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  <Label color={user.completed == '1' ? 'success' : 'error'}>
                    {user.completed == '1' ? 'Yes' : 'No'}
                  </Label>                
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Verified
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                   <Label color={user.completed == '1' ? 'success' : 'error'}>
                  {user.completed == '1' ? 'Yes' : 'No'}
                </Label>            
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box
            p={1}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
        >
        </Box>
      </Card>
  );
};

BasicsInfo.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired,
};

export default BasicsInfo;
