// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid, Paper,
  TextField, Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { api_get, api_post, api_put } from "../../../utils/Api";
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import mapStateToRequest from '../../../helpers/mapStateToRequest';
import Checkbox from "@material-ui/core/Checkbox";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import DescriptionField from 'components/DescriptionField';
import LoadingApi from 'components/LoadingApi/LoadingApi';
import {getSubjects, getGroups} from "../../../helpers/methods";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    overflow: "visible",
  },
  mtop: {
    marginTop: 24,
  },

  mtop0: {
    marginTop: 0,
  },
  content: {
    padding: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
}));
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
type Props = {
  edit: boolean,
  className: string
}
const Form = (props: Props): React$Element<any> => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
    visible: true,
    description:undefined,
    subject: null,
    position: 1,
    type: 'course',
    chapterGroups: [],
  });
  const [message, setAlertMessage]=useState('');
  const [severity, setAlertSeverity]=useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) {
      setLoading(true)
      api_get(`chapters/${id}`).then((result)=>{
        setLoading(false);
        const data= result.payload;
        setValues({
          name: data.name,
          visible: data.visible,
          description: data.description,
          subject: data.subject,
          position: data.position,
          type: data.type,
          chapterGroups: data?.chapter_groups,

        });
      });
    }
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const groupsChange = (options) => {
      setValues({
        ...values,
        chapterGroups: options,
      });

  };

  const handleCheckChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked,
    });
  };
  const handleDescriptionChange = (event) => {
    setValues((events)=>({ ...events, description: event.editor.getData() }));
  };
  const showErrorMessage=(errors)=>{
    Object.keys(errors).map(function(key, index) {
      if (index==0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const chapterFormat = (values) => {
    let newArr = [];
    values.chapterGroups && values.chapterGroups.map(option=>{
      newArr.push({'group': option.group? option.group.id:option.id})
    })
    values.chapterGroups = newArr;
    return values;
  }
  const formValidation = (values) => {
    if (!values?.name) {
      return { success: false, 'message': 'Name is missing' };
    }
    if (!values?.type) {
      return { success: false, 'message': 'Chapter\'s type is missing' };
    }
    if (!values?.subject || values?.subject?.length <= 0) {
      return { success: false, 'message': 'Subjects are missing' };
    }
  };
  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
      const valuesState = chapterFormat(values);
      setLoading(true);
      const requestValues = mapStateToRequest(valuesState, ['chapterGroups']);
      requestValues.visible = requestValues.visible == 1 ? true : false;
      if (edit) {
        api_put(`chapters/${id}`, requestValues).then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            setAlertSeverity('success');
            setAlertMessage('Chapter updated successfully');
            setOpen(true);
            setLoading(false);
            window.location.href = '/contents/chapters';
          }
        });
      } else {
        api_post(`chapters`, requestValues).then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            setAlertSeverity('success');
            setAlertMessage('chapter created successfully');
            setOpen(true);
            setLoading(false);
            window.location.href = '/contents/chapters';
          }
        }).catch((error) => {
          throw new Error(error);
        });
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }
  const subjectChange = (options) => {
    setValues({...values, subject: options});
  };

  return (
      <div {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Card className={classes.root}>
                <CardContent>
                  <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      required
                      value={values.name || ""}
                      variant="outlined"
                      size="small"
                  />
                  <Box mt={2} mb={1}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Description
                    </Typography>
                  </Box>
                  <Paper variant="outlined">
                  {(edit && values?.description  ) ? (
                    <DescriptionField
                      values={values}
                      handleBodyChange={handleDescriptionChange}
                      value={values?.description}
                    />
                  ) : !edit ? (
                    <DescriptionField
                      values={values}
                      handleBodyChange={handleDescriptionChange}
                    />
                  ) : values?.description===null || values?.description==='' ?(
                    <DescriptionField
                    values={values}
                    handleBodyChange={handleDescriptionChange}
                  />
                  ):
              ""}
                  </Paper>
                </CardContent>
              </Card>
              <Box mt={3}>
                <Card>
                  <CardHeader title="Subjects" />
                  <Divider />
                  <CardContent>
                    <Box mt={0} mb={1}>
                        <AsyncPaginate
                            loadOptions={getSubjects}
                            value={values.subject}
                            onChange={subjectChange}
                            getOptionLabel={(option) => option.division.name+' | '+option.name}
                            getOptionValue={(option) => option}
                            placeholder="Subjects"
                            isMulti={!edit}
                            additional={{
                              page: 1,
                            }}
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: '53px',
                              }),
                              placeholder: (base) => ({
                                ...base,
                                color: '#000',
                                fontSize: theme.typography.fontSize,
                                fontFamily: theme.typography.fontFamily,
                              }),
                            }}
                            menuPortalTarget={document.querySelector('body')}
                        />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box mt={0}>
                <Card className={classes.root}>
                  <CardHeader title="Status" />
                  <Divider className={classes.mtop0} />
                  <CardContent className={classes.mtop0}>
                    <Box mt={0}>
                      <FormControlLabel
                          control={
                            <Checkbox
                                checked={values.visible}
                                onChange={handleCheckChange}
                                value={values.visible}
                                name="visible"
                                size="small"
                            />
                          }
                          label="Visible ?"
                      />
                    </Box>
                    <Box mt={2}>
                      <TextField
                          fullWidth
                          label="Position"
                          name="position"
                          onChange={handleChange}
                          required
                          value={values.position || ''}
                          variant="outlined"
                      />
                    </Box>
                    <Box mt={2}>
                      <FormControl variant="outlined" fullWidth>
                      <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={values.type}
                          onChange={handleChange}
                          name="type"
                      >
                        <MenuItem value="course">Course</MenuItem>
                        <MenuItem value="recording">Recording</MenuItem>
                        <MenuItem value="exam">Exams</MenuItem>
                        <MenuItem value="divers">Divers</MenuItem>

                      </Select>
                      </FormControl>
                    </Box>

                  </CardContent>
                </Card>
              </Box>
              <Box mt={3}>
                <Card className={classes.root}>
                  <CardHeader title="Allowed Groups" />
                  <Divider className={classes.mtop0} />
                  <CardContent className={classes.mtop0}>
                    <Box mt={0}>
                      <AsyncPaginate
                          loadOptions={getGroups}
                          value={values.chapterGroups}
                          onChange={groupsChange}
                          getOptionLabel={(option) => option.group? option.group.name: option.name}
                          getOptionValue={(option) => option.group? option.group.id:option.id}
                          placeholder="Groups"
                          isMulti
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>

          <Box mt={3}>
            <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
            >
              Save details
            </Button>
          </Box>
        </form>
        <SnackBar
            open={open}
            message={message}
            severity={severity}
            handleClose={handleClose}
        />
 <LoadingApi open={loading} onClick={handleCloseLoading}/>
      </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,


};

export default Form;
