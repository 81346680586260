import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Grid,
  TableRow,
  TableCell,
  Tooltip,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  CardContent,
  DialogTitle,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LoadingScreen from "../LoadingScreen";
import { openModal } from "../../slices/modals";
import { fetchMonthDetailsDirect } from "../../slices/incomes";
import { headersRowMonthIncomes as headersRow } from "../../utils/constants";
import Typography from "theme/typography";
import useStyles, { colors } from "./modalStyle";

export const classBadge = (value) => {
  if (value === 0) return colors.disable.color;
  if (value > 0) return colors.balance.color;
  if (value < 0) return colors.fault.color;
};

const MonthIncomes = ({ id, data, open, handleClose }) => {
  const classes = useStyles();
  const { MonthData, status } = useSelector(
    (state) => state.incomes.monthIncomes
  );
  const { month, year } = useSelector((state) => state.incomes.currentDataDate);
  const dispatch = useDispatch();
  const tableData = MonthData?.data;

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={(e, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <div
            className={classes.label}
          >{`${MonthData?.info?.month} ${MonthData?.info?.year}`}</div>
          <div className={classes.close_btn} onClick={() => handleClose(id)}>
            X
          </div>
        </div>
      </DialogTitle>
      {status === "failed" && (
        <>
          <DialogContent className={classes.dialogContent}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.dialogContentHeader}
            >
              <Paper elevation={0}>
                <Alert severity="error">
                  Un error est servenu: Merci de contacter administrateur
                </Alert>
              </Paper>
            </Grid>
          </DialogContent>
        </>
      )}
      {status === "idle" && <div>Pas de contenu </div>}
      {status === "loading" && <LoadingScreen />}
      {status === "succeeded" && (
        <>
          <DialogContent className={classes.dialogContent}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.dialogContentHeader}
            >
              <Paper className={classes.point_title} elevation={0}>
                Les revenus
              </Paper>
              <Paper className={classes.point_info}>
                <div
                  style={{
                    color: `${classBadge(MonthData?.info?.totalBalance)}`,
                  }}
                >
                  {`${MonthData?.info?.totalBalance} pts`}
                </div>
              </Paper>
            </Grid>
            <Grid container spacing={3}>
              <Card>
                <form autoComplete="off" noValidate>
                  {tableData?.length ? (
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            {headersRow.map((tableCell) => {
                              return <TableCell>{tableCell.title}</TableCell>;
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableData.map((row, index) => (
                            <TableRow key={row?.id}>
                              <TableCell component="th" scope="row">
                                {row?.articleName}
                              </TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>
                                {row?.quantity}
                              </TableCell>

                              <TableCell
                                align="left"
                                style={{ fontWeight: "bold" }}
                              >
                                {row.unitPrices.length > 0 ? (
                                  <Tooltip
                                    title={row.unitPrices.map((t) => (
                                      <h3 key={t.title}>
                                        {`${t.title}: ${t.unitPrice} pts`}{" "}
                                      </h3>
                                    ))}
                                    placement="right"
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      cursor: "pointer",
                                      display: "inline-block",
                                    }}
                                  >
                                    <h2>...</h2>
                                  </Tooltip>
                                ) : (
                                  "0"
                                )}
                              </TableCell>
                              <TableCell
                                style={{ color: "red", fontWeight: "bold" }}
                              >
                                {row?.default}
                              </TableCell>
                              <TableCell
                                style={{ color: "#00B828", fontWeight: "bold" }}
                              >
                                {row?.totalPrice}
                              </TableCell>
                              {row.articleName === "Séan. en direct" ? (
                                <TableCell
                                  align="right"
                                  style={{
                                    color: "#0093e2",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    dispatch(
                                      fetchMonthDetailsDirect({
                                        instructor: data.id,
                                        month,
                                        year,
                                      })
                                    );
                                    dispatch(
                                      openModal(
                                        "live-session-month-incomes-modal",
                                        { id: data.id }
                                      )
                                    );
                                    handleClose(id);
                                  }}
                                >
                                  <span>Voir détails</span>
                                </TableCell>
                              ) : (
                                <TableCell></TableCell>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <CardContent>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        This Teacher does not have any items
                      </Typography>
                    </CardContent>
                  )}
                </form>
              </Card>
            </Grid>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default MonthIncomes;
