import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Checkbox from '@material-ui/core/Checkbox';
import SnackBar from '../SnackBar';
import {makeStyles} from '@material-ui/styles';
import {adminRoleActions} from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  inner: {
    minWidth: 1050,
  },
  actions: {
    justifyContent: 'flex-end',
  },

}));


const AdminRoleTable = ({  roles,
                                     checkedRoles,
                                     handleClose,
                                     open,
                                     message,
                                     severity,
                                     handleChange }) =>
{
  const classes = useStyles();
  const formatAdminRole = (role, action) => {
    return `ROLE_${role}_${action}`;
  };
  const checkChecked = (role,action) => {
    const exist_role = checkedRoles?.find(r => r === formatAdminRole(role,action));
    if (!exist_role) {
      return false;
    }
   return true
  };
  return (

      <div className={classes.inner}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Roles/Actions</TableCell>
                {adminRoleActions.map((role) => (
                    <TableCell align="right">{role}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {roles &&
              roles?.map((role, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {role}
                    </TableCell>

                    {adminRoleActions.map(action => {
                      return (
                          <TableCell align="right">
                            <Checkbox
                                checked={checkChecked(role,action)}
                                onChange={() => handleChange(formatAdminRole(role,
                                    action),true)}
                                inputProps={{'aria-label': 'primary checkbox'}}
                            />
                          </TableCell>
                      );
                    })}

                  </TableRow>

              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SnackBar open={open} message={message} severity={severity}
                  handleClose={handleClose}/>

        {/*<TablePagination*/}
        {/*    component="div"*/}
        {/*    count={roles.total}*/}
        {/*    onChangePage={handlePageChange}*/}
        {/*    page={page - 1}*/}
        {/*    rowsPerPage={10}*/}
        {/*    rowsPerPageOptions={[10]}*/}
        {/*/>*/}
      </div>
  );
};

export default AdminRoleTable;