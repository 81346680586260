import {
  Button,
  Card,
  CardActionArea,
  CardHeader,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import Checkbox from "@material-ui/core/Checkbox";
import momentAdapter from "@material-ui/pickers/adapter/moment";
import Moment from "moment";
import { AsyncPaginate } from "react-select-async-paginate";
import DescriptionField from "../../../components/DescriptionField";
import theme from "../../../theme";
import optionsParser from "helpers/optionsParser";
import { api_get } from "utils/Api";
import { values } from "underscore";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const DivisionDetailForm = (props) => {
  const {
    formState,
    setFormState,
    divisionId,
    classes,
    schema,
    className,
    ...rest
  } = props;
  const details = formState.values.offerDivisionDetails;
  const selectedOptions =
    details && details[divisionId]?.periods
      ? details[divisionId]?.periods
      : null;
  const mapped = [];
  if (typeof selectedOptions === "string") {
    selectedOptions.split(",").forEach(([key, value]) =>
      mapped.push({
        label: key,
        value: key,
      })
    );
  }

  const [subject, setSubject] = useState("");
  const [divisionFormState, setDivisionFormState] = useState({
    isValid: false,
    values: {
      id:
        details && details[divisionId] && details[divisionId]?.id
          ? details[divisionId]?.id
          : "",
      division: divisionId,
      periods: mapped?.length > 0 ? mapped : selectedOptions,
      description:
        details && details[divisionId]?.description
          ? details[divisionId]?.description
          : formState?.values?.description,
      price:
        details && details[divisionId]?.price
          ? details[divisionId]?.price
          : formState?.values?.price,
     weeklyPrice:details && details[divisionId]?.weeklyPrice
          ? details[divisionId]?.weeklyPrice
          : formState?.values?.weeklyPrice,
     dailyPrice:details && details[divisionId]?.dailyPrice
          ? details[divisionId]?.dailyPrice
          : formState?.values?.dailyPrice,
     dailyDigitalVirgoServiceId:details && details[divisionId]?.dailyDigitalVirgoServiceId
     ? details[divisionId]?.dailyDigitalVirgoServiceId
     : formState?.values?.dailyDigitalVirgoServiceId,
     weeklyDigitalVirgoServiceId:details && details[divisionId]?.weeklyDigitalVirgoServiceId
     ? details[divisionId]?.weeklyDigitalVirgoServiceId
     : formState?.values?.weeklyDigitalVirgoServiceId,

      active: details && details[divisionId]?.active,
      startDate:
        details && details[divisionId]?.startDate
          ? Moment(details[divisionId]?.startDate).format("YYYY-MM-DD hh:mm")
          : formState?.values?.startDate,
      endDate:
        details && details[divisionId]?.endDate
          ? Moment(details[divisionId]?.endDate).format("YYYY-MM-DD hh:mm")
          : formState?.values?.endDate,
      validityEnd:
        details && details[divisionId]?.validityEnd
          ? Moment(details[divisionId]?.validityEnd).format("YYYY-MM-DD hh:mm")
          : formState?.values?.validityEnd,
      validityStart:
        details && details[divisionId]?.validityStart
          ? Moment(details[divisionId]?.validityStart).format("YYYY-MM-DD hh:mm")
          : formState?.values?.validityStart,
      validityMethod:
        details && details[divisionId]?.validityMethod
          ? details[divisionId]?.validityMethod
          : formState?.values?.validityMethod,
      validityDuration:
        details && details[divisionId]?.validityDuration
          ? details[divisionId]?.validityDuration
          : formState?.values?.validityDuration,

          offerDivisionSubjects:details[divisionId]?.offerDivisionSubjects
        ? details[divisionId]?.offerDivisionSubjects:formState?.values?.offerDivisionSubjects?formState?.values?.offerDivisionSubjects :[]
    },
    touched: {},
    errors: {},
  });

  const offerPeriods = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
  ];
  useEffect(() => {
    if (formState.values.offerDivisionDetails.hasOwnProperty(divisionId)) {
      /*const v = formState.values.offerDivisionDetails[divisionId];
       setDivisionFormState((formState) => ({
        ...formState,
         values: {
           division: divisionId,
           ...v,
         },
       }));*/
    }
  }, [divisionId, formState.values.offerDivisionDetails]);

  useEffect(() => {
    // let errors = {};
    // if (Object.keys(divisionFormState.values).length) {
    //   errors = validate(divisionFormState.values, schema);
    // }
    // setDivisionFormState((divisionFormState) => ({
    //   ...divisionFormState,
    //   isValid: errors ? false : true,
    //   errors: errors || {},
    // }));
    const divisionDetails = formState.values.offerDivisionDetails[divisionId];
    if (divisionDetails) {
      const formStateUpdated = formState;
      formStateUpdated.values.offerDivisionDetails[divisionId] =
        divisionFormState.values;
      setFormState({ ...formStateUpdated });
    }
  }, [divisionId, divisionFormState.values]);

  const getSubjects = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name"]).replace(
      "&search=",
      ";"
    );
    const result = await api_get(
      `subjects?page=${page.page}&search=division.id:${divisionId}${options}&searchJoin=and&orderBy=id&sortedBy=desc`
    );
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };

  const handleDivisionDetailChange = (event) => {
    event.persist();
    setDivisionFormState((formState) => ({
      ...formState,
      values: {
        ...divisionFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...divisionFormState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const handleDateChange = (value, name) => {
    if (value) {
      setDivisionFormState((formState) => ({
        ...formState,
        values: {
          ...divisionFormState.values,
          [name]: value.format("YYYY-MM-DD hh:mm"),
        },
        touched: {
          ...divisionFormState.touched,
          [name]: true,
        },
      }));
    }
  };
  const handlePeriodChange = (options) => {
    setDivisionFormState((formState) => ({
      ...formState,
      values: {
        ...divisionFormState.values,
        periods: options,
      },
    }));
  };
  const handleDescriptionChange = (value) => {
    setDivisionFormState((formState) => ({
      ...formState,
      values: {
        ...divisionFormState.values,
        description: value.editor.getData(),
      },
      touched: {
        ...divisionFormState.touched,
        description: true,
      },
    }));
  };

  const handleMethodChange = (event) => {
    setDivisionFormState((formState) => ({
      ...formState,
      values: {
        ...divisionFormState.values,
        validityMethod: event.target.value,
      },
      touched: {
        ...divisionFormState.touched,
        validityMethod: true,
      },
    }));
  };
  const handlePriceChange = (event) => {
    setDivisionFormState((formState) => ({
      ...formState,
      values: {
        ...divisionFormState.values,
        price: event.target.value,
      },
      touched: {
        ...divisionFormState.touched,
        price: true,
      },
    }));
  };
  const handleDailyPriceChange = (event) => {
    setDivisionFormState((formState) => ({
      ...formState,
      values: {
        ...divisionFormState.values,
        dailyPrice: event.target.value,
      },
      touched: {
        ...divisionFormState.touched,
        dailyPrice: true,
      },
    }));
  };
  const handleWeeklyPriceChange = (event) => {
    setDivisionFormState((formState) => ({
      ...formState,
      values: {
        ...divisionFormState.values,
        weeklyPrice: event.target.value,
      },
      touched: {
        ...divisionFormState.touched,
        weeklyPrice: true,
      },
    }));
  };
  const handleDigitalDailyChange = (event) => {
    setDivisionFormState((formState) => ({
      ...formState,
      values: {
        ...divisionFormState.values,
        dailyDigitalVirgoServiceId: event.target.value,
      },
      touched: {
        ...divisionFormState.touched,
        dailyDigitalVirgoServiceId: true,
      },
    }));
  };
  const handleDigitalWeeklyChange = (event) => {
    setDivisionFormState((formState) => ({
      ...formState,
      values: {
        ...divisionFormState.values,
        weeklyDigitalVirgoServiceId: event.target.value,
      },
      touched: {
        ...divisionFormState.touched,
        weeklyDigitalVirgoServiceId: true,
      },
    }));
  };
  const subjectChange = (value, index) => {

    // if(!divisionFormState.values.offerDivisionSubjects.subject?.includes(value.id)){
    setDivisionFormState((formState) => ({
      ...formState,
      values: {
        ...divisionFormState.values,
        offerDivisionSubjects: divisionFormState.values.offerDivisionSubjects?.concat(
          {
            subject: value,
            price: "",
            description: "",
            nb_sessions: null,
            active: false,
          }
        ),
      },
      touched: {
        ...divisionFormState.touched,
        offerDivisionSubjects: true,
      },
   } ) );
 //}
};
  const removeSubject=(value,index)=>{
    setDivisionFormState((previousData) => ({
      ...previousData,
      values: {
        ...divisionFormState.values,
        offerDivisionSubjects: divisionFormState.values.offerDivisionSubjects?.filter((num, i) => {
          return i != index;
         }),
    }}))
  }

  const handleSubjectPriceChange = (event, index) => {
    const newArr = { ...divisionFormState };
    newArr.values.offerDivisionSubjects[index].price = event.target.value;
    setDivisionFormState({ ...newArr });
  };
  const handleSubjectDescriptionChange = (event, index) => {
    const newArr = { ...divisionFormState };
    newArr.values.offerDivisionSubjects[index].description = event.target.value;
    setDivisionFormState({ ...newArr });
  };

  const handleSubjectSessionNumberChange = (event, index) => {
    const newArr = { ...divisionFormState };
    newArr.values.offerDivisionSubjects[index].nb_sessions = event.target.value;
    setDivisionFormState({ ...newArr });
  };

  const handleOffeSubjectActiveChange = (event, index) => {
    const newArr = { ...divisionFormState };
    newArr.values.offerDivisionSubjects[index].active = event.target.checked;
    setDivisionFormState({ ...newArr });
  };

  const hasError = (field) =>{
   if( divisionFormState?.touched[field] && divisionFormState?.errors[field])
       return true
     else return false}

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Paper variant="outlined">
        <DescriptionField
          handleBodyChange={handleDescriptionChange}
          value={divisionFormState.values.description || ""}
        />
      </Paper>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <LocalizationProvider dateAdapter={momentAdapter}>
            <DatePicker
              label="Visibility Start Date"
              name="startDate"
              inputFormat="DD/MM/yyyy"
              inputVariant="outlined"
              value={divisionFormState?.values.startDate}
              onChange={(value) => handleDateChange(value, "startDate")}
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} lg={6}>
          <LocalizationProvider dateAdapter={momentAdapter}>
            <DatePicker
              label="Visibility End Date"
              name="endDate"
              inputFormat="DD/MM/yyyy"
              inputVariant="outlined"
              value={divisionFormState?.values.endDate}
              onChange={(value) => handleDateChange(value, "endDate")}
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <LocalizationProvider dateAdapter={momentAdapter}>
            <DatePicker
              label="Validity Start Date"
              name="validityStart"
              inputFormat="DD/MM/yyyy"
              inputVariant="outlined"
              value={divisionFormState?.values.validityStart}
              onChange={(value) => handleDateChange(value, "validityStart")}
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  variant="outlined"
                  size="small"
                />
              )}
              error={hasError("validityStart")}
              helperText={
                hasError("validityStart")
                  ? divisionFormState?.errors?.validityStart[0]
                  : null
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} lg={6}>
          <LocalizationProvider dateAdapter={momentAdapter}>
            <DatePicker
              label="Validity End Date"
              name="validityEnd"
              inputFormat="DD/MM/yyyy"
              value={divisionFormState?.values.validityEnd}
              onChange={(value) => handleDateChange(value, "validityEnd")}
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  variant="outlined"
                  size="small"
                />
              )}
              error={hasError("validityEnd")}
              helperText={
                hasError("validityEnd")
                  ? divisionFormState?.errors?.validityEnd[0]
                  : null
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="demo-simple-select-outlined-label">
              Validity method
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={divisionFormState?.values.validityMethod || ""}
              onChange={handleMethodChange}
              label="validityMethod"
              error={hasError("validityMethod")}
              name="validityMethod"
            >
              <MenuItem key="fixed" value="fixed">
                Fixed
              </MenuItem>
              <MenuItem key="calculated" value="calculated">
                Calculated
              </MenuItem>
            </Select>
            {hasError("validityMethod") && (
              <FormHelperText>
                {formState?.errors?.validityMethod[0]}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            label="Validity duration"
            name="validityDuration"
            onChange={handleDivisionDetailChange}
            required
            value={divisionFormState.values.validityDuration || ""}
            variant="outlined"
            size="small"
            helperText={
              hasError("validityDuration")
                ? divisionFormState.errors.validityDuration[0]
                : null
            }
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            error={hasError("price")}
            helperText={
              hasError("price") ? divisionFormState.errors.price[0] : null
            }
            label="Price"
            name="price"
            size="small"
            onChange={handlePriceChange}
            value={divisionFormState.values.price}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <AsyncPaginate
            options={offerPeriods}
            value={divisionFormState?.values?.periods}
            onChange={handlePeriodChange}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            placeholder="Periods"
            isMulti
            additional={{
              page: 1,
            }}
            styles={{
              control: (base) => ({
                ...base,
                minHeight: "38px",
              }),
              /*   placeholder: (base) => ({
                    ...base,
                    color: '#000',
                    fontSize: theme.typography.fontSize,
                    fontFamily: theme.typography.fontFamily,
                  }),*/
            }}
            menuPortalTarget={document.querySelector("body")}
          />
        </Grid>
        <br/>
        <Divider  style={{width:'100%'}}/>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            error={hasError("dailyPrice")}
            helperText={
              hasError("dailyPrice") ? divisionFormState.errors.dailyPrice[0] : null
            }
            label="Daily price"
            name="dailyPrice"
            size="small"
            onChange={handleDailyPriceChange}
            value={divisionFormState.values.dailyPrice || ""}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            error={hasError("weeklyPrice")}
            helperText={
              hasError("weeklyPrice") ? divisionFormState.errors.weeklyPrice[0] : null
            }
            label="Weekly price"
            name="weeklyPrice"
            size="small"
            onChange={handleWeeklyPriceChange}
            value={divisionFormState.values.weeklyPrice || ""}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            error={hasError("dailyDigitalVirgoServiceId")}
            helperText={
              hasError("dailyDigitalVirgoServiceId") ? divisionFormState.errors.dailyDigitalVirgoServiceId[0] : null
            }
            label="Digitalvirgo daily service ID"
            name="dailyDigitalVirgoServiceId"
            size="small"
            onChange={handleDigitalDailyChange}
            value={divisionFormState.values.dailyDigitalVirgoServiceId || ""}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            fullWidth
            error={hasError("weeklyDigitalVirgoServiceId")}
            helperText={
              hasError("weeklyDigitalVirgoServiceId") ? divisionFormState.errors.weeklyDigitalVirgoServiceId[0] : null
            }
            label="Digitalvirgo weekly service ID"
            name="weeklyDigitalVirgoServiceId"
            size="small"
            onChange={handleDigitalWeeklyChange}
            value={divisionFormState.values.weeklyDigitalVirgoServiceId || ""}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Grid item xs={12} lg={6}>
            <Box mt={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={divisionFormState.values.active}
                    onChange={handleDivisionDetailChange}
                    value={divisionFormState.values.active}
                    name="active"
                    size="small"
                  />
                }
                label="Active"
              />
            </Box>
          </Grid>
          <Box mt={3}>
            <Card>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <CardHeader title="Subject" />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CardActionArea style={{ margin: "10px -10px" }}>
                    <AsyncPaginate
                      loadOptions={getSubjects}
                      onChange={subjectChange}
                      getOptionLabel={(option) =>
                        option.division.name + " | " + option.name
                      }
                      getOptionValue={(option) => option.id}
                      value={subject}
                      placeholder="+ Add subject"
                      noOptionsMessage={() =>
                        "You may choose a division before"
                      }
                      cacheUniqs={[divisionId]}
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "30px",
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: "#000",
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector("body")}
                    />
                  </CardActionArea>
                </Grid>
              </Grid>
              <Divider />

              <Box mt={3}>
                {divisionFormState.values.offerDivisionSubjects &&
                  divisionFormState.values.offerDivisionSubjects.length > 0 &&
                  divisionFormState.values.offerDivisionSubjects.map(
                    (item, index) => {
                      return (
                        <Grid
                          container
                          spacing={3}
                          style={{
                            marginLeft: "10px",
                            marginRight: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {" "}
                          <Grid item md={1} xs={1} >
                            <Typography>{item.subject.name}</Typography>
                          </Grid>
                          <Grid item md={2} xs={2}>
                            <TextField
                              size="small"
                              fullWidth
                              variant="outlined"
                              label="Price"
                              value={item.price}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleSubjectPriceChange(e, index);
                              }}
                            ></TextField>
                          </Grid>
                          <Grid item md={4} xs={4}>
                            <TextField
                              size="small"
                              fullWidth
                              variant="outlined"
                              label="Description"
                              value={item.description}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleSubjectDescriptionChange(e, index);
                              }}
                            ></TextField>
                          </Grid>
                          <Grid item md={2} xs={2}>
                            <TextField
                              size="small"
                              fullWidth
                              variant="outlined"
                              type="number"
                              label="Session number"
                              value={item.nb_sessions}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleSubjectSessionNumberChange(e, index);
                              }}
                            ></TextField>
                          </Grid>
                          <Grid item md={1} xs={1}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={item.active}
                                  onChange={(e) =>
                                    handleOffeSubjectActiveChange(e, index)
                                  }
                                  value={item.active}
                                  size="small"
                                />
                              }
                              label="Active"
                            />
                          </Grid>
                          <Grid item md={1} xs={1}>
                            <Button onClick={(e)=>removeSubject(e,index) }>
                             <DeleteForeverIcon/>
                            </Button>
                            </Grid>
                        </Grid>
                      );
                    }
                  )}
              </Box>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

DivisionDetailForm.propTypes = {
  className: PropTypes.string,
  formState: PropTypes.object,
  setFormState: PropTypes.func,
  classes: PropTypes.object,
  divisionId: PropTypes.number,
  schema: PropTypes.object,
};

export default DivisionDetailForm;
