import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Accordion,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import SnackBar from "../../../components/SnackBar";
import { api_get, api_post } from "../../../utils/Api";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListDiscountCodes from "./ListDiscountCodes";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const DiscountCodeForm = (props) => {
  const { className, search, user, offer, quantity, allYear, edit, ...rest } =
    props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openCheckModal, setOpenCheckModal] = useState(false);
  const [values, setValues] = useState({
    code: "",
    offerId: offer,
    userId: user,
    quantity: quantity,
    allYear: allYear,
  });
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("success");
  const [userDiscountCodes, setUserDiscountCodes] = useState([]);
  const tableCell = ["id", "Code", "Amount", "Type", "Quantity", "Action"];
  useEffect(() => {
    if (user) {
      getUserDiscountCodes();
    }
  }, [user]);
  const getUserDiscountCodes = () => {
    api_get(`user-discount-code/${user}`).then((result) => {
      setUserDiscountCodes(result.payload);
    });
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showErrorMessage = (errors) => {
    if (errors?.message) {
      setAlertMessage(errors.message);
    } else {
      Object.keys(errors).map(function (key, index) {
        if (index === 0) {
          setAlertMessage(errors[key][0]);
        }
      });
    }
    setAlertSeverity("error");
    setOpen(true);
  };
  const formValidation = (values) => {
    if (!values?.code) {
      return { success: false, message: "Discount Code is missing" };
    }
    if (!offer) {
      return { success: false, message: "offer is missing" };
    }
    if (!user) {
      return { success: false, message: "User is missing" };
    }
    if (!quantity && !allYear) {
      return { success: false, message: "Quantity is missing" };
    }
  };
  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity("warning");
      setOpen(true);
    } else {
      api_post(`discount-code`, {
        code: values.code,
        offerId: offer,
        userId: user,
        allYear: allYear === false ? 0 : 1,
        quantity: quantity.toString(),
      })
        .then((result) => {
          if (result.code !== 200) {
            showErrorMessage(result);
          } else {
            setAlertSeverity("success");
            setAlertMessage("User discount code successfully added");
            setOpen(true);
            getUserDiscountCodes();
            setValues({
              ...values,
              quantity: "",
              code: "",
            });
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  };

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <ListDiscountCodes
            userDiscountCodes={userDiscountCodes}
            tableCell={tableCell}
            getData={getUserDiscountCodes}
            user={user}
          />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes?.heading}>
                Add New Discount Code
              </Typography>
            </AccordionSummary>
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Code"
                    name="code"
                    onChange={handleChange}
                    type="string"
                    required
                    value={values.code || ""}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                onClick={(event) => handleSubmit(event)}
              >
                Add Code
              </Button>
            </CardActions>
          </Accordion>
        </form>
      </Card>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

DiscountCodeForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
};

export default DiscountCodeForm;
