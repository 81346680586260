// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { api_get, api_post, api_put } from "../../../utils/Api";
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import mapStateToRequest from '../../../helpers/mapStateToRequest';
import {getCountryStates} from "../../../helpers/methods";

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
}));

type Props = {
  edit: boolean,
  className: string
}
const Form = (props: Props): React$Element<any> => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
    state: '',
  });
  const [message, setAlertMessage]=useState('');
  const [severity, setAlertSeverity]=useState('success');

  const stateChange = (value) => {
    setValues({...values, ['state']: value});
  };

  useEffect(() => {
    if (edit) {
      api_get(`delegations/${id}`).then((result)=>{
        const {payload}=result;
        setValues({
          name: payload.name,
          state: payload.state,
        });
      });
    }
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const showErrorMessage=(errors)=>{
    Object.keys(errors).map(function(key, index) {
      if (index==0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };

  const formValidation = (values) => {
    if (!values?.name) {
      return { success: false, 'message': 'Name is missing' };
    }
    if (!values?.state) {
      return { success: false, 'message': 'State is missing' };
    }
  };

  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
        const requestValues = mapStateToRequest(values);
        if (edit) {
          api_put(`delegations/${id}`, requestValues).then((result) => {
            if (result.errors) {
              showErrorMessage(result.errors);
            } else {
              setAlertSeverity('success');
              setAlertMessage('Delegation updated successfully');
              setOpen(true);
              window.location.href = '/settings/delegations';
            }
          });
        } else {
          api_post(`delegations`, requestValues).then((result) => {
            if (result.errors) {
              showErrorMessage(result.errors);
            } else {
              setAlertSeverity('success');
              setAlertMessage('Delegation created successfully');
              setOpen(true);
              window.location.href = '/settings/delegations';
            }
          }).catch((error) => {
            throw new Error(error);
          });
        }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form
          autoComplete="off"
          noValidate
        >
          <CardHeader
            subheader="Delegations form"
            
          />
          <Divider/>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth

                  label="Name"

                  name="name"
                  onChange={handleChange}
                  required
                  value={values.name || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <AsyncPaginate
                  loadOptions={getCountryStates}
                  value={values.state}
                  onChange={stateChange}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder="States *"
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: '53px',
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: '#000',
                      fontSize: theme.typography.fontSize,
                      fontFamily: theme.typography.fontFamily,
                    }),
                  }}
                  menuPortalTarget={document.querySelector('body')}
                />
              </Grid>
            </Grid>

          </CardContent>
          <Divider/>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}>
                Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      <SnackBar open={open} message={message} severity={severity} handleClose={handleClose}/>
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
};

export default Form;
