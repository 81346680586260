// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Box,
  Typography,
  Paper,
 
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import mapStateToRequest from '../../../helpers/mapStateToRequest';
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import {api_get, api_post_serialize} from "../../../utils/Api";
import FileDropZone from "../../../components/FileDropZone";
import FilesList from "../../../components/Toolbar/FilesList";
import DescriptionField from "../../../components/DescriptionField";
import LoadingApi from 'components/LoadingApi/LoadingApi';
import {getScopes, getTeachers, getDivisions} from "../../../helpers/methods";
import optionsParser from "../../../helpers/optionsParser";

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    'overflow': 'visible',
  },
  mtop: {
    marginTop: 24,
  },
  mtop0: {
    marginTop: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
 
  content: {
    padding: 0,
  },
}));


type Props = {
  edit: boolean,
  className: string,
  search: string,
}
type values = {
  name: string,
  description: string,
  isDraft: boolean,
  active: boolean,
  rtl: boolean,
  downloadable: boolean,
};

const Form = (props: Props): React$Element<any> => {

  const {id} = useParams();
  const {className, search, edit, ...rest} = props;
  const [tabs, setTabs] = useState([]);
  const [division, setDivision] = useState(null);
  const [subject, setSubject] = useState(null);
  const [chapters, setChapters]= useState(null);
  const [selectedChapter, setChapter]= useState(null);
  const [loading, setLoading] = useState(false);
  const [tab, setTab]= useState({
    "id": 3,
    "name": "Magazines",
    "short_name": "Magazines",
  });
  const [tabSection, setTabSection]= useState({});
  const classes = useStyles();
  const [values, setValues] = useState<values>({
      name: '',
      active: true,
      type: 3,
      scope: '',
      description:undefined,
      instructor: '',
      isDraft: false,
      position: 0,
      rtl: false,
      downloadable: false,
      chapterContents: [],
      contentFiles: [],
  
  });
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [attachments, setAttachments] = useState([]);
  const [magazineId, setID] = useState(null);

  useEffect(() => {
    api_get(`tabs`).then((data) => {
      setTabs(data.payload);
    });
    if (edit) {
      getMagazineDatas()
    }
  }, []);

  const getMagazineDatas = () => {
    setLoading(true)
    api_get(`content-magazines/${id}`).then((result) => {
      setLoading(false)
      const data = result.payload;
      const currentChap = data?.chapter_contents[0] &&  data?.chapter_contents[0].chapter;
      const tabSection= data?.chapter_contents[0]?.section? data?.chapter_contents[0]?.section?.tab : null;
      setTab(data?.chapter_contents[0]?.tab);
      setTabSection(tabSection);
      setChapter(currentChap);
      setAttachments(data?.content_files)
      setID(data.id)
      setValues({
          name: data.name,
          description: data.description,
          isDraft: data.is_draft,
          active: data.active,
          rtl: data.rtl,
          downloadable: data.downloadable,
          instructor: data.instructor,
          scope: data.scope,
          chapterContents: data.chapter_contents? data?.chapter_contents : [],
          type: data.type.id,
      });

    });
  }
    const handleChange = (event) => {
      setValues({...values,  [event.target.name]: event.target.value});
  };

  const getSubjects = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']).replace( '&search=', ';' );
    const result = await api_get(`subjects?page=${page.page}&search=division.id:${division.id}${options}&searchJoin=and&orderBy=id&sortedBy=desc`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };

  const getChapters = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']).replace( '&search=', ';' );
    const result = await api_get(`chapters?page=${page.page}&search=subject.id:${subject.id}${options}&searchJoin=and&orderBy=id&sortedBy=desc`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  const chapterChange = (options) => {
      setChapters(options);
      setValues({
        ...values,
        chapterContents: [...values.chapterContents, ...[options.pop()]],
      });
  };
  const selectedChaptersChange = (e,options) => {
    setValues({...values,  chapterContents: options});
  };

  const instructorChange = (value) => {
    setValues({...values,  instructor: value});
  };
  const scopeChange = (value) => {
    setValues({...values,  scope: value});
  };
  const tabChange = (event, value) => {
    setTab(value)
  };

  const onFileChange = (files) => {
    if(files && files.length > 0) {
      setValues({
        ...values,
        contentFiles: files,
      });
    }
  };
  const handleDescriptionChange = (value) => 
  {setValues((valueState)=>({...valueState, description: value?.editor?.getData()}) )}
  
  const handleCheckChange = (event) => {
    setValues({...values,  [event.target.name]: event.target.checked});
  };

  const divisionChange = (value) => {
    setDivision(value);
  };
  const subjectChange = (value) => {
    setSubject(value);
  };
  const showAlertMessage = (message) => {
    setAlertSeverity('error');
    setAlertMessage(message);
    setOpen(true);
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map((key, index) => {
      if (index == 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const selectedChaptersFormat = (values) => {
    let newArr = [];
    let fileArr = [];
    
      values.chapterContents.map(chapter => {
        newArr.push({
          'chapter': chapter.chapter ? chapter.chapter.id : chapter.id,
          'tab':   tab ? tab?.id : chapter.tab ? chapter.tab.id : null,
          'position': chapter ? chapter?.position : 0,
          'section': chapter?.section?.id,
        });
      });
      values.chapterContents=newArr;
  
    if(values.contentFiles){
      values.contentFiles.map(option=>{
        fileArr.push({
          'position': 0,
          'file': {
            'file':option,
            'name': option.name,
            'attachment': 1,
            'solution': 0,
            'marks': 0,
            'homework': 0,
            'teacher': values.instructor.id,
            'document':0,
           
          },
        })
      })
      values.contentFiles = fileArr;
    }
    return values;
  };
  const clearDatas = (values) => {
    setValues({...values,
        chapterContents: '',
        scope: '',
        instructor: '',
    });
    setSubject('')
    setDivision('')
    setChapters('')
  };
  const formValidation = (values) => {
    if (!values?.name) {
      return { success: false, 'message': 'Name is missing' };
    }
    if (values?.name.length < 3 || values?.name?.length > 2000) {
      return { success: false, 'message': 'Name must be between 3 and 2000 characters' };
    }
    if (!values?.instructor) {
      return { success: false, 'message': 'Instructor is missing' };
    }
    if (!values?.scope) {
      return { success: false, 'message': 'Scope is missing' };
    }
    if (!values?.chapterContents || values.chapterContents?.length <= 0) {
      return { success: false, 'message': 'Chapters are missing' };
    }
    if ((!values?.contentFiles || values?.contentFiles?.length < 1) && attachments?.length < 1) {
      return { success: false, 'message': 'Magazine is missing' };
    }
  };

  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
      const valuesState = selectedChaptersFormat(values);
      const requestValues = mapStateToRequest(valuesState, ['contentFiles', 'chapterContents', 'scope', 'instructor'], []);
      setLoading(true);
      requestValues.instructor = requestValues.instructor.id;
      requestValues.scope = requestValues.scope.id;
      if (edit) {
        api_post_serialize(`content-magazines/${id}`, requestValues, true).then((result) => {
          if(result.errors){
            showErrorMessage(result.errors)
            clearDatas(values)

          }else if(result?.code !== 200 && result?.code !== 201){
            showAlertMessage(result?.message? result.message:'Technical error! Contact the Developers Team')
            clearDatas(values)
          } else {
            setAlertSeverity('success');
            setAlertMessage('magazine updated successfully');
            setOpen(true);
            clearDatas(values);
            setLoading(false);
            window.location.href = '/contents/magazines';
          }
        });
      } else {
        api_post_serialize(`content-magazines`, requestValues, true).then((result) => {
          if(result.errors){
            showErrorMessage(result.errors)
            clearDatas(values)

          }else if(result?.code !== 200 && result?.code !== 201){
            showAlertMessage(result?.message? result.message:'Technical error! Contact the Developers Team')
            clearDatas(values)
          } else {
            setAlertSeverity('success');
            setAlertMessage('magazine created successfully');
            setOpen(true);
            setLoading(false);
            window.location.href = '/contents/magazines';
          }
        }).catch((error) => {
          throw new Error(error);
        });
      }
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Card className={classes.root}>
              <CardContent>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  required
                  value={values.name || ''}
                  variant="outlined"
                  size="small"
                />
                <Box mt={2} mb={1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Description
                  </Typography>
                </Box>
                <Paper variant="outlined">
                {(edit && values?.description  ) ? (
                    <DescriptionField
                      values={values}
                      handleBodyChange={handleDescriptionChange}
                      value={values?.description}
                    />
                  ) : !edit ? (
                    <DescriptionField
                      values={values}
                      handleBodyChange={handleDescriptionChange}
                    />
                  ) : values?.description===null ?(
                    <DescriptionField
                    values={values}
                    handleBodyChange={handleDescriptionChange}
                  />
                  ):values?.description==''?<DescriptionField
                  values={values}
                  handleBodyChange={handleDescriptionChange}
                />:
              ""}
                </Paper>
              </CardContent>
            </Card>
            <Box mt={3}>
              <Card>
                <CardHeader title="Upload Zone" />
                <Divider />
                <CardContent>
                  <Box mt={0} mb={1}>
                    <Typography variant="subtitle2" color="textSecondary">
                      PDF
                    </Typography>
                  </Box>
                  <FileDropZone values={values} setValues={setValues} onFileChange={onFileChange} isPdf={true}/>
                  <FilesList attachments ={attachments} getElementDatas={getMagazineDatas} isMagazine={true} magazineId={magazineId}/>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card className={classes.root}>
              <CardHeader title="Basic" />
              <Divider className={classes.mtop0} />
              <CardContent className={classes.mtop0}>
                <Box mt={0}>
                  <AsyncPaginate
                    loadOptions={getScopes}
                    onChange={scopeChange}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={values.scope}
                    placeholder="Scope"
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        minHeight: '53px',
                      }),
                      placeholder: (base) => ({
                        ...base,
                        color: '#000',
                        fontSize: theme.typography.fontSize,
                        fontFamily: theme.typography.fontFamily,
                      }),
                    }}
                    menuPortalTarget={document.querySelector('body')}
                  />
                </Box>
                <Box mt={2}>
                  <AsyncPaginate
                    loadOptions={getTeachers}
                    onChange={instructorChange}
                    getOptionLabel={(option) => option.name+' '+option.last_name}
                    getOptionValue={(option) => option.id}
                    value={values.instructor}
                    placeholder="Instructor"
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        minHeight: '53px',
                      }),
                      placeholder: (base) => ({
                        ...base,
                        color: '#000',
                        fontSize: theme.typography.fontSize,
                        fontFamily: theme.typography.fontFamily,
                      }),
                    }}
                    menuPortalTarget={document.querySelector('body')}
                  />
                </Box>
              </CardContent>
            </Card>
            <Box mt={3}>
              <Card className={classes.root}>
                <CardHeader title="Organize" />
                <Divider className={classes.mtop0} />
                <CardContent className={classes.mtop0}>
                  <Box mt={0}>
                    <AsyncPaginate
                      loadOptions={getDivisions}
                      onChange={divisionChange}
                      placeholder="Division"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      value={division}
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                    />
                  </Box>
                  <Box mt={2}>
                    <AsyncPaginate
                    isDisabled={division?false:true}
                      loadOptions={getSubjects}
                      onChange={subjectChange}
                      getOptionLabel={(option) => option.division.name+' | '+option.name}
                      getOptionValue={(option) => option.id}
                      value={subject}
                      placeholder="Subject"
                      noOptionsMessage={() => 'You may choose a division before'}
                      cacheUniqs={[division]}
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                    />
                  </Box>
                  <Box mt={2}>
                    <AsyncPaginate
                     isDisabled={division?false:true}
                      loadOptions={getChapters}
                      isMulti
                      onChange={chapterChange}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      value={chapters}
                      cacheUniqs={[subject]}
                      placeholder="Chapters"
                      noOptionsMessage={() => 'You may choose a subject before'}
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                    />
                  </Box>
                  <Box mt={2}>
                  <Autocomplete
                  
                        id="combo-box-demo"
                        multiple
                        name='chapterContents'
                        disableCloseOnSelect
                        freeSolo
                        open={false}
                        onChange={selectedChaptersChange}
                        options={values?.chapterContents || ''}
                        value={values?.chapterContents}
                        getOptionLabel={(option) => (option.subject ?  (option?.subject?.division?.name +
                            '|' + option?.subject?.name + '|' + option.name) : (option?.chapter?.subject?.division?.name +'|' + option?.chapter?.subject?.name + '|' + option?.chapter?.name))||''}
                        getOptionSelected={(option, value) => value.id ===
                            option.id}
                        renderInput={(params) => (
                            <TextField {...params} label="Selected chapters"
                                       variant="outlined" fullWidth/>
                        )}
                        renderOption={(option, {selected}) => (
                            <React.Fragment>
                              <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{marginRight: 8}}
                                  checked={selected}
                              />
                              {option.name}
                            </React.Fragment>
                        )}
                    />
                  </Box>
                  <Box mt={2}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={tabs}
                      onChange={tabChange}
                      value={tab? tab : tabSection}
                      disabled={tab && edit?true:false}
                      getOptionLabel={(option) => option.name }
                      //getOptionValue={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={tabSection !== null && edit ? "This course has section": "Choose a tab"}
                          variant="outlined"
                        />
                      )}
                      size="small"
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box mt={3}>
              <Card className={classes.root}>
                <CardHeader title="Status" />
                <Divider className={classes.mtop0} />
                <CardContent className={classes.mtop0}>
                  <Box mt={0}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.active}
                          onChange={handleCheckChange}
                          value={values.active}
                          name="active"
                          size="small"
                        />
                      }
                      label="Active ?"
                    />
                  </Box>
                  <Box mt={0}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isDraft}
                          onChange={handleCheckChange}
                          value={values.isDraft}
                          name="isDraft"
                          size="small"
                        />
                      }
                      label="Private ?"
                    />
                  </Box>
                  <Box mt={0}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.rtl}
                          onChange={handleCheckChange}
                          value={values.rtl}
                          name="rtl"
                          size="small"
                        />
                      }
                      label="Arabic ?"
                    />
                  </Box>
                  <Box mt={0}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.downloadable}
                          onChange={handleCheckChange}
                          value={values.downloadable}
                          name="downloadable"
                          size="small"
                        />
                      }
                      label="Downloadable ?"
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Save details
          </Button>
        </Box>
      </form>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
       <LoadingApi open={loading} onClick={handleCloseLoading}/>
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,
};

export default Form;
