// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Box, Typography, Paper,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import MenuItem from '@material-ui/core/MenuItem';
import DescriptionField from "../../../components/DescriptionField";
import { api_get, api_put } from "../../../utils/Api";
import {AsyncPaginate} from "react-select-async-paginate";
import theme from "../../../theme";
import optionsParser from "../../../helpers/optionsParser";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
        overflow: "visible",
    },
    mtop: {
        marginTop: 24,
    },
    mtop0: {
        marginTop: 0,
    },
    content: {
        padding: 0,
    },
    uploadedFiles: {
        marginTop: 10,
    },
}));
const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["link", "image"],
        ["clean"],
    ],
};

const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
];
type Props = {
    edit: boolean,
    className: string
}
const FormEdit = (props: Props): React$Element<any> => {
    const classes = useStyles();
    const {id} = useParams();
    const {className, edit, ...rest} = props;
    const [fullName, setFullName]  = useState('');
    const [editReason, setReason]= useState('');
    const [values, setValues] = useState({
        user: null,
        //fullName: '',
        //reason: '',
        refusingReason: undefined,
        newDivision: {},
        oldDivision: {},
    });
    const [message, setAlertMessage]=useState('');
    const [severity, setAlertSeverity]=useState('success');
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        api_get(`edit-division/${id}`).then((result)=>{
            const data=result.payload;
            const reason = data.reason ? data.reason : data?.reason2.reason ;
            setReason(reason)
            setFullName(data.user.name+' '+data.user.last_name);
            setValues({
                user: data.user.id,
                //fullName: data.user.name+' '+data.user.last_name,
                status: data.status,
                reason2: data?.reason2?.id ,
                reason: data.reason,
                refusingReason: data.refusing_reason,
                newDivision: data.new_division,
                oldDivision: data.old_division,
                
            });
        });
    }, []);

    const showErrorMessage=(errors)=>{
        Object.keys(errors).map(function(key, index) {
            if (index==0) {
                setAlertSeverity('error');
                setAlertMessage(errors[key][0]);
            }
        });
    };
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };
    const handleDescriptionChange = (value) => 
    {setValues((valueState)=>({...valueState, refusingReason: value?.editor?.getData()}) )}

    const oldDivisionChange = (value) => {
        setValues({...values, oldDivision: value});
    };
    const newDivisionChange = (value) => {
        setValues({...values, newDivision: value});
    };
    const getDivisions = async (search, prevData, page) => {
        const options = optionsParser(search, null, null, ["name"])
            .replace("&search=", ";")
            .replace("searchJoin=or", ";");
        const result = await api_get(
            `divisions?page=${page.page}&search=isPublic:1${options}searchJoin=and`
        );
        return {
            options: [...result.payload],
            hasMore: result.meta.current_page !== result.meta.last_page,
            additional: {
                page: result.meta.current_page + 1,
            },
        };
    };
    const handleSubmit = () => {
        const newValues = {...values}
        newValues.oldDivision = newValues.oldDivision.id
        newValues.newDivision= newValues.newDivision.id
        api_put(`edit-division/${id}`, newValues).then((result) => {
            if (result.errors) {
                showErrorMessage(result.errors);
                setOpen(true);
            } else {
                setAlertSeverity('success');
                setAlertMessage('Edit division request updated successfully');
                setOpen(true);
                window.location.href = '/user-settings/edit-divisions';
            }
        });
    };
    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <form autoComplete="off" noValidate>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                        <Card className={classes.root}>
                            <CardContent>
                                <TextField
                                    fullWidth
                                    label="User ID"
                                    name="user"
                                    onChange={handleChange}
                                    disabled
                                    value={values.user || ''}
                                    variant="outlined"
                                    size="small"
                                />
                                <Box mt={2} mb={1}>
                                    <TextField
                                        fullWidth
                                        label="FullName"
                                        name="fullName"
                                        onChange={handleChange}
                                        disabled
                                        value={fullName || ''}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Box>
                                <Box mt={2} mb={1}>
                                    <TextField
                                        fullWidth
                                        label="Reason"
                                        name="reason"
                                        onChange={handleChange}
                                        disabled
                                        value={editReason}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Box>
                                <Box mt={2} mb={1}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Refusing Reason
                                    </Typography>
                                </Box>
                                <Paper variant="outlined">
                                {(edit && values?.refusingReason  ) ? (
                    <DescriptionField
                      values={values}
                      handleBodyChange={handleDescriptionChange}
                      value={values?.refusingReason}
                    />
                  ) : !edit ? (
                    <DescriptionField
                      values={values}
                      handleBodyChange={handleDescriptionChange}
                    />
                  ) : values?.refusingReason===null ?(
                    <DescriptionField
                    values={values}
                    handleBodyChange={handleDescriptionChange}
                  />
                  ):values?.refusingReason===''?
                  <DescriptionField
                  values={values}
                  handleBodyChange={handleDescriptionChange}
                />:
              ""}
                                </Paper>
                            </CardContent>
                        </Card>
                        <Box mt={3}>
                            <Card>
                                <CardHeader title="Division Change" />
                                <Divider />
                                <CardContent>
                                    <Box mt={0} mb={1}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} lg={6}>
                                                <AsyncPaginate
                                                    loadOptions={getDivisions}
                                                    onChange={oldDivisionChange}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    value={values.oldDivision}
                                                    placeholder="Old Division"
                                                    additional={{
                                                        page: 1,
                                                    }}
                                                    styles={{
                                                        control: (base) => ({
                                                            ...base,
                                                            minHeight: '53px',
                                                        }),
                                                        placeholder: (base) => ({
                                                            ...base,
                                                            color: '#000',
                                                            fontSize: theme.typography.fontSize,
                                                            fontFamily: theme.typography.fontFamily,
                                                        }),
                                                    }}
                                                    menuPortalTarget={document.querySelector('body')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <AsyncPaginate
                                                    loadOptions={getDivisions}
                                                    onChange={newDivisionChange}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    value={values.newDivision}
                                                    placeholder="New Division"
                                                    additional={{
                                                        page: 1,
                                                    }}
                                                    styles={{
                                                        control: (base) => ({
                                                            ...base,
                                                            minHeight: '53px',
                                                        }),
                                                        placeholder: (base) => ({
                                                            ...base,
                                                            color: '#000',
                                                            fontSize: theme.typography.fontSize,
                                                            fontFamily: theme.typography.fontFamily,
                                                        }),
                                                    }}
                                                    menuPortalTarget={document.querySelector('body')}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Card className={classes.root}>
                            <CardHeader title="Basic" />
                            <Divider className={classes.mtop0} />
                            <CardContent className={classes.mtop0}>
                                <Box mt={0}>
                                    <TextField
                                        classes={{root: classes.root}}
                                        select
                                        name="status"
                                        variant="outlined"
                                        label="Status"
                                        value={values.status || ''}
                                        onChange={handleChange}
                                    >
                                        <MenuItem key="pending" value="pending">Pending</MenuItem>
                                        <MenuItem key="approuved" value="approuved">Approuved</MenuItem>
                                        <MenuItem key="refused" value="refused">Refused</MenuItem>
                                    </TextField>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Save details
                    </Button>
                </Box>
            </form>
            <SnackBar
                open={open}
                message={message}
                severity={severity}
                handleClose={handleClose}
            />
        </div>
    );
};
FormEdit.propTypes = {
    user: PropTypes.object,
};
export default FormEdit;
