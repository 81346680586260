// @flow
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from "../../../theme";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import { useParams } from "react-router-dom";
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import SnackBar from "../../../components/SnackBar";
import PropTypes from "prop-types";
import { api_get, api_post, api_put } from "../../../utils/Api.js";
import { getPenaltyUnits } from "helpers/methods";


const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Form = (props) => {
  const { id } = useParams();
  const { className, edit, ...rest } = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    name: "",
    description: "",
    isInterval: false,
    penaltyUnit: '',
  });
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) {
      setLoading(true)
      api_get(`penalties/${id}`).then((result) => {
        setLoading(false)
        const data = result.payload;
        setValues({
          name: data?.name,
          description: data?.description,
          isInterval: data?.is_interval === true ? "1" : "0",
          penaltyUnit: data?.penalty_unit,
        });
      });
    }
  }, [edit, id]);
  
  const handleChange = (event) => {
    event.preventDefault();
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const penaltyUnitChange = (options) => {
    setValues({ ...values, penaltyUnit: options,});
  };

  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity("error");
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const formValidation = (values) => {
    if (!values.name || !values.name) {
      return { success: false, message: "Name is missing" };
    }
    if (!values.description) {
      return { success: false, message: "Description is missing" };
    }
    if (!values.penaltyUnit) {
      return { success: false, message: "Penalty unit is missing" };
    }
  };
  const handleSubmit = () => {
    const requestValues = mapStateToRequest(values);
    setLoading(true);
    const validation = formValidation(requestValues);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity("warning");
      setOpen(true);
    } else {
      if (props.edit) {
        api_put(`penalties/${id}`, requestValues).then((result) => {
          if (result?.errors) {
            showErrorMessage(result.errors);
          } else if (result?.code !== 200 && result?.code !== 201) {
            setAlertSeverity("warning");
            setAlertMessage(
              result?.message
                ? result.message
                : "Technical error! Contact the Developers Team"
            );
            setOpen(true);
          } else {
            setAlertSeverity("success");
            setAlertMessage("Penalty updated successfully");
            setLoading(false);
            setOpen(true);
            window.location.href = '/teacher-settings/penalties';
          }
        });
      } else {
        api_post(`penalties`, requestValues)
          .then((result) => {
            if (result?.errors) {
              showErrorMessage(result.errors);
            } else if (result?.code !== 200 && result?.code !== 201) {
              setAlertSeverity("warning");
              setAlertMessage(
                result?.message
                  ? result.message
                  : "Technical error! Contact the Developers Team"
              );
              setOpen(true);
            } else {
              setAlertSeverity("success");
              setAlertMessage("Penalty created successfully");
              setOpen(true);
              setLoading(false);
              window.location.href = '/teacher-settings/penalties';
            }
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
    }
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
            <form autoComplete="off" noValidate>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid
                    item
                    md={3}
                    xs={12}
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      autoComplete="none"
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      required
                      value={values.name || ""}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                  <TextField
                      fullWidth
                      autoComplete="none"
                      label="Description"
                      name="description"
                      onChange={handleChange}
                      required
                      value={values.description || ""}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={2} xs={4}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Interval</FormLabel>
                    <RadioGroup
                      aria-label="isInterval"
                      name="isInterval"
                      onChange={handleChange}
                      value={values.isInterval || ""}
                      row
                    >
                      <FormControlLabel
                        control={<Radio />}
                        value="1"
                        label="Yes"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        value="0"
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={2} xs={4}>
                <AsyncPaginate
                      loadOptions={getPenaltyUnits}
                      value={values.penaltyUnit || ''}
                      onChange={penaltyUnitChange}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder="Penalty Unit"
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                  />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Save details
                </Button>
              </CardActions>
            </form>
      </Card>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  handleCloseLoading: PropTypes.func,
  loading: PropTypes.bool,
};

export default Form;
