import React from 'react';

import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'id', width: '5%', show: true, sortable: true, type: 'userSwitch'},
  {accessor: 'last_name', name: 'lastName', label: 'Last name', width: '10%', show: true, sortable: true,},
  {accessor: 'name', name: 'name', label: 'First name', width: '20%', show: true, sortable: true,},
 
 
  {accessor: 'phone', label: 'Phone', width: '20%', show: true, type: 'fieldValue'},
  {accessor: 'phone2', label: 'Parent Phone', width: '20%', show: false, type: 'fieldValue'},

  {accessor: 'email', label: 'Email', width: '20%', show: true, type: 'fieldValue'},
  
  {accessor: 'division.name', label: 'Division', width: '20%', show: true, type: 'fieldValue'},
  {accessor: 'affiliations', label: 'affiliations', width: '20%', show: true, type: 'affiliationList',},
  {accessor: 'verified', type: 'boolean', color: 'primary', label: 'Verified', show: true, width: '20%',},
  {accessor: 'completed', type: 'boolean', color: 'primary', label: 'Completed', show: true,},
  {accessor: 'gift_code', label: 'GiftCode',  show: true, type: 'fieldValue'},
  {accessor: 'gift_point', name: 'giftPoint',label: 'GiftPoint',  show: true, type: 'fieldValue', sortable: true},
  {accessor: 'points', name:'points', label: 'Points', width: '30%', show: true, sortable: true, type: 'pointsType'},
  {accessor: 'created_at', name:'createdAt', label: 'Created At', width: '20%', show: false, sortable: true, type: 'date'},
];
const fieldSearchable = [
  'id',
  'name',
  'lastName',
  'email',
  'phone',
];
const fieldFilterable = [
  {name: 'division.id', label: 'Division', type: 'divisionAutocomplete'},
  {name: 'affiliations.group.id', label: 'User group', type: 'userGroupAutocomplete',},
  {name: 'codes', label: 'Faithful Users(Codes>=3)', type: 'dropdown', options: ['thisYear', 'lastYear', 'all'],},
  {name: 'createdAt', label: 'Created at', type: 'dateRange'},
  {name: 'state.id', label: 'State', type: 'stateAutocomplete'},
  {name: 'affiliation', label: 'Affiliations Expire in', type: 'number',},
  {name: 'allYearAffiliation', label: 'AllYear Subscriptions', type: 'dropdown', options: ['yes', 'no'],},
  {name: 'points', label: 'Points', type: 'numberRange'},
  {name: 'externalClient', label: 'External Users', type: 'dropdown', options: [{value: 1, name:"Ooredoo"}],},
  {name: 'phone', label: 'Phone', type: 'text'},
  {name: 'giftCode', label: 'Gift Code', type: 'text',},
  {name: 'giftPoint', label: 'Gift-Points', type: 'numberRange'},
  {name: 'optionalSubject.id', label: 'Optional Subject', type: 'optionalSubjectAutocomplete'},
  {name: 'verified', label: 'Verified', type: 'boolean'},
  {name: 'completed', label: 'Completed', type: 'boolean'},
  {name: 'noAffiliation', label: 'Users Without Offers', type: 'boolean',},
  {name: 'withoutEmail', label: 'Users Without Email', type: 'boolean',},
  {name: 'withoutGiftCode', label: 'Users Without Gift Code', type: 'boolean',},
  {name: 'withoutOptSubject', label: 'Users Without Optional Subjects', type: 'boolean',},
  {name: 'studentLevelUser', label: 'Users With Levels', type: 'boolean',},
  {name: 'markSheets', label: 'Users With Marksheets', type: 'boolean',},
  {name: 'istest', label: 'Test User', type: 'boolean'},

];

const UserList = () => {
  return (
        <TableList
               columnsList={columnsList}
               fieldSearchable={fieldSearchable}
               fieldFilterable={fieldFilterable}
               url={`users`}
               exportUrl={`users/export`}
               pageLink ={'/users/students'}
               searchMessage={'Search (ID, Name, Email and Phone)'}
               baseUrl={`users`}
               noDelete={true}
               noDeleteMany={true}
               cacheUrl={`flush-key`}
               offerAction={true}
        />
  );
};

export default UserList;
