import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Grid,
  Icon,
  TableRow,
  TableCell,
  Tooltip,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  CardContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LoadingScreen from "../LoadingScreen";
import { openModal } from "../../slices/modals";
import { fetchMonthDetailsDefaults, fetchMonthDetailsDirect } from "../../slices/incomes";
import { headersRowMonthDetailsDirect as headersRow } from "../../utils/constants";
import useStyles, { colors } from "./modalStyle";
import { classBadge } from "./MonthIncomes";


const LiveSessionMonthIncomes = ({ id,data, open, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data: dataAPI, status } = useSelector(
    (state) => state.incomes.monthDetailsDirect
  );

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={(e, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <div className={classes.label}>{`Séance direct`}</div>
          <div className={classes.close_btn} onClick={() => handleClose(id)}>
            X
          </div>
        </div>
      </DialogTitle>
      {status === "failed" && (
        <>
          <DialogContent className={classes.dialogContent}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.dialogContentHeader}
            >
              <Paper elevation={0}>
                <Alert severity="error">
                  Un error est servenu: Merci de contacter administrateur
                </Alert>
              </Paper>
            </Grid>
          </DialogContent>
        </>
      )}
      {status === "idle" && <div>Pas de contenu </div>}
      {status === "loading" && <LoadingScreen />}
      {status === "succeeded" && (
        <>
          <DialogContent className={classes.dialogContent}>
            {dataAPI && dataAPI?.data?.liveSessionsDetails?.length > 0 ? (
              <>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  className={classes.dialogContentHeader}
                >
                  <Paper className={classes.point_title} elevation={0}>
                    Les défauts
                  </Paper>
                  <Paper className={classes.point_info}>
                  <div
                     style={{
                      color:dataAPI?.data?.totalDefault===0? '#dddddd':'#c20000',
                    }}
                  >
                    {`${dataAPI?.data?.totalDefault===0?'':'-'}${dataAPI?.data?.totalDefault} pts`}
                    </div>
                  </Paper>
                </Grid>
                <Grid container spacing={3}>
                  <Card>
                    <form autoComplete="off" noValidate>
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              {headersRow.map((tableCell) => {
                                return <TableCell>{tableCell.title}</TableCell>;
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dataAPI?.data?.liveSessionsDetails.map((row) => (
                              <TableRow key={row?.eventId}>
                                <TableCell component="th" scope="row">
                                  {row?.date}
                                </TableCell>
                                <TableCell >
                                  {row?.title}
                                </TableCell>
                                <TableCell >
                                  {row?.offer}
                                </TableCell>
                                <TableCell
                                  style={{ color: "red", fontWeight: "bold" }}
                                >
                                  {`${row.default} pts`}
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold" }}>
                                  {`${row.unitPrice} pts`}
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: "#00B828",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {`${row.totalPrice} pts`}{" "}
                                </TableCell>

                                <TableCell
                                  align="right"
                                  style={{
                                    color: "#0093e2",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {   dispatch(
                                    fetchMonthDetailsDefaults({ event: row.eventId, title: row.title,instructor:data.id })
                                  );
                                  dispatch(openModal('month-details-defaults-modal'));
                                  handleClose(id);}}
                                >
                                  <span>Voir détails</span>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </form>
                  </Card>
                </Grid>
              </>
            ) : (
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  This Teacher does not have any items
                </Typography>
              </CardContent>
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default LiveSessionMonthIncomes;
