import React from "react";
import TableList from "../../components/List/TableList";

const columnsList = [
  { accessor: "id", name: 'id', label: "Id", width: "6%", show: true, sortable: true },
  { accessor: "name", label: "Name", show: true },
  { accessor: "adresse", label: "Address", width: "12%", show: true, type: 'fieldValue' },
  {accessor: "private", type: "boolean", label: "Private", width: "10%", show: true,},
  { accessor: "state.name", label: "State", width: "10%", show: true, type: 'fieldValue' },
  {accessor: "delegation.name", label: "Delegation", width: "12%", show: true, type: 'fieldValue'},
  {accessor: "approved", type: "boolean", label: "Approved", width: "10%", show: true,},
];
const fieldSearchable = ["name"];
const fieldFilterable = [
  { name: "state.id", label: "State", type: "stateAutocomplete" },
  {name: "delegation.id", label: "Delegation", type: "delegationAutocomplete",},
  { name: "private", label: "Private", type: "boolean" },
  { name: "approved", label: "Approved", type: "boolean" },
];
const InstituteList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`institutes`}
          exportUrl={`institutes/export`}
          pageLink ={'/settings/institutes'}
          searchMessage={'Search (Name)'}
          baseUrl={`institutes`}
          noShow={true}
      />
  );
};

export default InstituteList;
