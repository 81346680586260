import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'title', label: 'Title', width: '35%', show: true},
  {accessor: 'link', label: 'Link', width: '35%', show: true},
  {accessor: 'created_at', type: 'date', width: '10%', label: 'Created At', show: true},
  {accessor: 'updated_at', type: 'date', width: '10%', label: 'Updated At', show: true},
];
const fieldSearchable=[
 // 'id',
  'title',
];
const fieldFilterable = [
];
const HelpVideoList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`help-videos`}
          exportUrl={`help-videos/export`}
          pageLink ={'/settings/help-videos'}
          searchMessage={'Search (Title)'}
          baseUrl={`help-videos`}
          deleteUrl={`delete/many-help-videos`}
          keyValue={"helpVideos"}
          noFilter={true}
          noShow={true}
      />
  );
};

export default HelpVideoList;
