import React, {useEffect, useState} from 'react';
import TableList from "../../components/List/TableList";
import optionsParser from "../../helpers/optionsParser";
import {api_delete, api_get, get_file} from "../../utils/Api";
import Loading from "../../components/Loading/Loading";
import Toolbar from "../../components/Toolbar/Toolbar";
import Collapse from "@material-ui/core/Collapse";
import Columns from "../../components/Columns";
import Filter from "../../components/Filter/Filter";
import Table from "../../components/Table/Table";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '30%', show: true},
  {accessor: 'chapter_contents', label: 'Subject', width: '15%', show: true, type: 'subjectType'},
  {accessor: 'description', label: 'Description', width: '15%', show: false, type: 'fieldValue'},
  {accessor: 'scope.name', label: 'Scope', width: '10%', show: true},
  {accessor: 'rtl', type: 'boolean', color: 'default', label: 'Arabic', width: '5%', show: true,},
  {accessor: 'downloadable', type: 'boolean', color: 'primary', label: 'Downloadable', width: '5%', show: true,},
  {accessor: 'is_draft', type: 'boolean', color: 'default', label: 'is Draft', width: '5%', show: true,},
  {accessor: 'active', type: 'boolean', color: 'primary', label: 'Active', width: '5%', show: true,},
  {accessor: 'instructor', label: 'Instructor', width: '15%', show: true, type: 'instructorSwitch'},
  {accessor: 'created_at', name: 'createdAt', label: 'Created At', show: false, sortable: true, type : 'date'},
  {accessor: 'updated_at' , name: 'updatedAt', label: 'Updated At', show: false, sortable: true, type : 'date'},

];
const fieldSearchable = [
  'name',
];
const fieldFilterable = [
  {name: 'id', label: 'MagazineID', type: 'text'},
  //{name: 'name', label: 'Name', type: 'text'},
  {name: 'instructor.id', label: 'Instructor', type: 'instructorAutocomplete'},
  {name: 'scope.id', label: 'Scope', type: 'scopeAutocomplete'},
  {name: 'chapterContents.chapter.subject.division.id', label: 'Division', type: 'divisionAutocomplete',},
  {name: 'chapterContents.chapter.id', label: 'Chapter', type: 'chapterAutocomplete'},
  {name: 'createdAt', label: 'Created At', type: 'dateRange'},
  {name: 'active', label: 'Active', type: 'boolean'},
  {name: 'isDraft', label: 'Draft', type: 'boolean'},
  {name: 'downloadable', label: 'Downloadable', type: 'boolean'},
  {name: 'rtl', label: 'Rtl', type: 'boolean'},
];
const ContentMagazineList = () => {
  const [search, searchChange] = useState('');
  const [filters, filtersChange] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [columns, columnsChange] = useState(columnsList);
  const [sort, sortChange] = useState({accessor: 'id', order: 'desc'});
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(24);
  useEffect(() => {
    getData();
  }, [page, sort, rowsPerPage]);

  useEffect(() => {
    const options = optionsParser(search, filters, sort, fieldSearchable);
    if (search.length) {
      setPage(1);
      setIsSearch(true)
      api_get(`content-magazines?page=${page}${options}&searchJoin=and`).then((data) => {
        setData(data);
      });
    }
    if(Object.keys(filters).length){
      setPage(1);
      api_get(`content-magazines?page=${page}${options}&searchJoin=and`).then((data) => {
        setData(data);
      });
    }
  }, [filters, search]);

  useEffect(() => {
    if (!search && isSearch) {
      setIsSearch(false)
      setPage(1);
      getData();
    }
  }, [search]);
  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };
  const toggleColumns = () => {
    setColumnsOpen(!columnsOpen);
  };
  const handleSortChange = (accessor) => {
    sortChange({
      accessor: accessor,
      order: sort.order === 'asc' ? 'desc' : 'asc',
    });
  };

  const getData = () => {
    const options = optionsParser(search, filters, sort, fieldSearchable);
    api_get(`content-magazines?page=${page}${options}&perPage=${rowsPerPage}`).then((data) => {
      setData(data);
    });
  };

  const getListData = () => {
    const options = optionsParser(null, null, sort, null);
    api_get(`content-magazines?page=${page}${options}&perPage=${rowsPerPage}`).then((data) => {
      setData(data);
    });
  };
  const handlePageChange = (event, page) => {
    setPage(page + 1);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    //getData(event.target.value)
  };

  const handleExport = () => {
    const options = optionsParser(search, filters, sort, fieldSearchable);
    get_file(`content-magazines/export?searchJoin=and&page=${page}${options}`)
  }
  const classes = useStyles();
  if (!data.payload) {
    return (<Loading/>);
  }
  return (
      <div className={classes.root}>
        <Toolbar toggleFilters={toggleFilters} toggleColumns={toggleColumns}
                 searchChange={searchChange} pageLink='/contents/magazines' searchMessage={'Search (Name)'}
                 cacheUrl='flush-key/event' cache_method = {api_get} initializeData={getListData}
                 handleExport={handleExport}
        />
        <div className={classes.content}>
          <Collapse in={columnsOpen}>
            <Columns columnsChange={columnsChange} columns={columns}/>
          </Collapse>
          <Collapse in={filtersOpen}>
            <Filter fields={fieldFilterable} values={filters}
                    filtersChange={filtersChange} initializeData={getListData}/>
          </Collapse>
          <Table columns={columns} data={data}
                 handleSortChange={handleSortChange} sort={sort}
                 handlePageChange={handlePageChange} page={page}
                 rowsPerPage={rowsPerPage}
                 handleRowsPerPageChange={handleRowsPerPageChange}
                 tableService={{method: api_delete, base_url: `content-magazines`}}
                 deleteUrl={`delete/many-content-magazines`}
                 keyValue={"magazines"}
                 pageLink='/contents/magazines' 
                 initializeData={getData}
                 replace ={true}/>
        </div>
      </div>
  );
 /* return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`content-magazines`}
          exportUrl={`content-magazines/export`}
          pageLink ={'/contents/magazines'}
          searchMessage={'Search (ID, Name)'}
          baseUrl={`content-magazines`}
          cacheUrl={'flush-key/magazine'}
      />
  );*/
};

export default ContentMagazineList;
