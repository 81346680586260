// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Box, Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, TextField} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import { api_get, api_post, api_put } from "../../../utils/Api";
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import validate from 'validate.js';
import schemaParser from '../../../helpers/schemaParser';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from "@material-ui/core/MenuItem";
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import LoadingApi from 'components/LoadingApi/LoadingApi';

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
 
}));

let schema = {};
const getSchema = () => {
  schemaParser('divisions').then((data) => {
    schema = data;
  });
};
type Props = {
  edit: boolean,
  className: string
}
const Form = (props: Props): React$Element<any> => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let errors = {};
    if (Object.keys(formState.values).length) {
      errors = validate(formState.values, schema);
    }
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    getSchema();
    if (edit) {
      setLoading(true)
      api_get(`company-accounts/${id}`).then((result) => {
        setLoading(false)
        const data = result.payload;
        setFormState({
          ...formState,
          values: {
            numAccount: data.num_account,
            name: data.name,
            isCompanyAccount: data.is_company_account == true ? '1' : '0',
            type: data.type,
          },
        });
      });
    }
  }, []);
  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
            event.target.type === 'checkbox' ?
                event.target.checked :
                event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
;
  const handleApiValidation = (errors) => {
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  };
  const formValidation = (values) => {
    if (!values?.numAccount) {
      return { success: false, 'message': 'NumAccount is missing' };
    }
    if (!values?.name) {
      return { success: false, 'message': 'Name is missing' };
    }
    if(!values?.type){
      return { success: false, 'message': 'Type is missing'};
    }
    if(!values?.isCompanyAccount){
      return { success: false, 'message': 'is a company account?'};
    }
  };
  const handleSubmit = () => {
    const validation = formValidation(formState.values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
      const requestValues = mapStateToRequest(formState.values, [], ['isCompanyAccount']);
      setLoading(true);

      if (edit) {
        api_put(`company-accounts/${id}`, requestValues).then((result) => {
          if (result.errors) {
            handleApiValidation(result.errors);
          } else {
            setAlertSeverity('success');
            setAlertMessage('Company account updated');
            setOpen(true);
            setLoading(false);
            window.location.href = '/finance/company-accounts';
          }
        });
      } else {
        api_post(`company-accounts`, requestValues).then((result) => {
          if (result.errors) {
            handleApiValidation(result.errors);
          } else {
            setAlertSeverity('success');
            setAlertMessage('Company account created successfully');
            setOpen(true);
            setLoading(false);
            window.location.href = '/finance/company-accounts';
          }
        }).catch((error) => {
          throw new Error(error);
        });
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }
    

  const hasError = (field) =>
      formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form
          autoComplete="off"
          noValidate
        >
          <CardHeader
              subheader="Company Account form"
          />
          <Divider/>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={4}
                xs={12}
              >
                <TextField
                  error={hasError('numAccount')}
                  fullWidth
                  helperText={
                      hasError('numAccount') ? formState.errors.name[0] : null
                  }
                  label="num Account"
                  name="numAccount"
                  onChange={handleChange}
                  value={formState.values.numAccount || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                  item
                  md={4}
                  xs={12}
              >
                <TextField
                    error={hasError('name')}
                    fullWidth
                    helperText={
                      hasError('name') ? formState.errors.name[0] : null
                    }
                    label="Name"
                    name="name"
                    onChange={handleChange}
                    value={formState.values.name || ''}
                    variant="outlined"
                />
              </Grid>

              <Grid
                  item
                  md={4}
                  xs={12}
              >
                  <TextField
                      error={hasError('type')}
                      fullWidth
                      helperText={
                        hasError('type') ? formState.errors.type[0] : null
                      }
                      select
                      name="type"
                      variant="outlined"
                      label="Type"
                      value={formState.values.type || ''}
                      onChange={handleChange}
                  >
                    <MenuItem key="bank" value="bank">Bank</MenuItem>
                    <MenuItem key="poste" value="poste">Poste</MenuItem>
                    <MenuItem key="others" value="others">Other</MenuItem>
                  </TextField>
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
                <FormControl component="fieldset">
                  <FormLabel component="legend">is a companyAccount?</FormLabel>
                  <RadioGroup aria-label="isCompanyAccount" name="isCompanyAccount"
                    onChange={handleChange}
                    value={formState.values.isCompanyAccount || ''}
                    error={hasError('isCompanyAccount').toString()}
                    row>
                    <FormControlLabel control={<Radio/>} value="1"
                      label="Yes"/>
                    <FormControlLabel control={<Radio/>} value="0"
                      label="No"/>
                  </RadioGroup>
                  {hasError('isCompanyAccount') && <FormHelperText>{formState.errors.isCompanyAccount[0]}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>

          </CardContent>
          <Divider/>
          <CardActions>
            <Button
              //disabled={!formState.isValid}
              color="primary"
              variant="contained"
              onClick={handleSubmit}>
                            Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      <SnackBar open={open} message={message} severity={severity} handleClose={handleClose}/>
      <LoadingApi open={loading} onClick={handleCloseLoading}/>
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,
};

export default Form;
