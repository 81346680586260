// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Box, Button, Divider, Grid, CardContent} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../../components/SnackBar';
import mapStateToRequest from '../../../../helpers/mapStateToRequest';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {api_get, api_post, api_put} from '../../../../utils/Api';
import Expression from './Expression';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    overflow: 'visible',
  },
  mtop: {
    marginTop: 24,
  },
  mtop0: {
    marginTop: 0,
  },
  content: {
    padding: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
}));
const modules = {
  toolbar: [
    [{header: [1, 2, false]}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      {list: 'ordered'},
      {list: 'bullet'},
      {indent: '-1'},
      {indent: '+1'},
    ],
    ['link', 'image'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const Expressions = (props) => {
  const {handleSubmit,rule, edit} = props;
  const [expressions, setExpressions] = useState(rule ||
      {'expression-type-e0': 'simple'});
   const [expressionIndex, setIndex] = useState(0);

  const handleExpressionChange = (event) => {
    let newObject = {...expressions};
    let value = event.target.value;
    let name = event.target.name;
    let expressionId = name.substr(name.indexOf('e0'), name.length);
    setIndex(expressionId);
    if (value === 'combined') {
      newObject[`expression-type-${expressionId}-1`] = 'simple';
      newObject[`expression-type-${expressionId}-2`] = 'simple';
      newObject[`expression-operator-e0`] = 'or';
    } else {
      Object.keys(newObject).
          filter(expression => expression.includes(expressionId)).
          map(expression => {
            delete newObject[expression];
          });
    }
    newObject[event.target.name] = value;
    setExpressions(Object.fromEntries(Object.entries(newObject).sort()));
  };
  const handleAttributeChange = (event) => {
    let newObject = {...expressions};
    newObject[event.target.name] = event.target.value;
    setExpressions(newObject);
  };
  const handleChangeValue = (value,event) => {
    let exp=event.name.substr(event.name.indexOf('e0'), event.name.length)
    let newObject = {...expressions};
    // multiple value as array
   /* if(expressions[`operator-condition-c-${exp}`] === 'in') {
      const ids=value.map(v=>v.id);
      newObject[event.name] = ids;*/
      newObject[`${event.name}`]=value;
  /*  }
    else {
      newObject[event.name] = value;
    }*/
    setExpressions(newObject);
  };
  const handleOperatorChange = (event) => {
    let newObject = {...expressions};
    newObject[event.target.name] = event.target.value;
    setExpressions(newObject);
  };
  return (
      <div>
        <form autoComplete="off" noValidate>
          <Grid container spacing={3}>
            <CardContent>
            <Grid
                container
                spacing={3}>
              <Button  color="primary" href="#contained-buttons">
                {expressions['expression-type-e0']=== 'combined' ? 'E0': ''}
              </Button>
              <Grid
                  item
              >
              <FormControl variant="outlined" fullWidth size="small">
                <Select
                    labelId="demo-simple-select-outlined-label"
                    value={expressions['expression-type-e0']}
                    onChange={handleExpressionChange}
                    //label="Expression"
                    name="expression-type-e0"
                >
                  <MenuItem value="simple">Simple Expression</MenuItem>
                  <MenuItem value="combined">Combined Expression</MenuItem>
                </Select>
              </FormControl>
              </Grid>
            </Grid>
            </CardContent>
            <Divider/>
            {
              Object.keys(expressions).
                  filter((expression, index) => expressions['expression-type-e0'] !=
                  'combined' ?
                      expression.includes('expression-type') :
                      expression.includes('expression-type') && expression !=
                      'expression-type-e0').
                  map((expKey, index) => {
                return (
                        <>
                      <Expression
                        handleExpressionChange={handleExpressionChange}
                        handleOperatorChange={handleOperatorChange}
                        handleAttributeChange={handleAttributeChange}
                        expression={expressions[expKey]}
                        exp={expKey.substr(expKey.indexOf('e0'), expKey.length)}
                        expressions={expressions}
                        expressionIndex={edit ? (expKey.substr(expKey.indexOf('e0'), expKey.length)).replace('-1', '') :expressionIndex}
                        edit={edit}
                        setIndex={setIndex}
                        handleChangeValue={handleChangeValue}/>

                        </>
                    )
                  })
            }
          </Grid>
          <Box mt={1}>
            <Button
                color="primary"
                variant="contained"
                onClick={()=>handleSubmit(expressions)}
            >
              Save details
            </Button>
          </Box>
        </form>
      </div>
  );
};

Expressions.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
};

export default Expressions;


