
import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";


const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "35%",
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: "bottom",
      height: 20,
      width: 20,
    },
    details: {
      alignItems: "center",
    },
    column: {
      flexBasis: "33.33%",
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  }));
const GiftPoints = ({user}) => {
    const classes = useStyles();
  return (
    <div>
       <Card  className={clsx(classes.root)}>
        
          <CardHeader
            subheader="Student Gift"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Gift Points"
                  name="points"
                  value={user?.gift_point}
                  variant="outlined"
                  disabled
                />
              </Grid>
           
              <Grid item md={6} xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Gift Code"
                  name="points"
                  value={user?.gift_code}
                  variant="outlined"
                  disabled
                />
              </Grid></Grid> </CardContent></Card>
    </div>
  )
}

export default GiftPoints
