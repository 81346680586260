import React from 'react';

import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id',label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '95%', show: true},
];
const fieldSearchable=[
  'id',
  'name'
];
const fieldFilterable=[

];
const AdminRoleList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`admin-groups`}
          //exportUrl={`users/export`}
          pageLink ={'/settings/roles'}
          searchMessage={'Search (ID, Name)'}
          baseUrl={`admin-groups`}
          noFilter={true}
          noShow={true}
      />
  );
};

export default AdminRoleList;
