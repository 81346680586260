// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Box, Button, Card, CardContent, CardHeader, Divider, Grid, Paper, TextField} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { api_get, api_post, api_put } from "../../../utils/Api";
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import mapStateToRequest from '../../../helpers/mapStateToRequest';
import Checkbox from "@material-ui/core/Checkbox";
import LoadingApi from 'components/LoadingApi/LoadingApi';
import {getTeachers, getSubjects, getGroups, getLevels} from "../../../helpers/methods";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    overflow: "visible",
  },
  mtop: {
    marginTop: 24,
  },
  mtop0: {
    marginTop: 0,
  },
  
  content: {
    padding: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
}));
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
type Props = {
  edit: boolean,
  className: string
}
const Form = (props: Props): React$Element<any> => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
    instructor: null,
    price: 7,
    totalseances: 1,
    traineeshipSubjects: null,
    traineeshipLevels: null,
    traineeshipGroups: null,
    saleable: 1,
    versPilote: false,
  });
  const [message, setAlertMessage]=useState('');
  const [severity, setAlertSeverity]=useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) {
      setLoading(true)
      api_get(`traineeships/${id}`).then((result)=>{
        setLoading(false)
        const data= result.payload;
        setValues({
          name: data.name,
          instructor: data.instructor,
          price: data.price,
          totalseances: data.totalseances,
          traineeshipSubjects: data.traineeship_subjects,
          traineeshipLevels: data.traineeship_levels,
          traineeshipGroups: data.traineeship_groups,
          versPilote: data.vers_pilote,

        });
      });
    }
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]:
          event.target.type === 'checkbox' ?
          event.target.checked :
          event.target.value,
    });
  };

  const handleCheckChange = (event) => {
    setValues({
        ...values,
        [event.target.name]: event.target.checked,
    });
  };
  const showErrorMessage=(errors)=>{
    Object.keys(errors).map(function(key, index) {
      if (index==0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };

  const formValidation = (values) => {
    if (!values.name) {
      return { success: false, 'message': 'Name is missing' };
    }
    if (values?.name.length < 3 || values?.name?.length > 2000) {
      return { success: false, 'message': 'Name must be between 3 and 2000 characters' };
    }
    if (!values.instructor) {
      return { success: false, 'message': 'Instructor is missing' };
    }
    if (!values.traineeshipSubjects || values?.traineeshipSubjects?.length <=0) {
      return { success: false, 'message': 'Subjects are missing' };
    }
    if (!values.traineeshipGroups || values.traineeshipGroups?.length <= 0) {
      return { success: false, 'message': 'Groups are missing' };
    }
  };

  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
      setLoading(true);
        const requestValues = mapStateToRequest(values,
            ['traineeshipSubjects', 'traineeshipLevels', 'traineeshipGroups']);
        requestValues.versPilote = requestValues.versPilote == 0 ? false : true;
        let newArr = [];
        let newArr2 = [];
        let newArr3 = [];

        requestValues.traineeshipSubjects && requestValues.traineeshipSubjects.map(option => {
          newArr.push({"subject": option.subject ? option.subject.id : option.id})
        })
        requestValues.traineeshipSubjects = newArr;

        requestValues.traineeshipLevels && requestValues.traineeshipLevels.map(option => {
          newArr2.push({"level": option.level ? option.level.id : option.id})
        })
        requestValues.traineeshipLevels = newArr2;

        requestValues.traineeshipGroups && requestValues.traineeshipGroups.map(option => {
          newArr3.push({"group": option.group ? option.group.id : option.id})
        })
        requestValues.traineeshipGroups = newArr3;
        if (edit) {
          api_put(`traineeships/${id}`, requestValues).then((result) => {
            if (result.errors) {
              showErrorMessage(result.errors);
            } else {
              setAlertSeverity('success');
              setAlertMessage('Chapter updated successfully');
              setOpen(true);
              setLoading(false);
              window.location.href = '/hessaty/traineeships';
            }
          });
        } else {
          api_post(`traineeships`, requestValues).then((result) => {
            if (result.errors) {
              showErrorMessage(result.errors);
            } else {
              setLoading(false);
              setAlertMessage('chapter created successfully');
              setOpen(true);
              window.location.href = '/hessaty/traineeships';
            }
          }).catch((error) => {
            throw new Error(error);
          });
        }
    }
  };
  const subjectChange = (options) => {
    setValues({...values, traineeshipSubjects: options});
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }

  const teacherChange = (value) => {
    setValues({...values, instructor: value});
  };
  const groupsChange = (options) => {
      setValues({...values, traineeshipGroups: options,});
  };
  const levelsChange = (options) => {
    setValues({...values, traineeshipLevels: options});
  };

  return (
      <div {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Card className={classes.root}>
                <CardContent>
                  <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      required
                      value={values.name || ""}
                      variant="outlined"
                      size="small"
                  />
                  <Box mt={2} mb={1}>
                    <AsyncPaginate
                        loadOptions={getTeachers}
                        value={values.instructor}
                        onChange={teacherChange}
                        getOptionLabel={(option) => option.name+' '+option.last_name}
                        getOptionValue={(option) => option.id}
                        placeholder="Teachers"
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: '53px',
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: '#000',
                            fontSize: theme.typography.fontSize,
                            fontFamily: theme.typography.fontFamily,
                          }),
                        }}
                        menuPortalTarget={document.querySelector('body')}
                    />
                  </Box>
                </CardContent>
              </Card>
              <Box mt={3}>
                <Card>
                  <CardHeader title="Subjects" />
                  <Divider />
                  <CardContent>
                    <Box mt={0} mb={1}>
                        <AsyncPaginate
                            loadOptions={getSubjects}
                            value={values.traineeshipSubjects}
                            onChange={subjectChange}
                            getOptionLabel={(option) => option.subject ? (option.subject.division.name+' | '+option.subject.name) : (option.division.name+' | '+option.name)}
                            getOptionValue={(option) => option}
                            placeholder="Subjects"
                            isMulti
                            additional={{
                              page: 1,
                            }}
                            styles={{
                              control: (base) => ({
                                ...base,
                                minHeight: '53px',
                              }),
                              placeholder: (base) => ({
                                ...base,
                                color: '#000',
                                fontSize: theme.typography.fontSize,
                                fontFamily: theme.typography.fontFamily,
                              }),
                            }}
                            menuPortalTarget={document.querySelector('body')}
                        />
                    </Box>
                    <Box mt={2}>
                      <AsyncPaginate
                          loadOptions={getGroups}
                          value={values.traineeshipGroups}
                          onChange={groupsChange}
                          getOptionLabel={(option) => option.group ? option.group.name : option.name}
                          getOptionValue={(option) => option.group ? option.group.id : option.id}
                          placeholder="Groups"
                          isMulti
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                      />
                    </Box>
                    <Box mt={2}>
                      <AsyncPaginate
                          loadOptions={getLevels}
                          value={values.traineeshipLevels}
                          onChange={levelsChange}
                          getOptionLabel={(option) => option.level? option.level.name :option.name}
                          getOptionValue={(option) => option.id}
                          placeholder="Levels"
                          isMulti
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box mt={0}>
                <Card className={classes.root}>
                  <CardHeader title="Status" />
                  <Divider className={classes.mtop0} />
                  <CardContent className={classes.mtop0}>
                    <Box mt={0}>
                      <TextField
                          fullWidth
                          label="Total Sessions"
                          name="totalseances"
                          type="number"
                          onChange={handleChange}
                          required
                          value={values.totalseances || ''}
                          variant="outlined"
                      />
                    </Box>
                    <Box mt={2}>
                      <TextField
                          fullWidth
                          label="Price"
                          name="price"
                          type="number"
                          onChange={handleChange}
                          required
                          value={values.price || ''}
                          variant="outlined"
                      />
                    </Box>
                    <Box mt={2}>
                      <FormControlLabel
                          control={
                            <Checkbox
                                checked={values.versPilote}
                                onChange={handleCheckChange}
                                value={values.versPilote}
                                name="versPilote"
                                size="small"
                            />
                          }
                          label="Vers Pilote?"
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Box mt={3}>
            <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
            >
              Save details
            </Button>
          </Box>
        </form>
        <SnackBar
            open={open}
            message={message}
            severity={severity}
            handleClose={handleClose}
        />
       <LoadingApi open={loading} onClick={handleCloseLoading}/>
      </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,


};

export default Form;
