import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';

import Toolbar from '../../components/Toolbar/Toolbar'
import Table from '../../components/Table/Table';
import Collapse from '@material-ui/core/Collapse';
import Columns from '../../components/Columns';
import Filter from '../../components/Filter/Filter';
import optionsParser from '../../helpers/optionsParser';
import Loading from '../../components/Loading/Loading';
import { api_delete, api_get } from 'utils/Api';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));
const columnsList = [
  {accessor: 'id', width: '5%', label: 'Id', show: true, sortable: true},
  {accessor: 'name', width: '25%', label: 'Name', show: true},
  {accessor: 'description', width: '30%', label: 'Description', show: true},
  {
    accessor: 'active',
    width: '10%',
    label: 'Active',
    type: 'boolean',
    show: true,
  },
  {
    accessor: 'start_date',
    type: 'date',
    width: '15%',
    label: 'Start date',
    show: true,
    sortable: true,
  },
  {
    accessor: 'end_date',
    type: 'date',
    width: '15%',
    label: 'End date',
    show: true,
    sortable: true,
  },

];
const fieldSearchable = [
  'id',
];
const fieldFilterable = [
  {name: 'groups.user_group_id', label: 'Group', type: 'userGroupAutocomplete'},
  {name: 'start_date', label: 'Start date', type: 'date'},
  {name: 'end_date', label: 'End date', type: 'date'},
  {name: 'active', label: 'Active', type: 'boolean'},
];
const PermissionRuleList = () => {
  const [search, searchChange] = useState('');
  const [filters, filtersChange] = useState({});
  const [columns, columnsChange] = useState(columnsList);
  const [sort, sortChange] = useState({accessor: 'id', order: 'desc'});
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(12);

  useEffect(() => {
    getData();
  }, [page, sort]);

  useEffect(() => {
    if ((search.length || Object.keys(filters).length)) {
      setPage(1);
      getData();
    }
  }, [filters, search]);

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };
  const toggleColumns = () => {
    setColumnsOpen(!columnsOpen);
  };
  const handleSortChange = (accessor) => {
    sortChange({
      accessor: accessor,
      order: sort.order === 'asc' ? 'desc' : 'asc',
    });
  };

  const getData = (perPage = 12) => {
    const options = optionsParser(search, filters, sort, fieldSearchable);
    api_get(`groups?page=${page}${options}&perPage=${perPage}`).then((data) => {
      setData(data);
    });
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    getData(event.target.value)
  };
  const handlePageChange = (event, page) => {
    setPage(page + 1);
  };

  const classes = useStyles();
  if (!data.payload) {
    return (<Loading/>);
  }
  return (
      <div className={classes.root}>
        <Toolbar toggleFilters={toggleFilters} toggleColumns={toggleColumns}
                 searchChange={searchChange}  pageLink='/acl/permission-rules'/>
        <div className={classes.content}>
          <Collapse in={columnsOpen}>
            <Columns columnsChange={columnsChange} columns={columns}/>
          </Collapse>
          <Collapse in={filtersOpen}>
            <Filter fields={fieldFilterable} values={filters}
                    filtersChange={filtersChange}/>
          </Collapse>
          <Table columns={columns} data={data}
                 handleSortChange={handleSortChange} sort={sort}
                 handlePageChange={handlePageChange} page={page}
                 tableService={{method: api_delete, base_url: `permissions-rules`}}
                 rowsPerPage={rowsPerPage}
                 handleRowsPerPageChange={handleRowsPerPageChange}
                 pageLink='/acl/permission-rules'/>
        </div>
      </div>
  );
};

export default PermissionRuleList;
