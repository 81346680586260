import React, {useEffect, useState} from 'react';
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {makeStyles} from '@material-ui/styles';
import FlushCache from "./Action/FlushCache";
import {Button} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        'width': '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    content: {
        padding: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    paper: {minWidth: '500px'},
}));

const FlushCacheButton = (props) => {
    const {noButton,openModal, initializeData, rowsPerPage, cacheUrl, cache_method,  ...rest} = props;
    const theme = useTheme();
    const [openCache, setCache] = React.useState(false);

    const handleCache = () => {
        setCache(!openCache)
    }

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleCache}>
                Flush Cache
            </Button>
            <FlushCache
                open={openCache}
                handleCache={handleCache}
                cacheUrl={cacheUrl}
                cache_method={cache_method}
                initializeData={initializeData}
                rowsPerPage={rowsPerPage}

            />
        </div>
    )

}

export default FlushCacheButton;