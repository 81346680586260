import React from "react";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import optionsParser from "helpers/optionsParser";
import { useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { getDivisions } from "../../helpers/methods";
import theme from "../../theme";
import { api_get, api_post } from "../../utils/Api";
import SnackBar from "../SnackBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: "red",
    },
  },
  content: {
    padding: 0,
  },
}));
const CopyChapterIntoSubject = (props) => {
  const {
    className,
    handleCopy,
    open,
    itemId,
    service,
    pageLink,
    initializeData,
    rowsPerPage,
    itemName,
    setOpen,
    ...rest
  } = props;

  const classes = useStyles();
  const [message, setAlertMessage] = useState("");
  const [division, setDivision] = useState(null);
  const [severity, setAlertSeverity] = useState("success");
  const [openSnack, setOpenSnack] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [subject, setSubjects] = useState([]);
  const [chapters, setChapters] = useState(null);
  const [values, setValues] = useState({
    oldChapterId: `${itemId}`,
    subjectId: "",
  });
  const handleClose = () => {
    setOpenSnack(false);
  };

  const subjectChange = (value) => {
    setSubjects(value);
  };

  const showAlertMessage = (message) => {
    setAlertSeverity("error");
    setAlertMessage(message);
    setOpenSnack(true);
  };
  const divisionChange = (value) => {
    setDivision(value);
  };
  const chapterChange = (options) => {
    setChapters(options);
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity("error");
        setAlertMessage(errors[key][0]);
        setOpenSnack(true);
      }
    });
  };
  const getSubjects = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name"]).replace(
      "&search=",
      ";"
    );
    const result = await api_get(
      `subjects?page=${page.page}&search=division.id:${division.id}${options}&searchJoin=and&orderBy=id&sortedBy=desc`
    );
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  const getChapters = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name"]).replace(
      "&search=",
      ";"
    );
    const result = await api_get(
      `chapters?page=${page.page}&search=subject.id:${subject.id}${options}&searchJoin=and`
    );

    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };

  const handleCopyChapter = () => {
    if (!division) {
      setAlertSeverity("error");
      showAlertMessage("you have to choose a division");
    } else if (subject.length === 0) {
      setAlertSeverity("error");
      showAlertMessage("you have to choose a subject");
    } else if (!chapters) {
      setAlertSeverity("error");
      showAlertMessage("you have to choose a chapter");
    } else if (values) {
      api_post(`chapters/copy-chapter-chapter`, {
        oldChapterId: itemId,
        newChapterId: chapters?.id,
      }).then((result) => {
        if (result.errors) {
          showErrorMessage(result.errors);
        } else if (result?.code !== 200 && result?.code !== 201) {
          setAlertSeverity("error");
          showAlertMessage(
            result?.message
              ? result.message
              : "Technical error! Contact the Developers Team"
          );
        } else {
          setAlertSeverity("success");
          setAlertMessage("chapter into Chapter copied successfully");
          setOpenSnack(true);

          setTimeout(() => {
            setOpen(false);
          }, 1500);
        }
      });
    }
  };
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        open={open}
        onClose={handleCopy}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="responsive-dialog-title">
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>
            Chapter Copy Into Chapter:
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ marginBottom: "20px" }}>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                Chapter title :
              </span>
              <span style={{ color: "orange", fontWeight: "bold" }}>
                {itemName}.
              </span>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Chapter ID :
              </span>
              <span style={{ color: "green", fontWeight: "bold" }}>
                {itemId}
              </span>
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Select subjects to copy into :
            </div>
            <Box mt={0}>
              <AsyncPaginate
                loadOptions={getDivisions}
                onChange={divisionChange}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={division}
                placeholder="Division"
                additional={{
                  page: 1,
                }}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  control: (base) => ({
                    ...base,
                    minHeight: "40px",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#546e7a",
                    fontSize: theme.typography.fontSize,
                    fontFamily: theme.typography.fontFamily,
                  }),
                }}
                menuPortalTarget={document.querySelector("body")}
              />
            </Box>
            <Box mt={2}>
              <AsyncPaginate
                isDisabled={division ? false : true}
                loadOptions={getSubjects}
                onChange={subjectChange}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={subject}
                placeholder="Subject"
                noOptionsMessage={() => "You may choose a division before"}
                cacheUniqs={[division]}
                additional={{
                  page: 1,
                }}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  control: (base) => ({
                    ...base,
                    minHeight: "40px",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#546e7a",
                    fontSize: theme.typography.fontSize,
                    fontFamily: theme.typography.fontFamily,
                  }),
                }}
                menuPortalTarget={document.querySelector("body")}
              />
            </Box>
            <Box mt={2}>
              <AsyncPaginate
                isDisabled={division ? false : true}
                loadOptions={getChapters}
                onChange={chapterChange}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={chapters}
                cacheUniqs={[subject]}
                placeholder="Chapters"
                noOptionsMessage={() => "You may choose a subject before"}
                additional={{
                  page: 1,
                }}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  control: (base) => ({
                    ...base,
                    minHeight: "40px",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#546e7a",
                    fontSize: theme.typography.fontSize,
                    fontFamily: theme.typography.fontFamily,
                  }),
                }}
                menuPortalTarget={document.querySelector("body")}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={(event) => handleCopyChapter(event)}
            color="primary"
            autoFocus
          >
            copy
          </Button>
        </DialogActions>
        <SnackBar
          open={openSnack}
          message={message}
          severity={severity}
          handleClose={handleClose}
        />
      </Dialog>
    </div>
  );
};

export default CopyChapterIntoSubject;
