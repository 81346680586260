// @flow
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {

  Box,
  Button,
  Card,
  CardContent,
  CardHeader,

  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import Checkbox from "@material-ui/core/Checkbox";
import { DateTimePicker, LocalizationProvider } from "@material-ui/pickers";
import momentAdapter from "@material-ui/pickers/adapter/moment";
import { AsyncPaginate } from "react-select-async-paginate";
import theme from "../../../theme";
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import { api_get, api_post, api_put } from "../../../utils/Api";
import DescriptionField from "../../../components/DescriptionField";
import cartesian from "../../../helpers/crossJoin";
import LoadingApi from 'components/LoadingApi/LoadingApi';
import {getDivisions, getGroups, getStates, getUsers} from "../../../helpers/methods";
import Moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    overflow: "visible",
  },
  mtop: {
    marginTop: 24,
  },

  mtop0: {
    marginTop: 0,
  },
  content: {
    padding: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
}));

type Props = {
  edit: boolean,
  className: string,
};
const Form = (props: Props): React$Element<any> => {
    const { id } = useParams();
    const {
        className,
        edit,
        ...rest
    } = props;
    const classes = useStyles();
    const [divisions, setDivisions,] = useState(null);
    const [groups, setGroups,] = useState(null);
    const [states, setStates,] = useState(null);
    const [users, setUsers,] = useState(null);
    const [loading, setLoading,] = useState(false);
    const [values, setValues,] = useState({
        toNotAffiliated: false,
        active: 1,
        messageLinks: [],
        body: undefined,
        name: null,
        startDate: Moment().format("YYYY-MM-DD hh:mm"),
        endDate: Moment().format("YYYY-MM-DD hh:mm"),
    });
    const [message, setAlertMessage,] = useState("");
    const [severity, setAlertSeverity,] = useState("success");
    useEffect(() => {
        if (edit) {
            let groupArr = [];
            let divisionArr = [];
            let stateArr = [];
            let userArr = [];
            setLoading(true);
            api_get(`messages/${id}`
            ).then((result) => {
                setLoading(false);
                const {payload,} = result;
                payload?.message_links &&
                payload.message_links.map(
                    (option) => {
                        if (option?.division && !containsObject(option.division, divisionArr)) {
                            divisionArr.push(option.division);}
                        if (option?.group && !containsObject(option.group, groupArr)) {
                            groupArr.push(option.group);}
                        if (option?.state && !containsObject(option.state, stateArr)) {
                            stateArr.push(option.state);}
                        if (option?.user && !containsObject(option.user, userArr)) {
                            userArr.push(option.user);}});
                setDivisions(divisionArr);
                setGroups(groupArr);
                setStates(stateArr);
                setUsers(userArr);
                setValues({
                    name: payload.name,
                    summary:
                    payload.summary,
                    body: payload.body,
                    active:
                    payload.active,
                    toNotAffiliated:
                    payload.to_not_affiliated,
                    startDate:
                    payload.start_date,
                    endDate:
                    payload.end_date,
                });
            });
        }
        }, [id]);

    const containsObject = (obj, list) => {
        var i;
        for (i = 0; i < list.length; i++) {
            if (list[i].id === obj.id) {return true;}
        }
        return false;
    };
    const handleChange = (event) => {
        setValues({...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleCheckChange = (event) => {
        setValues({...values,
            [event.target.name]: event.target.checked,
        });
    };

    const divisionChange = (options) => {
        setDivisions(options);
    };
    const groupsChange = (options) => {
        setGroups(options);
    };
    const statesChange = (options) => {
        setStates(options);
    };
    const usersChange = (options) => {
        setUsers(options);
    };
    const dateChange = (value, name) => {
        if (value) {
            setValues({...values,
                [name]: value.format("YYYY-MM-DD HH:mm"),
            });
        }
    };

    const handleDescriptionChange = (event) => {
        setValues((events) => ({...events,
            body: event.editor.getData(),
        }));
    };
    const showErrorMessage = (errors) => {
        Object.keys(errors).map((key, index) => {
            if (index == 0) {
                setAlertSeverity("error");
                errors.message ? setAlertMessage(errors[0].message) : setAlertMessage(errors[key][0]);
                setOpen(true);
            }
        }
        );
    };

    const showValidationErrorMessage = (message) => {
        setAlertSeverity("error");
        setAlertMessage(message);
        setOpen(true);
    };
    const showSucessMessage = (message) => {
        setAlertSeverity("success");
        setAlertMessage(message);
        setOpen(true);
    };
    const formValidation = (values) => {
        if (!values?.name) {
            return {
                success: false,
                message: "Name is missing",};
        }
        if (!values?.body) {
            return {
                success: false,
                message: "Body is missing",
            };
        }
    };

    const selectedOptionsFormat = (values) => {
        let newArr = [];
        let inp = [divisions?.length ? divisions : [null],
            groups?.length ? groups : [null],
            states?.length ? states : [null],
            users?.length ? users : [null],
        ];
        let options = cartesian(...inp);
        options?.length && options.map((option) => {
            newArr.push({
                division: option[0] ? option[0]?.id : null,
                group: option[1] ? option[1]?.id : null,
                state: option[2] ? option[2]?.id : null,
                user: option[3] ? option[3]?.id : null,
            });
        }
        );
        values.messageLinks = newArr;
        return values;
    };
    const handleSubmit = () => {
        const validation = formValidation(values);
        if (validation) {
            showValidationErrorMessage(validation.message);
        } else {
            setLoading(true);
            const valuesState = selectedOptionsFormat(values);
            const requestValues = mapStateToRequest(valuesState, ["messageLinks"]);
            requestValues.toNotAffiliated = requestValues.toNotAffiliated == 1 ? true : false;
            requestValues.active = requestValues.active == 1 ? true : false;
            if (edit) {
                api_put(
                    `messages/${id}`, requestValues).then((result) => {
                        if (result.errors) {
                            showErrorMessage(result.errors);
                        } else {
                            setAlertSeverity("success");
                            setAlertMessage("Message updated successfully");
                            setOpen(true);
                            setLoading(false);
                            window.location.href = "/marketing/messages";
                        }
                    });
            } else {
                api_post(`messages`, requestValues).then((result) => {
                    if (result.errors) {
                        showErrorMessage(result.errors);
                    } else {
                        setAlertSeverity("success");
                        setAlertMessage("Message created successfully");
                        setLoading(false);
                        setOpen(true);
                        window.location.href = "/marketing/messages";
                    }
                })
                    .catch((error) => {
                        throw new Error(error);
                    });
            }
        }
    };

    const [open, setOpen,] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseLoading = () => {
        setLoading(false);
    };
    return (
        <div{...rest}
            className={clsx(
                classes.root,
                className
            )}
        >
            <form autoComplete="off" noValidate>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                        <Card className={classes.root}>
                            <CardContent>
                                <TextField
                                    fullWidth
                                    label=""
                                    name="name"
                                    onChange={handleChange}
                                    required
                                    value={values.name}
                                    variant="outlined"
                                    size="small"
                                    placeholder="name"
                                />
                                <Box mt={2} mb={1}>
                                    <TextField
                                        fullWidth
                                        label=""
                                        name="summary"
                                        onChange={handleChange}
                                        required
                                        value={values.summary}
                                        variant="outlined"
                                        size="small"
                                        placeholder="summary"
                                    />
                                </Box>
                                <Box mt={2} mb={1}>
                                    <Typography
                                        variant="subtitle2"
                                        color="textSecondary"
                                    >
                                        Body
                                    </Typography>
                                </Box>
                                <Paper variant="outlined">
                                    {edit &&
                                    values?.body ? (
                                        <DescriptionField
                                            values={values}
                                            handleBodyChange={handleDescriptionChange}
                                            value={values.body}
                                        />
                                    ) : !edit ? (
                                        <DescriptionField
                                            handleBodyChange={handleDescriptionChange}
                                        />
                                    ) : values?.body === "" || values.body === null ? (
                                        <DescriptionField handleBodyChange={handleDescriptionChange}/>
                                    ) : (
                                        ""
                                    )}
                                </Paper>
                            </CardContent>
                        </Card>
                        <Box mt={3}>
                            <Card>
                                <CardHeader title="Date & Duration" />
                                <Divider />
                                <CardContent>
                                    <Box mt={0} mb={1}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} lg={6}>
                                                <LocalizationProvider
                                                    dateAdapter={momentAdapter}
                                                >
                                                    <DateTimePicker
                                                        renderInput={(
                                                            props
                                                        ) => (
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                size="small"
                                                                {...props}
                                                            />
                                                        )}
                                                        autoOk
                                                        ampm={false}
                                                        value={values.startDate}
                                                        inputFormat="DD/MM/yyyy HH:mm"
                                                        label="Start date"
                                                        onChange={(value) => dateChange(value, "startDate")}
                                                        name="startDate"
                                                        inputVariant="outlined"
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <LocalizationProvider
                                                    dateAdapter={momentAdapter}>
                                                    <DateTimePicker
                                                        renderInput={(
                                                            props
                                                        ) => (
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                size="small"
                                                                {...props}
                                                            />
                                                        )}
                                                        autoOk
                                                        ampm={false}
                                                        value={values.endDate}
                                                        inputFormat="DD/MM/yyyy HH:mm"
                                                        label="End date"
                                                        onChange={(value) => dateChange(value, "endDate")}
                                                        name="endDate"
                                                        inputVariant="outlined"
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Card className={classes.root}>
                            <CardHeader title="Basic" />
                            <Divider className={classes.mtop0}/>
                            <CardContent className={classes.mtop0}>
                                <Box mt={0}>
                                    <AsyncPaginate
                                        isMulti
                                        loadOptions={getDivisions}
                                        onChange={divisionChange}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        value={divisions || ""}
                                        placeholder="Divisions"
                                        additional={{
                                            page: 1,
                                        }}
                                        styles={{
                                            control: (base) => ({...base
                                                , minHeight: "53px",}),
                                            placeholder: (base) => ({...base,
                                                color: "#000",
                                                fontSize: theme.typography.fontSize,
                                                fontFamily: theme.typography.fontFamily,
                                            }),
                                        }}
                                        menuPortalTarget={document.querySelector("body")}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <AsyncPaginate
                                        loadOptions={getGroups}
                                        value={groups}
                                        onChange={groupsChange}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        placeholder="Groups"
                                        isMulti
                                        additional={{
                                            page: 1,
                                        }}
                                        styles={{control: (base) => ({...base,
                                                minHeight: "53px",
                                            }),
                                            placeholder: (base) => ({...base,
                                                color: "#000",
                                                fontSize: theme.typography.fontSize,
                                                fontFamily: theme.typography.fontFamily,
                                            }),
                                        }}
                                        menuPortalTarget={document.querySelector("body")}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <AsyncPaginate
                                        loadOptions={getStates}
                                        value={states}
                                        onChange={statesChange}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        placeholder="States"
                                        isMulti
                                        additional={{page: 1,}}
                                        styles={{control: (base) => ({...base,
                                                minHeight: "53px",
                                            }),
                                            placeholder: (base) => ({...base,
                                                color: "#000",
                                                fontSize:
                                                theme.typography.fontSize,
                                                fontFamily: theme.typography.fontFamily,
                                            }),
                                        }}
                                        menuPortalTarget={document.querySelector("body")}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <AsyncPaginate
                                        loadOptions={getUsers}
                                        value={users}
                                        onChange={usersChange}
                                        getOptionLabel={(option) => "[" + option.id + "]" + " " + option.name + " " + option.last_name + " " + (option?.phone
                                                                           ? option.phone : "") + " " + (option?.email
                                                                           ? "[" + option?.email + "]"
                                                                           : "")
                                                                       }
                                        getOptionValue={(option) => option.id}
                                        placeholder="Users"
                                        isMulti
                                        additional={{page: 1,}}
                                        styles={{
                                            control: (base
                                            ) => ({...base,
                                                minHeight: "53px",
                                            }),
                                            placeholder: (base) => ({...base,
                                                color: "#000",
                                                fontSize: theme.typography.fontSize,
                                                fontFamily: theme.typography.fontFamily,
                                            }),
                                        }}
                                        menuPortalTarget={document.querySelector("body")}
                                    />
                                </Box>
                                <Box mt={0}>
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={values.toNotAffiliated}
                                            onChange={handleCheckChange}
                                            value={values.toNotAffiliated}
                                            name="toNotAffiliated"
                                            size="small"
                                        />
                                    }
                                                      label="Users without offers ?"
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                        <Box mt={3}>
                            <Card className={classes.root}>
                                <CardHeader title="Status" />
                                <Divider className={classes.mtop0}/>
                                <CardContent className={classes.mtop0}>
                                    <Box mt={0}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values?.active}
                                                    onChange={handleCheckChange}
                                                    value={values?.active}
                                                    name="active"
                                                    size="small"
                                                />
                                            }
                                            label="Active ?"
                                        />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Save details
                    </Button>
                </Box>
            </form>
            <SnackBar
                open={open}
                message={message}
                severity={severity}
                handleClose={handleClose}
            />
            <LoadingApi
                open={loading}
                onClick={handleCloseLoading}
            />
        </div>
    );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
  loading: PropTypes.bool,
};

export default Form;
