import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'offer.name', label: 'Offer', width: '15%', show: true},
  {accessor: 'division.name', label: 'Division', width: '20%', show: true, type: 'fieldValue'},
  {accessor: 'value', name:'value', label: 'Value', width: '5%', show: true, sortable: true, type: 'pointsType'},
  {accessor: 'discount_method', label: 'Discount Method', width: '15%', show: true, type: 'chip'},
  {accessor: 'quantity', name: 'quantity', label: 'Quantity', width: '5%', show: true, sortable: true,},
  {accessor: 'user_group', label: 'Group', width: '15%', show: true, type: 'offerGroup'},
  {accessor: 'offer_special_price_excludes', label: 'Excluded Groups', width: '15%', show: true, type: 'groupType'},
  {accessor: 'allyear', name: 'allyear', type: 'boolean', label: 'All year', width: '10%', show: true, sortable: true,},
  {accessor: 'priority', name: 'priority', label: 'Priority', width: '6%', show: true, sortable: true, type: 'fieldValue'},
  {accessor: 'end_date', type: 'date', label: 'Visibility EndDate', width: '7%', show: true,},
  {accessor: 'active', type: 'boolean', label: 'Active', width: '7%', show: true,},
];
const fieldSearchable = [
   'id',
  'value',
];
const fieldFilterable = [
  {name: 'division.id', label: 'Division', type: 'divisionAutocomplete'},
  {name: 'offer.id', label: 'Offer', type: 'offerAutocomplete'},
 // {name: 'userGroup.id', label: 'User group', type: 'userGroupAutocomplete'},
  {name: 'quantity', label: 'Quantity', type: 'number'},
  {name: 'startDate', label: 'Visibility start', type: 'dateRange'},
  {name: 'endDate', label: 'Visibility end', type: 'dateRange'},
  {name: 'discountMethod', label: 'Discount method', type: 'dropdown', options: ['fixe_discount', 'percentage_discount', 'fixe_price'],},
  {name: 'value', label: 'Promotion value', type: 'number'},
  {name: 'active', label: 'Active', type: 'boolean'},
  {name: 'allyear', label: 'All year', type: 'boolean'},
];
const OfferSpecialPriceList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`offers/special-prices`}
          exportUrl={`offers/special-prices/export`}
          pageLink ={'/offers/offer-special-prices'}
          searchMessage={'Search (ID, Value)'}
          baseUrl={`offers/special-prices`}
          deleteUrl={`delete/offers/many-special-prices`}
          keyValue={"offerSpecialPrices"}
      />
  );
};

export default OfferSpecialPriceList;
