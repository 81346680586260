// @flow
import React, { useEffect, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import { useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import { AsyncPaginate } from "react-select-async-paginate";
import theme from "../../../theme";
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import Box from "@material-ui/core/Box";
import { api_get, api_post_serialize } from "../../../utils/Api";
import FileDropZone from "../../../components/FileDropZone";
import LoadingApi from "components/LoadingApi/LoadingApi";
import { getDivisions, getGroups, getDomains } from "../../../helpers/methods";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    overflow: "visible",
  },
  content: {
    padding: 0,
  },
  uploadButton: {
    padding: "13px 21px",
  },
}));
type Props = {
  edit: boolean,
  className: string,
};
const Form = (props: Props): React$Element<any> => {
  const { id } = useParams();
  const { className, edit, ...rest } = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    name: "",
    visible: "",
    optional: "",
    division: "",
    domain: "",
    subjectGroups: [],
  });
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertseverity] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) {
      setLoading(true);
      api_get(`subjects/${id}`).then((result) => {
        const data = result.payload;
        setThumbnail(data.thumbnail);
        setLoading(false);
        setValues({
          name: data.name,
          visible: data.visible == true ? "1" : "0",
          optional: data.optional == true ? "1" : "0",
          division: data.division,
          domain: data?.domain,
          subjectGroups: data?.subject_groups,
        });
      });
    }
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const groupsChange = (options) => {
    setValues({
      ...values,
      subjectGroups: options,
    });
  };

  const divisionChange = (value) => {
    setValues({
      ...values,
      division: value,
    });
  };
  const domainChange = (value) => {
    setValues({
      ...values,
      domain: value,
    });
  };
  const onFileChange = (files) => {
    setValues({
      ...values,
      imageFile: files[0],
    });
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertseverity("error");
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };

  const registerValidation = (values) => {
    if (!values.name) {
      return { success: false, message: "Name or First Name is missing" };
    }
    if (!values.division) {
      return { success: false, message: "Division is missing" };
    }
    if (!values.visible) {
      return { success: false, message: "Visible is missing" };
    }
    if (!values.optional) {
      return { success: false, message: "Optional is missing" };
    }
    if (!values.domain) {
      return { success: false, message: "Domain is missing" };
    }
    if (
      (edit &&
        thumbnail === "" &&
          !thumbnail &&
          values.imageFile === "" &&
          !values.imageFile) ||
      (!edit && (values.imageFile === "" || !values.imageFile))
    ) {
      return { success: false, message: "Thumbnail is missing" };
    }
  };

  const subjectFormat = (values) => {
    let newArr = [];
    values.subjectGroups &&
      values.subjectGroups.map((option) => {
        newArr.push({ group: option.group ? option.group.id : option.id });
      });
    values.subjectGroups = newArr;
    return values;
  };

  const handleSubmit = () => {
    const validation = registerValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertseverity("warning");
      setOpen(true);
    } else {
      const valuesState = subjectFormat(values);
      const requestValues = mapStateToRequest(valuesState, ["subjectGroups"]);
      setLoading(true);

      if (edit) {
        api_post_serialize(`subjects/${id}`, requestValues, true).then(
          (result) => {
            if (result.errors) {
              showErrorMessage(result.errors);
            } else if (result?.code !== 200 && result?.code !== 201) {
              setOpen(true);
              setAlertseverity("warning");
              setAlertMessage("Please check the fields");
            } else {
              setAlertseverity("success");
              setAlertMessage("subject updated successfully");
              setOpen(true);
              setLoading(false);
              window.location.href = "/contents/subjects";
            }
          }
        );
      } else {
        api_post_serialize("subjects", requestValues, true)
          .then((result) => {
            if (result.errors) {
              showErrorMessage(result.errors);
            } else if (result?.code !== 200 && result?.code !== 201) {
              setOpen(true);
              setAlertseverity("warning");
              setAlertMessage("Please check the fields");
            } else {
              setAlertseverity("success");
              setAlertMessage("subject created successfully");
              setOpen(true);
              setLoading(false);
              window.location.href = "/contents/subjects";
            }
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Card>
              <CardHeader title="General" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      required
                      value={values.name || ""}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AsyncPaginate
                      loadOptions={getDivisions}
                      value={values.division}
                      onChange={divisionChange}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder="Division *"
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "53px",
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: "#000",
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector("body")}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AsyncPaginate
                      loadOptions={getDomains}
                      value={values.domain}
                      onChange={domainChange}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder="Domain *"
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "53px",
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: "#000",
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector("body")}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AsyncPaginate
                      loadOptions={getGroups}
                      value={values.subjectGroups}
                      onChange={groupsChange}
                      getOptionLabel={(option) =>
                        option.group ? option.group.name : option.name
                      }
                      getOptionValue={(option) =>
                        option.group ? option.group.id : option.id
                      }
                      placeholder="Groups"
                      isMulti
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "53px",
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: "#000",
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector("body")}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel
                        style={{ fontWeight: "bold" }}
                        component="legend"
                      >
                        Visible
                      </FormLabel>
                      <RadioGroup
                        aria-label="Visible"
                        name="visible"
                        onChange={handleChange}
                        value={values.visible || ""}
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value="1"
                          label="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value="0"
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel
                        style={{ fontWeight: "bold" }}
                        component="legend"
                      >
                        Optional
                      </FormLabel>
                      <RadioGroup
                        aria-label="Optional"
                        name="optional"
                        onChange={handleChange}
                        value={values.optional || ""}
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value="1"
                          label="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value="0"
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Box mt={3}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Save details
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box mt={0}>
              <Card>
                <CardHeader title="Image" />
                <Divider className={classes.mtop0} />
                <CardContent className={classes.mtop0}>
                  <FileDropZone
                    values={values}
                    thumbnail={thumbnail}
                    setValues={setValues}
                    onFileChange={onFileChange}
                    isImage={true}
                  />
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </form>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
      <LoadingApi open={loading} onClick={handleCloseLoading} />
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
  loading: PropTypes.bool,
};

export default Form;
