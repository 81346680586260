import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PersonOutline';
import Label from '../../../components/Label';
import {api_get} from "../../../utils/Api";

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const BasicsInfo = ({
                      user,
                      className,
                      ...rest
                    }) => {
  const classes = useStyles();
  const StudentUrl = process.env.REACT_APP_FRONT_STUDENT_URL;
  const switchStudent = (event, id) => {
    api_get(`users/login-as/${id}`).then((data) => {
      //window.open(data.redirect_url);
      window.open(`${StudentUrl}/switch?token=${data.payload?.token}&userId=${data.payload?.userId}&adminId=${data.payload?.adminId}`);
      //window.location.replace=`${StudentUrl}/switch/${data.payload?.token}`;


    });
  };

  const fullName=(firstName,lastName)=>{
    return `${firstName} ${lastName}`
  }
  return (
      <Card
          className={clsx(classes.root, className)}
          {...rest}
      >
        <CardHeader title="User info"/>
        <Divider/>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                ID
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.id}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Email
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.email ?? 'NULL'}
                </Typography>
                <Label color={user.verified == '1' ? 'success' : 'error'}>
                  {user.verified == '1' ? 'User verified' : 'User unverified'}
                </Label>
                <Label color={user.completed == '1' ? 'success' : 'error'}>
                  {user.completed == '1' ?
                      'User completed' :
                      'User uncompleted'}
                </Label>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Phone
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.phone ?? 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Full Name
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {fullName(user.last_name,user.name)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Class
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.division?.name ?? 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Brith date
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.birth_date ?? 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                State
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.state?.name ?? 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Company
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.company?.fullName ?? 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Username
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.user_name ?? 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box
            p={1}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
        >
          <Button startIcon={<PersonIcon/>}
                  onClick={(event) => switchStudent(event, user.id)}>
            Login as User
          </Button>
        </Box>
      </Card>
  );
};

BasicsInfo.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired,
};

export default BasicsInfo;
