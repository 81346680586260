import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', label: 'Id', width: '10%', show: true},
  {accessor: 'value', label: 'Rate', width: '20%', show: true},
  {accessor: 'created_at', label: 'Created At', type: 'date', width: '30%', show: true},
  {accessor: 'updated_at', label: 'Updated At', type: 'date', width: '30%', show: true},

];
const fieldSearchable=[
];
const fieldFilterable = [
];
const PointConversionList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`point-conversion`}
          //exportUrl={`affiliations/export`}
          noExport={true}
          pageLink ={'/users/points-conversions'}
          searchMessage={'Search (ID)'}
          baseUrl={`point-conversion`}
          noShow={true}
          noFilter={true}
      />
  );
};

export default PointConversionList;
