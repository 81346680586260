import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '5%', show: true},
  {accessor: 'name', label: 'Name', show: true},
  {accessor: 'description', label: 'Description', show: true},
  {accessor: 'created_at', label: 'Created At', width: '15%', show: true, type: 'date'},
  {accessor: 'updated_at', label: 'Updated At', width: '15%', show: true, type: 'date'},

];
const fieldSearchable=[
    'name'
];
const fieldFilterable = [];

const ContentScopeList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`content-scopes`}
          exportUrl={`content-scopes/export`}
          pageLink ={'/contents/content-scopes'}
          searchMessage={'Search (Name)'}
          baseUrl={`content-scopes`}
          noShow={true}
          noFilter={true}
      />
  );

};

export default ContentScopeList;
