import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '7%', show: true, sortable: true},
  {accessor: 'last_name', name: 'lastName', label: 'Last name', width: '10%', show: true, sortable: true,},
  {accessor: 'name', name: 'name', label: 'First name', width: '10%', show: true, sortable: true,},
  {accessor: 'phone', label: 'Phone', show: true, width: '10%', type: 'fieldValue'},
  {accessor: 'email', label: 'Email', show: true, width: '20%', type: 'fieldValue'},
  {accessor: 'division.name', label: 'Division', show: true, width: '10%', type: 'fieldValue'},
  {accessor: 'verified', type: 'boolean', color: 'primary', label: 'Verified', show: true},
  {accessor: 'completed', type: 'boolean', color: 'primary', label: 'Completed', show: true,},
  {accessor: 'points', name: 'points', label: 'Points', show: true, width: '10%', sortable: true, type: 'pointsType'},
];
const fieldSearchable = [
  'id',
  'name',
    'lastName',
  'email',
  'phone',
];
const fieldFilterable = [
  {name: 'division.id', label: 'Division', type: 'divisionAutocomplete'},
  {name: 'affiliations.group.id',label: 'User group',type: 'userGroupAutocomplete',},
  {name: 'points', label: 'Points', type: 'number'},
  {name: 'createdAt', label: 'Created at', type: 'dateRange'},
  {name: 'codes',label: 'Faithful Users(Codes>=3)',type: 'dropdown',options: ['thisYear', 'lastYear', 'all'],},
  {name: 'state.id', label: 'State', type: 'stateAutocomplete'},
  {name: 'verified', label: 'Verified', type: 'boolean'},
  {name: 'completed', label: 'Completed', type: 'boolean'},
 {name: 'noAffiliation',label: 'Users Without Offers',type: 'boolean',},
  {name: 'affiliation',label: 'Affiliations Expire in',type: 'number',},
  {name: 'studentLevelUser',label: 'Users Without Levels',type: 'boolean',},
  {name: 'isschool', label: 'Company User', type: 'boolean'},
  {name: 'istest', label: 'Test User', type: 'boolean'},

];

const UserArchiveList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`users/archived`}
          exportUrl={`users/archived/export`}
          pageLink ={'/archives/users'}
          searchMessage={'Search (ID, Name, Email and Phone)'}
          baseUrl={`users/archived`}
          removeAdd={true}
          restore={true}
          noShow={true} noDelete={true} noEdit = {true} noDeleteMany={true}
      />
  );
};

export default UserArchiveList;
