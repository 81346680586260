import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import {
  MenuItem,
  TextField,
  Select,
  FormControl,
  Grid,
  InputLabel,
  Card,
  CardContent,
  CardHeader,
  Button,
  Box,
  CardActions,
} from "@material-ui/core";
import { api_get, api_post, api_put } from "utils/Api";

const Forum = ({ edit }) => {
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [number, setNumber] = useState("");
  const { id } = useParams();
  const [forums, setForums] = useState([]);
  const [values, setValues] = useState([
    {
      month: month,
      year: year,
      number: "",
    },
  ]);

  useEffect(() => {
    if (edit) {
      setValues(
        forums.length > 0
          ? forums.map((el) => ({
              year: el.year,
              month: el.month,
              number: el.article_count,
            }))
          : [
              {
                month: month,
                year: year,
                number: "",
              },
            ]
      );
    }
  }, [edit, forums]);
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertseverity] = useState("");
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    api_get(
      `instructor-forum-monthly-stats?search=user.id:${id}&searchJoin=and&page=1&orderBy=id&sortedBy=desc&perPage=24`
    ).then((result) => {
      setForums(result.payload);
    });
  }, [edit, id]);

  const handleMonthChange = (event, index) => {
    setValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index]["month"] = event.target.value;
      return updatedValues;
    });
    setMonth(event.target.value);
  };

  const handleYearChange = (event, index) => {
    setValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index]["year"] = event.target.value;
      return updatedValues;
    });
    setYear(event.target.value);
  };

  const handleNumberChange = (event, index) => {
    values[index]["number"] = event.target.value;
    setValues(values);
    setNumber(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index === 0) {
        setAlertseverity("error");
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const showAlertMessage = (message) => {
    setAlertseverity("error");
    setAlertMessage(message);
    setOpen(true);
  };

  const hasDuplicates = (array) => {
    const seen = new Set();
    return array.some((item) => (seen.has(item) ? seen.add(item) : false));
  };

  const registerValidation = (values) => {
    let allMonths = [];
    let allYears = [];

    return values
      .map((el, index) => {
        if (!el.month) {
          return { success: false, message: `Month ${index + 1} is missing` };
        }
        allMonths.push(`${el.month}`);

        if (!el.year) {
          return { success: false, message: `Year ${index + 1} is missing` };
        }
        allYears.push(`${el.year}`);

        if (!el.articleCount) {
          return {
            success: false,
            message: `Article Count ${index + 1} is missing`,
          };
        }

        if (index === values.length - 1) {
          if (hasDuplicates(allMonths)) {
            return { success: false, message: `Duplicate month found` };
          }
          if (hasDuplicates(allYears)) {
            return { success: false, message: `Duplicate year found` };
          }
        }
      })
      .filter(Boolean);
  };

  const handleSubmit = () => {
    const validation = registerValidation(
      values.map((el) => ({
        month: Number(el.month),
        year: Number(el.year),
        articleCount: Number(el.number),
      }))
    ).filter(
      (validation) => validation?.message && validation.message !== undefined
    );

    if (validation.length > 0) {
      validation.map((validation) => {
        setAlertMessage(validation?.message);
        setAlertseverity("warning");
        setOpen(true);
      });
    } else {
      api_put(`instructors/${id}/update-instructor-forum-monthly-stats`, {
        instructorForumMonthlyStats: values.map((el) => ({
          month: Number(el.month),
          year: Number(el.year),
          articleCount: Number(el.number),
        })),
      }).then((data) => {
        if (data.errors) {
          showErrorMessage(data.errors);
        } else if (data?.code !== 200 && data?.code !== 201) {
          showAlertMessage(
            data?.message
              ? data.message
              : "Technical error! Contact the Developers Team"
          );
        } else {
          setAlertseverity("success");
          setAlertMessage("instructor updated successfully");
          setOpen(true);
          window.location.href = "/users/teachers";
        }
      });
    }
  };

  const AddRemoveStat = (type, idx) => {
    if (type === "plus") {
      setValues(
        values.concat([
          {
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
            number: "",
          },
        ])
      );
    } else {
      setValues(values.filter((el, index) => index !== idx));
    }
  };
  return (
    <Card>
      <CardContent>
        <CardHeader
          title="Forum"
          action={
            <Button onClick={() => AddRemoveStat("plus", 0)}>
              <AddIcon />
              Add Month Stat
            </Button>
          }
        />
        {values.map((elt, index) => (
          <Grid container spacing={2}>
            <Grid item md={3} xs={3}>
              <FormControl
                variant="outlined"
                margin={"1"}
                style={{ width: "100%", marginBottom: 32 }}
              >
                <InputLabel id="test-select-label">Month</InputLabel>
                <Select
                  labelId="test-select-label"
                  label="Month"
                  fullWidth
                  value={Number(elt.month)}
                  onChange={(e) => handleMonthChange(e, index)}
                >
                  {[...Array(12).keys()].map((index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {index + 1}
                    </MenuItem>
                  ))}
                </Select>{" "}
              </FormControl>
            </Grid>
            <Grid item md={3} xs={3}>
              <FormControl
                variant="outlined"
                margin={"1"}
                style={{ width: "100%", marginBottom: 32 }}
              >
                <InputLabel id="test-select-label">Year</InputLabel>
                <Select
                  labelId="test-select-label"
                  label="Year"
                  fullWidth
                  value={elt.year}
                  onChange={(e) => handleYearChange(e, index)}
                >
                  {[...Array(new Date().getFullYear() - 2012).keys()].map(
                    (index) => (
                      <MenuItem key={2013 + index} value={2013 + index}>
                        {2013 + index}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} xs={3}>
              <TextField
                label="Article Count Number"
                fullWidth
                type="number"
                variant="outlined"
                value={elt.number}
                name="number"
                onChange={(e) => handleNumberChange(e, index)}
              />
            </Grid>
            <Grid item md={2} xs={1}>
              <Box m={1} pt={1}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => AddRemoveStat("minus", index)}
                >
                  -
                </Button>
              </Box>
            </Grid>
          </Grid>
        ))}
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            disabled={!edit}
            onClick={handleSubmit}
          >
            Save details
          </Button>
        </CardActions>
      </CardContent>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </Card>
  );
};

export default Forum;
