import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import Label from '../../../components/Label';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const BasicsInfo = ({
                      contentVideo,
                      className,
                      ...rest
                    }) => {
  const classes = useStyles();
  return (
      <Card
          className={clsx(classes.root, className)}
          {...rest}
      >
        <CardHeader title="Content Video info"/>
        <Divider/>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                ID
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {contentVideo.id}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Content ID
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {contentVideo?.content?.id}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Name
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {contentVideo.content.name}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Chapters
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {contentVideo?.content?.chapter_contents?.map(chapter => {
                    return (
                        <div>
                          <Label color='primary'>{chapter.chapter.name} </Label>
                        </div>
                    );
                  }) || 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
              <TableRow>
                <TableCell className={classes.fontWeightMedium}>
                  Levels
                </TableCell>
                <TableCell>
                  <Typography
                      variant="body2"
                      color="textSecondary"
                  >
                    {contentVideo?.content?.content_student_levels?.map(level => {
                      return (
                          <div>
                            <Label>{level.student_level.name}</Label>
                          </div>
                      );
                    })}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.fontWeightMedium}>
                  Type
                </TableCell>
                <TableCell>
                  <Typography
                      variant="body2"
                      color="textSecondary"
                  >
                    {contentVideo?.content?.type?.name}
                  </Typography>
                </TableCell>
              </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Scope
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {contentVideo?.content?.scope?.name}
                </Typography>
              </TableCell>
            </TableRow>
              <TableRow>
                <TableCell className={classes.fontWeightMedium}>
                  Instructor
                </TableCell>
                <TableCell>
                  <Typography
                      variant="body2"
                      color="textSecondary"
                  >
                    {contentVideo?.content?.instructor?.name+' '+contentVideo?.content?.instructor?.last_name}
                  </Typography>
                </TableCell>
              </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Admin
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {contentVideo?.creator_admin?.name || 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
              <TableRow>
                <TableCell className={classes.fontWeightMedium}>
                  Created date
                </TableCell>
                <TableCell>
                  <Typography
                      variant="body2"
                      color="textSecondary"
                  >
                    {contentVideo.created_at}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.fontWeightMedium}>
                  Active
                </TableCell>
                <TableCell>
                  <Typography
                      variant="body2"
                      color="textSecondary"
                  >
                    <Label
                        color={contentVideo.active == '1' ?
                            'success' :
                            'error'}>
                      {contentVideo.active == '1' ? 'TRUE' : 'FALSE'}
                    </Label>
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.fontWeightMedium}>
                  Subject
                </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {contentVideo?.content?.chapter_contents?.map(chapter => {
                    return (
                        <div>
                          <Label >{chapter.chapter.subject.division.name} || {chapter.chapter.subject.name}  </Label>
                        </div>
                    );
                  }) || 'NULL'}
                </Typography>
              </TableCell>
              </TableRow>
          </TableBody>
        </Table>
      </Card>
);
};

BasicsInfo.propTypes = {
  className: PropTypes.string,
  affiliation: PropTypes.object.isRequired,
};

export default BasicsInfo;
