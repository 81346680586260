import React from 'react';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell, TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const LevelsInfo = ({
  subjects,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Subjects List"/>
      <Divider/>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Subject ID</TableCell>
            <TableCell>Subject</TableCell>
            <TableCell>Division</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subjects?.map((subject) => {
            return (
              <TableRow>
                <TableCell className={classes.fontWeightMedium}>
                  {subject?.subject?.id}
                </TableCell>
                <TableCell className={classes.fontWeightMedium}>
                  {subject?.subject?.name}
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    {subject?.subject?.division?.name}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}


        </TableBody>
      </Table>
    </Card>
  );
};

LevelsInfo.propTypes = {
  className: PropTypes.string,
  subjects: PropTypes.object.isRequired,
};

export default LevelsInfo;
