import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SnackBar from '../../../components/SnackBar';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {Link} from 'react-router-dom';
import {api_get, api_delete} from "../../../utils/Api";
import DeleteFromTabList from '../../../components/Action/DeleteFromTabList';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      maxWidth: 360,

    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    right: theme.spacing(0),

   },
  }));

const ChildrenForm = (props) => {
  const {className, userId, ...rest} = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertseverity] = useState('');
  const [tab, setTab] = useState(0);
  const [parentChildren, setParentChildren] = useState([]);
  const [parentChildrenID, setParentChildrenID] = useState('');


  useEffect(() => {
    api_get(`parent-users/${userId}`).then((result) => {
      setParentChildren(result?.payload?.parent_user_children);
    });
  }, [userId]);

  const StudentUrl = process.env.REACT_APP_FRONT_STUDENT_URL;

  const switchStudent = (id) => {
    api_get(`users/login-as/${id}`).then((data) => {
      window.open(
        `${StudentUrl}/switch?token=${data.payload?.token}&userId=${data.payload?.userId}&adminId=${data.payload?.adminId}`
      );
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleRowClick = (row) => {
    setParentChildrenID(row?.id);
 };

  const getListParentChild = () => {
    api_get(`parent-users/${userId}`).then((result) => {
      setParentChildren(result?.payload?.parent_user_children);
    });
  };

  const tableCell = [
    'id',
    'Name',
    'Birthdate',
    'Gender',
    'Phone',
    'Division',
    'Approved',
    'Actions',
  ];

  return (
    <div>
      <Grid
        container
        spacing={3}
      >
        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <form
            autoComplete="off"
            noValidate
          >
            <CardHeader
              subheader="The information can be edited"

            />
            <Divider/>
            {parentChildren?.length ? (
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {tableCell.map((tableCell) => {
                            return (
                              <TableCell>{tableCell}</TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {parentChildren.map((children) => (
                          <TableRow key={children.user.name} onClick={() => handleRowClick(children)}>
                            <TableCell component="th" scope="row">
                              {children.user.id}
                            </TableCell>
                            <TableCell>{children.user?.name + ' ' + children.user?.last_name}</TableCell>
                            <TableCell>{moment(children.user?.birth_date).format('DD/MM/YYYY').toString()}</TableCell>
                            <TableCell>{children.user?.gender}</TableCell>
                            <TableCell>{children.user?.phone}</TableCell>
                            <TableCell>{children.user?.division?.name}</TableCell>
                            <TableCell>
                              <Chip
                                size="small"
                                color={(children?.approved === true ) ? 'secondary' :'primary'}
                                label={children?.approved === true ? 'Yes' : 'No'}/>
                            </TableCell>
                            <TableCell className={classes.actions}>
                               <Tooltip
                                  enterDelay={300}
                                  title="Edit profil">
                                  <IconButton
                                    color="inherit"
                                    component={Link}
                                    to={`/users/students/edit/${children.user.id}`}
                                    target='_blank'>
                                    <EditIcon/>
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  enterDelay={300}
                                  title="Switch"
                                >
                                  <IconButton
                                      color="inherit"
                                      onClick={() => switchStudent(children.user.id)}>
                                  <AccountCircleIcon />
                                  </IconButton>
                                </Tooltip>
                                    <DeleteFromTabList
                                      itemId={parentChildrenID}
                                      tableService={{method:  api_delete, base_url: `parent-user-children`}}
                                      initializeData={getListParentChild}/>
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
              ) : (
                  <CardContent>
                    <Typography variant="body2" color="textSecondary"
                      component="p">
                      This user does not have any child
                    </Typography>
                  </CardContent>
              )}
          </form>

        </Card>

        <SnackBar open={open} message={message} severity={severity}
          handleClose={handleClose}/>
      </Grid>

    </div>
  );
};
ChildrenForm.propTypes = {
  className: PropTypes.string,
};

export default ChildrenForm;
