import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import SnackBar from '../SnackBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  TextField,
  Box,
} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
}));
const Delete = (props) => {
  const {className, handleDelete, open, itemId,service,pageLink, restore,initializeData, rowsPerPage, replace, ...rest} = props;
  const classes = useStyles();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [eventId, setEventId] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [severity, setAlertSeverity] = React.useState('success');

  const handleCheckChange = () => {
    setChecked(!checked);
  };

  const handleClose = () => {
    setOpenAlert(false);
    setChecked(false);
    setEventId('')
  };
 
  const deleteUser=()=>{
    if(service !== null) {
      service.method(`${service.base_url}/${itemId}`).then((data)=>{
        if(data?.code !== 200 && data?.code !== 201){
          setOpenAlert(true);
          let errorMessage = data?.message? data.message:'Technical error! Contact the Developers Team';
          setAlertSeverity('warning')
          setMessage(errorMessage)
        } else {
          setMessage('Event replaced with success')
          setOpenAlert(true);
          setAlertSeverity('success')
          handleDelete();
          initializeData(rowsPerPage)
          setMessage('Deleted successfully')
        //window.location.replace(pageLink);
        }
      });
    }
  };
  const replaceEvent=()=>{
    if(eventId !== null) {
      service.method(`${service.base_url}/${itemId}/customDelete/${eventId}`).then((data)=>{
        if(data?.code !== 200 && data?.code !== 201){
          setOpenAlert(true);
          setAlertSeverity('warning')
          let errorMessage = data?.message? data.message:'Technical error! Contact the Developers Team';
          setMessage(errorMessage)
        } else {
        setOpenAlert(true);
        setAlertSeverity('success')
        handleDelete();
        setChecked(false);
        setEventId('')
        initializeData(rowsPerPage)
        setMessage('Event replaced with success')
        }
      });
    }

  };
  return (
      <div
          {...rest}
          className={clsx(classes.root, className)}
      >
        <Dialog
            open={open}
            onClose={handleDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{pageLink=="/user-settings/banned-users" ? 'Unblock User Confirmation' : 'Delete Confirmation'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {pageLink=="/user-settings/banned-users"  ? 'Are you sure to continue unblocking this user?' : 'Are you sure to continue deleting?'}
            </DialogContentText>
            {
              replace &&            
              <div>
              <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckChange}
                  value={checked}
                  name="replace"
                  size="small"
                />
              }
              label="I want to replace this event with another one ?"
            />
            <Box m={1} pt={1} hidden={checked != true}>
              <TextField
                autoFocus
                margin="dense"
                type="number"
                name="eventId"
                label="Enter new event ID"
                value={eventId}
                onChange={(e) => setEventId(e.target.value)}

                fullWidth
              />
            </Box>
           </div>

            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDelete} color="primary">
              Cancel
            </Button>
            <Button onClick={(eventId && checked == true) ? replaceEvent : deleteUser} color="primary" autoFocus>
              {replace ?'Continue' :'Yes'}
            </Button>
          </DialogActions>
         
        </Dialog>
        <SnackBar
            open={openAlert}
            handleClose={handleClose}
            message={pageLink=="/user-settings/banned-users" ? 'User is successfully unblocked': message}
            severity={pageLink=="/user-settings/banned-users" ? 'success': severity}
        />
      </div>
  );
};

Delete.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  handleDelete: PropTypes.func,
  itemId: PropTypes.number,
};

export default Delete;
