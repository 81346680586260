import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import Toolbar from '../../components/Toolbar/Toolbar'
import Table from '../../components/Table/Table';
import Collapse from '@material-ui/core/Collapse';
import Columns from '../../components/Columns';
import Filter from '../../components/Filter/Filter';
import optionsParser from '../../helpers/optionsParser';
import Loading from '../../components/Loading/Loading';
import {api_delete, api_get, get_file} from 'utils/Api';
import Moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },  content: {
    marginTop: theme.spacing(2),
  },
}));

const columnsList = [
  {accessor: 'id', name: 'id',label: 'Id', show: true, sortable: true},
  {accessor: 'amount',  name: 'amount', label: 'Amount', show: true, sortable: true},
  {accessor: 'user', label: 'User', show: true, type: 'concatString'},
  {accessor: 'user.phone', label: 'Phone', show: true},
  {accessor: 'user.division.name', label: 'Class', show: true, type: 'chip'},
  {accessor: 'offer.name', color: 'primary', label: 'Offer', show: true, type: 'chip'},
  {accessor: 'payment_agreement_slices', color: 'primary', label: 'Slices', show: true, type: 'slicesListType'},
  {accessor: 'created_at', name: 'startDate', label: 'Created At', show: false, sortable: true, type: 'date'},
  {accessor: 'admin_user.name', label: 'Administrator', show: true},
  {accessor: 'active', type: 'boolean', color: 'primary', label: 'Active', show: true,},

];
const fieldSearchable = [
  'id',
];
const fieldFilterable = [
  {name: 'user.id', label: 'User', type: 'userAutocomplete'},
  {name: 'user.division.id', label: 'Division', type: 'divisionAutocomplete'},
  {name: 'offer.id', label: 'Offer', type: 'offerAutocomplete'},
  {name: 'paymentAgreementSlices.startDate', label: 'Slice Start date', type: 'dateRange'},
  {name: 'paymentAgreementSlices.endDate', label: 'Slice End date', type: 'dateRange'},
  {name: 'createdAt', label: 'Created At', type: 'dateRange'},
  {name: 'paymentAgreementSlices.paid', label: 'Expired Slices', type: 'boolean'},


];

const PaymentAgreementList = () => {
  const [search, searchChange] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [filters, filtersChange] = useState({});
  const [columns, columnsChange] = useState(columnsList);
  const [sort, sortChange] = useState({accessor: 'id', order: 'desc'});
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(24);

  const date = Moment().format('YYYY-MM-DD');
  useEffect(() => {
    getData();
  }, [page, sort]);

  useEffect(() => {
    if (!search && isSearch) {
      setIsSearch(false)
      setPage(1);
      getData();
    }
  }, [search]);

  useEffect(() => {
    const options = optionsParser(search, filters, sort, fieldSearchable);
    if (search.length) {
      setIsSearch(true)
      setPage(1);
      api_get(`payment-agreements?page=${page}${options}&searchJoin=or`).then((data) => {
        setData(data);
      });
    }
    if(Object.keys(filters).length){
      if(filters['paymentAgreementSlices.paid'] && filters['paymentAgreementSlices.paid'] === 1){
        setPage(1);
        api_get(`payment-agreements?page=${page}&search=paymentAgreementSlices.paid:0;paymentAgreementSlices.startDate:${date}&searchJoin=and&perPage=${rowsPerPage}&orderBy=id&sortedBy=desc`).then((data) => {
          setData(data);
        });
      }else{
        setPage(1);
        api_get(`payment-agreements?page=${page}${options}&searchJoin=and&perPage=${rowsPerPage}`).then((data) => {
          setData(data);
        });
      }

    }
  }, [filters, search]);

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };
  const toggleColumns = () => {
    setColumnsOpen(!columnsOpen);
  };
  const handleSortChange = (accessor) => {
    sortChange({
      accessor: accessor,
      order: sort.order === 'asc' ? 'desc' : 'asc',
    });
  };

  const getData = (perPage = 24) => {
    const options = optionsParser(search, filters, sort, fieldSearchable);
    if(filters['paymentAgreementSlices.paid'] && filters['paymentAgreementSlices.paid'] === 1){
      api_get(`payment-agreements?page=${page}&search=paymentAgreementSlices.paid:0;paymentAgreementSlices.startDate:${date}&perPage=${perPage}&searchJoin=and&orderBy=id&sortedBy=desc`).then((data) => {
        setData(data);
      });
    }else{
      api_get(`payment-agreements?page=${page}${options}&perPage=${perPage}`).then((data) => {
        setData(data);
      });
    }
  };
  const getListData = (perPage = 24) => {
    const options = optionsParser(null, null, sort, null);
    api_get(`payment-agreements?page=${page}${options}&perPage=${perPage}`).then((data) => {
      setData(data);
    });
  };
  const handlePageChange = (event, page) => {
    setPage(page + 1);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    getData(event.target.value)
  };

  const handleExport = () => {
    const options = optionsParser(search, filters, sort, fieldSearchable);
    if(filters['paymentAgreementSlices.paid'] && filters['paymentAgreementSlices.paid'] === 1) {
      get_file(`payment-agreements/export?searchJoin=and&page=${page}&search=paymentAgreementSlices.paid:0;paymentAgreementSlices.startDate:${date}&orderBy=id&sortedBy=desc`)
    }else{
      get_file(`payment-agreements/export?searchJoin=and&page=${page}${options}`)
    }
  }
  const classes = useStyles();
  if (!data.payload) {
    return (<Loading/>);
  }
  return (
      <div className={classes.root}>
        <Toolbar toggleFilters={toggleFilters} toggleColumns={toggleColumns}
                 searchChange={searchChange}
                 pageLink='/finance/payment-agreements'
                 searchMessage={'Search (ID)'}
                 handleExport={handleExport}
        />
        <div className={classes.content}>
          <Collapse in={columnsOpen}>
            <Columns columnsChange={columnsChange} columns={columns}/>
          </Collapse>
          <Collapse in={filtersOpen}>
            <Filter fields={fieldFilterable} values={filters}
                    filtersChange={filtersChange} initializeData={getListData}/>
          </Collapse>
          <Table columns={columns} data={data}
                 handleSortChange={handleSortChange} sort={sort}
                 handlePageChange={handlePageChange} page={page}
                 rowsPerPage={rowsPerPage}
                 handleRowsPerPageChange={handleRowsPerPageChange}
                 tableService={{method: api_delete, base_url: `payment-agreements`}}
                 deleteUrl={`delete/many-payment-agreements`}
                 keyValue={"paymentAgreements"}
                 pageLink='/finance/payment-agreements' initializeData={getData}/>
        </div>
      </div>
  );
};

export default PaymentAgreementList;
