import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import SnackBar from "../../../components/SnackBar";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import { api_get, api_put } from "../../../utils/Api";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "35%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
}));

const PointsForm = (props) => {
  const { className, points, userId, ...rest } = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    points: "",
    note: "",
  });
  const [open, setOpen] = useState(false);
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertseverity] = useState("");
  const [newPoints, setNewPoints] = useState(0);
  const [pointsHistory, getPointsHistory] = useState([]);

  useEffect(() => {
    api_get(`point-history/${userId}`).then((data) => {
      getPointsHistory(data.payload);
    });
  }, [newPoints]);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index === 0) {
        setAlertseverity("error");
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const handleSubmit = async () => {
    api_put(`users/${userId}/add-point`, values).then((data) => {
      if (data.errors) {
        showErrorMessage(data.errors);
      } else {
        setNewPoints(data?.payload?.points);
        setValues({
          points: "",
          note: "",
        });
      }
    });
  }
  const handleClose = () => {
    setOpen(false);
  };
 
  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <CardHeader
            subheader="The information can be edited"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  label="Points"
                  name="points"
                  onChange={handleChange}
                  required
                  value={newPoints || points}
                  variant="outlined"
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={3} xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Ajouter/Retirer des points"
                  name="points"
                  onChange={handleChange}
                  value={values.points}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Note"
                  name="note"
                  onChange={handleChange}
                  value={values.note}
                  variant="outlined"
                />
              </Grid>
              <Grid>
                <Box m={2.5}>
                  <Button
                    disabled = {values.points ==="" || values.note === "" }
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    +
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <br />

            <Divider />
            <Grid container spacing={3}>
              {pointsHistory?.map((point, index) => {

                return (
                  <Grid md={3} item>
                    <Box pt={2}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography
                            style={{
                              color:
                                  pointsHistory[index-1] == undefined || pointsHistory[index]?.new_total >= pointsHistory[index-1]?.new_total &&
                                point.points > 0
                                  ? "green"
                                  : "red",
                            }}
                            className={classes.heading}
                          >
                            {pointsHistory[index-1] == undefined || pointsHistory[index]?.new_total >= pointsHistory[index-1]?.new_total &&
                            point.points > 0 ? (
                              <ArrowUpwardIcon />
                            ) : point.points === 0 ? (
                              <ArrowDownwardIcon />
                            ) : (
                              <ArrowDownwardIcon />
                            )}
                            {`${point.points}  DT`}{" "}
                          </Typography>
                          <Typography className={classes.secondaryHeading}>
                            {moment(point.created_at)
                              .format("YYYY-MM-DD")
                              .toString()}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{ flexDirection: "column" }}>
                            <Typography>
                              <div className={classes.column} />
                              <div className={classes.column}>
                                <Chip label="Details" />
                              </div>
                              <div
                                className={clsx(classes.column, classes.helper)}
                              >
                                <Typography variant="caption">
                                  <p className={classes.link}>
                                    {point.purpose}
                                  </p>
                                  <p style={{ display: "flex" }}>
                                    Total points
                                    <p
                                      className={classes.link}
                                    >{` : ${point.new_total}`}</p>
                                  </p>
                                </Typography>
                              </div>
                            </Typography>

                            <Typography>
                              <div className={classes.column} />
                              <div className={classes.column}>
                                <Chip label="Note" />
                              </div>
                              <div
                                className={clsx(classes.column, classes.helper)}
                              >
                                <Typography variant="caption">
                                  <p>{point.note}</p>
                                </Typography>
                              </div>
                            </Typography>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </form>
      </Card>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};
PointsForm.propTypes = {
  className: PropTypes.string,
};

export default PointsForm;
