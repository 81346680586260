// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, TextField} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import mapStateToRequest from '../../../helpers/mapStateToRequest';
import {DatePicker, LocalizationProvider} from "@material-ui/pickers";
import momentAdapter from "@material-ui/pickers/adapter/moment";
import Moment from "moment";
import {api_get, api_post, api_put} from "../../../utils/Api";
import LoadingApi from 'components/LoadingApi/LoadingApi';
import {getTeachers, getWebinars} from "../../../helpers/methods";

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
 
}));

const importanceOpts = ['default', 'hight', 'very hight'];

const Form = (props) => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
    importance: '',
    user: null,
    dueDate: Moment().format('YYYY-MM-DD hh:mm'),
    appearanceDate: Moment().format('YYYY-MM-DD hh:mm'),
    description: '',
    webinarAccount: null,

  });
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) {
      setLoading(true)
      api_get(`tasks/${id}`).then((result) => {
        setLoading(false);
        const data = result.payload;
        setValues({
          name: data.name,
          importance: data.importance,
          user: data.user,
          dueDate: data.due_date,
          appearanceDate: data.appearance_date,
          description: data.description,
          webinarAccount: data.webinar_account,
        });
      });
    }
  }, []);
  const teacherChange = (value) => {
    setValues({...values, user: value});
  };
  const webinarChange = (value) => {
    setValues({...values, webinarAccount: value});
  };
  const importanceChange = (e, value) => {
    setValues({...values, importance: value});
  };
  const appearanceDateChange = (value) => {
    if(value){
      setValues({ ...values, appearanceDate: value.format("YYYY-MM-DD hh:mm"), });
    }
  };
  const dueDateChange = (value) => {
    if(value){
      setValues({ ...values, dueDate: value.format("YYYY-MM-DD hh:mm"), });
    }
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };

  const formValidation = (values) => {
    if (!values?.name) {
      return { success: false, 'message': 'Name is missing' };
    }
    if (!values?.user) {
      return { success: false, 'message': 'Importance is missing' };
    }
    if (!values?.importance) {
      return { success: false, 'message': 'User is missing' };
    }
  };

  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
      const requestValues = mapStateToRequest(values);
      setLoading(true);
      if (edit) {
        api_put(`tasks/${id}`, requestValues).then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            setAlertSeverity('success');
            setAlertMessage('task updated successfully');
            setOpen(true);
            setLoading(false);
            window.location.href = '/contents/tasks';
          }
        });
      } else {
        api_post('tasks', requestValues)
            .then((result) => {
              if (result.errors) {
                showErrorMessage(result.errors);
              } else {
                setAlertSeverity('success');
                setAlertMessage('task created successfully');
                setOpen(true);
                setLoading(false);
                window.location.href = '/contents/tasks';
              }
            })
            .catch((error) => {
              throw new Error(error);
            });
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }
  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete='off' noValidate>
          <CardHeader subheader='Tasks form' />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label='Name'
                  name='name'
                  onChange={handleChange}
                  required
                  value={values.name || ''}
                  variant='outlined'
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <AsyncPaginate
                  loadOptions={getTeachers}
                  value={values.user}
                  onChange={teacherChange}
                  getOptionLabel={(option) => option.name+' '+option.last_name}
                  getOptionValue={(option) => option.id}
                  placeholder="Teachers"
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: '53px',
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: '#000',
                      fontSize: theme.typography.fontSize,
                      fontFamily: theme.typography.fontFamily,
                    }),
                  }}
                  menuPortalTarget={document.querySelector('body')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  options={importanceOpts}
                  value={values.importance || ''}
                  onChange={(event, newValue) =>
                    importanceChange(event, newValue)
                  }
                  id='debug'
                  debug
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Importance'
                      required={true}
                      fullWidth
                      className={classes.autocomplete}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <AsyncPaginate
                  loadOptions={getWebinars}
                  onChange={webinarChange}
                  getOptionLabel={(option) => option.email}
                  getOptionValue={(option) => option}
                  value={values.webinarAccount}
                  placeholder="Webinars"
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: '53px',
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: '#000',
                      fontSize: theme.typography.fontSize,
                      fontFamily: theme.typography.fontFamily,
                    }),
                  }}
                  menuPortalTarget={document.querySelector('body')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <LocalizationProvider dateAdapter={momentAdapter}>
                  <DatePicker
                      label="Appearance Date"
                      name="appearanceDate"
                      inputFormat="DD/MM/yyyy"
                      value={values.appearanceDate}
                      onChange={appearanceDateChange}
                      renderInput={(props) => <TextField
                          {...props}
                          fullWidth
                          variant="outlined"
                      />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={6} xs={12}>
                <LocalizationProvider dateAdapter={momentAdapter}>
                  <DatePicker
                      label="Due Date"
                      name="dueDate"
                      inputFormat="DD/MM/yyyy"
                      value={values.dueDate}
                      onChange={dueDateChange}
                      renderInput={(props) => <TextField
                          {...props}
                          fullWidth
                          variant="outlined"
                      />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  id='description'
                  label='Description'
                  type='text'
                  value={ values.description || ''}
                  variant='outlined'
                  name='description'
                  onChange={(e) => handleChange(e)}
                  fullWidth
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}></Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color='primary'
              variant='contained'
              onClick={handleSubmit}
            >
              Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
      <LoadingApi open={loading} onClick={handleCloseLoading}/>

    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,
};

export default Form;
