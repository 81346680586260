import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TextField,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel"
import PropTypes from "prop-types";
import { api_get, api_post, api_put } from "../../../utils/Api";
import MenuItem from "@material-ui/core/MenuItem";
import optionsParser from '../../../helpers/optionsParser';
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import { Link, useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import LoadingApi from 'components/LoadingApi/LoadingApi';
import theme from "theme";
import SimpleToolbar from '../../../components/Toolbar/SimpleToolbar';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
  button: {
    margin: "10px",
  },
  importButton: {
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(-15),
  },
  
  downloadButton: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(-15),
    flexGrow: 1,
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginTop:100,
      }
  },
}));
type Props = {
  edit: boolean,
  className: string,
};

const   EventPenaltyForm = (props: Props): React$Element<any> => {
  const { id } = useParams();
  const { className, edit, Link, pageLink, title, eventId, instructorId, EventPenalties, ...rest } = props;
  const [Details, setDetails] = useState([]);
  const [message, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [severity, setAlertSeverity] = useState("success");
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [values, setValues] = useState({
    penalties: [
      {
        note: "",
        confirmed: '0',
        canceled: '0',
        event: eventId,
        penaltyDetail: null,
        instructor: instructorId,
      },
    ],
  });

  useEffect(() => {
    api_get(`penalty-details`).then((result) => {
      setDetails(result.payload);
    });
  }, []);

    useEffect(() => {
        if (EventPenalties.length > 0) {
        setValues({
          penalties: EventPenalties
        });
       }
  },[]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity("error");
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const formValidation = (values) => {
    return values?.penalties.map((penalty, index) => {
      if (!penalty?.penaltyDetail) {
        return { success: false, message: "Penalty Detail " + [index+1] + " is missing" };
      }
      if (!penalty?.note) {
        return { success: false, message: "note " + [index+1] + " is missing",};
      }
     if (!penalty?.confirmed) {
        return { success: false, message: "Confirmed " + [index+1] + " is missing" };
      }
      if (!penalty?.canceled) {
        return { success: false, message: "Canceled " + [index+1] + " is missing" };
      }
    });
  };
  const handleSubmit = () => {
    const validations = formValidation(values).filter(
      (validation) => validation?.message && validation.message !== undefined
    );
    setLoading(true);
    if (validations?.length > 0) {
      validations.map((validation) => {
        setAlertMessage(validation?.message);
        setAlertSeverity("warning");
        setOpen(true);
      });

    } else {
      
      api_post(`many-event-penalties`, values)
        .then((result) => {
          if (result.errors) {
            setAlertSeverity("error");
            showErrorMessage(result.errors);
          } else {
            setAlertSeverity("success");
            setAlertMessage("Penalties created successfully");
            setOpen(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  };
  const AddRemovePenalty = (operation, index) => {
    if (operation == "plus") {
      setValues((previousData) => ({
        ...previousData,
          penalties: values?.penalties?.concat({
          note: values?.penalties[0]?.note,
          confirmed:  values?.penalties[0]?.confirmed ,
          canceled: values?.penalties[0]?.canceled ,
          event:values?.penalties[0]?.event,
          penaltyDetail: values?.penalties[0].penaltyDetail,
          instructor: values?.penalties[0].instructor,
        }),
      }));
    } else {
      setValues((previousData) => ({
        ...previousData,
        penalties: values.penalties.filter((num, i) => {
          return i != index;
        }),
      }));
    }
  };

  const handleFiledsChange = (event, index,value) => {
    const newArr = { ...values };
    newArr.penalties[index][event.target.name] = event.target.value;
    setValues({ ...newArr });
  };

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <CardContent>
            {values.penalties.map((penalty, index) => {
              return (
                <Grid container spacing={3} key={index}>
                  <p></p>
                  <Grid item md={4} xs={4}>
                    <TextField
                      fullWidth
                      select
                      freeSolo
                      name="penaltyDetail"
                      id="penaltyDetail"
                      variant="outlined"
                      label={penalty.penaltyDetail ?  "" : "Penalty Detail" }
                      value={penalty.penaltyDetail}
                      required
                      onChange={(e) => handleFiledsChange(e, index)}
                    >
                      {Details.map((Detail) => {
                        return (
                          <MenuItem key={Detail.id} value={Detail.id}>
                            {`${Detail.penalty.name} | ${Detail.name}`}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <TextField
                      fullWidth
                      name="note"
                      id="note"
                      variant="outlined"
                      label="Note"
                      value={penalty?.note}
                      required
                      onChange={(e) => handleFiledsChange(e, index)}
                    ></TextField>
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Confirmed</FormLabel>
                      <RadioGroup
                        aria-label="confirmed"
                        name="confirmed"
                        onChange={(e) => handleFiledsChange(e, index)}
                        value={
                          penalty.confirmed 
                        }
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value="1"
                          label="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value="0"
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Canceled</FormLabel>
                      <RadioGroup
                        aria-label="canceled"
                        name="canceled"
                        onChange={(e) => handleFiledsChange(e, index)}
                        value={
                          penalty.canceled 
                        }
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value="1"
                          label="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value="0"
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <Box m={1} pt={1} hidden={index != 0}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => AddRemovePenalty("plus")}
                      >
                        +
                      </Button>
                    </Box>
                    <Box m={1} pt={1} hidden={index == 0}>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={(event) => AddRemovePenalty(event, index)}
                      >
                        -
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </CardContent>
          <Divider />
          <CardActions>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
     <LoadingApi open={loading} onClick={handleCloseLoading}/>
    </div>
  );
};

EventPenaltyForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
  loading: PropTypes.bool,
};

export default EventPenaltyForm;
