import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
    Box,
    Card,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {},
}));

const HistoriesSubscriptionsInfo = ({className, histories, ...rest}) => {
    const classes = useStyles();

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >

            <Box minWidth={1100}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Active</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Createad At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(histories && histories.length )? (histories.map((subscription) => (
                                <TableRow key={subscription.id}>
                                    <TableCell>
                                        {subscription.id}
                                    </TableCell>
                                    <TableCell>
                                        {subscription.name}
                                    </TableCell>
                                    <TableCell style={{display: 'flex'}}>
                                        {subscription.description}
                                    </TableCell>
                                    <TableCell>
                                        {subscription.active}
                                    </TableCell>
                                    <TableCell>
                                        {moment(subscription.start_date).format('DD/MM/YYYY')}
                                    </TableCell>
                                    <TableCell>
                                        {moment(subscription.end_date).format('DD/MM/YYYY')}
                                    </TableCell>
                                    <TableCell>
                                        {moment(subscription.created_at).format('DD/MM/YYYY')}
                                    </TableCell>

                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell>
                                    He does not have any subscription
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
        </Card>
    );
};

HistoriesSubscriptionsInfo.propTypes = {
    className: PropTypes.string,
    histories: PropTypes.object.isRequired,
};

export default HistoriesSubscriptionsInfo;
