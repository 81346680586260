import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import SnackBar from "../SnackBar";
import { Button , CardContent} from "@material-ui/core";
import { DeleteRounded } from "@material-ui/icons";
import { api_delete_many } from '../../utils/Api';
import {useStore} from "../../contexts/JWTAuthContext";
import {checkPermission} from "../../utils/acl/aclRouter";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    style: {
        color: 'red',
        fontSize: 20
    },
}));

const ActionDeleteMany = (props) => {
    const { pageLink, baseUrl, noDeleteMany, noCheck, deleteUrl,selectedItems, setSelectedItems,rowsPerPage,initializeData,keyValue, ...rest} = props;
    const {user} = useStore();
    const routeDelete = window.location.pathname+'/delete';
    const [message, setAlertMessage] = useState('');
    const [severity, setAlertSeverity] = useState('success');
    const [openModal, setOpenModal] = useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);


    const handleModalClose = () => {
        setOpenModal(!openModal);
        if(selectedItems.length == 0){
            setOpenModal(false);
        }
    };

    const handleCloseAlert= () => {
        setOpenAlert(false);
      };

    const handleDeleteItems = () => {
        api_delete_many( deleteUrl, selectedItems, keyValue).then((data) => {
                if(data?.code !== 200 && data?.code !== 201){      
                setAlertSeverity('warning');
                setAlertMessage(data.message);
                setOpenAlert(true);
            }
            else{
                setAlertSeverity('success');
                setAlertMessage('item(s) deleted successfully');
                setOpenModal(false);
                setOpenAlert(true);
            }
            initializeData();
            setSelectedItems([]);
         })
        };

    const classes = useStyles();

    return (    
        <div className={classes.root}>  
             {selectedItems.length > 0 && !noDeleteMany && user && checkPermission(routeDelete, user.roles, user?.admin_groups) && ( 
                <CardContent>               
                 Delete many items :                               
                    <Button
                        color="primary"
                        size="large"
                        onClick={handleModalClose}
                        startIcon={<DeleteRounded />}>                 
                    Delete
                    </Button>                          
                </CardContent>)}       
                <Dialog
                    open={openModal}
                    onClose={handleModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">Delete item(s) Confirmation</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete these <span className={classes.style}>{selectedItems.length}</span> item(s) ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleModalClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={()=> handleDeleteItems()} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <SnackBar
                    open={openAlert}
                    message={message}
                    severity={severity}
                    handleClose={handleCloseAlert}
                    autoHideDuration={6000}
                />  
        </div>   
    );
};

export default ActionDeleteMany;
