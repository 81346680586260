import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Grid, makeStyles } from "@material-ui/core";
import BasicsInfo from "./BasicsInfo";
import SegmentUsersInfo from "./SegmentUsersInfo";
import { api_get } from "utils/Api";

const useStyles = makeStyles(() => ({
  root: {},
}));

const Details = ({
  meta,
  segment,
  users,
  className,
  page,
  rowsPerPage,
  handleRowsPerPageChange,
  handlePageChange,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={3}
      {...rest}
    >
      <Grid item lg={3} md={6} xl={4} xs={12}>
        <BasicsInfo segment={segment} />
      </Grid>
      <Grid item lg={9} md={8} xl={8} xs={12}>
        <SegmentUsersInfo
          meta={meta}
          users={users}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onPageChange={handlePageChange}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  subjects: PropTypes.object.isRequired,
  histories: PropTypes.object.isRequired,
};

export default Details;
