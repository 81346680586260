import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import optionsParser from "../../../helpers/optionsParser";
import { AsyncPaginate } from "react-select-async-paginate";
import theme from "../../../theme";
import { api_get } from "../../../utils/Api";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const OptionalSubjectAutocompleteFilter = (props) => {
  const { className, filtersChange, field, values, ...rest } = props;
  const classes = useStyles();
  const getSubjects = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name_and_division"])
      .replace("&search=", ";")
      .replace("&searchJoin=or", "");
    const result = await api_get(
      `subjects?page=${page.page}&search=optional:1;visible:1${options}&searchJoin=and&orderBy=id&sortedBy=desc`
    );

    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  const handleChange = (value) => {
    filtersChange({
      [field.name]: value,
    });
  };
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <AsyncPaginate
        loadOptions={getSubjects}
        onChange={handleChange}
        getOptionLabel={(option) => option.division.name + " | " + option.name}
        getOptionValue={(option) => option.id}
        placeholder={field.label}
        isClearable={true}
        isMulti={true}
        additional={{
          page: 1,
        }}
        value={values[field.name] || null}
        styles={{
          placeholder: (base) => ({
            ...base,
            color: "#000",
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily,
          }),
        }}
        menuPortalTarget={document.querySelector("body")}
      />
    </div>
  );
};

OptionalSubjectAutocompleteFilter.propTypes = {
  className: PropTypes.string,
  filtersChange: PropTypes.func,
  field: PropTypes.object,
  values: PropTypes.object,
  handleChange: PropTypes.func,
};

export default OptionalSubjectAutocompleteFilter;
