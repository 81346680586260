import React from 'react';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Label from '../../../components/Label';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const DestinataireList = ({
                      segment,
                      division,
                      groups,
                      users,
                      states,
                      phone,
                      noAffiliated,
                      noEmail,
                            noLevels,
                      className,
                      ...rest
                    }) => {
  const classes = useStyles();
  return (
      <Card
          className={clsx(classes.root, className)}
          {...rest}
      >
        <CardHeader title="Sms info"/>
        <Divider/>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Users
              </TableCell>
              <TableCell>
                {users && users.map(user=>{
                  return(
                      <Typography
                          variant="body2"
                          color="textSecondary"
                      >
                        {user?.name+' '+user?.last_name}
                      </Typography>
                  )
                })}

              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                External phone             
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {phone}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Groups
              </TableCell>
              <TableCell>
                {groups && groups.map(group=>{
                  return(
                      <Typography
                          variant="body2"
                          color="textSecondary"
                      >
                        {group.name}
                      </Typography>
                  )
                })}

              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Segment
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {segment ? segment.name :'*****'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Divisions
              </TableCell>
              <TableCell>
                {division && division.map(item=>{
                  return(
                      <Typography
                          variant="body2"
                          color="textSecondary"
                      >
                        {item.name}
                      </Typography>
                  )
                })}

              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                States
              </TableCell>
              <TableCell>
                {states && states.map(state=>{
                  return(
                      <Typography
                          variant="body2"
                          color="textSecondary"
                      >
                        {state.name}
                      </Typography>
                  )
                })}

              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                users without  affiliations
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {noAffiliated ? 'Yes': 'No'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Users without emails
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {noEmail ? 'Yes': 'No'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Users without levels
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {noLevels ? 'Yes': 'No'}
                </Typography>
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </Card>
  );};

DestinataireList.propTypes = {
  className: PropTypes.string,
  points: PropTypes.object.isRequired,
};

export default DestinataireList;
