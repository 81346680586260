import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '5%', show: true},
  {accessor: 'name', label: 'Name', width: '15%', show: true},
  {accessor: 'address', label: 'Address', width: '50%', show: true},
  {accessor: 'created_at', type: 'date', color: 'default', label: 'Created At', width: '15%', show: true,},
  {accessor: 'updated_at', type: 'date', color: 'default', label: 'Updated At', width: '15%', show: true,},
];
const fieldSearchable = [
  'name',
];
const fieldFilterable = [];
const OfficeList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`offices`}
          exportUrl={`offices/export`}
          pageLink ={'/marketing/offices'}
          searchMessage={'Search (Name)'}
          baseUrl={`offices`}
          noShow={true}
      />
  );
};

export default OfficeList;
