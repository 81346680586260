import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '30%', show: true, type: 'fieldValue'},
  {accessor: 'created_at', name: 'createdAt', label: 'Date', width: '25%', type : 'date', show: true, sortable: true,},
  {accessor: 'updated_at', name: 'updatedAt', label: 'Date', width: '25%', type : 'date', show: true, sortable: true,},
];

const fieldSearchable = [
  'id',
  'name',
];
const fieldFilterable = [
  {name: 'createdAt', label: 'Created at', type: 'dateRange'},
  {name: 'updatedAt', label: 'Updated at', type: 'dateRange'},
];
const SegmentationList = () => {

  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`segments`}
          exportUrl={`segments/export`}
          pageLink ={'/user-settings/segments'}
          searchMessage={'Search (ID)'}
          baseUrl={`segments`}
          noEdit={true}
          deleteUrl={`delete/many-segments`}
          keyValue={"segments"}
      />
  );
};

export default SegmentationList;
