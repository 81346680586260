import React from "react";
import PropTypes from "prop-types";
import Delete from "./Delete";
import { Tooltip } from '@material-ui/core';
import { DeleteRounded } from "@material-ui/icons";
import { Button } from "@material-ui/core";


const DeleteFromTabList = (props) => {
  const { itemId, tableService, pageLink, initializeData, ...rest } = props;
  const [openDelete, setOpenDelete] = React.useState(false);

    const handleDelete = () => {
    setOpenDelete(!openDelete);
  };


  return (
   <div>
      <Tooltip
         enterDelay={300}
         title="Delete">
        <Button 
        startIcon={<DeleteRounded/>}
        onClick={handleDelete}/> 
      </Tooltip>
      <Delete
        open={openDelete}
        handleDelete={handleDelete}
        itemId={itemId}
        service={tableService}
        initializeData={initializeData}/>
    </div>
  );
};

DeleteFromTabList.propTypes = {
  className: PropTypes.string,
  itemId: PropTypes.number,
};

export default DeleteFromTabList;
