import React, {useEffect, useState} from 'react';
import {
  Box,
  Container,
  Divider,
  makeStyles,
  Tab,
  Tabs,
} from '@material-ui/core';
import Page from '../../components/Page';
import UserInfo from './ParentView/Index';
import {useParams} from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import SimpleToolbar from '../../components/Toolbar/SimpleToolbar';
import {api_get} from "../../utils/Api";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const UserShow = (props) => {
      const {Link , pageLink, title, ...rest} = props;
      const {id} = useParams();
      const classes = useStyles();
      const [user, setUser] = useState([]);
      const [children, setChildren] = useState([]);
      const [pointsHistory, setPointsHistory] = useState([]);
      const [currentTab, setCurrentTab] = useState('details');

      const tabs = [
        {value: 'details', label: 'Details'},
      ];

      const handleTabsChange = (event, value) => {
        setCurrentTab(value);
      };

      useEffect(() => {
              api_get(`parent-users/${id}`).then((result) => {
              setUser(result?.payload);
              setChildren(result?.payload?.parent_user_children);
              });
              api_get(`parent-users/point-history/${id}`).then((result) => {
                setPointsHistory(result?.payload);
              });
      }, [currentTab, id]);

      return (
          <div>
            <SimpleToolbar
              Link = {Link}
              pageLink={"/users/parent-users"}
              title = {'Parents'} />
            <Page
                className={classes.root}
                title="Details"
            >
              <Container maxWidth={false}>
                <Box mt={3}>
                  <Tabs
                      onChange={handleTabsChange}
                      scrollButtons="auto"
                      value={currentTab}
                      variant="scrollable"
                      textColor="secondary"
                  >
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.value}
                            label={tab.label}
                            value={tab.value}
                        />
                    ))}
                  </Tabs>
                </Box>
                <Divider/>
                <Box mt={3}>
                  {currentTab === 'details' &&
                  <UserInfo user={user} 
                            children={children}
                            pointsHistory={pointsHistory}/>}
                </Box>
              </Container>
            </Page>
          </div>
      );
    }


export default UserShow;
