// @flow
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Label from "../../../components/Label";

const useStyles = makeStyles(() => ({
  root: {},
}));
type Props = {
  className: string,
  handlePageChange: Function,
  page: number,
  users: {
    meta: {total: number},
    data : Array<{id: number, user:{id: number, full_name: string, email: string }}>,
  },
}
const UsersEventInfo = (props: Props): React$Element<any> => {
  const classes = useStyles();
  const {className, handlePageChange, page, users} = props;

  return (
      <Card
          className={clsx(classes.root, className)}
      >
        <CardHeader
            title={"Number of participated users : " + users?.length}
        />
        <Divider />
        <Box minWidth={1150}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User Id</TableCell>
                <TableCell>Full name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users ? (users.map((event) => (
                      <TableRow key={event.id}>
                        <TableCell>
                          {event.user?.id}
                        </TableCell>
                        <TableCell>
                          {event.user?.name+' '+ event.user?.last_name}
                        </TableCell>
                        <TableCell>
                          <Label color='success'>
                            {event.user?.phone}
                          </Label>
                        </TableCell>
                        <TableCell>
                          {event.user?.email}
                        </TableCell>
                      </TableRow>
                  ))
              ) : (
                  <TableRow>
                    <TableCell>
                      He does not have any user
                    </TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
       {/* <TablePagination
            component="div"
            count={users.meta?.total}
            onChangePage={handlePageChange}
            //onRowsPerPageChange={() => {}}
            onChangeRowsPerPage={() => {}}
            page={page-1}
            rowsPerPage={10}
            rowsPerPageOptions={[ 10]}
        />*/}
      </Card>
  );
};

UsersEventInfo.propTypes = {
  className: PropTypes.string,
  users: PropTypes.object.isRequired,
};

export default UsersEventInfo;
