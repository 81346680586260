import React, {useEffect, useState} from 'react';
import {
  Box,
  Container,
  Divider,
  makeStyles,
  Tab,
  Tabs,
} from '@material-ui/core';
import Page from '../../components/Page';
import UserInfo from './UserView/Index';
import {useParams} from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import SimpleToolbar from '../../components/Toolbar/SimpleToolbar';
import PaymentAgreement from './UserView/PaymentAgreement';
import SubscriptionsInfo from './UserView/SubscriptionsInfo';
import {api_get} from "../../utils/Api";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const UserShow = (props) => {
      const {Link , pageLink, title, ...rest} = props;
      const {id} = useParams();
      const classes = useStyles();
      const [user, setUser] = useState([]);
      const [studentLevels, setStudentLevels] = useState([]);
      const [pointsHistory, setPointsHistory] = useState([]);
      const [currentTab, setCurrentTab] = useState('details');
      const [paymentAgreement, setPaymentAgreement] = useState([]);
      const [userSubscriptions, getSubscriptions] = useState([]);

      const tabs = [
        {value: 'details', label: 'Basics'},
        {value: 'subscriptions', label: 'Subscriptions'},
        {value: 'paymentAgreement', label: 'Payment Agreement'},
      ];

      const handleTabsChange = (event, value) => {
        setCurrentTab(value);
      };

      useEffect(() => {
        switch (currentTab) {
          case 'details':
              api_get(`users/${id}`).then((result) => {
              setUser(result.payload);
            });
              api_get(`student-levels/get-levels/${id}`).then(data => {
              setStudentLevels(data.payload);
            });
              api_get(`point-history/${id}`).then((data) => {
              setPointsHistory(data.payload);
            });
            break;
          case 'paymentAgreement':
            api_get(`payment-agreements?search=user.id:${id}&searchJoin=and`).then((result) => {
              setPaymentAgreement(result.payload);
            });
            break;
          case 'subscriptions':
              api_get(`user-subscriptions/${id}`)
                .then((result) => {
                  getSubscriptions(result.payload);
                });
            break;
        }

      }, [currentTab, id]);
      if (!Object.keys(user).length && currentTab === 'details')
        return (<Loading/>);
      return (
          <div>
            <SimpleToolbar
              Link = {Link}
              pageLink = {'/users/students'}
              title = {'Students'} />
            <Page
                className={classes.root}
                title="Customer Details"
            >
              <Container maxWidth={false}>
                <Box mt={3}>
                  <Tabs
                      onChange={handleTabsChange}
                      scrollButtons="auto"
                      value={currentTab}
                      variant="scrollable"
                      textColor="secondary"
                  >
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.value}
                            label={tab.label}
                            value={tab.value}
                        />
                    ))}
                  </Tabs>
                </Box>
                <Divider/>
                <Box mt={3}>
                  {currentTab === 'details' &&
                  <UserInfo user={user} levels={studentLevels}
                            pointsHistory={pointsHistory}/>}
                  {currentTab === 'subscriptions' &&
                  <SubscriptionsInfo subscriptions={userSubscriptions}/>}
                  {currentTab === 'paymentAgreement' &&
                  <PaymentAgreement paymentAgreement={paymentAgreement}/>}
                </Box>
              </Container>
            </Page>
          </div>
      );
    }
;

export default UserShow;
