import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'ID', show: true},
  {accessor: 'name', label: 'Name', width: '20%', type: 'fieldValue', show: true},
  {accessor: 'points', label: 'Points', width: '20%', type: 'fieldValue', show: true},
  {accessor: 'number', label: 'Number', width: '20%', type: 'fieldValue', show: true},
  {accessor: 'penalty.name', label: 'Penalty', width: '20%', show: true},

];

const fieldSearchable = [
  'id',
];
const fieldFilterable = [
  {name: 'id', label: 'ID', type: 'text'},
  {name: 'name', label: 'Name', type: 'text'},
  {name: 'penalty.id', label: 'Penalty', type: 'PenaltyAutocompleteFilter'},

];
const PenaltyDetailsList = () => {

  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`penalty-details`}
          exportUrl={`penalty-details/export`}
          pageLink ={'/teacher-settings/penalty-details'}
          baseUrl={`penalty-details`}
          deleteUrl={`delete/many-penalty-details`}
          keyValue={"penaltiesDetails"}
          noShow={true}
      />
  );
};

export default PenaltyDetailsList;
