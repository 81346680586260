// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import { api_get, api_post, api_put } from "../../../utils/Api";
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import moment from 'moment';
import LoadingApi from 'components/LoadingApi/LoadingApi';

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
 
}));
type Props = {
  edit: boolean,
  className: string
}
const Form = (props: Props): React$Element<any> => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    user: '',
    //is_public: '',
  });
  const [message, setAlertMessage]=useState('');
  const [severity, setAlertSeverity]=useState('success');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) {
      setLoading(true)
      api_get(`called-users/${id}`).then((result)=>{
        setLoading(false)
        const data=result.payload;
        setValues({
          user: data.user,
          marketer: data.marketer,
          note: data.note,
          called: data.called === true ? '1' : '0' ,
          isConfirmed: data.isConfirmed === true ? '1' : '0',
        });
      });
    }
  }, []);

 /* const userC = (value) => {
    //setValues({...values, instructor_id: value});
    setValues({
      ...values, content: {
        ...values.content, instructor: value,
      }
    });
  };*/

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const showErrorMessage=(errors)=>{
    Object.keys(errors).map(function(key, index) {
      if (index==0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const handleSubmit = () => {
   /* values.user = values.user.id;
    values.marketer = values.marketer.id;*/
    const requestValues = mapStateToRequest(values, [],['called','isConfirmed']);
    setLoading(true);
    
    if (edit) {
      //api_put(`called-users/${id}`, values).then((result) => {
        api_post(`called-users/${id}`, requestValues).then((result) => {
        if (result.errors) {
          showErrorMessage(result.errors);
        } else {
          setAlertSeverity('success');
          setAlertMessage(' updated successfully');
          setOpen(true);
          setLoading(false);
          //window.location.href = '/marketing/called-users';
        }
      });
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }
  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form
          autoComplete="off"
          noValidate
        >
          <CardHeader
            subheader="CalledUsers form"
           
          />
          <Divider/>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  required
                  disabled
                  value={(values.user.name+' '+values.user.last_name )|| ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                  item
                  md={6}
                  xs={12}
              >
                <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    onChange={handleChange}
                    required
                    disabled
                    value={values.user.phone || ''}
                    variant="outlined"
                />
              </Grid>
              <Grid
                  item
                  md={6}
                  xs={12}
              >
                <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    required
                    disabled
                    value={values.user.email || ''}
                    variant="outlined"
                />
              </Grid>
              <Grid
                  item
                  md={6}
                  xs={12}
              >
                <TextField
                    fullWidth
                    label="Division"
                    name="division"
                    onChange={handleChange}
                    required
                    disabled
                    value={values.user?.division?.name || ''}
                    variant="outlined"
                />
              </Grid>
              <Grid
                  item
                  md={6}
                  xs={12}
              >
                <TextField
                    fullWidth
                    label="Created at"
                    name="created_at"
                    onChange={handleChange}
                    required
                    disabled
                    value={moment(values.user.created_at).format('DD/MM/YYYY') || ''}
                    variant="outlined"
                />
              </Grid>
              <Grid
                  item
                  md={6}
                  xs={12}
              >
                <TextField
                    fullWidth
                    label="Commercial"
                    name="commercial"
                    onChange={handleChange}
                    required
                    disabled
                    value={values.marketer?.username || ''}
                    variant="outlined"
                />
              </Grid>
              <Grid
                  item
                  md={6}
                  xs={12}
              >
                <TextField
                    fullWidth
                    label="Note"
                    name="note"
                    onChange={handleChange}
                    required
                    value={values.note || ''}
                    variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={4}
                xs={2}
              >
                <FormControl component="fieldset">
                  <FormLabel component="legend">Confirmed</FormLabel>
                  <RadioGroup aria-label="Confirmed" name="isConfirmed"
                    onChange={handleChange}
                    value={values.isConfirmed || ''} row>
                    <FormControlLabel control={<Radio/>} value="1"
                      label="Yes"/>
                    <FormControlLabel control={<Radio/>} value="0"
                      label="No"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                  item
                  md={4}
                  xs={2}
              >
                <FormControl component="fieldset">
                  <FormLabel component="legend">Called</FormLabel>
                  <RadioGroup aria-label="Called" name="called"
                              onChange={handleChange}
                              value={values.called || ''} row>
                    <FormControlLabel control={<Radio/>} value="1"
                                      label="Yes"/>
                    <FormControlLabel control={<Radio/>} value="0"
                                      label="No"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

          </CardContent>
          <Divider/>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}>
                Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      <SnackBar open={open} message={message} severity={severity} handleClose={handleClose}/>
      <LoadingApi open={loading} onClick={handleCloseLoading}/>
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,
};

export default Form;
