import React, { useEffect, useState } from "react";
import SimpleToolbar from "../../components/Toolbar/SimpleToolbar";
import { api_get, api_post } from "../../utils/Api";
import theme from "../../theme";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  MenuItem,
  Switch,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import optionsParser from "../../helpers/optionsParser";
import { AsyncPaginate } from "react-select-async-paginate";
import SnackBar from "components/SnackBar";
import DiscountCodeForm from "./components/DiscountCodeForm";
import GiftCodeForm from "./components/GiftCodeForm";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const BuyForUserForm = (props) => {
  const { Link, pageLink, title, ...rest } = props;
  const classes = useStyles();
  const [discounts, setDiscounts] = useState([
    { name: "Réduction en pourcentage", value: "percentage_discount" },
    { name: "Réduction fixe", value: "fixe_discount" },
    { name: "Prix fixe", value: "fixe_price" },
  ]);
  const [values, setValues] = useState({
    quantity: 1,
    note: "",
    discount_method: "",
    discount_value: null,
    rest_year: false,
    offer: null,
    user: null,
    offer_subject_details: null,
  });
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("success");
  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openMessage, setOpenMessageModal] = useState(false);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  useEffect(() => {}, []);
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function (key, index) {
      if (index == 0) {
        setAlertSeverity("error");
        setAlertMessage(errors[key][0]);
        setOpenMessageModal(true);
      }
    });
  };
  const formValidation = (values) => {
    if (!values?.offer) {
      return { success: false, message: "Offer is missing" };
    }
    if (!values?.user) {
      return { success: false, message: "User is missing" };
    }
    if (!values?.offer) {
      return { success: false, message: "Offer is missing" };
    }
    if (!values?.quantity && !values?.rest_year) {
      return { success: false, message: "Choose quantity is missing" };
    }
    if (
      values?.quantity &&
      parseInt(values?.quantity) === 0 &&
      !values?.rest_year
    ) {
      return { success: false, message: "Quantity value must be > 0" };
    }
    if (values?.discount_value && parseInt(values?.discount_value) === 0) {
      return { success: false, message: "Discount value must be > 0" };
    }
  };
  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity("warning");
      setOpenAlert(true);
    } else {
      values.offer = values.offer.id;
      values.user = values.user.id;
      api_post(`buy-for-user`, values)
        .then((result) => {
          if (result?.errors) {
            showErrorMessage(result.errors);
          } else if (result?.code !== 200 && result?.code !== 201) {
            setAlertSeverity("warning");
            setAlertMessage(result?.message);
            setOpenAlert(true);
          } else {
            setAlertSeverity("success");
            setOpenAlert(true);
            setAlertMessage(
              result?.payload
                ? result.payload
                : "Affiliation created successfully"
            );
            setOpen(false);
            setValues({
              amount: 0,
              note: "",
              user: null,
              offer: null,
              quantity: 0,
              discount_method: "",
              discount_value: 0,
              rest_year: false,
            });
            window.location.href = "/finance/affiliations";
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };
  const getOffers = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name"])
      .replace("&search=", ";")
      .replace("&searchJoin=or", "&searchJoin=and");
    const result = await api_get(
      `offers?search=active:1${options}&page=${page.page}`
    );
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  const groupChange = (value) => {
    setValues({
      ...values,
      offer: value,
    });
  };
  const userChange = (value) => {
    setValues({
      ...values,
      user: value,
    });
  };
  const getUsers = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, [
      "name",
      "lastName",
      "id",
      "phone",
      "email",
    ]);
    const result = await api_get(
      `users?search=type.id=1&page=${page.page}${options}`
    );
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  return (
    <div className={classes.root}>
      <SimpleToolbar
        Link={Link}
        pageLink={"/finance/affiliations"}
        title={"Buy For User"}
      />
      <div className={classes.content}>
        <div>
          <form autoComplete="off" noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={8}>
                <Card className={classes.root}>
                  <CardHeader title="Basic information" />
                  <Divider />
                  <CardContent>
                    <AsyncPaginate
                      loadOptions={getUsers}
                      value={values.user}
                      onChange={userChange}
                      getOptionLabel={(option) =>
                        "[" +
                        option.id +
                        "]" +
                        " " +
                        option.name +
                        " " +
                        option.last_name +
                        " " +
                        (option?.phone ? option.phone : "") +
                        " " +
                        (option?.email ? "[" + option?.email + "]" : "")
                      }
                      getOptionValue={(option) => option.id}
                      placeholder="User"
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "53px",
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: "#000",
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector("body")}
                    />
                    <Box mt={2} mb={1}>
                      <AsyncPaginate
                        loadOptions={getOffers}
                        value={values.offer}
                        onChange={groupChange}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        placeholder="Offer"
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            minHeight: "53px",
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: "#000",
                            fontSize: theme.typography.fontSize,
                            fontFamily: theme.typography.fontFamily,
                          }),
                        }}
                        menuPortalTarget={document.querySelector("body")}
                      />
                    </Box>
                  </CardContent>
                </Card>
                <Box mt={3}>
                  <Card>
                    <CardHeader title="Discount information" />
                    <Divider />
                    <CardContent>
                      <Box mt={0} mb={1}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} lg={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              select
                              name="discount_method"
                              id="discount"
                              label="Discount"
                              value={values.discount_method || ""}
                              onChange={handleChange}
                            >
                              {discounts.map((discount) => {
                                return (
                                  <MenuItem
                                    key="discount-value"
                                    value={discount.value}
                                  >
                                    {discount.name}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              type="number"
                              name="discount_value"
                              label="discount value"
                              value={values.discount_value || ""}
                              onChange={handleChange}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
                <Box mt={3}>
                  <Card>
                    <CardHeader title="Note" />
                    <Divider />
                    <CardContent>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Note"
                        name="note"
                        onChange={handleChange}
                        required
                        value={values.note || ""}
                        variant="outlined"
                      />
                    </CardContent>
                  </Card>
                </Box>
                <Box mt={3}>
                  <Card>
                    <CardHeader title="User Discount Codes Information " />
                    <Divider />
                    <DiscountCodeForm
                      user={values?.user?.id}
                      offer={values?.offer?.id}
                      quantity={values?.quantity}
                      allYear={values?.rest_year}
                    />
                  </Card>
                </Box>
                <Box mt={3}>
                  <Card>
                    <CardHeader title="User Gift Codes Information " />
                    <Divider />
                    <GiftCodeForm
                      user={values?.user?.id}
                      offer={values?.offer?.id}
                      quantity={values?.quantity}
                      allYear={values?.rest_year}
                    />
                  </Card>
                </Box>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Box mt={0}>
                  <Card className={classes.root}>
                    <CardHeader title="Quantity" />
                    <Divider className={classes.mtop0} />
                    <CardContent className={classes.mtop0}>
                      <Box mt={0}>
                        <TextField
                          autoFocus
                          variant="outlined"
                          size="small"
                          type="number"
                          name="quantity"
                          label="quantity"
                          value={values.quantity || ""}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Box>
                      <Box m={1} pt={1}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.rest_year}
                              onChange={handleChange}
                              name="rest_year"
                              color="primary"
                              disabled={
                                values?.offer?.type === "pack" ? true : false
                              }
                            />
                          }
                          label="Rest year"
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Grid>
            <Box mt={3}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Save details
              </Button>
            </Box>
          </form>
        </div>
      </div>
      <SnackBar
        open={openAlert}
        message={message}
        severity={severity}
        handleClose={handleCloseAlert}
      />
    </div>
  );
};

export default BuyForUserForm;
