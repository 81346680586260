// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {

  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import {DatePicker, LocalizationProvider} from "@material-ui/pickers";
import momentAdapter from "@material-ui/pickers/adapter/moment";
import Moment from "moment";
import {api_get, api_post, api_put} from "../../../utils/Api";
import LoadingApi from 'components/LoadingApi/LoadingApi';

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },

}));

const Form = (props)  => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    email: '',
    password: '',
    active: '1' ,
    authKey: '',
    refreshToken: '',
    organizerKey: '',
    consumerKey: '',
    consumerSecret: '',
    accountKey: '',
    responseKey: '',
    expiryDate: Moment().format('YYYY-MM-DD hh:mm'),
  });
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) {
      api_get(`webinars/${id}`).then((result) => {
        const data = result.payload;
        setValues({
          email: data.email,
          active: data.active == true ? '1' : '0',
          password: data.password,
          authKey: data.auth_key,
          refreshToken: data.refresh_token,
          organizerKey: data.organizer_key,
          consumerKey: data.consumer_key,
          consumerSecret: data.consumer_secret,
          accountKey: data.account_key,
          responseKey: data.response_key,
          expiryDate: data.expiry_date,
        });
      });
    }
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const dateChange = (value) => {
    if(value){
      setValues({ ...values, expiryDate: value.format("YYYY-MM-DD hh:mm"), });
    }
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };

  const formValidation = (values) => {
    if (!values?.email) {
      return { success: false, 'message': 'Email is missing' };
    }
    if (!values.organizerKey) {
      return { success: false, 'message': 'OrganizerKey is missing' };
    }
  };

  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
      setLoading(true);
      if (edit) {
        setLoading(true)
        api_put(`webinars/${id}`, values).then((result) => {
        setLoading(false); 
          if (result?.errors) {
            showErrorMessage(result.errors);
           }
          else if(result?.code !== 200  && result?.code !== 201){
            setAlertSeverity('warning');
            setAlertMessage(result?.message ? result.message : 'Technical error! Contact the Developers Team');
            setOpen(true);
         
        } else {
            setAlertSeverity('success');
            setAlertMessage('account updated successfully');
            setOpen(true);
            setLoading(false);
            window.location.href = '/contents/webinar-accounts';
          }
        });
      } else {
        api_post('webinars', values)
            .then((result) => {
              if (result?.errors) {
                showErrorMessage(result.errors);
               }
              else if(result?.code !== 200  && result?.code !== 201){
                setAlertSeverity('warning');
                setAlertMessage(result?.message ? result.message : 'Technical error! Contact the Developers Team');
                setOpen(true);
             
            } else {
                setAlertMessage('account created successfully');
                setOpen(true);
                setLoading(false);
                window.location.href = '/contents/webinar-accounts';
              }
            })
            .catch((error) => {
              throw new Error(error);
            });
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }
  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete='off' noValidate>
          <CardHeader
            subheader='WebinarAccounts form'
         
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label='Email'
                  name='email'
                  onChange={handleChange}
                  required
                  value={values.email || ''}
                  variant='outlined'
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label='Password'
                  type="text"
                  name='password'
                  onChange={handleChange}
                  required
                  value={values.password || ''}
                  variant='outlined'
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label='Auth Key'
                  name='authKey'
                  onChange={handleChange}
                  required
                  value={values.authKey || ''}
                  variant='outlined'
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label='Refresh Token'
                  name='refreshToken'
                  onChange={handleChange}
                  required
                  value={values.refreshToken || ''}
                  variant='outlined'
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label='Organizer Key'
                  name='organizerKey'
                  onChange={handleChange}
                  required
                  value={values.organizerKey || ''}
                  variant='outlined'
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label='Consumer Key'
                  name='consumerKey'
                  onChange={handleChange}
                  required
                  value={values.consumerKey || ''}
                  variant='outlined'
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label='Consumer Secret'
                  name='consumerSecret'
                  onChange={handleChange}
                  required
                  value={values.consumerSecret || ''}
                  variant='outlined'
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label='Account Key'
                  name='accountKey'
                  onChange={handleChange}
                  required
                  value={values.accountKey || ''}
                  variant='outlined'
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label='Response Key'
                  name='responseKey'
                  onChange={handleChange}
                  required
                  value={values.responseKey || ''}
                  variant='outlined'
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <LocalizationProvider dateAdapter={momentAdapter}>
                  <DatePicker
                      label="Expiry Date"
                      name="expiryDate"
                      inputFormat="DD/MM/yyyy"
                      value={values.expiryDate}
                      onChange={dateChange}
                      renderInput={(props) => <TextField
                          {...props}
                          fullWidth
                          variant="outlined"
                      />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>Active</FormLabel>
                  <RadioGroup
                    aria-label='Active'
                    name='active'
                    onChange={handleChange}
                    value={values.active || ''}
                    row
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value='1'
                      label='Yes'
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value='0'
                      label='No'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color='primary'
              variant='contained'
              onClick={(event) => handleSubmit()}
            >
              Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
      <LoadingApi open={loading} onClick={handleCloseLoading}/>
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,

};

export default Form;
