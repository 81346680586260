import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import SnackBar from '../../../components/SnackBar';
import {Box, Grid, TextField} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { api_post } from 'utils/Api';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        'width': '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    content: {
        padding: 0,
    },
    paper: {
        minWidth: '500px',
        [theme.breakpoints.down('xs')]: {
            minWidth: 300
        }

},

}));
const CopyCode = (props) => {
    const {className, accounts, open,setOpenModal, ...rest} = props;
    const theme = useTheme();
    const classes = useStyles();
    const [openAlert, setOpenAlert] = React.useState(false);
    const [message, setAlertMessage] = useState('');
    const [severity, setAlertSeverity] = useState('success');
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [values, setValues] =  useState({
        comment : '',
        account: '',
        type: 'transfer',
        references: '',
    });
    const handleClose = () => {
        setOpenAlert(false);
    };
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };
    const handleCloseModal= () => {
        setOpenModal(false);
    };
    const showErrorMessage = (errors) => {
        Object.keys(errors).map(function(key, index) {
            if (index === 0) {
                setAlertSeverity('error');
                setAlertMessage(errors[key][0]);
                setOpenAlert(true);
            }
        });
    };
    const formValidation = (values) => {
        if (!values?.references) {
            return { success: false, 'message': 'Reference is missing' };
        }
    };
    const addCodes=()=>{
        const validation = formValidation(values);
        if (validation) {
            setAlertMessage(validation.message);
            setAlertSeverity('warning');
            setOpenAlert(true);
        }
        else {
            api_post(`codes-copy-many`, values).then((data) => {
                if(data?.errors) {
                    showErrorMessage(data.errors);
                }
                else if(data?.code !== 200 && data?.code !== 201){
                    setAlertSeverity('warning');
                    setAlertMessage(data?.message);
                    setOpenAlert(true);
                } else {
                    setAlertMessage(data?.message? data.message:'Codes added with success')
                    setOpenAlert(true);
                    setAlertSeverity('success')
                    handleCloseModal();
                    window.location.href = '/finance/finance-codes';

                }
            });
        }
    };
    return (

        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="responsive-dialog-title"
                classes={{paper: classes.paper}}

            >
                <DialogTitle id="responsive-dialog-title">Paste Codes</DialogTitle>
                <DialogContent>
                    <Box  m={1} pt={1}>
                        <TextField
                            fullWidth
                            name="references"
                            id="references"
                            multiline
                            rows={2}
                            rowsMax={50}
                            variant="outlined"
                            label="Reference"
                            value={values?.references}
                            onChange={handleChange}
                        >
                        </TextField>
                    </Box>
                    <Box  m={1} pt={1}>
                        <TextField
                            fullWidth
                            select
                            name="account"
                            id="account"
                            variant="outlined"
                            label="account"
                            value={values.account}
                            onChange={handleChange}
                        >
                            {
                                accounts.map((account)=>{
                                    return (
                                        <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>);
                                })
                            }
                        </TextField>
                    </Box>
                    <Box  m={1} pt={1}>
                        <TextField
                            fullWidth
                            select
                            name="type"
                            variant="outlined"
                            label="Type"
                            // defaultValue="payment"
                            value={values.type}
                            onChange={handleChange}
                        >
                            <MenuItem key="payment" value="payment">Versement</MenuItem>
                            <MenuItem key="transfer" value="transfer">Virement</MenuItem>
                            <MenuItem key="cash" value="cash">Espèce</MenuItem>
                            <MenuItem key="check" value="check">Chèques</MenuItem>
                            <MenuItem key="poste" value="poste">Poste</MenuItem>
                        </TextField>
                    </Box>
                    <Box  m={1} pt={1}>
                        <TextField
                            fullWidth
                            name="comment"
                            id="comment"
                            variant="outlined"
                            label="Comment"
                            value={values.comment}
                            onChange={handleChange}
                        >
                        </TextField>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={addCodes} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackBar open={openAlert} handleClose={handleClose} message={message} severity={severity}/>
        </div>
    );
};

CopyCode.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    handleDuplicate: PropTypes.func,
    itemId: PropTypes.number,
};

export default CopyCode;
