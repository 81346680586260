import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '6%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '20%', show: true},
  {accessor: 'reason', label: 'Value', width: '20%', show: true},
  {accessor: 'created_at', label: 'Created at', width: '20%', show: true, type:'date'},
  {accessor: 'updated_at', label: 'Updated at', width: '20%', show: true, type:'date'},
];
const fieldSearchable = [
  'reason',
  //'id',
];
const fieldFilterable = [];
const VideoReportReasonList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`report-reasons`}
          exportUrl={`report-reasons/export`}
          pageLink ={'/settings/video-reports-reasons'}
          searchMessage={'Search (Reason)'}
          baseUrl={`report-reasons`}
          noFilter={true}
      />
  );
};

export default VideoReportReasonList;
