import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Grid, makeStyles} from '@material-ui/core';
import BasicsInfo from './BasicsInfo';
import UsersEventInfo from './UsersEventInfo';

const useStyles = makeStyles(() => ({
  root: {},
}));

const Details = ({
                   contentVideo,
                   className,
                   ...rest
                 }) => {
  const classes = useStyles();
  return (
      <Grid
          className={clsx(classes.root, className)}
          container
          spacing={3}
          {...rest}
      >
        <Grid
            item
            lg={6}
            md={6}
            xl={3}
            xs={12}
        >
          <BasicsInfo contentVideo={contentVideo}/>
        </Grid>
      </Grid>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  contentVideo: PropTypes.object.isRequired,
};

export default Details;
