import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id',label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '20%', show: true},
  {accessor: 'division.name', label: 'Division', width: '20%', show: true},
  {accessor: 'visible', type: 'boolean', color: 'primary', label: 'Visible', width: '10%', show: true},
  {accessor: 'optional', type: 'boolean', color: 'primary', label: 'Optional', width: '10%', show: true},
  {accessor: 'thumbnail', label: 'Thumbnail', width: '15%', show: true},
  {accessor: 'created_at.date', type: 'date', width: '10%', label: 'Created At', show: true},
  {accessor: 'updated_at.date', type: 'date', width: '10%', label: 'Updated At', show: true},
];
const fieldSearchable=[
  'id',
  'name',
];
const fieldFilterable = [
  {name: 'division.id', label: 'Division', type: 'divisionAutocomplete'},
  {name: 'visible', label: 'Visible', type: 'boolean'},
];
const SubjectArchiveList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`subjects/archived`}
          exportUrl={`subjects/archived/export`}
          pageLink ={'/archives/subjects'}
          searchMessage={'Search (ID, Name)'}
          baseUrl={`subjects/archived`}
          removeAdd={true}
          restore={true}
          noShow={true} noDelete={true} noEdit = {true} noDeleteMany={true}
      />
  );
};

export default SubjectArchiveList;
