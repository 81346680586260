import React from 'react';
import TableList from "../../components/List/TableList";


const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '20%', show: true },
  {accessor: 'user', label: 'Teacher', width: '20%', show: true, type: 'instructorSwitch' },
  {accessor: 'importance', type: 'chip', color: 'primary', label: 'Importance', width: '8%', show: true},
  {accessor: 'webinar_account', color: 'primary', label: 'Type', width: '10%', show: true, type: 'webinarType'},
  {accessor: 'status', name: 'status', type: 'chip', color: 'primary', label: 'Status', width: '8%', show: true, sortable: true},
  {accessor: 'due_date', name: 'dueDate', type: 'date', color: 'default', label: 'Deadline', width: '10%', show: true, sortable: true},
  {accessor: 'seenAt', name: 'seenAt', type: 'date', color: 'default', label: 'Seen At', width: '10%', show: true, sortable: true},
];
const fieldSearchable=[
  'id',
  'name',
];
const fieldFilterable = [
  {name: 'createdAt', label: 'Created at', type: 'dateRange'},
  {name: 'importance', label: 'Importance', type: 'dropdown', options: ['default', 'hight', 'very hight']},
  {name: 'status', label: 'Status', type: 'dropdown', options: ['new', 'seen', 'done', 'archived']},
  {name: 'user.id', label: 'Instructor', type: 'instructorAutocomplete'},
  {name: 'webinarAccount.id', label: 'Webinar Account', type: 'webinarAccountAutocomplete'},
  {name: 'dueDate', label: 'Due date', type: 'dateRange'},
  {name: 'appearanceDate', label: 'Appearance date', type: 'dateRange'},
];

const TaskList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`tasks`}
          noExport={true}
          pageLink ={'/contents/tasks'}
          searchMessage={'Search (ID, Name)'}
          baseUrl={`tasks`}
          deleteUrl={`delete/many-tasks`}
          keyValue={"tasks"}
          noShow={true}
      />
  );
};

export default TaskList;
