import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id',label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '20%', show: true},
  {accessor: 'description', label: 'Description', width: '15%', show: false},
  {accessor: 'subject.name', label: 'Subject', width: '10%', show: true},
  {accessor: 'subject.division.name', label: 'Division', width: '20%', show: true,},
  {accessor: 'visible', type: 'boolean', label: 'Visible', width: '10%', show: true,},
  {accessor: 'type', type: 'fieldType', label: 'Type', width: '10%', show: true,},
  {accessor: 'created_at', type: 'date', label: 'Created at at', width: '10%', show: true,},
  {accessor: 'updated_at', type: 'date', label: 'Deleted at', width: '10%', show: true,},
];
const fieldSearchable = [
  'id',
  'name',
];
const fieldFilterable = [
  {name: 'subject.id', label: 'Subject', type: 'subjectAutocomplete'},
  {name: 'visible', label: 'Visible', type: 'boolean'},
  {name: 'createdAt', label: 'Created At', type: 'dateRange'},

];
const ChapterArchiveList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`chapters/archived`}
          exportUrl={`chapters/archived/export`}
          pageLink ={'/archives/chapters'}
          searchMessage={'Search (ID, Name)'}
          baseUrl={`chapters/archived`}
          removeAdd={true}
          restore={true}
          noShow={true} noDelete={true} noEdit = {true} noDeleteMany={true}
      />
  );
};

export default ChapterArchiveList;
