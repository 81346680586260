// @flow
import React, {useEffect, useState, useRef} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
  LinearProgress,
} from '@material-ui/core';
import { api_get } from "utils/Api";


const useStyles = makeStyles((theme) => ({
  root: {},
  details: {
    display: 'flex',
  },
  avatar: {
    height: 110,
    width: 110,
    flexShrink: 0,
    flexGrow: 0,
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  uploadButton: {
    marginRight: theme.spacing(22),
  },
}));

type Props = {
  className: string
}

const AccountProfile = (props: Props): React$Element<any> => {
  const {className, ...rest} = props;

  const classes = useStyles();

  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);



  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <div className={classes.details}>
          <Avatar
              alt='Person'
              className={classes.avatar}
              src={user.avatar}
            />
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        <Button disabled
          className={classes.uploadButton}
          color="primary"
          variant="text"
        >
          Upload picture
        </Button>
        <Button disabled
          variant="text" 
        >
          Remove picture
        </Button>
      </CardActions>
    </Card>
  );
};

export default AccountProfile;
