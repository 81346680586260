import optionsParser from "./optionsParser";
import { api_get } from "../utils/Api";

export const getTeachers = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, [
    "id",
    "name",
    "lastName",
    "email",
    "phone",
  ]);
  const result = await api_get(`instructors?page=${page.page}${options}`);
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};

export const getWebinars = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ['email'])
      .replace( '&search=', ';' )
      .replace( 'searchJoin=or', ';' );
  const result = await api_get(`webinars?page=${page.page}&search=active:1${options}searchJoin=and`);
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};
export const getOffices = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["name"]);
  const result = await api_get(`offices?page=${page.page}${options}`);
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};
export const getAdminGroups = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["name"]);
  const result = await api_get(`admin-groups?page=${page.page}${options}`);
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};
export const getGroups = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["name"])
    .replace("&search=", ";")
    .replace("searchJoin=or", ";");
  const result = await api_get(
    `groups?page=${page.page}&search=active:1${options}searchJoin=and`
  );
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};

export const getUsers = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, [
    "id",
    "name",
    "lastName",
    "email",
    "phone",
  ]);
  const result = await api_get(`users?page=${page.page}${options}`);
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};

export const getParents = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, [
    "id",
    "name",
    "lastName",
    "email",
    "phone",
  ]);
  const result = await api_get(`parent-users?page=${page.page}${options}`);
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};

export const getTraineeships = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["name"]);
  const result = await api_get(
    `traineeships?page=${page.page}&search=${options}`
  );
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};

export const getDivisions = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["name"])
      .replace("&search=", ";")
      .replace("searchJoin=or", ";");
  const result = await api_get(
      `divisions?page=${page.page}&search=isPublic:1${options}searchJoin=and`
  );
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};

export const getSubjects = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ['name_and_division'])
      .replace("&search=", ";")
      .replace( '&searchJoin=or', '' );
  const result = await api_get(`subjects?page=${page.page}&search=visible:1${options}&searchJoin=and&orderBy=id&sortedBy=desc`);
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};

export const getLevels = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["name"]);
  const result = await api_get(`student-levels?page=${page.page}${options}`);

  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};

export const getScopes = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["name"]);
  const result = await api_get(`content-scopes?page=${page.page}${options}`);
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};
export const getContentTypes = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["name"]);
  const result = await api_get(
    `content-types?page=${page.page}${options}&searchJoin=and&orderBy=id&sortedBy=desc`
  );
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};
export const getEvents = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["content.name", "id"]);
  const result = await api_get(`events?page=${page.page}${options}`);

  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};

export const getOffers = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["name"]);
  const result = await api_get(`offers?page=${page.page}${options}`);
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};

export const getDomains = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["name"]);
  const result = await api_get(`domains?page=${page.page}${options}`);
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};
export const getOfferDivisionDetails = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, [
    "id",
    "division.name",
    "offer.name",
  ]);
  const result = await api_get(
    `offer-divisions-details?page=${page.page}${options}`
  );
  return {
    options: [...result.payload],
    /* hasMore: result.meta.current_page !== result.meta.last_page,
         additional: {
           page: result.meta.current_page + 1,
         },*/
  };
};

export const getSegment = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["name"]);
  const result = await api_get(`segments?page=${page.page}${options}`);
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};
export const getCountries = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["name"]);
  const result = await api_get(`countries?page=${page.page}${options}`);
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};

export const getStates = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["name"]);
  const result = await api_get(`states?page=${page.page}${options}`);
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};

export const getCountryStates = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["name"]);
  const result = await api_get(`1/states?page=${page.page}${options}`);
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};

export const getDelegations = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["name"]);
  const result = await api_get(`1/delegations?page=${page.page}${options}`);
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};
export const getPenalties = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["name"]);
  const result = await api_get(`penalties?page=${page.page}${options}`);
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};

export const getPenaltyUnits = async (search, prevData, page) => {
  const options = optionsParser(search, null, null, ["name"]);
  const result = await api_get(`penalty-units?page=${page.page}${options}`);
  return {
    options: [...result.payload],
    hasMore: result.meta.current_page !== result.meta.last_page,
    additional: {
      page: result.meta.current_page + 1,
    },
  };
};

export const convertMonth = (month) => {
  if (month > 6) return month - 7;
  if (month <= 7) return month + 5;
};

export const SearchUrlHandler = (Search, sort=null) => {
  let url = "&search=";
  Search.slice(1)
    .split(",")
    .map((e) => {
      url += `${e};`;
    });
  let formattedUrl = url.replaceAll("*", ",").slice(0, url.length - 1);
  if (sort && Object.keys(sort).length) {
    return formattedUrl+`&searchJoin=and&orderBy=${sort.accessor}&sortedBy=${sort.order}`
  }
  return formattedUrl+`&orderBy=id&sortedBy=desc`;
};

export const UrlHandler = (filterValues, storedValues) => {
  if(Object.keys(filterValues).length !== 0){
    return Object.keys(filterValues).map((param) => {
        let url = "";
        if (Array.isArray(filterValues[param])) {
          let element = "";
          filterValues[param].map((elem) => {
            element = elem?.id
              ? element + "*" + elem?.id
              : element + "*" + elem;
            return element;
          });
          return url + `${param}:${element.slice(1)}`;
        } else {
          return (
            url +
            `${param}:${
              filterValues[param]?.id
                ? filterValues[param]?.id
                : filterValues[param] == true
                ? 1
                : filterValues[param] == false
                ? 0
                : filterValues[param]
            }`
          );
        }
      })
   }else{
       return  Object.keys(storedValues).map((param) => {
        let url = "";
        if (Array.isArray(storedValues[param])) {
          let element = "";
          storedValues[param].map((elem) => {
            element = elem?.id
              ? element + "*" + elem?.id
              : element + "*" + elem;
            return element;
          });
          return url + `${param}:${element.slice(1)}`;
        } else {
          return (
            url +
            `${param}:${
              storedValues[param]?.id
                ? storedValues[param]?.id
                : storedValues[param] == true
                ? 1
                : storedValues[param] == false
                ? 0
                : storedValues[param]
            }`
          );
        }
      })};
};
