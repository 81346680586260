import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import SnackBar from '../../../components/SnackBar';
import {Box, Grid, TextField} from "@material-ui/core";
import {useParams} from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import { api_post } from 'utils/Api';

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0,
    },
    messageValidation : {
        margin: 'auto',
        display: 'flex',
        fontSize: '15px',
        fontWeight: 'bold',
        fontFamily:theme.typography.fontFamily,
        color: '#e94a14',
        padding: '10px',
      }
}));
const Duplicate = (props) => {
    const {className, handleDuplicate, open, setOpen, itemId, initializeData, ...rest} = props;
    const days = [1,2,3,4,5,6,7];
    const classes = useStyles();
    const [message, setAlertMessage]=useState('');
    const [severity, setAlertSeverity]=useState('success');
    const [openMessage, setOpenMessageModal] = useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [validation,setValidation]=useState('');
    const [values, setValues] =  useState({
        eventId : itemId,
        nbDay: '',

    });

    const handleCloseAlert= () => {
        setOpenAlert(false);
      };

    const handleChange = (event) => {
        setValues({
            eventId: itemId,
            nbDay: event.target.value,
        });
    };
    const showErrorMessage=(errors)=>{
        Object.keys(errors).map(function(key, index) {
            if (index==0) {
                setAlertSeverity('error');
                setAlertMessage(errors[key][0]);
                setOpenMessageModal(true);
            }
        });
    };

    const formValidation = (values) => {
        if (!values?.nbDay) {
            return { success: false, 'message': 'Number of days is missing' };
        }
    };
    const duplicateEvent=()=>{
        const validation = formValidation(values);
        if (validation) {
            setValidation(validation.message);
        }else {
        setValidation('')
        api_post(`events/duplicate`, values).then((data)=>{
            if(data?.errors) {
                showErrorMessage(data.errors);
            }
            else if(data?.code !== 200 && data?.code !== 201){
                setAlertSeverity('warning');
                setAlertMessage(data?.message);
                setOpenAlert(true);
                setValidation(data?.message);

            } else {
                setValues(data);
                setAlertSeverity('success');
                setOpenAlert(true);
                setAlertMessage('ContentEvent duplicated successfully');
                handleDuplicate();
                initializeData();
            }
        });
    };
}
    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Dialog
                open={open}
                onClose={handleDuplicate}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Duplicate Event</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure to continue duplicating?
                    </DialogContentText>
                    <TextField
                        autoFocus
                        select
                        required
                        margin="dense"
                        type="number"
                        name="nb_day"
                        label="Duplicated in"
                        value={values.nbDay || ''}
                        onChange={handleChange}
                        fullWidth
                    >
                        {
                            days.map((day)=>{
                                return (
                                    <MenuItem key='nb_day' value={day}>{day}</MenuItem>);
                            })
                        }
                    </TextField>
                    <div>
                        {validation && 
                            <span    className={classes.messageValidation}> 
                            {validation}
                            </span>
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDuplicate} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={duplicateEvent} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackBar open={openAlert} handleClose={handleCloseAlert} message={message} severity={severity}/>
        </div>
    );
};

Duplicate.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    handleDuplicate: PropTypes.func,
    itemId: PropTypes.number,
};

export default Duplicate;
