import React from "react";
import TableList from "../../components/List/TableList";

const columnsList = [
  {
    accessor: "id",
    name: "id",
    label: "Id",
    width: "5%",
    show: true,
    sortable: true,
  },
  {
    accessor: "points",
    name: "points",
    label: "Points",
    width: "10%",
    type: "pointsType",
    color: "primary",
    show: true,
    sortable: true,
  },
  {
    accessor: "from_user",
    label: "From User",
    width: "17%",
    show: true,
    type: "concatString",
  },
  {
    accessor: "from_parent_user",
    label: "From Parent",
    width: "17%",
    show: true,
    type: "concatString",
  },
  {
    accessor: "to_user",
    label: "To User",
    width: "17%",
    show: true,
    type: "concatString",
  },
  {
    accessor: "is_confirmed",
    label: "Confirmed",
    width: "5%",
    show: true,
    type: "boolean",
  },
  {
    accessor: "created_at",
    name: "createdAt",
    type: "dateTime",
    label: "Created At",
    width: "15%",
    show: true,
    sortable: true,
  },
  {
    accessor: "updated_at",
    name: "updatedAt",
    type: "dateTime",
    label: "Updated At",
    width: "15%",
    show: false,
    sortable: true,
  },

  {
    accessor: "note",
    type: "fieldValue",
    name: "note",
    width: "20%",
    label: "Note",
    show: true,
  },
  {
    accessor: "admin.username",
    label: "Admin",
    width: "20%",
    show: true,
    type: "fieldValue",
  },
];
const fieldSearchable = ["id"];
const fieldFilterable = [
  { name: "fromUser.id", label: "From user", type: "usersAutocomplete" },
  {
    name: "fromParentUser.id",
    label: "From ParentUser",
    type: "parentUserAutocomplete",
  },
  { name: "toUser.id", label: "To user", type: "usersAutocomplete" },
  { name: "admin.id", label: "Admin", type: "adminAutocomplete" },
  { name: "points", label: "Points", type: "numberRange" },
];
const PointTransactionList = () => {
  return (
    <TableList
      columnsList={columnsList}
      fieldSearchable={fieldSearchable}
      fieldFilterable={fieldFilterable}
      url={"transfer-points"}
      //exportUrl={`affiliations/export`}
      pageLink={"/users/point-transfers"}
      searchMessage={"Search (ID)"}
      baseUrl={"transfer-points"}
      noShow={true}
      noEdit={true}
      noAction={true}
      noExport={true}
    />
  );
};

export default PointTransactionList;
