import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '3%', show: true},
  {accessor: 'email', label: 'Email', width: '12%', show: true},
  {accessor: 'password', label: 'Password', width: '10%', show: false},
  {accessor: 'organizer_key', label: 'organizer_key', width: '10%', show: true},
  {accessor: 'consumer_key', label: 'consumer_key', width: '10%', show: true, type: 'fieldValue'},
  {accessor: 'consumer_secret', label: 'consumer_secret', width: '8%', show: true, type: 'fieldValue'},
  {accessor: 'account_key', label: 'account_key', width: '10%', show: true, type: 'fieldValue'},
  {accessor: 'expiry_date', type: 'date', label: 'expiry_date', width: '10%', show: true},
  {accessor: 'active', type: 'boolean', label: 'Active', width: '3%', show: true},
  {accessor: 'created_at', type: 'date', label: 'Created At', width: '12%', show: true},
  {accessor: 'updated_at', type: 'date', label: 'Updated At', width: '12%', show: true},
];
const fieldSearchable=[
  'email',
];
const fieldFilterable = [
  {name: 'active', label: 'Active', type: 'boolean'},
  {name: 'createdAt', label: 'Created at', type: 'dateRange'},
];
const WebinarAccountList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`webinars`}
          exportUrl={`webinars/export`}
          pageLink ={'/contents/webinar-accounts'}
          searchMessage={'Search (Email)'}
          baseUrl={`webinars`}
      />
  );
};

export default WebinarAccountList;
