import React from "react";
import TableList from "../../components/List/TableList";

  const columnsList = [
    {accessor: 'id', name: 'id', label: 'Id', width: '7%', show: true, sortable: true, type: 'userSwitch'},
    {accessor: 'last_name', name: 'lastName', label: 'Last name', width: '10%', show: true, sortable: true,},
    {accessor: 'name', name: 'name', label: 'First name', width: '20%', show: true, sortable: true,},
    {accessor: 'phone', label: 'Phone', width: '20%', show: true, type: 'fieldValue'},
    {accessor: 'email', label: 'Email', width: '20%', show: true, type: 'fieldValue'},
    {accessor: 'verified', type: 'boolean', color: 'primary', label: 'Verified', show: true, width: '20%',},
    {accessor: 'completed', type: 'boolean', color: 'primary', label: 'Completed', show: true,},
    {accessor: 'points', name:'points', label: 'Points', width: '30%', show: true, sortable: true, type: 'pointsType'},

  ];
  const fieldSearchable = [
    'id',
    'phone',
    'name',
    'lastName'
  ];
  const fieldFilterable = [
    {name: 'parentUserChildren.user.id', label: 'Child', type: 'userAutocomplete'},
    {name: 'createdAt', label: 'Created At', type: 'dateRange'},
    {name: 'points', label: 'Points', type: 'numberRange'},
    {name: 'state.id', label: 'State', type: 'stateAutocomplete'},
    {name: 'verified', label: 'Verified', type: 'boolean'},
    {name: 'completed', label: 'Completed', type: 'boolean'}, 
  ];
const ParentList = () => {
  return (
    <TableList
      columnsList={columnsList}
      fieldSearchable={fieldSearchable}
      fieldFilterable={fieldFilterable}
      url={`parent-users`}
      exportUrl={`parent-users/export`}
      pageLink={"/users/parent-users"}
      searchMessage={"Search(ID, Name and Phone)"}
      baseUrl={`parent-users`}  
    />
  );
};

export default ParentList;