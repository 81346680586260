import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id',label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'user', label: 'User', show: true ,type:'concatString'},
  {accessor: 'group.name', label: 'Group', show: true},
  {accessor: 'division.name', label: 'Division', show: true},
  {accessor: 'amount_paid', name: 'amountPaid', label: '', show: true, sortable: true},
  {accessor: 'quantity', name: 'quantity',label: 'Quantity', show: true, sortable: true},
  {accessor: 'responsible', label: 'Source', show: true},
  {accessor: 'start_date', name: 'startDate', label: 'Start Date', show: true, sortable: true, type: 'date'},
  {accessor: 'end_date', name: 'endDate', label: 'End Date', show: true, sortable: true, type: 'date'},
  {accessor: 'active', type: 'boolean', color: 'primary', label: 'Active', show: true,},
  {accessor: 'deleted_at', name:'deletedAt', label: 'Deleted At', show: true, sortable: true, type: 'date'},
];

const fieldSearchable = [
  'id',
];
const fieldFilterable = [
  {name: 'user.id', label: 'User', type: 'userAutocomplete'},
  {name: 'group.id', label: 'Group', type: 'userGroupAutocomplete'},
  {name: 'division.id', label: 'Division', type: 'divisionAutocomplete'},
  {name: 'traineeship.id', label: 'Hessaty Traineeships', type: 'traineeshipAutocompleteFilter'},
  {name: 'startDate', label: 'Start date', type: 'dateRange'},
  {name: 'endDate', label: 'End date', type: 'dateRange'},
  {name: 'quantity', label: 'Quantity', type: 'number'},
  {name: 'active', label: 'Active', type: 'boolean'},
];
const AffiliationList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`affiliations/archived`}
          exportUrl={'affiliations/archived/export'}
          pageLink ={'/archives/affiliations'}
          searchMessage={'Search (ID)'}
          baseUrl={`affiliations/archived`}
          removeAdd={true}
          restore={true}
          noShow={true} noDelete={true} noEdit = {true} noDeleteMany={true}
      />
  );
};

export default AffiliationList;
