import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'name', label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '20%', show: true},
  {accessor: 'description', label: 'Description', width: '25%', show: true},
  {accessor: 'start_date', name: 'startDate', label: 'Start date', width: '20%', type: 'date', show: true, sortable: true,},
  {accessor: 'end_date', name: 'endDate', label: 'End date', width: '20%', type: 'date', show: true, sortable: true,},
  {accessor: 'active', name: 'active', label: 'Active', type: 'boolean', color: 'primary', width: '10%', show: true, sortable: true,},
];
const fieldSearchable = [
  'name',
];
const fieldFilterable = [
  {name: 'active', label: 'Active', type: 'boolean'},
  {name: 'startDate', label: 'Start date', type: 'dateRange'},
  {name: 'endDate', label: 'End date', type: 'dateRange'},
];
const UserGroupList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`groups`}
          exportUrl={`groups/export`}
          pageLink ={'/acl/groups'}
          searchMessage={'Search (Name)'}
          deleteUrl={`delete/many-groups`}
          keyValue={"groups"}
      />
  );
};

export default UserGroupList;
