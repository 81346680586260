import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import SnackBar from '../SnackBar';

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0,
    },
}));
const Restore = (props) => {
    const {className, handleRestore, open, itemId,service,pageLink,initializeData, rowsPerPage, ...rest} = props;
    const classes = useStyles();
    const [openAlert, setOpenAlert] = React.useState(false);
    const [message, setAlertMessage] = useState('');
    const [severity, setAlertSeverity] = useState('success');
    const handleClose = () => {
        setOpenAlert(false);
    };
    const showErrorMessage = (errors) => {
        Object.keys(errors).map(function(key, index) {
            if (index == 0) {
                setAlertSeverity('error');
                setAlertMessage(errors[key][0]);
                setOpenAlert(true);
            }
        });
    };
    const showAlertMessage = (message) => {
        setAlertSeverity('error');
        setAlertMessage(message);
        setOpenAlert(true);
    };
    const restoreElement=()=>{
        if(service !== null) {
            service.method(`${service.base_url}/${itemId}`).then((data)=>{
                if(data.errors){
                    showErrorMessage(data.errors)
                }else if(data?.code !== 200 && data?.code !== 201){
                    showAlertMessage(data?.message? data.message:'Technical error! Contact the Developers Team')
                } else {
                    setOpenAlert(true);
                    handleRestore();
                    initializeData(rowsPerPage);
                }
            });
        }
    };
    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Dialog
                open={open}
                onClose={handleRestore}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Restore Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                         Are you sure to continue restoring the deleted element?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRestore} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={restoreElement} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
                <SnackBar
                    open={openAlert}
                    handleClose={handleClose}
                    message={message}
                    severity={severity}
                />
            </Dialog>

        </div>
    );
};

Restore.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    handleRestore: PropTypes.func,
    itemId: PropTypes.number,
};

export default Restore;
