import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import { api_get, api_put } from "../../../utils/Api";
import { AsyncPaginate } from "react-select-async-paginate";
import theme from "../../../theme";
import optionsParser from "../../../helpers/optionsParser";
import Box from "@material-ui/core/Box";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import momentAdapter from "@material-ui/pickers/adapter/moment";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
  buttonPadding: {
    padding: "10px",
  },
}));
type Props = {
  className: string,
};
const Form = (props: Props): React$Element<any> => {
  const { id } = useParams();
  const {
    className,
    edit,
    instructorPrices,
    instructorForumPrices,
    ...rest
  } = props;
  const classes = useStyles();
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertseverity] = useState("");
  const [formState, setFormState] = useState({
    instructorPrices: [
      {
        price: null,
        contentType: null,
        divisions: [],
        startDate: null,
        endDate: null,
      },
    ],
  });
  const [forumFormState, setForumFormState] = useState({
    instructorForumSalary: [
      {
        price: null,
        startDate: null,
        endDate: null,
      },
    ],
  });

  useEffect(() => {
    if (edit) {
      setFormState({
        instructorPrices: instructorPrices.length
          ? instructorPrices
          : [
              {
                divisions: [],
                price: null,
                contentType: null,
                startDate: null,
                endDate: null,
              },
            ],
      });

      setForumFormState({
        instructorForumSalary: instructorForumPrices.length
          ? instructorForumPrices.map((el) => ({
              price: el.price,
              startDate: el.start_date,
              endDate: el.end_date,
            }))
          : [
              {
                price: null,
                startDate: null,
                endDate: null,
              },
            ],
      });
    }
  }, []);

  const handleFieldsChange = (event, index) => {
    const newArr = { ...formState };

    newArr.instructorPrices[index][event.target.name] = event.target.value;
    setFormState({ ...newArr });
  };
  const handleFieldsForumChange = (event, index) => {
    const newArr = { ...forumFormState };

    newArr.instructorForumSalary[index][event.target.name] = event.target.value;

    setForumFormState({ ...newArr });
  };
  const divisionChange = (value, index) => {
    const newArr = { ...formState };
    newArr.instructorPrices[index]["divisions"] = value;
    setFormState({ ...newArr });
  };
  const contentTypeChange = (value, index) => {
    const newArr = { ...formState };
    newArr.instructorPrices[index]["contentType"] = value;
    setFormState({ ...newArr });
  };
  const startDateChange = (value, index) => {
    const newArr = { ...formState };
    newArr.instructorPrices[index]["start_date"] = moment(
      value,
      "YYYY/MM/DD"
    ).format("YYYY-MM-DD");
    setFormState({ ...newArr });
  };

  const startForumDateChange = (value, index) => {
    const newArr = { ...forumFormState };
    newArr.instructorForumSalary[index]["startDate"] = moment(
      value,
      "YYYY/MM/DD"
    ).format("YYYY-MM-DD");
    setForumFormState({ ...newArr });
  };
  const endDateChange = (value, index) => {
    const newArr = { ...formState };
    newArr.instructorPrices[index]["end_date"] = moment(
      value,
      "YYYY/MM/DD"
    ).format("YYYY-MM-DD");
    setFormState({ ...newArr });
  };
  const endDateForumChange = (value, index) => {
    const newArr = { ...forumFormState };
    newArr.instructorForumSalary[index]["endDate"] = moment(
      value,
      "YYYY/MM/DD"
    ).format("YYYY-MM-DD");
    setForumFormState({ ...newArr });
  };
  const AddRemoveSubscription = (operation, index) => {
    if (operation === "plus") {
      setFormState((previousData) => ({
        ...previousData,
        instructorPrices: formState.instructorPrices.concat({
          divisions: [],
          price: null,
          contentType: null,
          startDate: null,
          endDate: null,
        }),
      }));
    } else {
      setFormState((previousData) => ({
        ...previousData,
        instructorPrices: formState.instructorPrices.filter((num, i) => {
          return i !== index;
        }),
      }));
    }
  };

  const AddRemoveForumSubscription = (operation, index) => {
    if (operation === "plus") {
      setForumFormState((previousData) => ({
        ...previousData,
        instructorForumSalary: forumFormState.instructorForumSalary.concat({
          price: null,
          startDate: null,
          endDate: null,
        }),
      }));
    } else {
      setForumFormState((previousData) => ({
        ...previousData,
        instructorForumSalary: forumFormState.instructorForumSalary.filter(
          (num, i) => {
            return i !== index;
          }
        ),
      }));
    }
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index === 0) {
        setAlertseverity("error");
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const showAlertMessage = (message) => {
    setAlertseverity("error");
    setAlertMessage(message);
    setOpen(true);
  };
  const formatInstructorPrices = (values) => {
    let newArr = { ...values };
    newArr.instructorPrices = newArr.instructorPrices.map(
      (contentPrice, index) => ({
        divisions: contentPrice?.divisions?.map((division) => {
          return division.division ? division?.division.id : division.id;
        }),
        contentType: contentPrice?.contentType
          ? contentPrice.contentType.id
          : contentPrice.content_type.id,
        price: contentPrice.price,
        startDate: moment(
          contentPrice?.start_date
            ? contentPrice?.start_date
            : contentPrice?.startDate
        ).format("YYYY-MM-DD"),
        endDate: moment(
          contentPrice?.end_date
            ? contentPrice?.end_date
            : contentPrice?.endDate
        ).format("YYYY-MM-DD"),
      })
    );
    return newArr;
  };

  const formValidation = (values) => {
    return values?.instructorPrices.map((instructorPrice, index) => {
      if (instructorPrice?.divisions?.length === 0) {
        return {
          success: true,
          message: "Division " + [index + 1] + " is missing",
        };
      }
      if (instructorPrice?.contentType === null) {
        return {
          success: true,
          message: "Content Type " + [index + 1] + " is missing",
        };
      }
      if (!instructorPrice?.start_date) {
        return {
          success: true,
          message: "Start Date " + [index + 1] + " is missing",
        };
      }
      if (!instructorPrice?.end_date) {
        return {
          success: true,
          message: "End Date " + [index + 1] + " is missing",
        };
      }
      if (!instructorPrice?.price) {
        return {
          success: true,
          message: "Price " + [index + 1] + " is missing",
        };
      }
      if (instructorPrice?.start_date > instructorPrice?.end_date) {
        return {
          success: true,
          message: "Start or end date " + [index + 1] + "  is invalide",
        };
      }
    });
  };

  const formForumValidation = (values) => {
    const errors = [];
    const dateSet = new Set();
    values.instructorForumSalary.forEach((instructorPrice, index) => {
      const priceErrors = {};

      if (
        !instructorPrice?.startDate ||
        !instructorPrice?.endDate ||
        !instructorPrice?.price
      ) {
        priceErrors.missingFields = true;
        priceErrors.message = `Missing fields for Forum ${index +
          1}: Start Date, End Date, and Price are required.`;
      }

      if (instructorPrice?.startDate >= instructorPrice?.endDate) {
        priceErrors.invalidDateRange = true;
        priceErrors.message = `Invalid date range for Forum ${index +
          1}: Start Date must be earlier than End Date.`;
      }

      const formattedStartDate = moment(instructorPrice.startDate).format(
        "YYYY-MM-DD"
      );
      const formattedEndDate = moment(instructorPrice.endDate).format(
        "YYYY-MM-DD"
      );
      if (dateSet.has(formattedStartDate) || dateSet.has(formattedEndDate)) {
        priceErrors.duplicateDates = true;
        priceErrors.message = `Duplicate date detected for Forum ${index +
          1}. Please ensure all dates are unique.`;
      } else {
        dateSet.add(formattedStartDate);
        dateSet.add(formattedEndDate);
      }

      if (Object.keys(priceErrors).length > 0) {
        errors.push({
          ...priceErrors,
          success: false,
        });
      } else {
        errors.push({
          success: true,
        });
      }
    });

    return errors;
  };

  const handleSubmit = () => {
    const validations = formValidation(formState).filter(
      (validation) => validation?.message && validation.message !== undefined
    );
    const forumValidations = formForumValidation(forumFormState).filter(
      (validation) => validation?.message && validation.message !== undefined
    );
    if (validations?.length > 0) {
      validations.map((validation) => {
        setAlertMessage(validation?.message);
        setAlertseverity("warning");
        setOpen(true);
      });
    } else if (forumValidations?.length > 0) {
      forumValidations.map((validation) => {
        setAlertMessage(validation?.message);
        setAlertseverity("warning");
        setOpen(true);
      });
    } else {
      let values = formatInstructorPrices(formState);
      if (edit) {
        api_put(`instructors/${id}/update-instructor-price`, {
          ...values,
          instructorForumPrices: forumFormState?.instructorForumSalary?.map(
            (el) => ({
              ...el,
              price: parseFloat(el.price),
              startDate: moment(el.startDate).format("YYYY-MM-DD"),
              endDate: moment(el.endDate).format("YYYY-MM-DD"),
            })
          ),
        }).then((data) => {
          if (data?.errors) {
            data.errors.instructorForumPrices.forEach((errorObject) => {
              Object.entries(errorObject).forEach(([index, errors]) => {
                if (Array.isArray(errors)) {
                  errors.map((errorMessage) => {
                    return showErrorMessage(
                      errorMessage?.replace("This", index)
                    );
                  });
                }
              });
            });
          } else if (data?.code !== 200 && data?.code !== 201) {
            showAlertMessage(
              data?.message
                ? data.message
                : "Technical error! Contact the Developers Team"
            );
          } else {
            setAlertseverity("success");
            setAlertMessage("instructor updated successfully");
            setOpen(true);
            window.location.href = "/users/teachers";
          }
        });
      }
    }
  };

  const getDivisions = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name"])
      .replace("&search=", ";")
      .replace("searchJoin=or", ";");
    const result = await api_get(
      `divisions?page=${page.page}&search=isPublic:1${options}searchJoin=and`
    );
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };

  const getContentTypes = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name"]);
    const result = await api_get(`content-types?page=${page.page}${options}`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes?.heading}>Content Salary</Typography>
          </AccordionSummary>
          <CardContent>
            <Grid item md={3} xs={8}>
              <Box m={2} pt={3}>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  style={{ textTransform: "none" }}
                  onClick={() => AddRemoveSubscription("plus")}
                >
                  Add Salary
                </Button>
              </Box>
            </Grid>
            {formState?.instructorPrices?.length > 0 &&
              formState?.instructorPrices?.map((contentPrice, index) => {
                return (
                  <Grid container spacing={3} key={index}>
                    <Grid item md={3} xs={3}>
                      <AsyncPaginate
                        loadOptions={getDivisions}
                        value={
                          contentPrice.divisions
                            ? contentPrice.divisions
                            : contentPrice?.instructor_price_divisions
                        }
                        isMulti
                        onChange={(v) => divisionChange(v, index)}
                        getOptionLabel={(option) =>
                          option.division ? option.division?.name : option?.name
                        }
                        getOptionValue={(option) =>
                          option.division ? option.division?.id : option?.id
                        }
                        placeholder="Division"
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            minHeight: "53px",
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: "#000",
                            fontSize: theme.typography.fontSize,
                            fontFamily: theme.typography.fontFamily,
                          }),
                        }}
                        menuPortalTarget={document.querySelector("body")}
                      />
                    </Grid>
                    <Grid item md={2} xs={2}>
                      <AsyncPaginate
                        loadOptions={getContentTypes}
                        value={
                          contentPrice.contentType
                            ? contentPrice.contentType
                            : contentPrice.content_type
                        }
                        onChange={(v) => contentTypeChange(v, index)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        placeholder="Content Type"
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: "53px",
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: "#000",
                            fontSize: theme.typography.fontSize,
                            fontFamily: theme.typography.fontFamily,
                          }),
                        }}
                        menuPortalTarget={document.querySelector("body")}
                      />
                    </Grid>
                    <Grid item md={2} xs={2}>
                      <LocalizationProvider dateAdapter={momentAdapter}>
                        <DatePicker
                          renderInput={(props) => (
                            <TextField
                              variant="outlined"
                              fullWidth
                              {...props}
                            />
                          )}
                          value={
                            contentPrice.start_date
                              ? contentPrice.start_date
                              : contentPrice.startDate
                          }
                          autoOk
                          ampm={false}
                          inputFormat="DD/MM/yyyy"
                          label="Start date"
                          onChange={(v) => startDateChange(v, index)}
                          name="startDate"
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item md={2} xs={2}>
                      <LocalizationProvider dateAdapter={momentAdapter}>
                        <DatePicker
                          renderInput={(props) => (
                            <TextField
                              variant="outlined"
                              fullWidth
                              {...props}
                            />
                          )}
                          value={
                            contentPrice.end_date
                              ? contentPrice.end_date
                              : contentPrice.endDate
                          }
                          autoOk
                          ampm={false}
                          inputFormat="DD/MM/yyyy"
                          label="End date"
                          onChange={(v) => endDateChange(v, index)}
                          name="endDate"
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item md={1} xs={1}>
                      <TextField
                        fullWidth
                        name="price"
                        variant="outlined"
                        label="Price"
                        value={contentPrice.price}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => handleFieldsChange(e, index)}
                      ></TextField>
                    </Grid>
                    <Grid item md={2} xs={1}>
                      <Box m={1} pt={1}>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={(event) =>
                            AddRemoveSubscription(event, index)
                          }
                        >
                          -
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}
          </CardContent>
        </Accordion>
        <Divider />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes?.heading}>Forum Salary</Typography>
          </AccordionSummary>
          <CardContent>
            <Grid item md={3} xs={8}>
              <Box m={2} pt={3}>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  style={{ textTransform: "none" }}
                  onClick={() => AddRemoveForumSubscription("plus")}
                >
                  Forum Salary
                </Button>
              </Box>
            </Grid>
            {forumFormState?.instructorForumSalary?.length > 0 &&
              forumFormState?.instructorForumSalary?.map(
                (contentPrice, index) => {
                  return (
                    <>
                      <Grid container spacing={3} key={index}>
                        <Grid item md={2} xs={2}>
                          <LocalizationProvider dateAdapter={momentAdapter}>
                            <DatePicker
                              renderInput={(props) => (
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  {...props}
                                />
                              )}
                              value={
                                contentPrice.start_date
                                  ? contentPrice.start_date
                                  : contentPrice.startDate
                              }
                              autoOk
                              ampm={false}
                              inputFormat="DD/MM/yyyy"
                              label="Start date"
                              onChange={(v) => startForumDateChange(v, index)}
                              name="startDate"
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item md={2} xs={2}>
                          <LocalizationProvider dateAdapter={momentAdapter}>
                            <DatePicker
                              renderInput={(props) => (
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  {...props}
                                />
                              )}
                              value={
                                contentPrice.end_date
                                  ? contentPrice.end_date
                                  : contentPrice.endDate
                              }
                              autoOk
                              ampm={false}
                              inputFormat="DD/MM/yyyy"
                              label="End date"
                              onChange={(v) => endDateForumChange(v, index)}
                              name="endDate"
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item md={1} xs={1}>
                          <TextField
                            fullWidth
                            name="price"
                            variant="outlined"
                            label="Price"
                            value={parseFloat(contentPrice.price)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="number"
                            onChange={(e) => handleFieldsForumChange(e, index)}
                          ></TextField>
                        </Grid>
                        <Grid item md={2} xs={1}>
                          <Box m={1} pt={1}>
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={(event) =>
                                AddRemoveForumSubscription(event, index)
                              }
                            >
                              -
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  );
                }
              )}
          </CardContent>
        </Accordion>
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            disabled={!edit}
            onClick={handleSubmit}
          >
            Save details
          </Button>
        </CardActions>
      </Card>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
};

export default Form;
