import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '20%', show: true},
  {accessor: 'name', label: 'Name', show: true},
  {accessor: 'description', label: 'Description',type: 'fieldType', show: true},
];

const fieldSearchable = [
  'id',
];
const fieldFilterable = [
  {name: 'id', label: 'ID', type: 'text'},
  {name: 'name', label: 'Name', type: 'text'},

];
const PenaltyUnitList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`penalty-units`}
          pageLink ={'/teacher-settings/penalty-units'}
          baseUrl={`penalty-units`}
          noShow={true}
          noExport={true}
      />
  );
};

export default PenaltyUnitList;
