import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const BasicsInfo = ({
                      specialPrice,
                      className,
                      ...rest
                    }) => {
  const classes = useStyles();
  return (
      <Card
          className={clsx(classes.root, className)}
          {...rest}
      >
        <CardHeader title="Offer Special Price info"/>
        <Divider/>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                ID
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {specialPrice.id}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Name
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {specialPrice.offer ? specialPrice.offer.name : '****'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Division
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {specialPrice.division ? specialPrice.division.name : '****'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Active
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {specialPrice.active ? 'Yes': 'No'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Visibility Start Date
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {specialPrice.start_date}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Visibility End Date
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {specialPrice.end_date}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Discount Method
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {specialPrice.discount_method}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Value
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {specialPrice.value}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Quantity
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {specialPrice.quantity}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
  );
};

BasicsInfo.propTypes = {
  className: PropTypes.string,
  specialPrice: PropTypes.object.isRequired,
};

export default BasicsInfo;
