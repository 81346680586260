import React from 'react';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const PaymentAgreementInfo = ({
                                paymentAgreement,
                                className,
                                ...rest
                              }) => {
  const classes = useStyles();
  return (
      <Card
          className={clsx(classes.root, className)}
          {...rest}
      >
        <CardHeader title="Payment Agreement info"/>
        <Divider/>
        {Object.keys(paymentAgreement).length ? (
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>
                    ID
                  </TableCell>
                  <TableCell>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                      {paymentAgreement.id}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>
                    Active
                  </TableCell>
                  <TableCell>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                      {paymentAgreement.active}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>
                    Amount
                  </TableCell>
                  <TableCell>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                      {paymentAgreement.amount}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>
                    Start Date
                  </TableCell>
                  <TableCell>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                      {paymentAgreement.start_date}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>
                    End Date
                  </TableCell>
                  <TableCell>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                      {paymentAgreement.end_date}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>
                    Admin
                  </TableCell>
                  <TableCell>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                      {paymentAgreement.admin_user?.name}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>
                    Student
                  </TableCell>
                  <TableCell>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                      {paymentAgreement.user?.email ?? 'NULL'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>
                    Created At
                  </TableCell>
                  <TableCell>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                      {paymentAgreement.created_at}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
        ) : (
            <TableRow >
                <TableCell>
                    User does not have any payment agreement
                </TableCell>
            </TableRow>
        )
        }
      </Card>
  );
};

PaymentAgreementInfo.propTypes = {
  className: PropTypes.string,
  paymentAgreement: PropTypes.object.isRequired,
};

export default PaymentAgreementInfo;
