import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id',  label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '30%', show: true},
  {accessor: 'description', label: 'Description', width: '15%', show: false},
  {accessor: 'traineeship_groups', label: 'Groups', width: '10%', show: true, type: 'groupType'},
  {accessor: 'traineeship_subjects', label: 'Subject', width: '15%', show: true, type: 'concatSubject'},
  {accessor: 'traineeship_levels', label: 'Levels', width: '10%', show: false, type: 'levels'},
  {accessor: 'instructor', label: 'Professor', width: '10%', show: true, type: 'instructorSwitch'},
  {accessor: 'creator_admin.username', label: 'Admin', width: '10%', show: true},
  {accessor: 'totalseances', label: 'total Seances', width: '10%', show: true},
  {accessor: 'vers_pilote', label: 'Vers Pilote', width: '10%', show: true, type: 'boolean'},
  {accessor: 'created_at', label: 'Created At', width: '10%', show: true, type: 'date'},

];
const fieldSearchable = [
  'id',
  'name',
];
const fieldFilterable = [
  {name: 'traineeshipSubjects.subject.id', label: 'Subject', type: 'subjectAutocomplete'},
  {name: 'instructor.id', label: 'Instructor', type: 'instructorAutocomplete'},
  {name: 'createdAt', label: 'Created At', type: 'dateRange'},
  {name: 'versPilote', label: 'Vers Pilote', type: 'boolean'},
  {name: 'traineeshipLevels.level.id', label: 'Levels', type: 'levelAutocompleteFilter'},
  //{name: 'traineeshipGroups.group.id', label: 'Group', type: 'userGroupAutocomplete'},

];
const TraineeshipList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`traineeships`}
          exportUrl={`traineeships/export`}
          pageLink ={'/hessaty/traineeships'}
          searchMessage={'Search (ID, Name)'}
          baseUrl={`traineeships`}
          deleteUrl={`delete/many-traineeships`}
          keyValue={"traineeships"}
      />
  );
};

export default TraineeshipList;
