import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Accordion,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import {Link, useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import moment from 'moment';
import optionsParser from '../../../helpers/optionsParser';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import {api_get, api_post, api_put} from "../../../utils/Api";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListPayments from "./ListPayments";
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import {AsyncPaginate} from "react-select-async-paginate";
import theme from "../../../theme";
import Moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const PaymentAgreementForm = (props) => {
  const {id} = useParams();
  const {className, search, userId, edit, ...rest} = props;
  const classes = useStyles();
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [accounts, getAccounts] = useState([]);
  const [offices, getOffices] = useState([]);
  const [agencies, getAgencies] = useState([]);
  const [open, setOpen] = useState(false);
  const [openCheckModal, setOpenCheckModal] = useState(false);
  const [checkSliceNumber, setCheckSLiceNumber] = useState('');
  const [slicesValues, setSlicesValues] = useState({
    slices: '',
    totalamount: null,
    enddate: '',
    user: null,
  });
  const [values, setValues] = useState({
    user: userId || '',
    offer: '',
    quantity: '',
    active: '',
    amount: 0,
    slices: [],
    user_group: null,
    start_date: Moment().format('YYYY-MM-DD hh:mm'),
    end_date: Moment().format('YYYY-MM-DD hh:mm'),
  });
  const [checks, setChecks] = useState({});
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [userPayments, getPayments] = useState([]);
  const tableCell = [
    'id',
    'Active',
    'Amount',
    //'End date',
    'Created At',
    'Slices',
    'Offer',
    'Division',
  ];
  useEffect(() => {
    api_get(`company-accounts`).then((data) => {
      getAccounts(data.payload);
    });
    api_get(`offices`).then((data) => {
      getOffices(data.payload);
    });
    api_get(`agencies`).then((data) => {
      getAgencies(data.payload);
    });
    getUserPayment()

  }, []);

  const getUserPayment = () => {
    api_get(`user-payment-agreements/${userId}`).then((result) => {
      getPayments(result.payload);
    });
  }
  const getUserGroup = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']);
    const result = await api_get(`offers?page=${page.page}${options}`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleCheckChange = (event, value) => {
    setChecks({
      ...checks,
      [event.target.name]: event.target.value,
    });
  };
  const handleFiledsChange = (event, index) => {
    const newArr = values;
    newArr.slices[index][event.target.name] = event.target.value;
    setValues({...newArr});
  };
  const handleSliceCheckChange = (event, index) => {
    const newArr = {...values};
    newArr.slices[index][event.target.name] = event.target.checked ? '1' : '0';
    setValues({...newArr});
    if (event.target.name == 'check' && event.target.checked) {
      setOpenCheckModal(true);
      setCheckSLiceNumber(index);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const dateChange = (value) => {
    const check_date = moment(value).
        format('YYYY-MM-DD').
        toString();
    if (value) {
      setChecks({...checks, check_date});
    }
  };
  const updateCheck = (event, index) => {
    setCheckSLiceNumber(index);
    setOpenCheckModal(true);
    setChecks(values.slices[index]['check_details']);
  };
  const handleSlicesChange = (event) => {
    setSlicesValues({
      ...slicesValues,
      [event.target.name]: event.target.value,
    });
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const AddSlices = () => {
    api_post(`payment-agreements/decompose-amount-to-slice`, slicesValues).
        then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            values.slices = result;
            values.amount = slicesValues.totalamount;
            setValues({
              ...values,
              ['slices']: Object.values(result) ? Object.values(result) : null,
            });
          }
        }).
        catch((error) => {
          throw new Error(error);
        });
  };
  const handleSubmitCheck = (event, sliceIndex) => {
    api_post(`checks`, checks).then((result)=>{
      if (result.errors) {
        showErrorMessage(result.errors);
      } else {
        setAlertSeverity('success');
        setAlertMessage(result.message);
        setOpen(true);
        const newArr = values;
        newArr.slices[sliceIndex]['check_details'] = checks;
        setValues({...newArr});
        setChecks({});
        setOpenCheckModal(false);
      }
    });
  };
  const offerChange = (value) => {
    setValues({...values, offer: value});
  };
  const handleCheckClose = () => {
    setOpenCheckModal(false);
  };
  const userChange = (event, value) => {
    setValues({...values, ['user_id']: value ? value.id : null});
  };
  const formValidation = (values) => {
    if (!values?.amount) {
      return { success: false, 'message': 'Amount is missing' };
    }
    if (!values?.user) {
      return { success: false, 'message': 'User is missing' };
    }
    if (!values?.offer) {
      return { success: false, 'message': 'Offer is missing' };
    }
    if (!values?.quantity) {
      return { success: false, 'message': 'Quantity is missing' };
    }
  };
  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
      const requestValues = mapStateToRequest(values, ['slices'], ['active']);
        api_post(`payment-agreements`, requestValues).then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            setAlertSeverity('success');
            setAlertMessage('payment agreement created successfully');
            setOpen(true);
            getUserPayment();
            setValues({...values,
              user: userId || '',
              offer: '',
              quantity: '',
              active: '',
              amount: 0,
              slices: [],
              start_date: Moment().format('YYYY-MM-DD hh:mm'),
              end_date: Moment().format('YYYY-MM-DD hh:mm'),
            });
          }
        }).catch((error) => {
          throw new Error(error);
        });
    }
  };

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form
          autoComplete="off"
          noValidate
        >
         <ListPayments userPayments={userPayments} tableCell={tableCell}/>
          <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
              <Typography className={classes?.heading}>Add new Payment's agreement</Typography>
            </AccordionSummary>
         <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Number of Slices"
                  name="slices"
                  onChange={handleSlicesChange}
                  type="number"
                  required
                  value={slicesValues.slices || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Amount"
                  name="totalamount"
                  onChange={handleSlicesChange}
                  type="number"
                  required
                  value={slicesValues.totalamount}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  name="enddate"
                  onChange={handleSlicesChange}
                  type="date"
                  required
                  value={slicesValues.enddate || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item
                md={3}
                xs={12}>
                <Button color="primary" variant="contained"
                  onClick={() => AddSlices()}>
                    ADD SLICES
                </Button>
              </Grid>

              {
                  values.slices ?
                      (
                          values.slices.map((slice, index) => {
                            return (
                              <Grid
                                container
                                spacing={3}
                                key={index}
                              >
                                <Grid
                                  item
                                  md={1}
                                  xs={4}
                                >
                                  <Chip label={`Slice : ${index + 1}`} variant="outlined" color="primary" style={{marginLeft: 20}}>
                                    Slice </Chip>
                                </Grid>
                                <Grid
                                  item
                                  md={2}
                                  xs={4}
                                >
                                  <TextField
                                    fullWidth
                                    name="amounts"
                                    label="Amount"
                                    type="number"
                                    value={slice.amounts ?
                                                slice.amounts :
                                                slice.amount}
                                    onChange={(e) => handleFiledsChange(e,
                                        index)}
                                    variant="outlined"

                                  />
                                </Grid>
                                <Grid item
                                  md={2}
                                  xs={4}>
                                  <TextField
                                    fullWidth
                                    name="start_date"
                                    onChange={(e) => handleFiledsChange(e,
                                        index)}
                                    type="date"
                                    required
                                    value={
                                      moment(slice.start_date).
                                          format('YYYY-MM-DD').
                                          toString()}
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item
                                  md={2}
                                  xs={4}>
                                  <TextField
                                    fullWidth
                                    name="validities"
                                    onChange={(e) => handleFiledsChange(e,
                                        index)}
                                    type="date"
                                    required
                                    value={slice.validities ?
                                                slice.validities :
                                                moment(slice.validity_date).
                                                    format('YYYY-MM-DD').
                                                    toString()}
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={1}
                                  xs={4}
                                >
                                  <FormControl component="fieldset">
                                    <FormLabel component="legend"
                                      hidden={index !=
                                                     0}>Paid</FormLabel>

                                    <Checkbox
                                      defaultChecked
                                      name='paid'
                                      inputProps={{'aria-label': 'secondary checkbox'}}
                                      onChange={(e) => handleSliceCheckChange(
                                          e,
                                          index)}
                                      checked={slice.paid === '1' ?
                                                  true :
                                                  false}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid
                                  item
                                  md={1}
                                  xs={4}
                                >
                                  <FormControl component="fieldset">
                                    <FormLabel component="legend"
                                      hidden={index !=
                                                     0}>Check</FormLabel>
                                    <Checkbox
                                      defaultChecked
                                      name='check'
                                      // color="primary"
                                      inputProps={{'aria-label': 'secondary checkbox'}}
                                      checked={slice.check === '1' ?
                                                  true :
                                                  false}
                                      onChange={(e) => handleSliceCheckChange(
                                          e,
                                          index)}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item
                                  md={1}
                                  xs={4}>
                                  {slice.check === '1' &&
                                        <FormControl component="fieldset">
                                          <FormLabel component="legend">
                                            <IconButton
                                              aria-label="more"
                                              aria-controls="long-menu"
                                              aria-haspopup="true"
                                              onClick={(event) => updateCheck(
                                                  event, index)}
                                            >
                                              <EditIcon hidden={true}
                                              />
                                            </IconButton>
                                          </FormLabel>
                                        </FormControl>
                                  }
                                </Grid>
                              </Grid>
                            );
                          },
                          )
                      ) : (
                          null
                      )
              }

            </Grid>
          </CardContent>
          <Divider/>
          <CardHeader
            subheader="User Informations"
          />
          <Divider/>
          <Divider/>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <AsyncPaginate
                    loadOptions={getUserGroup}
                    value={values.offer}
                    onChange={offerChange}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder="Offer"
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: '53px',
                      }),
                      placeholder: (base) => ({
                        ...base,
                        color: '#000',
                        fontSize: theme.typography.fontSize,
                        fontFamily: theme.typography.fontFamily,
                      }),
                    }}
                    menuPortalTarget={document.querySelector('body')}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Quantity"
                  name="quantity"
                  onChange={handleChange}
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 12, min: 0, step: '1',
                    },
                  }}
                  required
                  value={values.quantity || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Start Date"
                  name="start_date"
                  onChange={handleChange}
                  type="date"
                  required
                  value={moment(values.start_date).
                      format('YYYY-MM-DD').
                      toString() || ''}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="End date"
                  name="end_date"
                  onChange={handleChange}
                  type="date"
                  required
                  value={moment(values.end_date).
                      format('YYYY-MM-DD').
                      toString() || ''}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <FormControl component="fieldset">
                  <FormLabel component="legend">Active</FormLabel>
                  <RadioGroup aria-label="Active" name="active"
                    onChange={handleChange}
                    value={values.active || ''} row>
                    <FormControlLabel control={<Radio/>} value="1"
                      label="Yes"/>
                    <FormControlLabel control={<Radio/>} value="0"
                      label="No"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

          </CardContent>
          <Divider/>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={(event) => handleSubmit(event)}>
                Save details
            </Button>
          </CardActions>
        </Accordion>
        </form>
      </Card>
      <SnackBar open={open} message={message} severity={severity}
        handleClose={handleClose}/>
      <Dialog
        open={openCheckModal}
        onClose={handleCheckClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title">{`Check of slice number :  ${checkSliceNumber +
          1}`}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            select
            name="office_id"
            id="office_id"
            label="Office"
            value={checks.office_id || ''}
            onChange={handleCheckChange}
          >
            {
              offices.map((office) => {
                return (
                  <MenuItem key={office.id} value={office.id}>{office.name}</MenuItem>);
              })
            }
          </TextField>
          <TextField
            autoFocus
            fullWidth
            select
            name="agency_id"
            id="agency_id"
            label="Account"
            value={checks?.agency_id || ''}
            onChange={handleCheckChange}
          >
            {
              accounts?.map((account) => {
                return (
                  <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>);
              })
            }
          </TextField>
          <TextField
            autoFocus
            margin="dense"
            name="num_check"
            id="num_check"
            label="Check Number"
            value={checks.num_check || ''}
            onChange={handleCheckChange}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            name="fullName"
            id="fullname"
            label="FullName"
            value={checks.fullName || ''}
            onChange={handleCheckChange}
            fullWidth
          />
          <TextField
            fullWidth
            label="Phone Number"
            margin="dense"
            name="phone"
            id="phone"
            onChange={handleCheckChange}
            type="number"
            value={checks.phone || ''}
          />
          <TextField
            autoFocus
            margin="dense"
            name="check_date"
            id="check_date"
            label="check date"
            value={checks.check_date ? moment(checks.check_date).
                format('YYYY-MM-DD').
                toString() : null}
            onChange={dateChange}
            fullWidth
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCheckClose} color="primary">
              Close
          </Button>
          <Button
            onClick={(event) => handleSubmitCheck(event, checkSliceNumber)}
            color="primary" autoFocus>
              Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

PaymentAgreementForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
};

export default PaymentAgreementForm;
