import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '6%', sortable: true, show: true},
  {accessor: 'offer.name', width: '27%', label: 'Name', show: true},
  {accessor: 'user_group.name', label: 'Group', width: '27%', show: true},
  {accessor: 'active', type: 'boolean', color: 'primary', label: 'Active', width: '10%', show: true,},
  {accessor: 'start_date', name: 'startDate', type: 'date', label: 'Start Date', width: '15%', show: true, sortable: true,},
  {accessor: 'end_date', name: 'endDate', type: 'date', label: 'End Date', width: '15%', show: true, sortable: true,},
];
const fieldSearchable = ['id',];
const fieldFilterable = [
  {name: 'offer.id', label: 'Offer', type: 'offerAutocomplete'},
  {name: 'userGroup.id', label: 'User group', type: 'userGroupAutocomplete'},
  {name: 'active', label: 'Active', type: 'boolean'},
];
const OfferGroupList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`offer-group-links`}
          noExport={true}
          pageLink ={'/offers/offer-group-links'}
          searchMessage={'Search (ID)'}
          baseUrl={`offer-group-links`}
          deleteUrl={`delete/many-offer-group-links`}
          keyValue={"offerGroupLinks"}
      />
  );
};

export default OfferGroupList;
