import React, {useEffect, useState} from 'react';
import {
  Box,
  Container,
  Divider,
  makeStyles,
} from '@material-ui/core';
import UserInfo from './childrenView/Index';
import {useParams} from 'react-router-dom';
import SimpleToolbar from '../../components/Toolbar/SimpleToolbar';
import {api_get} from "../../utils/Api";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const ChildrenShow = (props) => {
      const {Link , pageLink, title, ...rest} = props;
      const {id} = useParams();
      const classes = useStyles();
      const [parent, setparent] = useState([]);
      const [child, setchild] = useState([]);
      const [parentUserChild, setParentUserChild] = useState([]);
      const [files, setFiles] = useState([]);


      useEffect(() => {
              api_get(`parent-user-children/${id}`).then((result) => {
                setparent(result?.payload?.parentUserChild?.parent_user);
                setchild(result?.payload?.parentUserChild?.user);
                setParentUserChild(result?.payload?.parentUserChild);
                setFiles(result?.payload?.files);
            });
      }, [id]);

      return (
          <div>
            <SimpleToolbar
              Link = {Link}
              pageLink = {'/users/parent-user-children'}
              title = {'Approval Request'} />
              <Container maxWidth={false}>
                <Divider/>
                <Box mt={3}>
                  <UserInfo parent={parent}
                            child={child}
                            parentUserChild={parentUserChild}
                            files={files}
                            />
                </Box>
              </Container>
          </div>
      );
    }
;

export default ChildrenShow;
