// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import {api_get, api_post, api_put} from "../../../utils/Api";

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
}));
type Props = {
  edit: boolean,
  className: string
}
const Form = (props: Props): React$Element<any> => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
  });
  const [message, setAlertMessage]=useState('');
  const [severity, setAlertSeverity]=useState('success');

  useEffect(() => {
    if (edit) {
      api_get(`admin-config/${id}`).then((result)=>{
        const data =result.payload;
        setValues({
          name: data.name,
          value: data.value,
          description: data.description
        });
      });
    }
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const showErrorMessage=(errors)=>{
    Object.keys(errors).map(function(key, index) {
      if (index==0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const formValidation = (values) => {
    if (!values.name) {
      return { success: false, 'message': 'Name is missing' };
    }
    if (!values.value) {
      return { success: false, 'message': 'Value is missing' };
    }
  };
  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else{
    if (edit) {
      api_put(`admin-config/${id}`,values).then((result) => {
        if (result?.errors) {
          showErrorMessage(result.errors);
         }
        else if(result?.code !== 200  && result?.code !== 201){
          setAlertSeverity('warning');
          setAlertMessage(result?.message ? result.message : 'Technical error! Contact the Developers Team');
          setOpen(true);
        } else {
          setAlertSeverity('success');
          setAlertMessage(result?.message ? result.message :'updated successfully');
          setOpen(true);
          window.location.href = '/finance/config';
        }
      });
    } else {
      api_post('admin-config',values).then((result) => {
        if (result.errors) {
          showErrorMessage(result.errors);
        } else {
          setAlertSeverity('success');
          setAlertMessage(result?.message ? result.message :'Config created successfully');
          setOpen(true);
          window.location.href = '/finance/admin-config';
        }
      }).catch((error) => {
        throw new Error(error);
      });
    }
  }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form
          autoComplete="off"
          noValidate
        >
          <CardHeader
            subheader="Create config"
            //title="UserTypes"
          />
          <Divider/>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  required
                  value={values.name || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Value"
                  name="value"
                  onChange={handleChange}
                  required
                  value={values.value || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  onChange={handleChange}
                  required
                  value={values.description || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>

          </CardContent>
          <Divider/>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={(event) => handleSubmit()}>
                Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      <SnackBar open={open} message={message} severity={severity} handleClose={handleClose}/>
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
};

export default Form;
