// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, TextField} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import { api_get, api_post, api_put } from "../../../utils/Api";
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import validate from 'validate.js';
import schemaParser from '../../../helpers/schemaParser';
import FormHelperText from '@material-ui/core/FormHelperText';
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import LoadingApi from 'components/LoadingApi/LoadingApi';


const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
 
}));

let schema = {};
const getSchema = () => {
  schemaParser('divisions').then((data) => {
    schema = data;
  });
};
type Props = {
  edit: boolean,
  className: string
}
const Form = (props: Props): React$Element<any> => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let errors = {};
    if (Object.keys(formState.values).length) {
      errors = validate(formState.values, schema);
    }
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    getSchema();
    if (edit) {
      setLoading(true);
      api_get(`divisions/${id}`).then((result) => {
        setLoading(false);
        const data = result.payload;
        setFormState({ 
          
          ...formState,
          values: {
            name: data.name,
            isPublic: data.is_public == true ? '1' : '0',
            position: data.position,
          },
        });
      });
    }
  }, []);
  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
            event.target.type === 'checkbox' ?
                event.target.checked :
                event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
;
  const handleApiValidation = (errors) => {
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  };
  const handleSubmit = () => {
    const requestValues = mapStateToRequest(formState.values,[],['isPublic']);
    setLoading(true);

    if (edit) {
      api_put(`divisions/${id}`, requestValues).then((result) => {
        if (result.errors) {
          handleApiValidation(result.errors);
        } else {
          setAlertSeverity('success');
          setAlertMessage('division updated');
          setOpen(true);
          setLoading(false);
          window.location.href = '/contents/divisions';
        }
      });
    } else {
      api_post(`divisions`, requestValues).then((result) => {
        if (result.errors) {
          handleApiValidation(result.errors);
        } else {
          setAlertMessage('Division created successfully');
          setOpen(true);
          setLoading(false);
          window.location.href = '/contents/divisions';
        }
      }).catch((error) => {
        throw new Error(error);
      });
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }

  const hasError = (field) =>
      formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form
          autoComplete="off"
          noValidate
        >
          <CardHeader
            subheader="Divisions form"
            
          />
          <Divider/>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={4}
                xs={12}
              >
                <TextField
                  error={hasError('name')}
                  fullWidth
                  helperText={
                      hasError('name') ? formState.errors.name[0] : null
                  }
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  value={formState.values.name || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                  item
                  md={4}
                  xs={12}
              >
                <TextField
                    error={hasError('position')}
                    fullWidth
                    helperText={
                      hasError('position') ? formState.errors.name[0] : null
                    }
                    label="Position"
                    name="position"
                    onChange={handleChange}
                    type="number"
                    value={formState.values.position || ''}
                    variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
                <FormControl component="fieldset">
                  <FormLabel component="legend">Public</FormLabel>
                  <RadioGroup aria-label="Public" name="isPublic"
                    onChange={handleChange}
                    value={formState.values.isPublic || ''}
                    error={hasError('isPublic').toString()}
                    row>
                    <FormControlLabel control={<Radio/>} value="1"
                      label="Yes"/>
                    <FormControlLabel control={<Radio/>} value="0"
                      label="No"/>
                  </RadioGroup>
                  {hasError('isPublic') && <FormHelperText>{formState.errors.isPublic[0]}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>

          </CardContent>
          <Divider/>
          <CardActions>
            <Button
              disabled={!formState.isValid}
              color="primary"
              variant="contained"
              onClick={handleSubmit}>
                            Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      <SnackBar open={open} message={message} severity={severity} handleClose={handleClose}/>
      <LoadingApi open={loading} onClick={handleCloseLoading}/>
    </div>
    
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,

};

export default Form;
