// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import {api_get, api_post, api_put} from "../../../utils/Api";

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
}));

const Form = (props) => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    reason: '',
    name: '',
  });
  const [message, setAlertMessage]=useState('');
  const [severity, setAlertSeverity]=useState('success');

  useEffect(() => {
    if (edit) {
      api_get(`report-reasons/${id}`).then((result)=>{
        const data = result.payload;
        setValues({
          reason: data.reason,
          name: data.name
        });
      });
    }
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const showErrorMessage=(errors)=>{
    Object.keys(errors).map(function(key, index) {
      if (index==0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };

  const formValidation = (values) => {
    if (!values.name) {
      return { success: false, 'message': 'Reason name is missing' };
    }
    if (values?.name.length < 3 || values?.name?.length > 2000) {
      return { success: false, 'message': 'Name must be between 3 and 2000 characters' };
    }
    if(!values.reason ) {
      return { success: false, 'message': 'Reason is missing' };
    }
    if (values?.reason.length < 3 || values?.reason?.length > 2000) {
      return { success: false, 'message': 'Reason must be between 3 and 2000 characters' };
    }
  };
  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
      if (edit) {
        api_put(`report-reasons/${id}`, values).then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            setAlertSeverity('success');
            setAlertMessage(' report reason updated successfully');
            setOpen(true);
            window.location.href = '/settings/video-reports-reasons';
          }
        });
      } else {
        api_post('report-reasons', values).then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            setAlertMessage('report reason updated successfully');
            setOpen(true);
            window.location.href = '/settings/video-reports-reasons';
          }
        }).catch((error) => {
          throw new Error(error);
        });
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form
          autoComplete="off"
          noValidate
        >
          <CardHeader
            subheader="Video Report Reason form"
          />
          <Divider/>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                  item
                  md={6}
                  xs={12}
              >
                <TextField
                    fullWidth
                    label="Name in arabic"
                    name="name"
                    onChange={handleChange}
                    required
                    value={values.name || ''}
                    variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Reason"
                  name="reason"
                  onChange={handleChange}
                  required
                  value={values.reason || ''}
                  variant="outlined"
                />
              </Grid>

            </Grid>

          </CardContent>
          <Divider/>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={(event) => handleSubmit()}>
                Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      <SnackBar open={open} message={message} severity={severity} handleClose={handleClose}/>
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
};

export default Form;
