const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles(() => ({
    dialog: {
      padding: '0px',
      margin: '0px',
    },
    dialogTitle: {
      padding: '0px 20px',
      margin: '0px',
    },
    dialogContent: {
      padding: '0 20px 20px 20px',
      margin: '0px',
    },
    icon: {
      width: '24px',
      height: '24px',
      cursor: 'pointer',
      color: '#2BA7DF',
    },
    dialogContentHeader: {
      marginBottom: '20px',
      justifyContent: 'space-between',
    },
    iconUndo: {
      width: '24px',
      height: '24px',
      cursor: 'pointer',
      color: '#2BA7DF',
      marginRight: '12px',
    },
    dialogTitle:{
        display:'flex',
        justifyContent:'space-between !important',
        alignItems: 'center', 
        marginBottom:'20px'
    },
    label:{
        fontSize:'20px',
        color:'rgb(0, 147, 226)'
    },
    close_btn:{
        color:'rgb(0, 147, 226)',
        cursor:'pointer'
    },
    point_info:{
        padding:'10px',
        fontWeight:'bold',
       
    },
    point_title:{
        fontSize:'18px',
        fontWeight:'bold',
        fontSize:'16px',
        color:'rgb(0, 147, 226)'
    } ,
    importIncomes_title:{
      fontSize:'20px',
      fontWeight:'bold',
      fontSize:'16px',
      color:'#c20000'
  }   ,
  table:{
    minWidth:'800px'
  }
  }));
  export default useStyles;

 export const colors={
    balance: {
    color:'#00b828',
    border: '2px solid #00b828',
  },
  fault: {
    color: '#c20000',
    border: '2px solid #c20000',
  },
  disable: {
    color: '#dddddd',
    border: '2px solid #dddddd',
  }}

  