import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '10%', show: true},
  {accessor: 'name', label: 'Name', show: true},
  {accessor: 'description', label: 'Description', show: true},
  {accessor: 'is_interval', label: 'Interval',type: 'boolean',  show: true},
  {accessor: 'penalty_unit.name', label: 'Penalty Unit',type:'fieldValue', show: true},

];

const fieldSearchable = [
  'id',
];
const fieldFilterable = [
  {name: 'id', label: 'ID', type: 'text'},
  {name: 'name', label: 'Name', type: 'text'},
  {name: 'penaltyUnit.id', label: 'Penalty Unit', type: 'PenaltyUnitAutocomplete'},
  {name: 'isInterval', label: 'Interval', type: 'boolean'},
];
const PenaltyList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`penalties`}
          exportUrl={`penalties/export`}
          pageLink ={'/teacher-settings/penalties'}
          baseUrl={`penalties`}
          deleteUrl={`delete/many-penalties`}
          keyValue={"penalties"}
          noShow={true}
      />
  );
};

export default PenaltyList;
