import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SnackBar from '../../../components/SnackBar';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import {Link} from 'react-router-dom';
import {api_get} from "../../../utils/Api";

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      maxWidth: 360,

    },
  },

  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const ChildrenInfo = ({
  children,
  className,
  ...rest
}) => {
  const classes = useStyles();

  const tableCell = [
    'id',
    'Name',
    'Division',
  ];

  return (
    <div>
      <Grid
        container
        spacing={0}
      >
        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <form
            autoComplete="off"
            noValidate
          >
            <CardHeader title="Children"/>
            <Divider/>
            {children?.length ? (
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {tableCell.map((tableCell) => {
                            return (
                              <TableCell>{tableCell}</TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {children.map((children) => (
                          <TableRow key={children.user.name}>
                            <TableCell component="th" scope="row">
                              {children?.user?.id}
                            </TableCell>
                            <TableCell>{children?.user?.name + ' ' + children?.user?.last_name}</TableCell>
                            <TableCell>{children?.user?.division?.name}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
              ) : (
                  <CardContent>
                    <Typography variant="body2" color="textSecondary"
                      component="p">
                      This user does not have any child
                    </Typography>
                  </CardContent>
              )}
          </form>

        </Card>
      </Grid>

    </div>
  );
};

ChildrenInfo.propTypes = {
  className: PropTypes.string,
  children: PropTypes.object.isRequired,
};

export default ChildrenInfo;
