import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '20%', show: true},
  {accessor: 'division.name', label: 'Division', width: '20%', show: true},
  {accessor: 'visible', name: 'visible', type: 'boolean', color: 'primary', label: 'Visible', width: '10%', show: true, sortable: true},
  {accessor: 'optional', name: 'optional', type: 'boolean', color: 'primary', label: 'Optional', width: '10%', show: true, sortable: true},
  {accessor: 'thumbnail', label: 'Thumbnail', width: '15%', show: true, type: 'thumbnailDisplay'},
  {accessor: 'created_at', type: 'date', width: '10%', label: 'Created At', show: true},
  {accessor: 'updated_at', type: 'date', width: '10%', label: 'Updated At', show: true},
];
const fieldSearchable=[
 'id',
  'name',
];
const fieldFilterable = [
  {name: 'division.id', label: 'Division', type: 'divisionAutocomplete'},
  {name: 'visible', label: 'Visible', type: 'boolean'},
  {name: 'optional', label: 'Optional', type: 'boolean'},

];
const SubjectList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`subjects`}
          noExport={true}
          pageLink ={'/contents/subjects'}
          searchMessage={'Search (ID, Name)'}
          baseUrl={`subjects`}
          deleteUrl={`delete/many-subjects`}
          keyValue={"subjects"}
          noShow={true}
      />
  );
};

export default SubjectList;
