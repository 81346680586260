// @flow
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Label from "../../../components/Label";

const useStyles = makeStyles((theme) => ({
  root: {},
  inner: {
    minWidth: 700,
    [theme.breakpoints.down('xs')]: {
      minWidth: 50,
      overflowX: "initial"
    },
  },

}));
type Props = {
  className: string,
  handlePageChange: Function,
  page: number,
  users: {
    meta: {total: number},
    data : Array<{id: number, user:{id: number, full_name: string, email: string }}>,
  },
}
const UsersEventInfo = (props: Props): React$Element<any> => {
  const classes = useStyles();
  const {className, users} = props;
  return (
      <Card
          className={clsx(classes.root, className)}
      >
        <CardHeader
            title={"Number of participated users : " + users?.length}
        />
        <Divider />
        <PerfectScrollbar>
        <Box className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Group</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users ? (users.map((event) => (
                      <TableRow key={event.id}>
                        <TableCell>
                          <Label color='warning'>
                            {event.user?.id}
                          </Label><br/>
                          {event.user?.name+' '+ event.user?.last_name}
                        </TableCell>
                        <TableCell>
                          <Label color='success'>
                            {event.user?.phone}
                          </Label>
                        </TableCell>
                        <TableCell>
                          {event.user?.email}
                        </TableCell>
                        <TableCell>
                          {event.token?.affiliation?.group?.name}
                        </TableCell>
                      </TableRow>
                  ))
              ) : (
                  <TableRow>
                    <TableCell>
                      He does not have any user
                    </TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
          </PerfectScrollbar>
      </Card>
  );
};

UsersEventInfo.propTypes = {
  className: PropTypes.string,
  users: PropTypes.object.isRequired,
};

export default UsersEventInfo;
