import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Grid, makeStyles} from '@material-ui/core';
import BasicsInfo from './BasicsInfo';
import FilesInfo from './FilesInfo';


const useStyles = makeStyles(() => ({
  root: {},
}));

const Details = ({
  parent,
  child,
  parentUserChild,
  files,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Grid 
      className={clsx(classes.root, className)}
      container
      spacing={3}
      direction="column"
      {...rest}
    >
      <Grid 
        item
        lg={4}
        md={6}
        xl={4}
        xs={6}
      >
        <BasicsInfo parent={parent} child={child} parentUserChild={parentUserChild} />
      </Grid>
      <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={6}
      >
        <FilesInfo files={files}/>
      </Grid>
    </Grid>
  );
};

Details.propTypes = {
  className: PropTypes.string,
};

export default Details;
