// @flow
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import { useParams } from "react-router-dom";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { AsyncPaginate } from "react-select-async-paginate";
import theme from "../../../theme";
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import SnackBar from "../../../components/SnackBar";
import PropTypes from "prop-types";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import momentAdapter from "@material-ui/pickers/adapter/moment";
import { api_get, api_post, api_put } from "../../../utils/Api.js";
import LoadingApi from 'components/LoadingApi/LoadingApi';
import {getCountries, getStates, getDelegations} from "../../../helpers/methods";
import PointsForm from "./PointsForm";
import ChildrenForm from "./ChildrenForm";
import Switch from "@material-ui/core/Switch";
import Collapse from "@material-ui/core/Collapse";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  },
  content: {
    padding: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      minWidth:'10px'
    },
  }, 
}));

const Form = (props) => {
  const { id } = useParams();
  const { className, edit, ...rest } = props;
  const classes = useStyles();
  const [tab, setTab] = useState(1);
  const [switchShowPassword, setSwitchPassword] = useState(false);
  const [values, setValues] = useState({
 //   userName: "",
    name: "",
    lastName: "",
    phone: null,
    email: null,
    plainPassword: "",
    gender: "male",
    birthDate: null,
    verified: false,
    completed: false,
    country: { id: 1, name: "تونس" },
    state: null,
    delegation: null,
  });
  const [message, setAlertMessage]=useState('');
  const [severity, setAlertSeverity]=useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [points, setPoints] = useState(0);


  useEffect(() => {
    if (props.edit && tab === 1) {
      setLoading(true)
      api_get(`parent-users/${id}`).then((result) => {
        setLoading(false)
        const {payload} = result;
        setPoints(payload.points);
        setValues({
     //     userName: payload.username,
          name: payload.name,
          lastName: payload.last_name,
          email: payload.email,
          phone: payload.phone,
          gender: payload.gender,
          birthDate: payload.birth_date,
          verified: payload.verified === true ? "1" : "0",
          completed: payload.completed === true ? "1" : "0",
          country: payload.country,
          delegation: payload.delegation,
          state: payload.state,
        });
      });
    }
  }, [edit, id, tab]);
  const handleChange = (event) => {
    event.preventDefault();
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const birthDateChange = (value) => {
    if (value) {
      setValues({ ...values, birthDate: value.format("YYYY-MM-DD") });
    }
  };
  const stateChange = (value) => {
    setValues({
      ...values,
      state: value,
    });
  };
  const countryChange = (value) => {
    setValues({
      ...values,
      country: value,
    });
  };
  const delegationChange = (value) => {
    setValues({
      ...values,
      delegation: value,
    });
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index==0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const showSucessMessage = (message) => {
    setAlertSeverity("success");
    setAlertMessage(message);
    setOpen(true);
  };
  const ParentUrl = process.env.REACT_APP_FRONT_PARENT_URL;
  const switchParent = () => {
    api_get(`parent-users/login-as/${id}`).then((data) => {
      window.open(`${ParentUrl}/switch?token=${data.payload?.token}&parentId=${data.payload?.parentId}&adminId=${data.payload?.adminId}`);
    });
  };
  const formValidation = (values) => {
    if (!values.name) {
      return { success: false, 'message': 'First name is missing' };
    }
    if (values?.name.length < 3 || values?.name?.length > 2000) {
      return { success: false, 'message': 'First name must be between 3 and 2000 characters' };
    }
    if(!values.lastName ) {
      return { success: false, 'message': 'Last name is missing' };
    }
    if (values?.lastName.length < 3 || values?.lastName?.length > 2000) {
      return { success: false, 'message': 'Last name must be between 3 and 2000 characters' };
    }
    if (!values.phone) {
      return { success: false, 'message': 'Phone is missing' };
    }
    if(values.phone.length != 8 || isNaN(values.phone)){
      return { success: false, 'message': 'Phone number must be a 8 digit' };
    }
    
    if ((!values.plainPassword || values.plainPassword < 8) && !edit) {
      return { success: false, 'message': 'Password is missing' };
    }
  };
  const handleSubmit = () => {
    const requestValues = mapStateToRequest(values,  [], []);
    setLoading(true);
    const validation = formValidation(requestValues);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity("warning");
      setOpen(true);
    } else {
      if (props.edit) {
        api_put(`parent-users/${id}`, requestValues).then((result) => {
          if (result?.errors) {
            showErrorMessage(result.errors);
          } else if (result?.code !== 200 && result?.code !== 201) {
            setAlertSeverity("warning");
            setAlertMessage(
              result?.message
                ? result.message
                : "Technical error! Contact the Developers Team"
            );
            setOpen(true);
          } else {
            setAlertSeverity("success");
            setAlertMessage("user updated successfully");
            setLoading(false);
            setOpen(true);
          }
        });
      } else {
        api_post("parent-users", requestValues)
          .then((result) => {
            if (result?.errors) {
              showErrorMessage(result.errors);
            } else if (result?.code !== 200 && result?.code !== 201) {
              setAlertSeverity("warning");
              setAlertMessage(
                result?.message
                  ? result.message
                  : "Technical error! Contact the Developers Team"
              );
              setOpen(true);
            } else {
              setAlertSeverity("success");
              setAlertMessage("user created successfully");
              setOpen(true);
              setLoading(false);
              window.location.href = '/users/parent-users';
            }
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
  }
  const onFocus = (event) => {
    if(event.target.autocomplete)
    {
      event.target.autocomplete = "";
    }
  }
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  const handleCheckedShowPassword = (e,value) => {
    setSwitchPassword(e.target.checked);
  };
  return (
    <div>
    <Card {...rest} className={clsx(classes.root, className)}>
      <TabContext value={tab}>
        <div className={classes.header}>
          <TabList
             scrollButtons="auto"
            variant="scrollable"
            className={classes.header}
            onChange={handleChangeTab}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Basics" value={1} />
            <Tab label="Children" value={2} />
            <Tab label="Points" value={3} />
          </TabList>
          <div>
       { props.edit &&  <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={switchParent}
            >
              <AccountCircleIcon style={{ display: edit ? "" : "none" }} />
              <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                LOGIN AS USER
              </span>
            </IconButton>}
          </div>
        </div>
        <TabPanel value={1}>
          <form autoComplete="off" noValidate>
            <CardHeader
              subheader="The information can be edited"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid
                  item md={3}  xs={12}
                  inputProps={{
                    form: {
                      autocomplete: "off",
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    autoComplete="none"
                    label="First name"
                    name="name"
                    onChange={handleChange}
                    required
                    value={values.name || ""}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    autoComplete="none"
                    label="Last name"
                    name="lastName"
                    onChange={handleChange}
                    required
                    value={values.lastName || ""}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    label="Email Address"
                    name="email"
                    onChange={handleChange}
                    value={values.email || ""}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    autoComplete="none"
                    label="Phone Number"
                    name="phone"
                    onChange={handleChange}
                    type="number"
                    value={values.phone || ""}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <LocalizationProvider dateAdapter={momentAdapter}>
                    <DatePicker
                      label="Birth Date"
                      name="start_date"
                      value={values.birthDate}
                      onChange={birthDateChange}
                      renderInput={(props) => (
                        <TextField {...props} fullWidth variant="outlined" />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={3} xs={12}>
                  <AsyncPaginate
                    loadOptions={getCountries}
                    value={values.country}
                    onChange={countryChange}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder="Countries"
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: "53px",
                      }),
                      placeholder: (base) => ({
                        ...base,
                        color: "#000",
                        fontSize: theme.typography.fontSize,
                        fontFamily: theme.typography.fontFamily,
                      }),
                    }}
                    menuPortalTarget={document.querySelector("body")}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <AsyncPaginate
                    loadOptions={getStates}
                    value={values.state}
                    onChange={stateChange}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder="States"
                    additional={{
                      page: 1,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: "53px",
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: "#000",
                            fontSize: theme.typography.fontSize,
                            fontFamily: theme.typography.fontFamily,
                          }),
                        }}
                    menuPortalTarget={document.querySelector("body")}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <AsyncPaginate
                    loadOptions={getDelegations}
                    value={values.delegation}
                    onChange={delegationChange}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder="Delegations"
                    additional={{
                      page: 1,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: "53px",
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: "#000",
                            fontSize: theme.typography.fontSize,
                            fontFamily: theme.typography.fontFamily,
                          }),
                        }}
                    menuPortalTarget={document.querySelector("body")}
                  />
                </Grid>
                <Grid item md={2} xs={4}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Gender</FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      name="gender"
                      onChange={handleChange}
                      value={values.gender || ""}
                      row
                    >
                      <FormControlLabel
                        control={<Radio />}
                        value="male"
                        label="Male"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        value="female"
                        label="Female"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={2} xs={4}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Verified</FormLabel>
                    <RadioGroup
                      aria-label="verified"
                      name="verified"
                      onChange={handleChange}
                      value={values.verified || ""}
                      row
                    >
                      <FormControlLabel
                        control={<Radio />}
                        value="1"
                        label="Yes"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        value="0"
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={2} xs={4}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Completed</FormLabel>
                    <RadioGroup
                      aria-label="completed"
                      name="completed"
                      onChange={handleChange}
                      value={values.completed || ""}
                      row
                    >
                      <FormControlLabel
                        control={<Radio />}
                        value="1"
                        label="Yes"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        value="0"
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          <Divider />
            <CardContent>
              <CardHeader
                  subheader="Edit password"
              />
              <Divider />
              <FormControlLabel
                  control={
                    <Switch
                        checked={switchShowPassword}
                        onChange={handleCheckedShowPassword}
                        color="primary"
                    />
                  }
                  label={"Password field edit"}
              />
              <Collapse in={switchShowPassword} timeout="auto" unmountOnExit>
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    <TextField
                        fullWidth
                        inputProps={{
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        label="password"
                        name="plainPassword"
                        autoComplete="none"
                        onChange={handleChange}
                        value={values.plainPassword || ""}
                        variant="outlined"
                        type="password"
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Save details
              </Button>
            </CardActions>
          </form>
        </TabPanel>
        <TabPanel value={2}>
            <ChildrenForm userId={id}/>
        </TabPanel>
        <TabPanel value={3}>
            <PointsForm points={points} userId={id}/>
        </TabPanel>
      </TabContext>
    </Card>
    <SnackBar
      open={open}
      message={message}
      severity={severity}
      handleClose={handleClose}
    />
    <LoadingApi open={loading} onClick={handleCloseLoading}/>
  </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  handleCloseLoading: PropTypes.func,
  loading: PropTypes.bool,
};

export default Form;


