import React from "react";
import TableList from "../../components/List/TableList";
import {useStore} from "../../contexts/JWTAuthContext";

const columnsList = [
  {
    accessor: "id",
    name: "id",
    label: "Id",
    width: "5%",
    show: true,
    sortable: true,
  },
  { accessor: "user", label: "User", show: true, type: "concatString" },
  { accessor: "group.name", label: "Group", show: true },
  { accessor: "division.name", label: "Division", show: true },
  {
    accessor: "amount_paid",
    name: "amountPaid",
    label: "Amount paid",
    show: true,
    sortable: true,
    type: "fieldValue",
  },
  {
    accessor: "quantity",
    name: "quantity",
    label: "Quantity",
    show: true,
    sortable: true,
    type: "fieldValue",
  },
  { accessor: "responsible", label: "Source", show: true, type: "fieldValue" },
  {
    accessor: "start_date",
    name: "startDate",
    label: "Start Date",
    show: true,
    sortable: true,
    type: "dateTime",
  },
  {
    accessor: "end_date",
    name: "endDate",
    label: "End Date",
    show: true,
    sortable: true,
    type: "dateTime",
  },
  {
    accessor: "active",
    type: "boolean",
    color: "primary",
    label: "Active",
    show: true,
  },
  {
    accessor: "note",
    type: "fieldValue",
    name: "note",
    label: "Note",
    show: false,
  },
  {
    accessor: "created_at",
    type: "dateTime",
    name: "created_at",
    label: "Created At",
    sortable: true,
    show: false,
  },
  {
    accessor: "payment_agreements",
    type: "affilliationSlicesType",
    label: "Slices",
    show: false,
  },
];
const fieldSearchable = ["id"];

const AffiliationList = () => {
  const { user } = useStore();

    const fieldFilterable = [
    { name: "user.id", label: "User", type: "userAutocomplete", show: true },
    { name: "group.id", label: "Group", type: "userGroupAutocomplete", show: user.can_display_affiliations },
    { name: "division.id", label: "Division", type: "divisionAutocomplete", show: user.can_display_affiliations },
    {
      name: "traineeship.id",
      label: "Hessaty Traineeships",
      type: "traineeshipAutocompleteFilter",
      show: true
    },
    { name: "startDate", label: "Start date", type: "dateRange", show: user.can_display_affiliations },
    { name: "endDate", label: "End date", type: "dateRange", show: user.can_display_affiliations },
    { name: "quantity", label: "Quantity", type: "number", show: true },
    { name: "active", label: "Active", type: "boolean", show: user.can_display_affiliations },
    { name: "freeOffer", label: "free offer", type: "boolean", show: true },
  ];
  const customFieldFilterable = fieldFilterable.filter(field => field.show === true);
  return (
    <TableList
      columnsList={columnsList}
      fieldSearchable={fieldSearchable}
      fieldFilterable={customFieldFilterable}
      url={`affiliations`}
      exportUrl={`affiliations/export`}
      pageLink={"/finance/affiliations"}
      searchMessage={"Search (ID)"}
      baseUrl={`affiliations`}
      deleteUrl={`delete/many-affiliations`}
      keyValue={"affiliations"}
    />
  );
};

export default AffiliationList;
