import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "services/api";

const initialState = {
  data: [],
  status: "idle",
};
export const fetchPubCards = createAsyncThunk(
  "pubCards/fetchPubCards",
  async () => {
    let data;
    try {
      const response = await api.get(`/admin/pubs-cards`);

      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const slice = createSlice({
  name: "pubCards",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPubCards.pending]: (state) => {
      state.status = "loading";
    },
    [fetchPubCards.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    },
    [fetchPubCards.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export const reducer = slice.reducer;

export default slice;
