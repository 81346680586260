export const aclPages = (pages, roles, groups) => {
  let groupSupAdmin = false;
  groups.map((group) => {
    if (isSupAdmin(group.roles)) {
      groupSupAdmin = true;
    }
  });

  if (groupSupAdmin || isSupAdmin(roles)) {
    return pages;
  }
  let result = [];

  pages.forEach(function(page) {
    let temp = [],
      object = { title: page.title, icon: page.icon },
      found = false;

    if (Array.isArray(page.children)) {
      temp = page.children.filter((childrenPage) =>
        checkPermissionPage(formatTilePage(childrenPage.title), roles, groups)
      );
      if (temp.length) {
        object.children = temp;
        found = true;
      }
    }
    if (found) {
      result.push(object);
    }
  });
  return result;
};

const checkPermissionPage = (roleName, roles, groups) => {
  let exist = false;
  if (roleName === "*") {
    return true;
  }
  if (
    roleName.includes("CRM_VIEW") &&
    (isSupAdmin(roles) ||
      checkAdminName(groups, "confirmed_developer") ||
      checkAdminName(groups, "customer_service_agent") ||
      checkAdminName(groups, "quality_control_agent"))
  ) {
    return true;
  }
  if (
    roleName.includes("ANALYTICS_VIEW") &&
    (isSupAdmin(roles) ||
      checkAdminName(groups, "confirmed_developer") ||
      checkAdminName(groups, "customer_service_agent") ||
      checkAdminName(groups, "responsable_content_finance"))
  ) {
    return true;
  }
  if (
    roleName.includes("STATISTICS_VIEW") &&
    (isSupAdmin(roles) ||
      checkAdminName(groups, "confirmed_developer") ||
      checkAdminName(groups, "responsable_content_finance"))
  ) {
    return true;
  }
  if (
      roleName.includes("POPUPS_VIEW")

  ) {
    return true;
  }
  if (
    roleName.includes("ADMINCODES_VIEW") &&
    (isSupAdmin(roles) ||
      checkAdminName(groups, "confirmed_developer") ||
      checkAdminName(groups, "responsable_finance"))
  ) {
    return true;
  }

  if (
    roleName.includes("ARCHIVES_VIEW") &&
    (isSupAdmin(roles) || checkAdminName(groups, "confirmed_developer"))
  ) {
    return true;
  }
  /*if (roleName.includes('SwitchLog_VIEW') && (isSupAdmin(roles) || checkAdminName(groups, 'confirmed_developer') || checkAdminName(groups, 'developer'))) {
    return true;
  }*/
  roles.map((role) => {
    if (checkRoleName(role, roleName)) {
      exist = true;
    }
  });
  groups.map((group) => {
    group.roles.map((role) => {
      if (checkRoleName(role, roleName)) {
        exist = true;
        return;
      }
    });
  });
  return exist;
};

const checkRoleName = (role, roleName) => {
  return roleName === formatAdminRoleName(role, true);
};

export const formatAdminRoleName = (roleName, withAction = false) => {
  roleName = roleName.replace("ROLE_", "");
  if (withAction) {
    return roleName;
  }
  return roleName.slice(0, roleName.indexOf("_"));
};

const formatTilePage = (title) => {
  //if (['Business', 'Students', 'Teachers'].includes(title)) {

  if (["Students", "Teachers"].includes(title)) {
    title = "users";
  }
  if (["Buy For Student"].includes(title)) {
    title = "affiliations";
  }
  if (["Videos", "Scopes", "Exams"].includes(title)) {
    title = "content" + title;
  }
  if (["Magazines"].includes(title)) {
    title = "contents";
  }
  if (["Events", "Hessaty Events"].includes(title)) {
    title = "Content Events";
  }
  if (["Hessaty Chapters"].includes(title)) {
    title = "traineeships";
  }
  if (["User Tokens"].includes(title)) {
    title = "access user event token";
  }
  if (["Segmentation"].includes(title)) {
    title = "segments";
  }
  if (["User Levels"].includes(title)) {
    title = "student level user";
  }
  if (["Autogenerated Codes"].includes(title)) {
    title = "codes";
  }
  if (["Codes"].includes(title)) {
    title = "admin codes";
  }
  if (["Qcm"].includes(title)) {
    title = "contents";
  }
  if (
    [
      "Deleted Chapters",
      "Deleted Subjects",
      "Deleted Contents",
      "Deleted Users",
      "Deleted Codes",
      "Deleted Affiliations",
      "Deleted Payments",
    ].includes(title)
  ) {
    title = "archives";
  }

  if (["Audit", "Logs", "Switch Logs"].includes(title)) {
    title = "switch Log";
  }
  if (["Payment Slices"].includes(title)) {
    title = "Payment Agreement Slices";
  }
  if (["Admin Roles"].includes(title)) {
    title = "Admin Groups";
  }

  if (["Ooredoo Orders"].includes(title)) {
    title = "Digital Virgo Orders";
  }

  if (["Parents"].includes(title)) {
    title = "Parent Users";
  }
  if (["Approval Requests"].includes(title)) {
    title = "Parent User Children";
  }
  if (["Report  Reason"].includes(title)) {
    title = "video reports reasons";
  }
  if (["Affiliations Evolution"].includes(title)) {
    title = "affiliations";
  }
  //return title.replace(/\s/g, '').replace('-', '').toUpperCase() + '_LIST';
  return (
    title
      .replace(/\s/g, "")
      .replace("-", "")
      .toUpperCase() + "_VIEW"
  );
};

export const isSupAdmin = (roles) => {
  return roles?.includes("ROLE_SUPER_ADMIN");
};

export const checkAdminName = (groups, name) => {
  let isAdmin = false;
  groups.map((group) => {
    if (group?.name === name) {
      isAdmin = true;
    }
  });
  return isAdmin;
};
