// @flow
import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
    Box,
    Card,
    CardHeader,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    makeStyles,
} from '@material-ui/core';
import Label from '../../../components/Label';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
    root: {
    },
}));
type Props = {
    histories: [
        {
            id: null,
            code_id: null,
            user_id: null,
            amount: 0,
            comment:  '',
            reference: '',
            status:  'pending' | 'ignored' | 'approuved' | 'active',
            created_at: null,
        },
    ],
    className: string,
}
const HistoriesCodeInfo = (props: Props): React$Element<any> => {
    const {className, histories} = props;
    const classes = useStyles();
    return (
        <Card
            className={clsx(classes.root, className)}
        >

            <CardHeader
                title="Code Histories"
            />
            <Divider />
            <Box >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>History</TableCell>
                            <TableCell>Code ID</TableCell>
                            <TableCell>User ID</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Comment</TableCell>
                            <TableCell>Reference</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>created At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(histories && histories.length >0 )? (histories.map((history, index) => (
                                <TableRow key={history.id}>
                                    <TableCell>
                                        {index}
                                    </TableCell>
                                    <TableCell>
                                        {history.code_id}
                                    </TableCell>
                                    <TableCell>
                                        {history.user_id}
                                    </TableCell>
                                    <TableCell>
                                        {history.amount}
                                    </TableCell>
                                    <TableCell>
                                        {history.comment}
                                    </TableCell>
                                    <TableCell>
                                        {history.reference}
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                        >
                                            <Label color={history.status === 'approuved' ?
                                                'success' :
                                                history.status === 'ignored' ? 'error' : 'secondary'}>
                                                {history.status}
                                            </Label>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {moment(history.created_at).format('DD/MM/YYYY')}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) :(
                            <TableRow >
                                <TableCell>
                                    Code does not have any history
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
        </Card>
    );
};

HistoriesCodeInfo.propTypes = {
    className: PropTypes.string,
    histories: PropTypes.object.isRequired,
};

export default HistoriesCodeInfo;
