import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import SnackBar from "../SnackBar";
import { api_get, api_post } from "../../utils/Api";
import optionsParser from "helpers/optionsParser";
import { AsyncPaginate } from "react-select-async-paginate";
import mapStateToRequest from "../../helpers/mapStateToRequest";
import theme from "../../theme";
import { Box, Checkbox, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: "red",
    },
  },
  content: {
    padding: 0,
  },
}));
const Copy = (props) => {
  const {
    className,
    handleCopy,
    open,
    itemId,
    service,
    pageLink,
    initializeData,
    rowsPerPage,
    itemName,
    item,
     setOpen,
    ...rest
  } = props;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const classes = useStyles();
  const [tab, setTab] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [openSnack, setOpenSnack] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [division, setDivision] = useState([]);
  const [values, setValues] = useState({
    oldChapterId:item?.content?.chapter_contents[0]?.chapter?.id ,
    contentId:item?.content?.id ,
    chapterIds: [],
  });



  const handleClose = () => {
    setOpenAlert(false);
  };

  const subjectChange = (options) => {
    setSubjects(options);
  };

  const divisionChange = (value) => {
    setDivision(value);
  };

  const selectedChaptersChange = (e, options) => {
    setValues({
      ...values,
      chapterIds: options,
    });
  };
  const chapterChange = (options) => {
    setValues({
      ...values,
      chapterIds: [...values?.chapterIds, ...[options.pop()]],
    });
  };
 
  const getChapters = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']).replace( '&search=', ';' );;
    const result = await api_get(`chapters?page=${page.page}&search=subject.id:${subjects.id}${options}&searchJoin=and`);
  
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  const getDivisions = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name"])
        .replace("&search=", ";")
        .replace("searchJoin=or", ";");
    const result = await api_get(
        `divisions?page=${page.page}&search=isPublic:1${options}searchJoin=and`
    );
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };

  const getSubjects = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']).replace( '&search=', ';' );
    const result = await api_get(`subjects?page=${page.page}&search=division.id:${division.id}${options}&searchJoin=and`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };

  const chapterFormat = (values) => {
    let newArr = [];
    values.chapterIds.map((option) => {
      newArr.push(option.group ? option.group.id.toString() : option.id.toString());
    });
    values.chapterIds = newArr;
    return values;
  };


  const showAlertMessage = (message) => {
    setAlertSeverity('error');
    setAlertMessage(message);
    setOpenSnack(true);
  };

  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpenSnack(true);
      }
    });
  };

  const handleCopyChapter =()=>{

    const valuesState = chapterFormat(values);
    if (valuesState){
    api_post(`contents/copy`, valuesState).then((result) => {
     if(result.errors){
     showErrorMessage(result.errors)
   }else if(result?.code !== 200 && result?.code !== 201){
     showAlertMessage(result?.message? result.message:'Technical error! Contact the Developers Team')
   } else {
     setAlertSeverity('success');
     setAlertMessage('content copied successfully');
     setOpenSnack(true);
     //window.location.href = '/contents/chapters';
     setTimeout(() => {
    setOpen(false)
     }, 1500); 
   }
 })}}


  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        open={open}
        onClose={handleCopy}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="responsive-dialog-title">
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>
            Content copy :
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ marginBottom: "20px" }}>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                Video title :
              </span>
              <span style={{ color: "orange", fontWeight: "bold" }}>
                {itemName}.
              </span>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Chapter ID :
              </span>
              <span style={{ color: "green", fontWeight: "bold" }}>
                {item?.content?.chapter_contents[0]?.chapter?.id}
              </span>
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Select chapter to copy into :
            </div>
            <Box mt={0}>
              <AsyncPaginate
                loadOptions={getDivisions}
                onChange={divisionChange}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={division}
                placeholder="Division"
                additional={{
                  page: 1,
                  tab,
                }}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  control: (base) => ({
                    ...base,
                    minHeight: "53px",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#000",
                    fontSize: theme.typography.fontSize,
                    fontFamily: theme.typography.fontFamily,
                  }),
                }}
                menuPortalTarget={document.querySelector("body")}
              />
            </Box>
            <Box mt={2}>
              <AsyncPaginate
                loadOptions={getSubjects}
                onChange={subjectChange}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={subjects}
                placeholder="Subject"
                noOptionsMessage={() => "You may choose a division before"}
                cacheUniqs={[division]}
                additional={{
                  page: 1,
                }}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  control: (base) => ({
                    ...base,
                    minHeight: "53px",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#000",
                    fontSize: theme.typography.fontSize,
                    fontFamily: theme.typography.fontFamily,
                  }),
                }}
                menuPortalTarget={document.querySelector("body")}
              />
            </Box>
            <Box mt={2}>
              <AsyncPaginate
                loadOptions={getChapters}
                isMulti
                onChange={chapterChange}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={0}
                cacheUniqs={[subjects]}
                placeholder="Chapters"
                noOptionsMessage={() => "You may choose a subject before"}
                additional={{
                  page: 1,
                }}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  control: (base) => ({
                    ...base,
                    minHeight: "53px",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#000",
                    fontSize: theme.typography.fontSize,
                    fontFamily: theme.typography.fontFamily,
                  }),
                }}
                menuPortalTarget={document.querySelector("body")}
              />
            </Box>
            <Box mt={2}>
              <Autocomplete
                id="combo-box-demo"
                multiple
                name="chapterContents"
                disableCloseOnSelect
                freeSolo
                open={false}
                onChange={selectedChaptersChange}
                options={values?.chapterIds || ""}
                value={values?.chapterIds}
                getOptionLabel={(option) =>
                  (option.subject
                    ? option?.subject?.division?.name +
                      "|" +
                      option?.subject?.name +
                      "|" +
                      option.name
                    : option?.chapter?.subject?.division?.name +
                      "|" +
                      option?.chapter?.subject?.name +
                      "|" +
                      option?.chapter?.name) || ""
                }
                getOptionSelected={(option, value) => value.id === option.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selected chapters"
                    variant="outlined"
                    fullWidth
                  />
                )}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCopy} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCopyChapter} color="primary" autoFocus>
            copy
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBar
        open={openAlert}
        handleClose={handleClose}
        message="Element successfully copied"
        severity={"success"}
      />
    </div>
  );
};

Copy.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  handleCopy: PropTypes.func,
  itemId: PropTypes.number,
};

export default Copy;
