// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid, Paper,
  TextField,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import optionsParser from '../../../helpers/optionsParser';
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import mapStateToRequest from '../../../helpers/mapStateToRequest';
import {DatePicker, LocalizationProvider} from "@material-ui/pickers";
import momentAdapter from "@material-ui/pickers/adapter/moment";
import Moment from "moment";
import {api_get, api_post, api_put} from "../../../utils/Api";
import QuillEditor from "../../../components/QuillEditor";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    overflow: "visible",
  },
  mtop: {
    marginTop: 24,
  },
  mtop0: {
    marginTop: 0,
  },

  content: {
    padding: 0,
  },
}));
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
const importanceOpts = ['default', 'hight', 'very hight'];

const Form = (props) => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [editDate, setDate]  = useState('0');

  const [values, setValues] = useState({
    reason: '',
    user: null,
    bannedUntil: Moment().format('YYYY-MM-DD hh:mm'),

  });
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');

  const getUsers = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['id', 'name', 'lastName','email', 'phone']);
    const result = await api_get(`users?page=${page.page}${options}&orderBy=id&sortedBy=desc`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  const handleCheckChange = (event) => {
    setDate(event.target.checked);
  };
  const handleDescriptionChange = (value) => {
    values.reason = value;
  };

  useEffect(() => {
    if (edit) {
      api_get(`banned-user/${id}`).then((result) => {
        const data = result.payload;
        setValues({
          reason: data.reason,
          bannedUntil: data.banned_until,
          user: data.user,
        });
      });
    }
  }, []);
  const userChange = (value) => {
    setValues({...values, user: value});
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const bannedDateChange = (value) => {
    if(value){
      setValues({ ...values, bannedUntil: value.format("YYYY-MM-DD hh:mm"), });
    }
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };

  const formValidation = (values) => {
    if (!values?.user) {
      return { success: false, 'message': 'User is missing' };
    }
    if (!values?.reason) {
      return { success: false, 'message': 'Reason is missing' };
    }
  };

  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
      const requestValues = mapStateToRequest(values);
      requestValues.bannedUntil = editDate == true ? requestValues.bannedUntil : null;
      if (edit) {
        api_put(`banned-user/${id}`, requestValues).then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            setAlertSeverity('success');
            setAlertMessage('Banned user updated successfully');
            setOpen(true);
            window.location.href = '/user-settings/banned-users';
          }
        });
      } else {
        api_post('banned-user', requestValues)
            .then((result) => {
              if (result.errors) {
                showErrorMessage(result.errors);
              } else {
                setAlertSeverity('success');
                setAlertMessage('User banned successfully');
                setOpen(true);
                window.location.href = '/user-settings/banned-users';
              }
            })
            .catch((error) => {
              throw new Error(error);
            });
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (

      <div {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Card className={classes.root}>
                <CardContent>
                  <AsyncPaginate
                      loadOptions={getUsers}
                      value={values.user}
                      onChange={userChange}
                      getOptionLabel={(option) => option.name+' '+option.last_name}
                      getOptionValue={(option) => option.id}
                      placeholder="Users"
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                  />
                  <Box mt={2} mb={1}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Banned Reason
                    </Typography>
                  </Box>
                  <Paper variant="outlined">
                    <QuillEditor
                        className={classes.editor}
                        value={values.reason || ""}
                        modules={modules}
                        formats={formats}
                        onChange={(value) => handleDescriptionChange(value)}
                    />
                  </Paper>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box mt={0}>
                <Card className={classes.root}>
                  <CardHeader title="Banned Until" />
                  <Divider className={classes.mtop0} />
                  <CardContent className={classes.mtop0}>
                    <Box mt={0}>
                      <FormControlLabel
                          control={
                            <Checkbox
                                checked={values.visible}
                                onChange={handleCheckChange}
                                value={editDate}
                                name="editDate"
                                size="small"
                            />
                          }
                          label="edit Date ?"
                      />
                    </Box>
                    <Box mt={2} hidden={editDate == '0'}>
                      <LocalizationProvider dateAdapter={momentAdapter}>
                        <DatePicker
                            label="banned until"
                            name="bannedUntil"
                            inputVariant="outlined"
                            value={values.bannedUntil}
                            onChange={bannedDateChange}
                            renderInput={(props) => <TextField
                                {...props}
                                fullWidth
                                variant="outlined"
                                size="small"
                            />}
                        />
                      </LocalizationProvider>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Box mt={3}>
            <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
            >
              Save details
            </Button>
          </Box>
        </form>
        <SnackBar
            open={open}
            message={message}
            severity={severity}
            handleClose={handleClose}
        />
      </div>


  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
};

export default Form;
