import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', width: '5%', label: 'Id', show: true, sortable: true},
  {accessor: 'content.name', width: '20%', label: 'Name', show: true},
  {accessor: 'content.type.name', width: '8%', label: 'Type', show: true},
  {accessor: 'content.active', width: '5%', label: 'Active', type: 'boolean', color: 'primary', show: true,},
  {accessor: 'content.is_draft', width: '5%', label: 'Draft', type: 'boolean', color: 'primary', show: true,},
  {accessor: 'content.scope.name', width: '15%', label: 'Scope', show: true},
  {accessor: 'duration', width: '5%', label: 'Duration', show: true, type: 'fieldValue'},
  {accessor: 'content.instructor', width: '12%', label: 'Teacher', type: 'instructorSwitch', show: true,},
  {accessor: 'content.creator_admin.username', width: '12%', label: 'Author Admin', show: true, type: 'fieldValue',},
  {accessor: 'created_at', name: 'createdAt', width: '12%', label: 'Created at ', show: true, sortable: true, type : 'date',},
  {accessor: 'updated_at', name: 'updatedAt', width: '12%', label: 'Updated At', show: true, sortable: true, type : 'date',},
  {accessor: 'content.chapter_contents', label: 'Subject', width: '5%', show: false, type: 'subjectType'},
  
];
const fieldSearchable = [
  'id',
  'content.name',
];
const fieldFilterable = [
  {name: 'content.id', label: 'Content ID', type: 'text'},
  {name: 'content.type.id', label: 'Type', type: 'contentTypeAutocomplete'},
  {name: 'content.scope.id', label: 'Scope', type: 'scopeAutocomplete'},
  {name: 'content.instructor.id', label: 'Instructor', type: 'instructorAutocomplete'},
  {name: 'content.chapterContents.chapter.subject.division.id', label: 'Division', type: 'divisionAutocomplete',},
  {name: 'content.chapterContents.chapter.subject.id', label: 'Subject', type: 'subjectAutocomplete',},
  {name: 'content.chapterContents.chapter.id', label: 'Chapter', type: 'chapterAutocomplete'},
  {name: 'event.traineeship.id', label:'Traineeship', type: 'traineeshipAutocompleteFilter'},
  {name: 'createdAt', label: 'Created At', type: 'dateRange'},
  {name: 'content.active', label: 'Active', type: 'boolean'},
  {name: 'content.isDraft', label: 'Draft', type: 'boolean'},

];
const ContentVideoList = () => {

  return (
  
    
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`content-videos`}
          exportUrl={`content-videos/export`}
          pageLink ={'/contents/content-videos'}
          searchMessage={'Search (ID, Name)'}
          baseUrl={`content-videos`}
          deleteUrl={`delete/many-content-videos`}
          keyValue={"videos"}
          cacheUrl={'flush-key/video'}
          
         
      />
      
  );
  
  
};

export default ContentVideoList;
