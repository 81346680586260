import React from 'react';
import TableList from "../../components/List/TableList";


const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '7%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '30%', show: true},
  {accessor: 'num_account', label: 'Num Account', width: '20%', show: true},
  {accessor: 'is_company_account', type: 'boolean', color: 'primary', width: '16%', label: 'Is Company Account?', show: true,},
  {accessor: 'type', type: 'chip', width: '20%', label: 'Type', show: true,},
  {accessor: 'created_at', type: 'date', width: '25%', label: 'Created At', show: true,},
];
const fieldSearchable = [
    'name',
];
const fieldFilterable = [
  {name: 'type', label: 'Type', type: 'dropdown', options: ['bank', 'poste', 'other'],},
  {name: 'isCompanyAccount', label: 'is a company account?', type: 'boolean'},
];

const CompanyAccountList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`company-accounts`}
          exportUrl={`company-accounts/export`}
          pageLink ={'/finance/company-accounts'}
          searchMessage={'Search (Name)'}
          baseUrl={`company-accounts`}
          noShow={true}
      />
  );
};

export default CompanyAccountList;
