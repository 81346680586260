import { combineReducers } from "@reduxjs/toolkit";
import { reducer as incomesReducer } from "../slices/incomes";
import { reducer as modalsReducer } from "../slices/modals";
import { reducer as pubCardsReducer } from "../slices/pubCards";

const combinedReducer = combineReducers({
  incomes: incomesReducer,
  modals: modalsReducer,
  pubCards: pubCardsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

// const rootReducer = combineReducers({
//   notifier: notifierReducer,
//   notifications: notificationReducer,
//   modals: modalsReducer,
//   subjects: subjectsReducer,
//   chapters: chaptersReducer,
//   contents: contentsReducer,
//   videos: videosReducer,
//   magazines: magazinesReducer,
//   tasks: tasksReducer,
//   user: userReducer,
//   diverse: diverseReducer,
//   exams: examsReducer,
// });

export default rootReducer;
