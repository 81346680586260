import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Grid, makeStyles} from '@material-ui/core';
import BasicsInfo from './BasicsInfo';
import UsersEventInfo from './UsersEventInfo';

const useStyles = makeStyles(() => ({
  root: {},
}));

const Details = ({
                   contentEvents,
                   usersEvent,
                   handlePageChange,
                   usersPage,
                   className,
                   ...rest
                 }) => {
  const classes = useStyles();
  return (
      <Grid
          className={clsx(classes.root, className)}
          container
          spacing={3}
          {...rest}
      >
        <Grid
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
        >
          <BasicsInfo contentEvents={contentEvents}/>
        </Grid>
        <Grid
            item
            lg={8}
            md={8}
            xl={8}
            xs={12}
        >
          <UsersEventInfo users={usersEvent} handlePageChange={handlePageChange}
                          page={usersPage}/>
        </Grid>
      </Grid>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  contentEvents: PropTypes.object.isRequired,
  usersEvent: PropTypes.object.isRequired,
  handlePageChange: PropTypes.func,
  page: PropTypes.number,

};

export default Details;
