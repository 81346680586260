import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import { api_get, api_post, api_put } from "../../../utils/Api";
import { AsyncPaginate } from "react-select-async-paginate";
import theme from "../../../theme";
import optionsParser from "../../../helpers/optionsParser";
import Box from "@material-ui/core/Box";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import momentAdapter from "@material-ui/pickers/adapter/moment";
import AddIcon from "@material-ui/icons/Add";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
  buttonPadding: {
    padding: "10px",
  },
}));
type Props = {
  className: string,
};
const Form = (props: Props): React$Element<any> => {
  const { id } = useParams();
  const { className, ...rest } = props;
  const classes = useStyles();
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertseverity] = useState("");
  const [switchShowPassword, setSwitchPassword] = useState(false);
  const [business,setBusiness]=useState()
  const [formState, setFormState] = useState({
    name: "",
    phone: "",
    username: "",
    email: null,
    plainPassword: "",
    businessSubscriptions: [
      // {
      //   total_seats: null,
      //   end_date: null,
      //   group: null,
      //   business_subscription_divisions: [
      //   ]
      // },
    ],
  });

  useEffect(() => {
    if (props.edit) {
      api_get(`business/${id}`).then((result) => {
        const { payload } = result;
        setFormState({
          email: payload?.email,
          username: payload?.username,
          name: payload?.name,
          phone: payload?.phone,
          businessSubscriptions: payload?.business_subscriptions.length
            ? payload.business_subscriptions
            : [],
        });
        setBusiness({businessSubscriptions:payload?.business_subscriptions.length
          ? payload.business_subscriptions
          : [],})
      });
    }
  }, []);
  const handleChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };
  const handleFieldsChange = (event, index) => {
    const newArr = { ...formState };
    newArr.businessSubscriptions[index][event.target.name] = event.target.value;
    setFormState({ ...newArr });
  };
  const divisionChange = (value, index) => {
    const newArr = { ...formState };
    newArr.businessSubscriptions[index].business_subscription_divisions = value;
    setFormState({ ...newArr });
  };

  const userGroupChange = (value, index) => {
    const newArr = { ...formState };
    newArr.businessSubscriptions[index]["group"] = value;
    setFormState({ ...newArr });
  };
  const endDateChange = (value, index) => {
    const newArr = { ...formState };
    newArr.businessSubscriptions[index]["end_date"] = value.format(
      "YYYY-MM-DD"
    );
    //newArr.businessSubscriptions[index]['group'] = value;
    setFormState({ ...newArr });
  };
  const AddRemoveSubscription = (operation, index) => {
    if (operation == "plus") {
      setFormState((previousData) => ({
        ...previousData,
        businessSubscriptions: formState?.businessSubscriptions.concat({
          business_subscription_divisions: [],
          total_seats: '',
          group: null,
          end_date: null,
        }),
      }));
    } else {
      setFormState((previousData) => ({
        ...previousData,
        businessSubscriptions: formState.businessSubscriptions.filter(
          (num, i) => {
            return i != index;
          }
        ),
      }));
    }
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertseverity("error");
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const formatSchoolSubscriptionDivisions = (values) => {
    let newArr = { ...values };
    newArr.businessSubscriptions =
      newArr.businessSubscriptions.length > 0 &&
      newArr.businessSubscriptions.map((businessSub) => ({
        totalSeats: businessSub?.total_seats,
        endDate: businessSub?.end_date,
        group: businessSub?.group?.id,
        businessSubscriptionDivisions:
          businessSub?.business_subscription_divisions.length &&
          businessSub?.business_subscription_divisions.map((division) => {
            return { division: division.division?division?.division?.id:division?.id };
          }),
      }));
    return newArr;
  };
  const formValidation = (values) => {
    if (!values?.plainPassword && !props.edit  ) {
      return { success: false, message: "Password  is missing" };
    }
    if(values?.plainPassword?.length<8 && !props.edit && setSwitchPassword ){
       return { success: false, message: "Password  is too short (8 character min)" }

    }
    if(values?.plainPassword?.length<8 && props.edit  ){
      return { success: false, message: "Password  is too short (8 character min)" }

   }
    if (!values?.name) {
      return { success: false, message: "name  is missing" };
    }
    if (!values?.username) {
      return { success: false, message: "userName is missing" };
    }
    if (!values?.phone) {
      return { success: false, message: "Phone is missing" };
    }
    if (!values?.email) {
      return { success: false, message: "Email is missing" };
    }
  };

  const formValidationSub=(values)=>{
    if(values && values.length>0 ){
      return values.map((businessSub, index) => {
       if(!businessSub.total_seats){
         return { success: false, message: "Total seat " + [index+1] + " is missing" };
       }
       if(businessSub?.business_subscription_divisions.length==0 && !props.edit){
         return { success: false, message: "Division " + [index+1] + " is missing" };
       }
       if(!businessSub?.group ){
         return { success: false, message: "Group " + [index+1] + " is missing" };
       }
       if(!businessSub?.end_date ){
        return { success: false, message: "Date " + [index+1] + " is missing" };
      }
     })
    }
  }

  const BusinessUrl = process.env.REACT_APP_FRONT_BUSINESS_URL;
  const switchBusiness = (event, id) => {
    api_get(`business/login-as/${id}`).then((data) => {
      window.open(`${BusinessUrl}/switch?token=${data.payload?.token}&adminId=${data.payload?.adminId}`);
    });
  };

  const handleSubmit = () => {
    const validation = formValidation(formState)

    const validationSub=formValidationSub(formState.businessSubscriptions)?.filter(
      (validation) => validation?.message && validation.message !== undefined
    );

    if (validation ) {
      setAlertMessage(validation?.message);
      setAlertseverity("warning");
      setOpen(true);
     
    }else if (validationSub?.length > 0) {
      validationSub.map((validation) => {
        setAlertMessage(validation?.message);
        setAlertseverity("warning");
        setOpen(true);
      });}
     else {
      let values = formatSchoolSubscriptionDivisions(formState);
      if (props.edit) {
        api_put(`business/${id}`, values).then((result) => {
          if (result?.code !== 200) {
            showErrorMessage(result?.message);
          } else {
            setAlertseverity("success");
            setAlertMessage("business updated successfully");
            setOpen(true);
            window.location.href = "/users/business";
          }
        });
      } else {
        api_post(`business`, values)
          .then((result) => {
            if (result.errors) {
              showErrorMessage(result?.errors);
            } else {
              setAlertseverity("success");
              setAlertMessage("business created successfully");
              setOpen(true);
              window.location.href = "/users/business";
            }
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
    }
  };

  const getDivisions = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name"])
        .replace("&search=", ";")
        .replace("searchJoin=or", ";");
    const result = await api_get(
        `divisions?page=${page.page}&search=isPublic:1${options}searchJoin=and`
    );
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };

  const getUserGroups = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name"]);
    const result = await api_get(`groups?page=${page.page}${options}`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCheckedShowPassword = (e,value) => {
    setSwitchPassword(e.target.checked);
  };
  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <CardHeader subheader="The information can be edited" />
          <div style={{ display: "flex", justifyContent: "end" }}>
            {props.edit && <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={(event) =>switchBusiness(event, id)}
            >
              <AccountCircleIcon
                style={{ display: props.edit ? "" : "none" }}
              />
              <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                LOGIN AS USER
              </span>
            </IconButton>}
          </div>

          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={2} xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  required
                  value={formState.name || ""}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  fullWidth
                  label="userName"
                  name="username"
                  onChange={handleChange}
                  required
                  value={formState.username || ""}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={2} xs={12}>
                <TextField
                  fullWidth
                  label="phone"
                  name="phone"
                  onChange={handleChange}
                  required
                  value={formState.phone || ""}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  onChange={handleChange}
                  required
                  value={formState.email || ""}
                  variant="outlined"
                />
              </Grid>
              {!props.edit && <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  label="password"
                  name="plainPassword"
                  onChange={handleChange}
                  required
                  value={formState.plainPassword || ""}
                  variant="outlined"
                  type="password"
                />
                </Grid>}
            </Grid>
            {props.edit && <CardContent>
                <CardHeader
                    subheader="Edit password"
                />
                <Divider />
                <FormControlLabel
                    control={
                      <Switch
                        checked={switchShowPassword}
                        onChange={handleCheckedShowPassword}
                        color="primary"                        
                      />
                    }
                    label={"Password field edit"}
                  />
               <Collapse in={switchShowPassword} timeout="auto" unmountOnExit>
                <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  label="password"
                  name="plainPassword"
                  onChange={handleChange}
                  required
                  value={formState.plainPassword || ""}
                  variant="outlined"
                  type="password"
                />
                </Grid>
                </Grid>
               </Collapse>
              </CardContent>}
            <br />
            <Divider />
            <Grid item md={3} xs={8}>
              <Box m={2} pt={3}>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  style={{ textTransform: "none" }}
                  onClick={() => AddRemoveSubscription("plus")}
                >
                  Add Subscriptions
                </Button>
              </Box>
            </Grid>
            {formState?.businessSubscriptions?.length > 0 &&
              formState?.businessSubscriptions.map((affiliation, index) => {
                return (
                  <Grid container spacing={3} key={index}>
                    <Grid item md={3} xs={4}>
                      <AsyncPaginate
                        loadOptions={getUserGroups}
                        value={affiliation.group}
                        onChange={(v) => userGroupChange(v, index)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        placeholder="User group *"
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: "53px",
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: "#000",
                            fontSize: theme.typography.fontSize,
                            fontFamily: theme.typography.fontFamily,
                          }),
                        }}
                        menuPortalTarget={document.querySelector("body")}
                      />
                    </Grid>
                    <Grid item md={4} xs={4}>
                      <AsyncPaginate
                        loadOptions={getDivisions}
                        value={affiliation?.business_subscription_divisions}
                        isMulti
                        onChange={(v) => divisionChange(v, index)}
                        getOptionLabel={(option) =>
                          option.division ? option.division.name : option.name
                        }
                        getOptionValue={(option) =>
                          option.division ? option.division.id : option.id
                        }
                        placeholder="Division *"
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            minHeight: "53px",
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: "#000",
                            fontSize: theme.typography.fontSize,
                            fontFamily: theme.typography.fontFamily,
                          }),
                        }}
                        menuPortalTarget={document.querySelector("body")}
                      />
                    </Grid>
                    <Grid item md={2} xs={3}>
                      <LocalizationProvider dateAdapter={momentAdapter}>
                        <DatePicker
                          label="End Date *"
                          name="end_date"
                          value={affiliation.end_date}
                          onChange={(v) => endDateChange(v, index)}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              fullWidth
                              variant="outlined"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item md={1} xs={2}>
                      <TextField
                        fullWidth
                        disabled={props.edit && index < business?.businessSubscriptions?.length}
                        name="total_seats"
                        variant="outlined"
                        label="Total seats *"
                        value={affiliation.total_seats}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => handleFieldsChange(e, index)}
                      ></TextField>
                    </Grid>
                    <Grid item md={2} xs={1}>
                      <Box m={1} pt={1}>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={(event) =>
                            AddRemoveSubscription(event, index)
                          }
                        >
                          -
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}
          </CardContent>
          <Divider />
          <CardActions>
            <Button disabled={formState.businessSubscriptions.length<=0} color="primary" variant="contained" onClick={handleSubmit}>
              Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
};

export default Form;
