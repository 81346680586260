//@flow
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';

const useStyles = makeStyles(() => ({
  root: {},
}));
type paginationType = {
  [key: string]: Array<{ total: number }>,
};
type usersType = {
  [key: string]: Array<{ id: number, full_name: string, email: string }>,
};
type eventType = {
  [key: string]: Array<{ id : string ,user: usersType, meta: paginationType }>,
};
type Props = {
  className: string,
  handlePageChange: Function,
  users: {
    meta: {total: number},
    data : Array<{id: number, user:{id: number, full_name: string, email: string }}>,
  },
  page: number
};
const UsersEventInfo = ({className, handlePageChange, users, page}: Props): React$Element<any> => {
  const classes = useStyles();
  return (
      <Card
          className={clsx(classes.root, className)}
      >
        <Box minWidth={1150}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>User Id</TableCell>
                <TableCell>Full name</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.data ? (users.data.map((event) => (
                      <TableRow key={event.id}>
                        <TableCell>
                          {event.id}
                        </TableCell>
                        <TableCell>
                          {event.user?.id}
                        </TableCell>
                        <TableCell>
                          {event.user?.full_name}
                        </TableCell>
                        <TableCell>
                          {event.user?.email}
                        </TableCell>
                      </TableRow>
                  ))
              ) : (
                  <TableRow>
                    <TableCell>
                      He does not have any user
                    </TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
        <TablePagination
            component="div"
            count={users.meta?.total}
            onChangePage={handlePageChange}
            //onRowsPerPageChange={() => {}}
            onChangeRowsPerPage={() => {}}
            page={page - 1}
            rowsPerPage={10}
            rowsPerPageOptions={[10]}
        />
      </Card>
  );
};

UsersEventInfo.propTypes = {
  className: PropTypes.string,
  users: PropTypes.object.isRequired,
};

export default UsersEventInfo;
