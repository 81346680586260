import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'user', label: 'User', width: '20%', show: true, type: 'concatString'},
  {accessor: 'old_division.name', label: 'Old Division', color: 'default', width: '15%', show: true,},
  {accessor: 'new_division.name', label: 'New Division', color: 'primary', width: '15%', show: true,},
  {accessor: 'reason2.reason', width: '25%', type: 'chip',label: 'Reason', show: true},
  {accessor: 'status', width: '10%', label: 'Status', show: true, type:'statusColor'},
  {accessor: 'created_at', type: 'date', width: '25%', label: 'Updated At', show: true,},
  {accessor: 'updated_at', type: 'date', width: '25%', label: 'Updated At', show: true,},

];
const fieldSearchable = ["id"];
const fieldFilterable = [
  {name: 'user.id', label: 'User', type: 'userAutocomplete'},
  {name: 'oldDivision.id', label: 'Old Division', type: 'divisionAutocomplete',},
  {name: 'newDivision.id', label: 'New Division', type: 'divisionAutocomplete',},
  {name: 'status', label: 'Status', type: 'dropdown', options: ['refused', 'approuved', 'pending'],},
];

const DivisionEditList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`edit-division`}
          exportUrl={`edit-division/export`}
          pageLink ={'/user-settings/edit-divisions'}
          searchMessage={'Search (ID)'}
          baseUrl={`edit-division`}
          deleteUrl={`delete/many-edit-divisions`}
          keyValue={"editDivisions"}
          removeAdd={true}
      />
  );
};

export default DivisionEditList;
