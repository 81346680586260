import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const BasicsInfo = ({
                      webinar,
                      className,
                      ...rest
                    }) => {
  const classes = useStyles();
  return (
      <Card
          className={clsx(classes.root, className)}
          {...rest}
      >
        <CardHeader title="Webinar Account info"/>
        <Divider/>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                ID
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {webinar.id}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Email
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {webinar.email}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Active
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {webinar.active ? 'Yes': 'No'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Expiry Date
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {webinar.expiry_date}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Created At
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {webinar.created_at}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Organizer Key
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {webinar.organizer_key}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Consumer Key
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {webinar.consumer_key}
                </Typography>
              </TableCell>
            </TableRow> <TableRow>
            <TableCell className={classes.fontWeightMedium}>
              Consumer Secret
            </TableCell>
            <TableCell>
              <Typography
                  variant="body2"
                  color="textSecondary"
              >
                {webinar.consumer_secret}
              </Typography>
            </TableCell>
          </TableRow> <TableRow>
            <TableCell className={classes.fontWeightMedium}>
              Account Key
            </TableCell>
            <TableCell>
              <Typography
                  variant="body2"
                  color="textSecondary"
              >
                {webinar.account_key}
              </Typography>
            </TableCell>
          </TableRow>
          </TableBody>
        </Table>
      </Card>
  );
};

BasicsInfo.propTypes = {
  className: PropTypes.string,
  webinar: PropTypes.object.isRequired,
};

export default BasicsInfo;
