import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '7%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '30%', show: true},
  {accessor: 'position', label: 'Position', width: '7%', show: true, type: 'fieldValue'},
  {accessor: 'is_public', type: 'boolean', color: 'primary', width: '16%', label: 'Public', show: true,},
  {accessor: 'created_at', type: 'date', width: '25%', label: 'Created At', show: true,},
  {accessor: 'updated_at', type: 'date', width: '25%', label: 'Updated At', show: true,
  },

];
const fieldSearchable = [
    'name',
];
const fieldFilterable = [];

const DivisionList = () => {

  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`divisions`}
          noExport={true}
          pageLink ={'/contents/divisions'}
          searchMessage={'Search (Name)'}
          baseUrl={`divisions`}
          noShow={true}
      />

  );
};

export default DivisionList;
