import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Box,
    Button,
    Card, CardContent,
    CardHeader,
    Divider,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PersonOutline';
import Label from '../../../components/Label';
import {api_get} from "../../../utils/Api";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import Moment from "moment";
import Chip from "@material-ui/core/Chip";
import CopyrightIcon from "@material-ui/icons/Copyright";
import IconButton from "@material-ui/core/IconButton";
import {Link} from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
    root: {},
    fontWeightMedium: {
        fontWeight: theme.typography.fontWeightMedium,
    },
}));

const ListPayments = ({
                          userPayments,
                        tableCell,
                        ...rest
                    }) => {
    const classes = useStyles();

    return (
        <>
        <CardHeader
            title="User's Payment Agreements List"
        />
    <Divider/>
    {userPayments && userPayments.length ? (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {tableCell.map((tableCell) => {
                            return (
                                <TableCell>{tableCell}</TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userPayments.map((payment) => (
                        <TableRow key={payment.name}>
                            <TableCell component="th" scope="row">
                                {payment.id}
                            </TableCell>
                            <TableCell>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    <Label
                                        color={payment.active == '1' ? 'success' : 'error'}>
                                        {payment.active == '1' ? "TRUE" : "FALSE"}
                                    </Label>
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.fontWeightMedium}>{payment.amount}</TableCell>
                            <TableCell>{Moment(payment.created_at).format('YYYY-MM-DD')}</TableCell>
                            <TableCell>
                                {payment?.payment_agreement_slices?.length && payment?.payment_agreement_slices.map((slice) => {
                                    return (
                                        <Chip
                                            label={slice.amount+' DT start from : '+Moment(slice.start_date).format('YYYY-MM-DD')+' to '+Moment(slice.validity_date).format('YYYY-MM-DD')}
                                            style={{backgroundColor: slice?.paid === true ? 'forestgreen' :'lightcoral', color:'white'}}
                                            icon={slice?.is_check === true ? <CopyrightIcon/> : null}
                                        />
                                    );
                                })
                                }
                            </TableCell>
                            <TableCell>{payment?.offer?.name}</TableCell>
                            <TableCell>{payment?.affiliation?.division?.name}</TableCell>

                            {/*  <TableCell>{subscription.created_at}</TableCell>*/}
                            <TableCell>
                                <IconButton
                                    className={classes.signOutButton}
                                    color="inherit"
                                    component={Link}
                                    to={`/finance/payment-agreements/edit/${payment.id}`}
                                    target='_blank'
                                >
                                    <EditIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ) : (
        <CardContent>
            <Typography variant="body2" color="textSecondary"
                        component="p">
                This user does not have any payments agreements
            </Typography>
        </CardContent>
    )}
        </>
    );
};

ListPayments.propTypes = {
    className: PropTypes.string,
};

export default ListPayments;
