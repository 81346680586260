// @flow
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import Collapse from '@material-ui/core/Collapse';
import Switch from '@material-ui/core/Switch';
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import { useParams } from "react-router-dom";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { AsyncPaginate } from "react-select-async-paginate";
import theme from "../../../theme";
import optionsParser from "../../../helpers/optionsParser";
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import LevelsForm from "./LevelsForm";
import SubscriptionsForm from "./SubscriptionsForm";
import PointsForm from "./PointsForm";
import SnackBar from "../../../components/SnackBar";
import PropTypes from "prop-types";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import momentAdapter from "@material-ui/pickers/adapter/moment";
import Moment from "moment";
import { api_get, api_post, api_put } from "../../../utils/Api.js";
import PaymentAgreementForm from "./PaymentAgreementForm";
import MarkSheetsForm from "./MarkSheetsForm";
import LoadingApi from 'components/LoadingApi/LoadingApi';
import {getCountries, getStates, getDivisions} from "../../../helpers/methods";
import GiftPoints from "./GiftPoints";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  },
  content: {
    padding: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {

      minWidth:'10px'


    },
  },
}));

const Form = (props) => {
  const { id } = useParams();
  const { className, edit, ...rest } = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    name: "",
    lastName: "",
    email: null,
    phone: null,
    phone2: null,
    plainPassword: "",
    gender: "male",
    verified: "0",
    completed: "0",
    confirmed: "1",
    isSingleMachine: "0",
    machineId: null,
    type: 1,
    // optionalSubject: null,
    country: { id: 1, name: "تونس" },
    state: null,
    birthDate: Moment().format("YYYY-MM-DD"),
    division: null,
    institute: null,
  });
  const [message, setAlertMessage] = useState("");
  const [severity, setAlertSeverity] = useState("");
  const [tab, setTab] = useState(1);
  const [userLevels, setUserLevels] = useState("");
  const [points, setPoints] = useState(0);
  const [loading, setLoading] = useState(false);
  const [switchShowPassword, setSwitchPassword] = useState(false);


  const StudentUrl = process.env.REACT_APP_FRONT_STUDENT_URL;

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const division_optional_subject = [
    8,9,10,11,12,14, 15, 16, 17, 18, 19, 20, 21, 22,36
  ];
const [user,setUser]=useState()
  useEffect(() => {
    if (edit && tab === 1) {
      setLoading(true)
      api_get(`users/${id}`).then((result) => {
        setLoading(false)
        const { payload } = result;
        setUser(payload)
        setPoints(payload.points);
        setUserLevels(payload.student_level_users);
        setValues({
          name: payload.name,
          lastName: payload.last_name,
          email: payload.email,
          phone: payload.phone,
          phone2: payload?.phone2,
          birthDate: payload.birth_date,
          gender: payload.gender,
          verified: payload.verified === true ? "1" : "0",
          completed: payload.completed === true ? "1" : "0",
          isSingleMachine: payload?.is_single_machine === true ? "1" : "0",
          machineId: payload?.machine_id,
          division: payload.division,
          confirmed: payload.confirmed,
          optionalSubject: payload.optional_subject,
          type: 1,
          state: payload.state,
          institute: payload.institute,
          country: payload.country,
          postalCode: payload?.postal_code,
        });
      });
    }
  }, [edit, id, tab]);
  const handleChange = (event) => {
    event.preventDefault();
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const birthDateChange = (value) => {
    if (value) {
      setValues({ ...values, birthDate: value.format("YYYY-MM-DD") });
    }
  };
  const switchStudent = () => {
    api_get(`users/login-as/${id}`).then((data) => {
      window.open(
        `${StudentUrl}/switch?token=${data.payload?.token}&userId=${data.payload?.userId}&adminId=${data.payload?.adminId}`
      );
    });
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index === 0) {
        setAlertSeverity("error");
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const registerValidation = (values) => {
    if (!values.lastName) {
      return { success: false, message: "Last Name is missing" };
    }
    if (!values.name) {
      return { success: false, message: "First Name is missing" };
    }
    if (values.name && !(/^[ ً ُ ٌ ِ ٍ ّء-يa-zA-Z]{2,20}(\s[ء-ي ً ُ ٌ ِ ٍ ّa-zA-Z](\s+){2,20})?$/.test(values.name.trim()))) {
      return { success: false, 'message': 'Please enter your real first name' };
    }
    if (values.lastName && !(/^[ ً ُ ٌ ِ ٍ ّء-يa-zA-Z]{2,20}(\s[ء-ي ً ُ ٌ ِ ٍ ّa-zA-Z](\s+){2,20})?$/.test(values.lastName.trim()))) {
      return { success: false, 'message': 'Please enter your real last name' };
    }
    if ((!edit && !values.plainPassword) || values.plainPassword < 8) {
      return { success: false, message: "Password is missing" };
    }
    if (!values.division) {
      return { success: false, message: "Division is missing" };
    }
  };
  const handleSubmit = () => {
    const requestValues = mapStateToRequest(values, [], []);
    setLoading(true);
    const validation = registerValidation(requestValues);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity("warning");
      setOpen(true);
    } else {
      if (props.edit) {
        api_put(`users/${id}`, requestValues).then((result) => {
          if (result?.errors) {
            showErrorMessage(result.errors);
          } else if (result?.code !== 200 && result?.code !== 201) {
            setAlertSeverity("warning");
            setAlertMessage(
              result?.message
                ? result.message
                : "Technical error! Contact the Developers Team"
            );
            setOpen(true);
          } else {
            setAlertSeverity("success");
            setAlertMessage("user updated successfully");
            setLoading(false);
            setOpen(true);
          }
        });
      } else {
        api_post("users", requestValues)
          .then((result) => {
            if (result?.errors) {
              showErrorMessage(result.errors);
            } else if (result?.code !== 200 && result?.code !== 201) {
              setAlertSeverity("warning");
              setAlertMessage(
                result?.message
                  ? result.message
                  : "Technical error! Contact the Developers Team"
              );
              setOpen(true);
            } else {
              setAlertSeverity("success");
              setAlertMessage("user created successfully");
              setOpen(true);
              setLoading(false);
              //window.location.href = '/users/students';
            }
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
    }
  };

  const getOptionalSubjects = async (search, prevData, page) => {
    const result = await api_get(
      `subjects?page=${page.page}&search=division.id:${values.division.id};optional:1&searchJoin=and`
    );
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };

  const getInstitute = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ["name"]);
    const result = await api_get(
      `institutes?search=state.id:${values.state.id}&searchJoin=and&page=${page.page}${options}`
    );
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  const divisionChange = (value) => {
    setValues({
      ...values,
      division: value,
      optionalSubject: null,
    });
  };
  const optionalSubjectsChange = (value) => {
    setValues({
      ...values,
      optionalSubject: value,
    });
  };
  const instituteChange = (value) => {
    setValues({
      ...values,
      institute: value,
    });
  };
  const stateChange = (value) => {
    setValues({
      ...values,
      state: value,
      institute: null,
    });
  };
  const countryChange = (value) => {
    setValues({
      ...values,
      country: value,
    });
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
  };
  const handleCheckedShowPassword = (e,value) => {
    setSwitchPassword(e.target.checked);
  };

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <TabContext value={tab}>
          <div className={classes.header}>
            <TabList
             scrollButtons="auto"
            variant="scrollable"
            className={classes.header}
              onChange={handleChangeTab}
              aria-label="simple tabs example"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Basics" value={1} />
              <Tab label="Levels" value={2} disabled={!edit} />
              <Tab label="Points" value={3} disabled={!edit} />
              <Tab label="Subscriptions" value={4} disabled={!edit} />
              <Tab label="Payment agreements" value={5} disabled={!edit} />
              <Tab label="Mark sheets" value={6} disabled={!edit} />
              <Tab label="Gift" value={7} disabled={!edit} />
            </TabList>
            <div>
              {/* <Button startIcon={<PersonIcon/>} className={classes.signOutButton} color="inherit"
                        onClick={switchStudent}>
                  Login as User
                </Button>*/}
          { props.edit &&   <IconButton
                className={classes.signOutButton}
                color="inherit"
                onClick={switchStudent}
              >
                <AccountCircleIcon style={{ display: edit ? "" : "none" }} />
                <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                  LOGIN AS USER
                </span>
              </IconButton>}
            </div>
          </div>
          <TabPanel value={1}>
            <form autoComplete="off" noValidate>
              <CardHeader
                subheader="The information can be edited"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid
                    item
                    md={3}
                    xs={12}
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      autoComplete="none"
                      label="First name"
                      name="name"
                      onChange={handleChange}
                      required
                      value={values.name || ""}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      autoComplete="none"
                      label="Last name"
                      name="lastName"
                      onChange={handleChange}
                      required
                      value={values.lastName || ""}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      inputProps={{
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      label="Email Address"
                      name="email"
                      onChange={handleChange}
                      required
                      value={values.email || ""}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      inputProps={{
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      autoComplete="none"
                      label="Phone Number"
                      name="phone"
                      onChange={handleChange}
                      type="number"
                      value={values.phone || ""}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <LocalizationProvider dateAdapter={momentAdapter}>
                      <DatePicker
                        label="Birth Date"
                        name="start_date"
                        value={values.birthDate}
                        onChange={birthDateChange}
                        renderInput={(props) => (
                          <TextField {...props} fullWidth variant="outlined" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Gender</FormLabel>
                      <RadioGroup
                        aria-label="gender"
                        name="gender"
                        onChange={handleChange}
                        value={values.gender || ""}
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value="male"
                          label="Male"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value="female"
                          label="Female"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Verified</FormLabel>
                      <RadioGroup
                        aria-label="verified"
                        name="verified"
                        onChange={handleChange}
                        value={values.verified || ""}
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value="1"
                          label="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value="0"
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Completed</FormLabel>
                      <RadioGroup
                        aria-label="completed"
                        name="completed"
                        onChange={handleChange}
                        value={values.completed || ""}
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value="1"
                          label="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value="0"
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <AsyncPaginate
                      loadOptions={getDivisions}
                      value={values.division}
                      onChange={divisionChange}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder="Division"
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: "53px",
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: "#000",
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector("body")}
                    />
                  </Grid>
                  {values.division &&
                    division_optional_subject.includes(values.division.id) && (
                      <Grid item md={3} xs={4}>
                        <AsyncPaginate
                          loadOptions={getOptionalSubjects}
                          value={values.optionalSubject}
                          onChange={optionalSubjectsChange}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          placeholder="Optional subjects"
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: "53px",
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: "#000",
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector("body")}
                        />
                      </Grid>
                    )}
                  <Grid item md={3} xs={12}>
                    <AsyncPaginate
                      loadOptions={getCountries}
                      value={values.country}
                      onChange={countryChange}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder="Countries"
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: "53px",
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: "#000",
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector("body")}
                    />
                  </Grid>
                  {values.country && values.country.id === 1 && (
                    <Grid item md={3} xs={12}>
                      <AsyncPaginate
                        loadOptions={getStates}
                        value={values.state}
                        onChange={stateChange}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        placeholder="States"
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: "53px",
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: "#000",
                            fontSize: theme.typography.fontSize,
                            fontFamily: theme.typography.fontFamily,
                          }),
                        }}
                        menuPortalTarget={document.querySelector("body")}
                      />
                    </Grid>
                  )}
                  {values.country &&
                    values.country.id === 1 &&
                    values.state?.id && (
                      <Grid item md={3} xs={12}>
                        <AsyncPaginate
                          loadOptions={getInstitute}
                          value={values.institute}
                          onChange={instituteChange}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          placeholder="Institute"
                          cacheUniqs={[values.institute]}
                          noOptionsMessage={() =>
                            "You may choose a institute before"
                          }
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: "53px",
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: "#000",
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector("body")}
                        />
                      </Grid>
                    )}
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      autoComplete="none"
                      label="Postal Code"
                      name="postalCode"
                      onChange={handleChange}
                      type="number"
                      value={values.postalCode || ""}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                        fullWidth
                        inputProps={{
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        autoComplete="none"
                        label="Parent PhoneNumber"
                        name="phone2"
                        onChange={handleChange}
                        type="number"
                        value={values.phone2 || ""}
                        variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <CardHeader
                    subheader="Edit password"
                />
                <Divider />
              <FormControlLabel
                    control={
                      <Switch
                        checked={switchShowPassword}
                        onChange={handleCheckedShowPassword}
                        color="primary"
                      />
                    }
                    label={"Password field edit"}
                  />
              <Collapse in={switchShowPassword} timeout="auto" unmountOnExit>
                <Grid container spacing={2}>
                 <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      inputProps={{
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      label="password"
                      name="plainPassword"
                      autoComplete="none"
                      onChange={handleChange}
                      value={values.plainPassword || ""}
                      variant="outlined"
                      type="password"
                    />
                  </Grid>
                </Grid>
               </Collapse>
              </CardContent>
              <Divider />
              <CardHeader
                  subheader="Is single machine ?"
              />
              <Divider />
              <CardContent>
              <Grid container spacing={3}>
              <Grid item md={3} xs={12}>
                <TextField
                    fullWidth
                    autoComplete="none"
                    label="Machine Id"
                    name="machineId"
                    onChange={handleChange}
                    value={values.machineId || ""}
                    variant="outlined"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Is Single Machine ?</FormLabel>
                  <RadioGroup
                      aria-label="isSingleMachine"
                      name="isSingleMachine"
                      onChange={handleChange}
                      value={values.isSingleMachine || ""}
                      row
                  >
                    <FormControlLabel
                        control={<Radio />}
                        value="1"
                        label="Yes"
                    />
                    <FormControlLabel
                        control={<Radio />}
                        value="0"
                        label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              </Grid>
                </CardContent>
                <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Save details
                </Button>
              </CardActions>
            </form>
          </TabPanel>
          <TabPanel value={2}>
            {values.division ? (
              <LevelsForm division={values.division} userLevels={userLevels} />
            ) : (
              <p> ⚠️ user without division </p>
            )}
          </TabPanel>
          <TabPanel value={3}>
            <PointsForm points={points} userId={id} />
          </TabPanel>
          <TabPanel value={4}>
            <SubscriptionsForm userId={id} />
          </TabPanel>
          <TabPanel value={5}>
            <PaymentAgreementForm userId={id} edit={edit} />
          </TabPanel>
          <TabPanel value={6}>
            <MarkSheetsForm userId={id} edit={edit} />
          </TabPanel>
          <TabPanel value={7}>
            <GiftPoints user={user}  />
          </TabPanel>
        </TabContext>
      </Card>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
      <LoadingApi open={loading} onClick={handleCloseLoading}/>
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  handleCloseLoading: PropTypes.func,
  loading: PropTypes.bool,
};

export default Form;
