import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'name', label: 'Name', width: '25%', show: true, type: 'fieldValue'},
  {accessor: 'value', label: 'Value', width: '20%', show: true, type: 'fieldValue'},
  {accessor: 'description', label: 'Description', width: '20%', show: true, type: 'fieldValue'},
  {accessor: 'created_at', label: 'Created At', width: '15%', show: true, type: 'date'},
  {accessor: 'updated_at', label: 'Updated At', width: '15%', show: true, type: 'date'},
];
const fieldSearchable = [
  'name',
];
const fieldFilterable = [];

const AdminConfigList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`admin-config`}
          exportUrl={`admin-config/export`}
          pageLink ={'/finance/config'}
          searchMessage={'Search (Name)'}
          baseUrl={`admin-config`}
          noFilter={true}
          noShow={true}
      />
  );
};

export default AdminConfigList;
