import React from 'react';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Label from '../../../components/Label';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const PointHistory = ({
  points,
  className,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Points History"/>
      <Divider/>
      <Table>
        <TableBody>
          { points?.length ? (points.map((point) => (
              <TableRow>
                <TableCell className={classes.fontWeightMedium}>
                  {point.id}
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    <Label color='success'>
                      Add
                    </Label>
                    {`${point?.points}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    <Label>
                      Total
                    </Label>
                    {` ${point?.new_total}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    <label>

                    </label>
                    {point?.purpose}
                  </Typography>
                </TableCell>
              </TableRow>
              ))
          ) : (
              <TableRow>
                <TableCell>
                  User does not have any points history
                </TableCell>
              </TableRow>
          )}


        </TableBody>
      </Table>
    </Card>
  );
};

PointHistory.propTypes = {
  className: PropTypes.string,
  points: PropTypes.object.isRequired,
};

export default PointHistory;
