import React from 'react';
import PropTypes from 'prop-types';
import {
    CardContent,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core';
import {api_delete} from "../../../utils/Api";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import IconButton from "@material-ui/core/IconButton";
import {DeleteOutline} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {},
    fontWeightMedium: {
        fontWeight: theme.typography.fontWeightMedium,
    },
}));

const ListDiscountCodes = ({userDiscountCodes, tableCell, getData, user, ...rest}) => {
    const classes = useStyles();
    const [openAlert, setOpenAlert] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [severity, setAlertSeverity] = React.useState('success');
    const deleteDiscountCode = (generatedDiscountCodeId, userId) => {
        api_delete(`user-discount-code/${generatedDiscountCodeId}/users/${userId}`).then((data)=>{
            if(data?.code !== 200 && data?.code !== 201){
               setOpenAlert(true);
                let errorMessage = data?.message? data.message:'Technical error! Contact the Developers Team';
                setAlertSeverity('warning')
                setMessage(errorMessage)
            } else {
                getData();
                setMessage('Discount code successfully deleted')
                setOpenAlert(true);
                setAlertSeverity('success')
                setMessage('Deleted successfully')
            }
        });
    }
    return (
        <>
    {userDiscountCodes && userDiscountCodes.length ? (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {tableCell.map((tableCell) => {
                            return (
                                <TableCell>{tableCell}</TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userDiscountCodes.map((code) => (
                        <TableRow key={code.id}>
                            <TableCell component="th" scope="row">
                                {code?.id}
                            </TableCell>
                            <TableCell>
                                {code?.discount_code_offer_division?.discount_code?.code}
                            </TableCell>
                            <TableCell className={classes.fontWeightMedium}>{code?.discount_code_offer_division?.amount}</TableCell>
                            <TableCell>{code?.discount_code_offer_division?.type}</TableCell>
                            <TableCell>{code?.discount_code_offer_division?.quantity}</TableCell>
                            <TableCell>
                                <IconButton
                                    className={classes.signOutButton}
                                    color="inherit"
                                    onClick={()=> deleteDiscountCode(code.id, user)}
                                >
                                    <DeleteOutline/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ) : (
        <CardContent>
            <Typography variant="body2" color="textSecondary"
                        component="p">
                This user does not have any discount code
            </Typography>
        </CardContent>
    )}
        </>
    );
};

ListDiscountCodes.propTypes = {
    className: PropTypes.string,
};

export default ListDiscountCodes;
