// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import mapStateToRequest from '../../../helpers/mapStateToRequest';
import {
  DatePicker,
  LocalizationProvider,
} from '@material-ui/pickers';
import Checkbox from "@material-ui/core/Checkbox";
import momentAdapter from '@material-ui/pickers/adapter/moment';
import Moment from "moment";
import {api_get, api_put, api_post} from "../../../utils/Api";
import LoadingApi from 'components/LoadingApi/LoadingApi';
import {getDivisions, getGroups, getOffers} from "../../../helpers/methods";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    overflow: "visible",
  },
  mtop: {
    marginTop: 24,
  },
  
  mtop0: {
    marginTop: 0,
  },
  content: {
    padding: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
}));

const Form = (props) => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    value: null,
    active: true,
    allyear: false,
    discountMethod: '',
    //quantity: 0,
    priority: 1,
    offerSpecialPriceExcludes: null,
    userGroup: null,
    offers: null,
    division: null,
    startDate: Moment().format('YYYY-MM-DD hh:mm'),
    endDate: Moment().format('YYYY-MM-DD hh:mm'),
  });

  const [message, setAlertMessage]=useState('');
  const [severity, setAlertSeverity]=useState('success');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) {
      setLoading(true)
      api_get(`offers/special-prices/${id}`).then((result)=>{
        setLoading(false)
        const data=result.payload;
        setValues({
          value: data.value,
          active: data.active,
          allyear: data.allyear,
          discountMethod: data.discount_method,
          quantity: data.quantity,
          priority: data.priority,
          offerSpecialPriceExcludes: data.offer_special_price_excludes,
          userGroup: data.user_group,
          offers: data.offer,
          division: data.division,
          //group: data.user_group,
          startDate: data.start_date,
          endDate: data.end_date,
        });
      });
    }
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const startDateChange = (value) => {
    if(value){
      setValues({ ...values, startDate: value.format("YYYY-MM-DD 00:00"), });
    }
  };
  const endDateChange = (value) => {
    if(value){
      setValues({ ...values, endDate: value.format("YYYY-MM-DD 23:59"), });
    }
  };
  const handleCheckChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked,
    });
  };
  const showErrorMessage=(errors)=>{
    Object.keys(errors).map(function(key, index) {
      if (index===0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const showValidationErrorMessage = (message) => {
    setAlertSeverity('error');
    setAlertMessage(message);
    setOpen(true);
  };
  const showSucessMessage = (message) => {
    setAlertSeverity('success');
    setAlertMessage(message);
    setOpen(true);
  };
  const formValidation = (values) => {
    if (!values?.discountMethod) {
      return { success: false, 'message': 'DiscountMethod is missing' };
    }
    if (!values?.value) {
      return { success: false, 'message': 'Description is missing' };
    }
  };
  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
      setLoading(true);
      const requestValues = mapStateToRequest(values,
          ['offerSpecialPriceExcludes']);
      let newArr = [];
      requestValues.offerSpecialPriceExcludes && requestValues.offerSpecialPriceExcludes.map(option => {
        newArr.push({"group": option.group ? option.group.id : option.id})
      })
      requestValues.offerSpecialPriceExcludes = newArr;
      requestValues.active = requestValues.active === 1 ? true : false;
      requestValues.allyear = requestValues.allyear ===  1 ? true : false;

      if (edit) {
        api_put(`offers/special-prices/${id}`, requestValues).then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            setLoading(false);
            showSucessMessage('Offer special price updated successfully');
            window.location.href = '/offers/offer-special-prices';
          }
        });
      } else {
        api_post(`offers/special-prices`, requestValues).then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else {
            setLoading(false);
            showSucessMessage('Offer special price created successfully');
            window.location.href = '/offers/offer-special-prices';
          }
        }).catch((error) => {
          throw new Error(error);
        });
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }
  const divisionsChange = (options) => {
      setValues({...values, ['division']: options,});
  };
  const offersChange = (options) => {
      setValues({...values, ['offers']: options,});
  };
  const excludedGroupsChange = (options) => {
    setValues({
      ...values,
      offerSpecialPriceExcludes: options,
    });
  };

 const groupsChange = (options) => {
   setValues({...values, ['userGroup']: options});
 };
  return (
      <div {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Card className={classes.root}>
                <CardContent>
                  <AsyncPaginate
                      isClearable={true}
                      loadOptions={getOffers}
                      onChange={offersChange}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      value={values.offers}
                      placeholder="Offers"
                      isMulti={!edit}
                      label="End Date"
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                  />
                </CardContent>
              </Card>
              <Box mt={3}>
                <Card>
                  <CardHeader title="Dates" />
                  <Divider />
                  <CardContent>
                    <Box mt={0} mb={1}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                          <LocalizationProvider dateAdapter={momentAdapter}>
                            <DatePicker
                                label="Visibility Start Date"
                                name="startDate"
                                inputFormat="DD/MM/yyyy"
                                value={values.startDate}
                                onChange={startDateChange}
                                renderInput={(props) => <TextField
                                    {...props}
                                    fullWidth
                                    variant="outlined"
                                />}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <LocalizationProvider dateAdapter={momentAdapter}>
                            <DatePicker
                                label="Visibility End date"
                                name="endDate"
                                inputFormat="DD/MM/yyyy"
                                value={values.endDate}
                                onChange={endDateChange}
                                renderInput={(props) => <TextField
                                    {...props}
                                    fullWidth
                                    variant="outlined"
                                />}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
              <Box mt={3}>
                <Card>
                  <CardHeader title="Promotion" />
                  <Divider />
                  <CardContent>
                    <Box mt={0} mb={1}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                          <InputLabel id="discount-method-label">Discount Method</InputLabel>
                          <Select
                              fullWidth
                              labelId="discount-method-label"
                              name="discountMethod"
                              id="discountMethod"
                              value={values.discountMethod}
                              onChange={handleChange}
                              label="Discount Method"
                          >
                            <MenuItem value="percentage_discount">Percentage Discount</MenuItem>
                            <MenuItem value="fixe_discount">Fixed Discount</MenuItem>
                            <MenuItem value="fixe_price">Fixed Price</MenuItem>
                          </Select>
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                        <TextField
                              type="number"
                              fullWidth
                              label="Quantity"
                              name="quantity"
                              onChange={handleChange}
                              required
                              value={values.quantity || ''}
                              variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                        <TextField
                              fullWidth
                              label="Promotion value"
                              name="value"
                              onChange={handleChange}
                              required
                              value={values.value || ''}
                              variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <TextField
                              fullWidth
                              label="Priority"
                              name="priority"
                              onChange={handleChange}
                              required
                              value={values.priority || ''}
                              variant="outlined"
                          />
                        </Grid>
                        </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box mt={0}>
                <Card className={classes.root}>
                  <CardHeader title="Organize" />
                  <Divider className={classes.mtop0} />
                  <CardContent className={classes.mtop0}>
                    <Box mt={0}>
                      <AsyncPaginate
                           isClearable={true}
                          loadOptions={getDivisions}
                          //value={edit? values.division: values.divisions}
                          value={values.division}
                          onChange={divisionsChange}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          placeholder="Division"
                          isMulti={!edit}
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                      />
                    </Box>
                    <Box mt={2}>
                      <AsyncPaginate
                          isClearable={true}
                          loadOptions={getGroups}
                          //value={edit ? values.group : values.user_groups}
                          value={values.userGroup}
                          onChange={groupsChange}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          placeholder="Groups"
                          isMulti={!edit}
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                      />
                    </Box>
                    <Box mt={2}>
                      <AsyncPaginate
                          isClearable={true}
                          loadOptions={getGroups}
                          onChange={excludedGroupsChange}
                          getOptionLabel={(option) => option.group ? option.group.name : option.name}
                          getOptionValue={(option) => option.id}
                          value={values.offerSpecialPriceExcludes}
                          placeholder="Excluded groups"
                          isMulti
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
              <Box mt={3}>
                <Card className={classes.root}>
                  <CardHeader title="Status" />
                  <Divider className={classes.mtop0} />
                  <CardContent className={classes.mtop0}>
                    <Box mt={0}>
                      <FormControlLabel
                          control={
                            <Checkbox
                                checked={values.active}
                                onChange={handleCheckChange}
                                value={values.active}
                                name="active"
                                size="small"
                            />
                          }
                          label="Active"
                      />
                    </Box>
                    <Box mt={0}>
                      <FormControlLabel
                          control={
                            <Checkbox
                                checked={values.allyear}
                                onChange={handleCheckChange}
                                value={values.allyear}
                                name="allyear"
                                size="small"
                            />
                          }
                          label="All Year"
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Box mt={3}>
            <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
            >
              Save details
            </Button>
          </Box>
        </form>
        <SnackBar
            open={open}
            message={message}
            severity={severity}
            handleClose={handleClose}
        />
        <LoadingApi open={loading} onClick={handleCloseLoading}/>
      </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,

};

export default Form;
