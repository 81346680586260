import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', show: true, sortable: true},
  {accessor: 'amount', name: 'amount',label: 'Amount', show: true, sortable: true},
  {accessor: 'user', label: 'User', show: true, type: 'concatString'},
  {accessor: 'user.phone', label: 'Phone', show: true},
  {accessor: 'user.division.name', label: 'Class', show: true, type: 'chip'},
  {accessor: 'offer.name', color: 'primary', label: 'Offer', show: true, type: 'chip'},
  {accessor: 'created_at', name: 'createdAt',label: 'Created At', show: true, sortable: true, type: 'date'},
  {accessor: 'admin_user.name', label: 'Administrator', show: true},
  {accessor: 'active', type: 'boolean', color: 'primary', label: 'Active', show: true,},
];
const fieldSearchable = [
  'id',
];
const fieldFilterable = [
  {name: 'user.id', label: 'User', type: 'userAutocomplete'},
  {name: 'user.division.id', label: 'Division', type: 'divisionAutocomplete'},
  {name: 'offer.id', label: 'Offer', type: 'offerAutocomplete'},
  {name: 'startDate', label: 'Start date', type: 'date'},
  {name: 'endDate', label: 'End date', type: 'date'},
];

const PaymentAgreementArchiveList = () => {

  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`payment-agreements/archived`}
          exportUrl={`payment-agreements/archived/export`}
          pageLink ={'/archives/payment-agreements'}
          searchMessage={'Search (ID)'}
          baseUrl={`payment-agreements/archived`}
          removeAdd={true}
          restore={true}
          noShow={true} noDelete={true} noEdit = {true} noDeleteMany={true}
      />
  );
};

export default PaymentAgreementArchiveList;
