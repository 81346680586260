import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '3%', show: true},
  {accessor: 'name', label: 'Name', width: '12%', show: true},
    {accessor: 'summary', name: 'Summary', label: 'summary', show: true, sortable: true,type: 'fieldValue'},
    {accessor: 'body', name: 'Body', label: 'body', show: false, sortable: true,},
    {accessor: 'active', type: 'boolean', color: 'primary', label: 'Active', show: true},
   {accessor: 'start_date', type: 'dateTime', label: 'Start Date', show: true},
   {accessor: 'message_links', label: 'Target', width: '13%', show: true, type: 'targetType'},
    {accessor: 'end_date', type: 'dateTime', label: 'End Date', show: true},
    {accessor: 'created_at', type: 'dateTime', label: 'Created At', show: true},
];
const fieldSearchable = [
  'name',
];
const fieldFilterable = [
    {name: 'createdAt', label: 'Created at', type: 'dateRange'},
    {name: 'messageLinks.group.id', label: 'Group', type: 'userGroupAutocomplete'},
    {name: 'messageLinks.division.id', label: 'Division', type: 'divisionAutocomplete'},
    {name: 'messageLinks.state.id', label: 'State', type: 'stateAutocomplete'},
    {name: 'active', label: 'Active', type: 'boolean'},
];
const MessageList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`messages`}
          noExport={true}
          pageLink ={'/marketing/messages'}
          searchMessage={'Search (Name)'}
          baseUrl={`messages`}
          noShow={true}
          deleteUrl={`delete/many-messages`}
          keyValue={"messages"}
      />
  );
};

export default MessageList;
