import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import SnackBar from '../SnackBar';
import {api_post} from "../../utils/Api";
import {TextField} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0,
    },
}));
const AddFreeOffer = (props) => {
    const {className, handleOffer, open, itemId,service,pageLink, initializeData, rowsPerPage, ...rest} = props;
    const classes = useStyles();
    const [openAlert, setOpenAlert] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [severity, setAlertSeverity] = React.useState('success');
    const [values, setValues] =  useState({
        user : itemId,
        group: '',

    });
    const handleClose = () => {
        setOpenAlert(false);
    };
    const handleChange = (event) => {
        setValues({
            user: itemId,
            group: event.target.value,
        });
    };
    const AddFreeOffer=()=>{
        if(service !== null) {
            api_post(`users/add-free-minutes`, values).then((data)=>{
                if(data?.code !== 200 && data?.code !== 201){
                    setOpenAlert(true);
                    let errorMessage = data?.message? data.message:'Technical error! Contact the Developers Team';
                    setAlertSeverity('warning')
                    setMessage(errorMessage)
                } else {
                    setOpenAlert(true);
                    setMessage('Success')
                    handleOffer();
                    initializeData(rowsPerPage);
                }
            });
        }
    };
    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Dialog
                open={open}
                onClose={handleOffer}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Trial/Trial+</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to give this user a free minutes?
                    </DialogContentText>
                    <TextField
                        autoFocus
                        select
                        required
                        margin="dense"
                        name="groupId"
                        label="Choose free offer"
                        value={values.group || ''}
                        onChange={handleChange}
                        fullWidth
                    >
                        <MenuItem key="trial" value="83">Trial</MenuItem>
                        <MenuItem key="trial+" value="84">Trial+</MenuItem>
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOffer} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={AddFreeOffer} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackBar
                open={openAlert}
                handleClose={handleClose}
                message={message}
                severity={severity}
            />
        </div>
    );
};

AddFreeOffer.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    handleRestore: PropTypes.func,
    itemId: PropTypes.number,
};

export default AddFreeOffer;
