import { Button } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { importIncomes } from 'slices/incomes';
import LoadingApi from './LoadingApi/LoadingApi';

const ImportIncomes = () => {

  const dispatch =useDispatch()
  const { status } = useSelector(
    (state) => state.incomes.importIncomes
  );

  const handleFileSelect = (event) => {
    event.preventDefault()
    dispatch(
      importIncomes({
        fileData: { file: event.target.files[0] },
      })
    ).then(event.target.value = null)
    
  };

    const uploadIncomes = () => {
        document.querySelector('#file').click();
      };
  return (
     <div  title="Importer sous format csv">
          <input
            name="file"
            type="file"
            onChange={handleFileSelect}
            id="file"
            hidden
            accept=".csv,.ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <Button variant="outlined" color="primary"  onClick={uploadIncomes}>
             import salary 
          </Button> 
        <LoadingApi importText={'Importation en cours...'} open={status==='loading'} />
          </div>
  )
}

export default ImportIncomes