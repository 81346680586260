import React from 'react';
import TableList from "../../components/List/TableList";

const columnsList = [
  {accessor: 'id', name: 'id', label: 'Id', width: '5%', show: true, sortable: true},
  {accessor: 'title', width: '25%', label: 'Title', show: true},
  {accessor: 'text', width: '60%', label: 'Text', show: true},
  {accessor: 'created_at', width: '10%', label: 'Created at', show: true, type:'date'},
];
const fieldSearchable= [
  'title',
];
const fieldFilterable = [
];
const SmsList = () => {
  return (
      <TableList
          columnsList={columnsList}
          fieldSearchable={fieldSearchable}
          fieldFilterable={fieldFilterable}
          url={`sms`}
          exportUrl={`sms/export`}
          pageLink ={'/marketing/sms'}
          searchMessage={'Search (Title)'}
          baseUrl={`sms`}
          noEdit={true}
          deleteUrl={`delete/many-sms`}
          keyValue={"sms"}
      />
  );
};

export default SmsList;
