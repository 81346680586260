import React from "react";
import TableList from "../../components/List/TableList";

const columnsList = [
  {
    accessor: "id",
    name: "id",
    label: "Id",
    width: "5%",
    show: true,
    sortable: true,
  },
  { accessor: "name", label: "Name", width: "30%", show: true },
  {
    accessor: "admin_groups",
    type: "simpleList",
    field: "name",
    color: "primary",
    label: "Admin Groups",
    width: "20%",
    show: true,
  },
  {
    accessor: "first_day_work",
    name: "first_day_work",
    type: "date",
    label: "First Day At Work",
    width: "15%",
    show: true,
    sortable: true,
  },
  { accessor: "phone", label: "Phone", width: "30%", show: true },
];
const fieldSearchable = ["id", "name"];
const fieldFilterable = [
  { name: "email", label: "Email", type: "text" },
  { name: "phone", label: "Phone", type: "text" },
  { name: "username", label: "Username", type: "text" },
  { name: "offices.id", label: "Office", type: "officeIsMultiAutocomplete" },
  { name: "firstDayWork", label: "First Day At Work", type: "dateRange" },
];
const AdminUserList = () => {
  return (
    <TableList
      columnsList={columnsList}
      fieldSearchable={fieldSearchable}
      fieldFilterable={fieldFilterable}
      url={`admin-users`}
      exportUrl={`admin-users/export`}
      pageLink={"/settings/admin-users"}
      searchMessage={"Search (ID, Name)"}
      baseUrl={`admin-users`}
      noShow={true}
    />
  );
};

export default AdminUserList;
