import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PersonOutline';
import Label from '../../../components/Label';
import {api_get} from "../../../utils/Api";

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const BasicsInfo = ({
                      parent,
                      child,
                      parentUserChild,
                      className,
                      ...rest
                    }) => {
  const classes = useStyles();

  const fullName=(firstName,lastName)=>{
    return `${firstName} ${lastName}`
  }
  return (
      <Card
          className={clsx(classes.root, className)}
          {...rest}
      >
        <CardHeader title="Approval Request info"/>
        <Divider/>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                ID
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {parentUserChild.id}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Parent
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                {fullName(parent.name,parent.last_name)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Children
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {fullName(child.name,child.last_name)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Approved
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary">
                   <Label color={parentUserChild.approved == '1' ? 'success' : 'error'}>
                    {parentUserChild.approved == true ? 'Yes' : 'No'}
                  </Label>   
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>

  );
};

BasicsInfo.propTypes = {
  className: PropTypes.string,
  parent: PropTypes.object,
  child: PropTypes.object,
  parentUserChild: PropTypes.object,
};

export default BasicsInfo;
