import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField, Tooltip,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import {api_get, api_put} from '../../../utils/Api';
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import moment from "moment";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Chip from "@material-ui/core/Chip";
const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "35%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
}));

const LevelsForm = (props) => {
  const {id} = useParams();
  const {className, division, ...rest} = props;
  const classes = useStyles();
  const [subjects, setSubjects] = useState([]);
  const [levels, setLevels] = useState([]);
  const [values, setValues] = useState({
    subjects: [],
    division,
    level: '',
    note: '',
  });
  const [open, setOpen] = useState(false);
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertseverity] = useState('');
  const [userLevels, setUserLevels] = useState(props.userLevels);

  useEffect(() => {
    api_get(`subjects?perPage=50&search=division.id:${division?.id}&searchJoin=and`).
        then(data => {
          setSubjects(data.payload);
        });
    api_get(`student-levels?perPage=50`).then(data => {
      setLevels(data.payload);
    });
    api_get(`users/${id}?search=studentLevelUsers&searchJoin=and`).
        then(data => {
        });
  }, []);

  const formatLevelSubjects = () => {
    const newArr = [];
    userLevels.map(userLevel => {
      newArr.push({
        'subject': userLevel.subject.id,
        'studentLevel': userLevel.student_level.id,
        'note': userLevel.note,
      });
    });
    return newArr;
  };
  const subjectsChange = (event, options) => {
    setValues({...values, 'subjects': options});
  };
  const levelsChange = (event, value) => {
    setValues({...values, 'level': value});
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertseverity('error');
        setAlertMessage(errors[0] ? errors[0] :errors[key][0]);
        setOpen(true);
      }
    });
  };

  const showAlertMessage = (message) => {
    setAlertseverity('error');
    setAlertMessage(message);
    setOpen(true);
  };

  const containsObject = (obj, list) => {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i]?.student_level?.id === obj?.student_level?.id && list[i]?.subject?.id === obj?.subject?.id) {
            return true;
        }
    }
    return false;
}
  const addLevel = () => {
    let levels = values.subjects.map(subject => {
      return {
        'student_level': values.level,
        'subject': subject,
        'note': values.note ? values.note : ''
      };
    });
    for (let i=0; i<userLevels.length ; i++) {
      if(!containsObject(userLevels[i], levels)){
        levels.push(userLevels[i])

      }
    }
    setUserLevels(levels);
    setValues({...values, 'level': '', 'subjects': [], 'note': ''});
  };
  const handleSubmit = () => {
      api_put(`users/${id}/update-levels-subjects`,
          {'studentLevelUsers': formatLevelSubjects()}).then(data => {
        if(data.errors){
          showErrorMessage(data.errors)

        }else if(data?.code !== 200 && data?.code !== 201){
          showAlertMessage(data?.message? data.message:'Technical error! Contact the Developers Team')
        } else {
          setAlertseverity('success');
          setAlertMessage('levels updated successfully');
          setOpen(true);
          setUserLevels(data.payload.student_level_users);
          setValues({});
        }
      });
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
      <div>
        <Card {...rest} className={clsx(classes.root, className)}>
          <form autoComplete="off" noValidate>
            <CardHeader subheader="The information can be edited"/>
            <Divider/>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                  <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={subjects}
                      getOptionLabel={(option) => option?.name}
                      value={values?.subjects || []}
                      filterSelectedOptions
                      onChange={subjectsChange}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              variant="outlined"
                              label="subjects"
                              placeholder="Select a subject"
                              name="subjects"
                          />
                      )}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <Autocomplete
                      id="tags-outlined"
                      name="level_id"
                      options={levels}
                      getOptionLabel={(option) => option?.name}
                      value={values?.level || ''}
                      filterSelectedOptions
                      onChange={levelsChange}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              variant="outlined"
                              label="levels"
                              placeholder="Select a level"
                          />
                      )}
                  />
                </Grid>
                <Grid item  md={3} xs={12}>
                  <TextField
                      fullWidth
                      name="note"
                      id="note"
                      variant="outlined"
                      label="note"
                      value={values?.note || ''}
                      onChange={handleChange}
                  />
                </Grid>
                <IconButton color="secondary" style={{backgroundColor: 'transparent'}}>
                  <AddCircleIcon onClick={addLevel}/>
                </IconButton>
                <Grid item md={8} xs={12}>
                  <Autocomplete
                      id="combo-box-demo"
                      multiple
                      disableCloseOnSelect
                      value={userLevels}
                      options={levels}
                      freeSolo
                      open={false}
                     getOptionLabel={(option) => option.subject?.name + ' | ' +
                          option?.student_level?.name + (option.note !== null ? ' | ' + option.note : '' )}
                      getOptionSelected={(
                          option, value) => value.student_level ===
                          option.id}
                      style={{width: 500}}
                      renderInput={(params, option) => (
                          <TextField {...params} label="user levels"
                                     variant="outlined" fullWidth/>
                      )}
                      onChange={(_, selectedOptions) => setUserLevels(
                          selectedOptions)}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                  color="primary"
                  variant="contained"
                  onClick={(event) => handleSubmit(event)}>
                Save details
              </Button>
            </CardActions>

              <Divider />
          <CardContent>
              <Grid container spacing={3}>
                {userLevels?.map((level, index) => {
                  return (
                      <Grid md={3} item>
                        <Box pt={2}>
                          <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                              <Typography style={{color: "green"}} className={classes.heading}>
                                {level.subject?.name}
                              </Typography>
                              <Typography className={classes.secondaryHeading}>
                                <span style={{ fontWeight: "bold", fontSize: "16px", padding: "5px", color:"orange" }}>Updated At</span>
                                {moment(level.updated_at)
                                    .format("YYYY-MM-DD")
                                    .toString()}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div style={{ flexDirection: "column" }}>
                                <Typography>
                                  <div className={classes.column} />
                                  <div className={classes.column}>
                                    <Chip label="Details" />
                                  </div>
                                  <div
                                      className={clsx(classes.column, classes.helper)}
                                  >
                                    <Typography variant="caption">
                                      <p className={classes.link}>
                                        {level.student_level?.name}
                                      </p>
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>
                                      Created at {moment(level.created_at)
                                          .format("YYYY-MM-DD")
                                          .toString()}
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>
                                      {level?.admin_user?.username}
                                    </Typography>
                                  </div>
                                </Typography>
                                <Typography>
                                  <div className={classes.column} />
                                  <div className={classes.column}>
                                    <Chip label="Note" />
                                  </div>
                                  <div className={clsx(classes.column, classes.helper)}>
                                    <Typography variant="caption">
                                      <p>{level.note}</p>
                                    </Typography>
                                  </div>
                                </Typography>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      </Grid>
                  );
                })}
              </Grid>
          </CardContent>
          </form>
        </Card>
        <SnackBar open={open} message={message} severity={severity} handleClose={handleClose}/>
      </div>
  );
};
LevelsForm.propTypes = {
  className: PropTypes.string,
};

export default LevelsForm;
