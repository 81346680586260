// @flow
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid, Paper,
  TextField, Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import {AsyncPaginate} from "react-select-async-paginate";
import theme from "../../../theme";
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import QuillEditor from "../../../components/QuillEditor";
import {DatePicker, LocalizationProvider} from '@material-ui/pickers';
import momentAdapter from '@material-ui/pickers/adapter/moment';
import Checkbox from "@material-ui/core/Checkbox";
import Moment from "moment";
import {api_get, api_post, api_put} from "../../../utils/Api";
import {getGroups, getContentTypes, getScopes} from "../../../helpers/methods";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    overflow: "visible",
  },
  mtop: {
    marginTop: 24,
  },
  mtop0: {
    marginTop: 0,
  },
  content: {
    padding: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
}));
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const Form = (props) => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
    active: 1,
    description: '',
    type_id: null,
    scope_id: null,
    groups: {},
    start_date: Moment().format('YYYY-MM-DD hh:mm'),
    end_date: Moment().format('YYYY-MM-DD hh:mm'),
  });
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');

  useEffect(() => {
    if (edit) {
      api_get(`permissions-rules/${id}`).then((result) => {
        const {data} = result;
        setValues({
          name: data.name,
          active: data.active,
          start_date:data.start_date,
          end_date:data.end_date,
          description:data.description,
          type_id:data.types,
          groups: data.groups,
          scope_id: data.scopes
        });
      });
    }
  }, []);
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const userGroupChange = (options) => {
    setValues({...values, groups: options});
  }
  const scopeChange = (value) => {
    setValues({...values, scope_id: value});
  };
  const typeChange = (value) => {
    setValues({...values, type_id: value});
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index === 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };
  const handleDescriptionChange = (value) => {
    values.description = value;
  };
  const startDateChange = (value) => {
    if(value){
      setValues({ ...values, start_date: value.format("YYYY-MM-DD hh:mm"), });
    }
  };
  const endDateChange = (value) => {
    if(value){
      setValues({ ...values, end_date: value.format("YYYY-MM-DD hh:mm"), });
    }
  };
  const handleCheckChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked,
    });
  };
  const handleSubmit = () => {
    const requestValues = mapStateToRequest(values);
    if (edit) {
      api_put(`permissions-rules/${id}`, requestValues).then((result) => {
        if (result.errors) {
          showErrorMessage(result.errors);
        } else {
          setAlertSeverity('success');
          setAlertMessage(' updated successfully');
          setOpen(true);
          window.location.href = '/acl/permission-rules';
        }
      });
    } else {
      api_post(`permissions-rules`, requestValues).then((result) => {
        if (result.errors) {
          showErrorMessage(result.errors);
        } else {
          setAlertSeverity('success');
          setAlertMessage('user created successfully');
          setOpen(true);
          window.location.href = '/acl/permission-rules';
        }
      }).catch((error) => {
        throw new Error(error);
      });
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
      <div {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Card className={classes.root}>
                <CardContent>
                  <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      onChange={handleChange}
                      required
                      value={values.name || ""}
                      variant="outlined"
                      size="small"
                  />
                  <Box mt={2} mb={1}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Description
                    </Typography>
                  </Box>
                  <Paper variant="outlined">
                    <QuillEditor
                        className={classes.editor}
                        value={values.description || ""}
                        modules={modules}
                        formats={formats}
                        onChange={(value) => handleDescriptionChange(value)}
                    />
                  </Paper>
                </CardContent>
              </Card>
              <Box mt={3}>
                <Card>
                  <CardHeader title="Dates" />
                  <Divider />
                  <CardContent>
                    <Box mt={0} mb={1}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                          <LocalizationProvider dateAdapter={momentAdapter}>
                            <DatePicker
                                label="Start Date"
                                name="start_date"
                                inputFormat="DD/MM/yyyy"
                                inputVariant="outlined"
                                value={values.start_date}
                                onChange={startDateChange}
                                renderInput={(props) => <TextField
                                    {...props}
                                    fullWidth
                                    variant="outlined"
                                />}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <LocalizationProvider dateAdapter={momentAdapter}>
                            <DatePicker
                                label="End Date"
                                name="end_date"
                                inputFormat="DD/MM/yyyy"
                                inputVariant="outlined"
                                value={values.end_date}
                                onChange={endDateChange}
                                renderInput={(props) => <TextField
                                    {...props}
                                    fullWidth
                                    variant="outlined"
                                />}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box mt={0}>
                <Card className={classes.root}>
                  <CardHeader title="Organize" />
                  <Divider className={classes.mtop0} />
                  <CardContent className={classes.mtop0}>
                    <Box mt={0}>
                      <AsyncPaginate
                          loadOptions={getGroups}
                          value={values.groups}
                          onChange={userGroupChange}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          placeholder="User group"
                          isMulti
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                      />
                    </Box>
                    <Box mt={2}>
                      <AsyncPaginate
                          isMulti
                          loadOptions={getContentTypes}
                          onChange={typeChange}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          value={values.type_id}
                          placeholder="Content types"
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                      />
                    </Box>
                    <Box mt={2}>
                      <AsyncPaginate
                          isMulti
                          loadOptions={getScopes}
                          onChange={scopeChange}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          value={values.scope_id}
                          placeholder="Scope"
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
              <Box mt={3}>
                <Card className={classes.root}>
                  <CardHeader title="Status" />
                  <Divider className={classes.mtop0} />
                  <CardContent className={classes.mtop0}>
                    <Box mt={0}>
                      <FormControlLabel
                          control={
                            <Checkbox
                                checked={values.active}
                                onChange={handleCheckChange}
                                value={values.active}
                                name="active"
                                size="small"
                            />
                          }
                          label="Active ?"
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Box mt={0}>
            <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
            >
              Save details
            </Button>
          </Box>
        </form>
        <SnackBar
            open={open}
            message={message}
            severity={severity}
            handleClose={handleClose}
        />
      </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
};

export default Form;
